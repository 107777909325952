import { Typography } from "@mui/material";

const PublicBanner = () => (
  <>
    <Typography variant="h3" sx={{ color: "white" }}>
      PUBLIC INFORMATION
    </Typography>
    <Typography variant="subtitle1" sx={{ color: "common.grayOpacity" }}>
      Other Foundy users can view these details
    </Typography>
  </>
);

export default PublicBanner;
