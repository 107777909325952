import React from "react";
import { SnackbarProvider, SnackbarKey } from "notistack";

export const NotificationService = {
  Ref: React.createRef<SnackbarProvider>(),

  Notify: (msg: string) => {
    NotificationService.Ref.current?.enqueueSnackbar(msg, {
      variant: "default",
    });
  },
  Info: (msg: string) => {
    NotificationService.Ref.current?.enqueueSnackbar(msg, { variant: "info" });
  },
  Success: (msg: string) => {
    NotificationService.Ref.current?.enqueueSnackbar(msg, {
      variant: "success",
    });
  },
  Warning: (msg: string) => {
    NotificationService.Ref.current?.enqueueSnackbar(msg, {
      variant: "warning",
    });
  },
  Error: (msg: string) => {
    NotificationService.Ref.current?.enqueueSnackbar(msg, { variant: "error" });
  },
  Close: (key: SnackbarKey) => {
    NotificationService.Ref.current?.closeSnackbar(key);
  },
};
