import { Box, Container } from "@mui/material";
import { MarketplaceHeader } from "../../components/MarketplaceHeader";
import { useState } from "react";
import { Companies } from "./components/Companies";
import { Buyers } from "./components/Buyers";
import { useAppContext } from "../../../common/context/AppContext";

export const Dashboard = () => {
  const { lastTab, updateTab } = useAppContext();
  const [activeTab, setActiveTab] = useState(lastTab);

  const handleTab = (tab: string) => {
    updateTab(tab);
    setActiveTab(tab);
  };
  return (
    <Box sx={{ backgroundColor: "common.lightgray", minHeight: "100%" }}>
      <MarketplaceHeader handleTab={handleTab} selectedTab={activeTab} />
      <Container
        style={{ position: "relative" }}
        disableGutters={activeTab === "buyers"}
        maxWidth={activeTab === "buyers" ? false : "lg"}
      >
        {activeTab === "companies" && <Companies />}
        {activeTab === "buyers" && <Buyers />}
      </Container>
    </Box>
  );
};
