import { LINKEDIN_CONVERSATION_ID } from "../constants";

declare global {
  interface Window {
    lintrk: (value?: string, obj?: { [key: string]: number }) => void;
  }
}

export const LinkedinService = {
  Track: () => {
    window.lintrk("track", { conversion_id: LINKEDIN_CONVERSATION_ID });
  },
};
