import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InfoIcon from "@mui/icons-material/Info";
import i18n from "../../../../common/utils/i18n";
import { useAppContext } from "../../../../common/context/AppContext";
import { BuyerService } from "../../../../common/services/BuyerService";
import { BuyerRoles } from "../../../../common/types";
import { HookedForm } from "../../../components/HookedForm";
import { HookedRadioGroup } from "../../../components/HookedRadioGroup";
import { RoutePaths } from "../../../consts/enums";
import { BUYER_ROLES } from "../../../../common/constants";
import { useCurrentBuyer } from "../../../../common/hooks";
import InvestorTypesInformation from "../InvestorTypesInformation";
import PublicBanner from "../PublicBanner";
import ProfileNavigationButtons from "../ProfileNavigationButtons";

const InvestorTypeOptions = [
  {
    value: "SOPHISTICATED_INVESTOR",
    label: "Sophisticated Investor",
  },
  {
    value: "HIGH_NET_WORTH_INVESTOR",
    label: "High Net Worth Investor",
  },
  {
    value: "PROFESSIONAL_INVESTOR",
    label: "Professional Investor",
  },
];

const options = BUYER_ROLES.map((role) => {
  const label = i18n.t(`userTypes.buyer.buyerType.${role}`);
  return {
    label,
    value: label,
  };
});

const schema = yup
  .object({
    role: yup.string().required().nullable().trim().label("Type of buyer"),
    buyer_type: yup
      .string()
      .nullable()
      .required()
      .trim()
      .label("Type of investor"),
  })
  .required();

const BuyerType = () => {
  const { user, updateUser } = useAppContext();
  const { data, isLoading } = useCurrentBuyer();
  const navigate = useNavigate();
  const formHook = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  const [investorInfoVisible, setInvestorInfoVisible] = useState(false);
  const toggleInvestorVisible = () =>
    setInvestorInfoVisible(!investorInfoVisible);

  const handleNext = (values: any) => {
    BuyerService.PatchBuyer({ ...values });
    updateUser({ ...user, buyerFull: { ...user.buyerFull, ...values } });
    navigate(RoutePaths.REGISTER_ACQUIRER + "/categories");
  };

  useEffect(() => {
    if (data && !isLoading) {
      formHook.reset({ ...data });
    }
  }, [data, isLoading]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "common.black",
          backgroundImage: "url(/images/BiggerGradient.png)",
          backgroundSize: "cover",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          width: "100%",
          minHeight: "100%",
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            padding: 4,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box>
            <PublicBanner />
            <Paper elevation={2} sx={{ marginTop: 4, padding: 3 }}>
              <Typography variant="h3" sx={{ marginBottom: 1 }}>
                {`${i18n.t("onboarding.typeOfBuyerQuestion")}`}
              </Typography>

              <HookedForm formHook={formHook}>
                <HookedRadioGroup
                  name="role"
                  options={options}
                  defaultValue={BuyerRoles.AcquisitionEntrepreneur as string}
                />
              </HookedForm>

              <Typography variant="h3" sx={{ marginBottom: 1, marginTop: 3 }}>
                What sort of investor are you?
              </Typography>
              <HookedForm formHook={formHook}>
                <HookedRadioGroup
                  name="buyer_type"
                  options={InvestorTypeOptions}
                />
              </HookedForm>
              <Stack
                onClick={toggleInvestorVisible}
                sx={{ color: "common.gray", cursor: "pointer" }}
                alignItems="center"
                direction="row"
                gap={2}
                marginTop={2}
              >
                <InfoIcon />
                <Typography variant="body2">
                  Learn more about each investor type
                </Typography>
              </Stack>
            </Paper>
            <ProfileNavigationButtons
              onSaveAndContinue={formHook.handleSubmit(handleNext)}
            />
          </Box>
        </Container>
      </Box>
      <InvestorTypesInformation
        open={investorInfoVisible}
        handleDialog={toggleInvestorVisible}
      />
    </>
  );
};

export default BuyerType;
