import { Avatar, Box, Typography } from "@mui/material";
import { getCompanyCategoryIcon } from "../../../common/models/mappers";
import { IStartup } from "../../../common/models";
import { useBusinessCategories, useStartupTypes } from "../../../common/hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const TinyListing = ({
  id,
  business_type,

  business_categories,
}: IStartup) => {
  const navigate = useNavigate();
  const [startupCategories, setStartupCategories] = useState<string[]>([]);
  const typeLabel = useStartupTypes().data?.find(
    (x) => x.id === Number(business_type),
  );
  const { data: businessCategories } = useBusinessCategories(String(id));
  const Icon = getCompanyCategoryIcon(Number(business_type));

  useEffect(() => {
    if (businessCategories) {
      setStartupCategories(
        businessCategories
          .filter((x) => business_categories?.includes(x.id))
          .map((category) => category.name),
      );
    }
  }, [businessCategories]);

  return (
    <Box
      sx={{ display: "flex", gap: 1, cursor: "pointer" }}
      onClick={() => navigate(`/company/${id}`)}
    >
      <Avatar
        variant="rounded"
        sx={{
          width: 40,
          height: 40,
          backgroundColor: "common.lightpurple",
          color: "#C62A8F",
        }}
      >
        <Box sx={{ height: 25 }}>
          <Icon />
        </Box>
      </Avatar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            letterSpacing: "0.01em",
            fontWeight: 700,
            fontSize: 14,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {business_type ? typeLabel?.name : ""}
          <br />
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontSize: 14,
            display: "flex",
            alignItems: "flex-start",
            textTransform: "capitalize",
            textAlign: "left",
          }}
        >
          {startupCategories}
        </Typography>
      </Box>
    </Box>
  );
};
