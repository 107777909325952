import {
  Box,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  useCompany,
  useHandlePageTraversal,
  useKeyAssets,
} from "../../../../common/hooks";
import { HookedForm } from "../../../components/HookedForm";
import { RoutePaths } from "../../../consts/enums";
import ProfileNavigationButtons from "../ProfileNavigationButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { HookedField } from "../../../components/HookedField";
import PublicBanner from "../PublicBanner";
import { HookedNumberField } from "../../../components/HookedNumberField";
import { HookedCheckbox } from "../../../components/HookedCheckbox";
import { LocationsOptions } from "../../../consts/consts";
import WelcomeSellerModal from "./WelcomeSellerModal";

const schema = yup
  .object({
    description: yup
      .string()
      .max(10000)
      .trim()
      .nullable()
      .required()
      .label("Business overview"),
    startup_team_size: yup
      .number()
      .min(1)
      .nullable()
      .required()
      .label("Number of team members"),
    operating_regions: yup
      .array()
      .min(1)
      .nullable()
      .required()
      .label("Operating regions"),
    key_assets_new: yup
      .array()
      .min(1)
      .nullable()
      .required()
      .label("Key assets"),
  })
  .required();

const BusinessInformation = () => {
  const { id: startupId } = useParams();
  if (startupId === undefined) {
    return <Navigate to="/dashboard" replace={true} />;
  }
  const navigate = useNavigate();

  const { data: keyAssets, isLoading: loadingAssets } = useKeyAssets();
  const { data, isLoading, isError } = useCompany(startupId);

  const formHook = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  const { handleNext } = useHandlePageTraversal(formHook, Number(startupId));

  useEffect(() => {
    if (data && !isLoading) {
      formHook.reset({ ...data });
    }
  }, [data, isLoading]);

  if (isError) {
    return <Navigate to="/dashboard" replace={true} />;
  }
  if (!data) return <CircularProgress />;

  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <WelcomeSellerModal />
      <Container maxWidth="md" sx={{ padding: 4 }}>
        <PublicBanner />
        <Paper elevation={2} sx={{ marginTop: 4, padding: 3 }}>
          <HookedForm formHook={formHook}>
            <Typography variant="h3" sx={{ marginBottom: 2 }}>
              Business Overview / Bio
            </Typography>
            <HookedField
              hiddenLabel
              fullWidth
              component={TextField}
              name="description"
              multiline
              maxCharacters={600}
              characterCounter
              variant="filled"
              minRows={8}
              helperText="All bios must be anonymous. The Foundy team reserves the right to remove personal or company identity from submissions."
              placeholder="Provide a brief overview of your company to best promote it to our
              buyer network"
            />
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              marginBottom={2}
              marginTop={3}
            >
              <Typography variant="h3" fontWeight={600} sx={{ width: "100%" }}>
                How many full time employees do you have?
              </Typography>
              <HookedNumberField
                hiddenLabel
                variant="outlined"
                name="startup_team_size"
              />
            </Stack>

            <Typography variant="h3" sx={{ marginTop: 2, marginBottom: 1 }}>
              What region does your business operate in?
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "common.gray", marginBottom: 2 }}
            >
              Select all that apply
            </Typography>
            <HookedCheckbox
              name="operating_regions"
              options={LocationsOptions}
            />

            <Typography variant="h3" sx={{ marginTop: 2, marginBottom: 1 }}>
              Key Assets
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "common.gray", marginBottom: 2 }}
            >
              Key assets are valuable items your company owns, creates, or
              benefits from that are attractive to potential acquirers.
            </Typography>
            <HookedCheckbox
              loading={loadingAssets}
              name="key_assets_new"
              options={keyAssets?.map((x) => ({
                value: x.value,
                label: x.display_name,
              }))}
            />
          </HookedForm>
        </Paper>

        <ProfileNavigationButtons
          onBack={() =>
            navigate(RoutePaths.REGISTER_SELLER + `/${startupId}/footprint`)
          }
          onSaveAndContinue={formHook.handleSubmit(() =>
            handleNext(
              RoutePaths.REGISTER_SELLER + `/${startupId}/financial-info`,
            ),
          )}
        />
      </Container>
    </Box>
  );
};

export default BusinessInformation;
