import {
  Container,
  Box,
  Grid,
  Button,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import { GradientTick } from "../../../common/assets/icons/GradientTick";
import { QuotesIcon } from "../../../common/assets/icons/QuotesIcon";
import { DisabledTick } from "../../../common/assets/icons/DisabledTick";
import { EventName, trackEvent } from "../../../common/utils/helpers";
import { RoutePaths } from "../../consts/enums";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../common/context/AppContext";

const items = [
  "Browse all listings",
  "Message business owners directly",
  "Unlock additional company info",
  "Free acquisition payment transfer",
];

const losingItems = [
  "Message business owners directly",
  "Unlock additional company info",
  "Free acquisition payment transfer",
];

const handleClick = (url?: string) => {
  window.open(url, "_blank");
};

export const BasicBuyerStep = ({
  selectedTab,
  handleTab,
}: {
  selectedTab: string;
  handleTab: (value: string) => void;
}) => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: 3 }}>
        <Grid container spacing={3}>
          <Grid item sm={12} lg={8} sx={{ padding: { xs: 0, md: 4 } }}>
            <Box
              sx={{
                transition: "ease-in-out 500ms",
                border: "1px solid gray",
                borderRadius: "100px",
                width: "100%",
                display: "flex",
                marginBottom: 3,
              }}
            >
              <Button
                sx={{
                  paddingX: { xs: 5, md: 10 },
                  fontSize: { xs: 12, md: 16 },
                  flex: "1 1 auto",
                }}
                variant={selectedTab === "pro" ? "roundgradient" : "roundlink"}
                onClick={() => handleTab("pro")}
              >
                Pro Buyer
              </Button>
              <Button
                sx={{
                  paddingX: { xs: 5, md: 10 },
                  fontSize: { xs: 12, md: 16 },
                  flex: "1 1 auto",
                }}
                variant={
                  selectedTab === "basic" ? "roundgradient" : "roundlink"
                }
                onClick={() => handleTab("basic")}
              >
                Basic Buyer
              </Button>
            </Box>
            <Typography variant="h2">
              Basic buyers cannot message business owners
            </Typography>
            <Typography variant="body2" marginTop={2}>
              To start messaging sellers and access other popular buyer perks,
              you will need to
              <br /> upgrade to a Pro Buyer membership
            </Typography>

            <Typography variant="h3" marginTop={4} fontSize={24}>
              <span>Are you sure you want to continue </span>
              <br />
              <span>as a Basic Buyer? You&apos;re losing out on:</span>
            </Typography>
            <Box
              component="ul"
              fontSize={16}
              fontWeight={500}
              sx={{
                padding: 0,
                listStyle: "none",
                marginTop: 2,
                "& li": {
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  textDecoration: "line-through",
                  color: "gray",
                  marginTop: 2,
                  "&:first-of-type": {
                    marginTop: 0,
                  },
                },
              }}
            >
              {losingItems.map((itm: any) => (
                <li key={itm}>
                  <Box sx={{ marginRight: 1, width: 32, height: 32 }}>
                    <DisabledTick />
                  </Box>
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    sx={{
                      color: "gray",
                      marginTop: 0.5,
                    }}
                  >
                    {itm}
                  </Typography>
                </li>
              ))}
            </Box>

            <Stack spacing={2} direction="row" marginTop={2}>
              <Button
                variant="roundprimary"
                sx={{ backgroundColor: "common.lightgray", color: "gray" }}
                onClick={() => {
                  trackEvent(EventName.BASIC_PRESS);
                  navigate(RoutePaths.DASHBOARD);
                }}
              >
                Continue as Basic Buyer
              </Button>
              <Button
                variant="roundgradient"
                onClick={() => {
                  trackEvent(EventName.STARTER_PRESS);
                  handleClick(
                    `https://securepay.foundy.com/b/3cs5nOgEQcLL20U8wT${
                      user.customer_key
                        ? "?client_reference_id=" + user.customer_key
                        : ""
                    }`,
                  );
                }}
              >
                Become a Pro Buyer
              </Button>
            </Stack>

            {/* <form id="payment-form" onSubmit={handleSubmit}>
  <LinkAuthenticationElement
    id="link-authentication-element"
    onChange={(
      e // @ts-ignore
    ) => setEmail(e.target?.value)}
  />
  <PaymentElement id="payment-element" options={paymentElementOptions} />
  <button disabled={isLoading || !stripe || !elements} id="submit">
    <span id="button-text">
      {isLoading ? (
        <div className="spinner" id="spinner"></div>
      ) : (
        "Pay now"
      )}
    </span>
  </button>
  {message && <div id="payment-message">{message}</div>}
</form> */}
          </Grid>
          <Grid item sm={12} lg={4}>
            <Box
              sx={{
                borderRadius: 3,
                backgroundColor: "common.lightgray",
                padding: 4,
              }}
            >
              <Typography variant="h2">Why Become a Pro Buyer?</Typography>
              <Box
                component="ul"
                fontSize={16}
                fontWeight={500}
                sx={{
                  padding: 0,
                  listStyle: "none",
                  marginTop: 2,
                  "& li": {
                    display: "inline-flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    marginTop: 2,
                    "&:first-of-type": {
                      marginTop: 0,
                    },
                  },
                }}
              >
                {items.map((itm: any) => (
                  <li key={itm}>
                    <Box sx={{ marginRight: 1, width: 32, height: 32 }}>
                      <GradientTick />
                    </Box>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      sx={{
                        color: "common.black",
                        marginTop: 0.5,
                      }}
                    >
                      {itm}
                    </Typography>
                  </li>
                ))}
              </Box>
              <Divider />
              <Box marginTop={3}>
                <QuotesIcon />
                <Typography
                  color="common.black"
                  fontSize={12}
                  sx={{ fontStyle: "italic" }}
                  fontWeight={500}
                >
                  Foundy has been the ideal partner. We are thrilled to
                  collaborate with their leading digital platform and team to
                  find acquisition opportunities and close deals together. My
                  company, Treasure Hunter, is a serial acquirer. We have over
                  30 content businesses in our portfolio.
                </Typography>
                <Box sx={{ textAlign: "right" }}>
                  <QuotesIcon />
                </Box>
                <Stack direction="row" spacing={2} marginTop={2}>
                  <Typography fontSize={12}>
                    Michael Fink, Founder & CEO of Treasure Hunter
                    <br />
                    30 businesses in their portfolio
                  </Typography>
                  <Box
                    component="img"
                    width={73}
                    height={73}
                    src="/images/Michael-Fink.png"
                  />
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
