export enum RouteType {
  "AUTHENTICATED" = "AUTHENTICATED",
  "PUBLIC" = "PUBLIC",
}

export enum RoutePaths {
  "LOGIN" = "/login",
  "REGISTER" = "/register",
  "DASHBOARD" = "/dashboard",
  "REGISTER_ACQUIRER" = "/register/buyer",
  "REGISTER_SELLER" = "/register/seller",
  "SETTINGS" = "/settings",
  "FORGOT_PASSWORD" = "/forgot-password",
  "RESET_PASSWORD" = "/password",
  "YOU_ACQUISITION" = "/your-acquisition",
  "CONNECTIONS" = "/messages",
  "PLANS" = "/plans",
}

export enum LabelsKeys {
  "STARTUP_TEAM_SIZE" = "startup_team_size",
  "ASKING_PRICE" = "asking_price",
  "ANNUAL_PROFIT" = "annual_profit",
  "ANNUAL_REVENUE" = "annual_revenue",
  "CLV" = "clv",
  "CREATED_AT" = "created_at",
  "ACQUISITION_TYPE" = "acquisition_type",
  "MIN_REVENGE_RANGE" = "minimum_revenge_range",
  "VALUATION" = "valuation",
  "STARTUP_TYPES" = "startup_types",
  "LOCATION" = "location",
  "DATE_FOUNDED" = "date_founded",
  "TTM_MULTIPLIER" = "ttm_multiplier",
  "TRAILING_REVENUE" = "trailing_revenue",
  "COUNTRY" = "country",
}
