import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
// import { useMemo } from "react";
import { DateTime } from "luxon";
import SBConversation from "@sendbird/uikit-react/Channel";
import SBChannelList from "@sendbird/uikit-react/ChannelList";
import MessageInput from "@sendbird/uikit-react/Channel/components/MessageInput";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { RoutePaths } from "../../consts/enums";
import { abbreviateNumber } from "../../../common/utils";
import MenuIcon from "@mui/icons-material/Menu";
import { ArrowBack } from "@mui/icons-material";
import "@sendbird/uikit-react/dist/index.css";
import { GroupChannel } from "@sendbird/chat/groupChannel";
import { ChatMateType, SBCustomMember } from ".";
import { IStartup, IStartupType } from "../../../common/models";
import { useMemo } from "react";

type MessagePropsType = {
  channelInformation?: GroupChannel;
  startUp?: IStartup;
  otherUser?: ChatMateType;
  otherUserSBInfo?: SBCustomMember;
  setChannel: (channel: GroupChannel) => void;
  dropAllChannelData: () => void;
  startupTypes?: IStartupType[];
  handleMobileMenu: () => void;
  blurred?: boolean;
};

export const MessageMobile = ({
  channelInformation: channel,
  startUp,
  otherUserSBInfo,
  otherUser,
  setChannel,
  dropAllChannelData,
  startupTypes,
  handleMobileMenu,
  blurred,
}: MessagePropsType) => {
  const navigate = useNavigate();
  const query = useMemo(() => {
    return {
      channelListQuery: {
        includeEmpty: true,
      },
    };
  }, []);

  return (
    <>
      <Header raw>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "white",
            minHeight: 88,
            zIndex: 20,
            position: "relative",
            boxShadow: "0px 1px 2px rgba(2, 17, 37, 0.12)",
          }}
        >
          <Box
            sx={{
              paddingLeft: 2,
              gridArea: "1 / 1 / 2 / 2",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h2">Connections</Typography>
          </Box>

          <Box>
            <Avatar
              onClick={() => navigate(RoutePaths.SETTINGS)}
              variant="circular"
              sx={{
                height: 40,
                width: 40,
                cursor: "pointer",
                display: { xs: "none", md: "inline-flex" },
              }}
            />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleMobileMenu}
              sx={{
                mr: { xs: 0, md: 2 },
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
      </Header>
      <Container disableGutters sx={{ height: "calc(100% - 88px)" }}>
        <Box
          component={SBChannelList}
          // FIXME: returns everything if the filter is empty, need to feed it something ludicrous to not return anything.
          // queries={
          //   isBasicPlan(user)
          //     ? {
          //         channelListQuery: {
          //           channelUrlsFilter: filteredChatsUrls?.length
          //             ? filteredChatsUrls
          //             : ["absolutelyNotValidUrl"],
          //         },
          //       }
          //     : undefined
          // }
          queries={query}
          disableAutoSelect={true}
          sx={{
            display: channel && channel.url ? "none" : "block",
            width: "100%",
            height: "100%",
            zIndex: 10,
            boxShadow: "10px -1px 10px rgba(0, 0, 0, 0.05)",
            ".sendbird-channel-preview": {
              borderBottom: 0,
              width: "100%",
            },
            ".sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper":
              {
                width: "auto",
              },
            ".sendbird-channel-preview__content__upper__header__total-members":
              { display: "none" },
            ".sendbird-channel-list__header": { display: "none" },
            ".sendbird-channel-header__right-icon": { display: "none" },
            ".sendbird-channel-preview__action": { display: "none" },
            ".sendbird-text-message-item-body": {
              background:
                "linear-gradient(99.95deg, #8E8EF2 -24.43%, #6911E7 100%) !important",
              filter: blurred ? "blur(3px)" : "none",
            },
            ".sendbird-channel-preview__content__lower": {
              filter: blurred ? "blur(3px)" : "none",
            },
          }}
          renderHeader={() => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            />
          )}
          allowProfileEdit={false}
          disableUserProfile
          onChannelSelect={(channel: GroupChannel) => {
            if (channel && channel.url) {
              setChannel(channel);
            }
          }}
        />
        <Box
          sx={{
            display: !channel ? "none" : "block",
            width: "100%",
            height: "100%",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
            "sendbird-admin-message": {
              background: "#F3F3F3",
              padding: 3,
            },
          }}
        >
          <SBConversation
            channelUrl={channel?.url || ""}
            renderUserProfile={() => {
              return (
                <Box sx={{ padding: 2 }}>
                  <Typography variant="h3" sx={{ fontWeight: 600 }}>
                    {otherUserSBInfo?.nickname}
                  </Typography>
                  <Divider sx={{ marginY: 1 }} />
                  {otherUserSBInfo?.metaData.user_type === "seller" && (
                    <>
                      <Typography variant="h3">
                        {startUp?.business_type && (
                          <span>{`${
                            startupTypes?.find(
                              (x: IStartupType) =>
                                x.id === Number(startUp?.business_type),
                            )?.name
                          } ${
                            startUp?.industry
                              ? `- ${startUp?.industry
                                  ?.replace(/_/g, " ")
                                  .toLocaleLowerCase()}`
                              : ""
                          }`}</span>
                        )}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ marginTop: 2, fontWeight: 600 }}
                      >
                        Description
                      </Typography>
                      <Typography variant="body2" fontWeight={400}>
                        {startUp?.foundy_says ?? startUp?.description}
                      </Typography>
                    </>
                  )}
                  {otherUser?.user_type !== "seller" && (
                    <>
                      <Typography variant="h3">
                        <span>{otherUser?.role}</span>
                      </Typography>
                      {otherUser?.valuation && (
                        <Typography variant="body2">
                          {`£ ${abbreviateNumber(
                            Number(otherUser?.valuation),
                          )}`}
                        </Typography>
                      )}
                      {otherUser?.bio && (
                        <>
                          <Typography
                            variant="body2"
                            sx={{ marginTop: 2, fontWeight: 600 }}
                          >
                            Bio
                          </Typography>
                          <Typography variant="body2" fontWeight={400}>
                            {otherUser.description}
                          </Typography>
                        </>
                      )}
                    </>
                  )}

                  <Button
                    onClick={() =>
                      navigate(
                        `/${
                          otherUserSBInfo?.metaData.user_type === "seller"
                            ? `company/${startUp?.id}`
                            : `buyer/${otherUser?.id}`
                        }`,
                      )
                    }
                    variant="roundgradient"
                    sx={{ marginTop: 2 }}
                  >
                    View Page
                  </Button>
                </Box>
              );
            }}
            renderMessageInput={() => (
              <Box
                sx={{
                  "& .sendbird-message-input": {
                    width: "93%",
                    zIndex: 0,
                    boxSizing: "border-box",
                    border: "1px solid transparent",
                    backgroundClip: "padding-box",
                    position: "relative",
                    borderRadius: 3.5,
                    "& .sendbird-message-input--textarea": {
                      border: 0,
                    },
                    "&:before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      zIndex: -1,
                      margin: "-1px",
                      borderRadius: "inherit",
                      background:
                        "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
                    },
                  },

                  height: 82,
                  paddingY: 2,

                  "& .sendbird-message-input .sendbird-message-input--textarea":
                    {
                      lineBreak: "anywhere",
                    },
                }}
              >
                <Box component={MessageInput} />
              </Box>
            )}
            renderChannelHeader={() => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  padding: 2,
                  boxShadow: "0px 1px 2px rgba(2, 17, 37, 0.12)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "common.gray",
                    fontSize: 12,
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                  onClick={() => dropAllChannelData()}
                >
                  <ArrowBack sx={{ width: 20 }} /> Back
                </Box>
                {channel && otherUser && channel.url && (
                  <Box>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Typography
                        onClick={() =>
                          navigate(
                            `/${
                              otherUserSBInfo?.metaData.user_type === "seller"
                                ? "company"
                                : "buyer"
                            }/${otherUserSBInfo?.metaData.profile_id}`,
                          )
                        }
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          color: "#0E1114",
                          cursor: "pointer",
                        }}
                      >
                        {otherUserSBInfo?.nickname}
                      </Typography>
                      <Typography
                        fontWeight={400}
                        fontSize={13}
                        variant="body2"
                        color="#616c76"
                      >
                        {!otherUserSBInfo?.lastSeenAt
                          ? ""
                          : `Last seen at ${DateTime.fromMillis(
                              otherUserSBInfo.lastSeenAt,
                            ).toFormat("dd LLL yyyy HH:mm")}`}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          />
        </Box>
      </Container>
    </>
  );
};
