import { Box, Container, Paper, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useCompany, useHandlePageTraversal } from "../../../../common/hooks";
import { HookedDatePicker } from "../../../components/HookedDatePicker";
import { HookedForm } from "../../../components/HookedForm";
import { RoutePaths } from "../../../consts/enums";
import PrivateBanner from "../PrivateBanner";
import ProfileNavigationButtons from "../ProfileNavigationButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import i18n from "../../../../common/utils/i18n";
import { HookedField } from "../../../components/HookedField";
import WelcomeSellerModal from "./WelcomeSellerModal";
import { DateTime } from "luxon";

const schema = yup
  .object({
    date_founded: yup
      .date()
      .max(new Date(), "The date cannot be in the future.")
      .typeError(
        i18n.t("error.required", {
          name: i18n.t("startupInfoAbout.startupDateFounded"),
        }),
      )
      .nullable()
      .required()
      .label(i18n.t("startupInfoAbout.startupDateFounded")),
    founder_linkedin: yup
      .string()
      .url()
      .nullable()
      .required()
      .max(600)
      .trim()
      .label("Founder’s LinkedIn"),
  })
  .required();

const BusinessFootprint = () => {
  const { id: startupId } = useParams();
  if (startupId === undefined) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  const navigate = useNavigate();
  const { data, isLoading, isError } = useCompany(startupId);

  const formHook = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  const { handleNext } = useHandlePageTraversal(formHook, Number(startupId));

  useEffect(() => {
    if (data && !isLoading) {
      formHook.reset({ ...data });
    }
  }, [data, isLoading]);

  if (isError) {
    return <Navigate to="/dashboard" replace={true} />;
  }
  if (!data) return <CircularProgress />;

  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <WelcomeSellerModal />
      <Container maxWidth="md" sx={{ padding: 4 }}>
        <PrivateBanner color="white" secondaryColor="common.grayOpacity" />
        <Paper elevation={2} sx={{ marginTop: 4, padding: 3 }}>
          <HookedForm formHook={formHook}>
            <Typography variant="h3" sx={{ marginBottom: 1 }}>
              Date founded / Date of incorporation
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "common.gray", marginBottom: 2 }}
            >
              When was your company founded?
            </Typography>
            <HookedDatePicker
              label="Data founded"
              name="date_founded"
              variant="filled"
              maxDate={DateTime.now()}
              fullWidth
            />

            <Typography variant="h3" sx={{ marginTop: 3, marginBottom: 1 }}>
              Founder&apos;s LinkedIn
            </Typography>
            <HookedField
              component={TextField}
              fullWidth
              label="Founder's LinkedIn"
              name="founder_linkedin"
              variant="filled"
            />
          </HookedForm>
        </Paper>

        <ProfileNavigationButtons
          onBack={() =>
            navigate(RoutePaths.REGISTER_SELLER + `/${startupId}/basic`)
          }
          onSaveAndContinue={formHook.handleSubmit(() =>
            handleNext(
              RoutePaths.REGISTER_SELLER + `/${startupId}/business-info`,
            ),
          )}
        />
      </Container>
    </Box>
  );
};

export default BusinessFootprint;
