export const Web3 = () => (
  <svg
    height="100%"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.85 51.2187C60.1384 48.8393 60.3547 46.4599 60.3547 44.0084C60.3547 41.5569 60.1384 39.1775 59.85 36.7981H72.0354C72.6122 39.1054 72.9727 41.5208 72.9727 44.0084C72.9727 46.4959 72.6122 48.9114 72.0354 51.2187M53.4689 71.2633C55.632 67.2616 57.2903 62.9354 58.444 58.429H69.0792C65.5864 64.4433 60.0451 68.9992 53.4689 71.2633ZM52.5676 51.2187H35.6955C35.335 48.8393 35.1187 46.4599 35.1187 44.0084C35.1187 41.5569 35.335 39.1414 35.6955 36.7981H52.5676C52.8921 39.1414 53.1444 41.5569 53.1444 44.0084C53.1444 46.4599 52.8921 48.8393 52.5676 51.2187ZM44.1315 72.7053C41.1393 68.3792 38.7238 63.5843 37.2457 58.429H51.0174C49.5393 63.5843 47.1238 68.3792 44.1315 72.7053ZM29.711 29.5878H19.1839C22.6407 23.5572 28.178 18.994 34.7582 16.7535C32.5951 20.7552 30.9728 25.0814 29.711 29.5878ZM19.1839 58.429H29.711C30.9728 62.9354 32.5951 67.2616 34.7582 71.2633C28.1918 68.998 22.6623 64.4413 19.1839 58.429ZM16.2277 51.2187C15.6509 48.9114 15.2904 46.4959 15.2904 44.0084C15.2904 41.5208 15.6509 39.1054 16.2277 36.7981H28.4131C28.1247 39.1775 27.9084 41.5569 27.9084 44.0084C27.9084 46.4599 28.1247 48.8393 28.4131 51.2187M44.1315 15.2754C47.1238 19.6015 49.5393 24.4324 51.0174 29.5878H37.2457C38.7238 24.4324 41.1393 19.6015 44.1315 15.2754ZM69.0792 29.5878H58.444C57.3156 25.1227 55.6448 20.8125 53.4689 16.7535C60.1023 19.0247 65.6182 23.6032 69.0792 29.5878ZM44.1315 7.95691C24.1951 7.95691 8.08008 24.1801 8.08008 44.0084C8.08008 53.5698 11.8783 62.7397 18.6393 69.5006C21.987 72.8483 25.9613 75.5038 30.3352 77.3156C34.7092 79.1273 39.3972 80.0598 44.1315 80.0598C53.693 80.0598 62.8628 76.2616 69.6238 69.5006C76.3848 62.7397 80.183 53.5698 80.183 44.0084C80.183 39.274 79.2505 34.586 77.4388 30.2121C75.627 25.8381 72.9715 21.8638 69.6238 18.5161C66.2761 15.1685 62.3018 12.5129 57.9278 10.7012C53.5539 8.88941 48.8659 7.95691 44.1315 7.95691Z"
      fill="url(#paint0_linear_2497_5111)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2497_5111"
        x1="11.6852"
        y1="7.95691"
        x2="91.0052"
        y2="28.4904"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C72A8E" />
        <stop offset="1" stopColor="#7815DA" />
      </linearGradient>
    </defs>
  </svg>
);
