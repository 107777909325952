import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import {
  IPrismicImage,
  IStartup,
  IStartupType,
  IUser,
} from "../../../common/models";
import { truncateText } from "../../../common/utils";
import { Link } from "react-router-dom";
import { HeartIcon } from "../../../common/assets/icons";
import { EventName, trackEvent } from "../../../common/utils/helpers";
import { PrismicImage } from "@prismicio/react";
import { MarketplaceItemButtonGroup } from "./MarketplaceItemButtonGroup";
import { FomoBadge } from "../FomoBadges";
import { LinkIcon } from "../../../common/assets/icons/LinkIcon";
import { NotificationService } from "../../../common/services/NotificationService";

const LABEL_KEYS = [
  "asking_price",
  "annual_revenue",
  "annual_profit",
  "ttm_multiplier",
];

export const MarketplaceItemDesktop = ({
  headline,
  disableFavorite,
  disableNewListingGlow,
  isHotListing,
  editLink,
  user,
  saveFavourite,
  typeLabel,
  isFavourite,
  categoryImage,
  description,
  controlled,
  getBusinessCategoryLabel,
  ...rest
}: IStartup & {
  disableFavorite?: boolean;
  disableNewListingGlow?: boolean;
  startupTypes?: IStartupType[];
  isHotListing?: boolean;
  editLink?: boolean;
  description: string | null;
  //
  user?: IUser;
  saveFavourite: (e: React.MouseEvent) => void;
  typeLabel?: IStartupType;
  isFavourite?: boolean;
  linkTo?: string;
  controlled?: boolean;
  categoryImage: IPrismicImage | null;
  getBusinessCategoryLabel: (id: number) => string;
}) => {
  const linkTo = editLink
    ? `/register/seller/${rest.id}/basic`
    : `/company/${rest.id}`;

  const scrollToElement = () => {
    const element = document.getElementById("listing-view-description-summary");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Box
      component={controlled ? Box : Link}
      sx={{ color: "inherit", borderRadius: 2 }}
      width={controlled ? "100%" : undefined}
      to={linkTo}
      onClick={() => {
        if (!controlled) {
          trackEvent(EventName.OPEN_HOT_LISTING, { label: String(rest.id) });
        }
      }}
    >
      <Box
        component="div"
        sx={
          controlled
            ? undefined
            : {
                cursor: "pointer",
                background: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "common.lightgray",
                borderRadius: 2,
                // padding: 2,
                transition: "200ms ease-in-out",
                boxShadow:
                  rest?.is_new_listing !== true ||
                  user?.active_role !== "buyer" ||
                  disableNewListingGlow
                    ? "0px 1px 2px rgba(0, 0, 0, 0.05)"
                    : "0px 0px 5px rgba(203, 44, 138, 10) !important",
                "&:hover": {
                  boxShadow:
                    rest?.is_new_listing !== true ||
                    user?.active_role !== "buyer" ||
                    disableNewListingGlow
                      ? "0px 0px 10px #c3c3c3"
                      : "0px 0px 10px rgba(203, 44, 138, 20) !important",
                  transform: "scale(1.01)",
                },
                position: "relative",
              }
        }
      >
        <Grid
          container
          height={282}
          direction="row"
          sx={{ borderRadius: 2 }}
          justifyContent="space-between"
        >
          <Grid item borderRadius={2} display="flex" className="ListingImage">
            {!categoryImage ? (
              <CircularProgress />
            ) : (
              // @ts-ignore
              <PrismicImage
                style={{ borderRadius: 2 }}
                field={categoryImage}
                width={323}
                height={282}
                widths={[600]}
                imgixParams={{
                  // fit: "crop",
                  // crop: ["left", "right"],
                  // 1.145 ratio
                  rect: [
                    "center",
                    "middle",
                    categoryImage.dimensions.height,
                    categoryImage.dimensions.height,
                  ],
                  cornerRadius: 8,
                  mask: "corners",
                }}
              />
            )}
          </Grid>
          <Grid
            item
            md={8}
            className="ListingDataArea"
            display="flex"
            p={controlled ? undefined : 3}
            pl={1}
            direction="column"
            gap={1}
            justifyContent="space-between"
          >
            <Grid container direction="row" justifyContent="space-between">
              <Grid
                item
                className="HeadersElement"
                direction="column"
                display="flex"
              >
                <Box
                  flexDirection="row"
                  display="flex"
                  alignItems="center"
                  gap={1}
                  py={1}
                >
                  <Typography
                    variant="h2"
                    gap={2}
                    fontWeight={600}
                    lineHeight={1}
                    alignItems="center"
                    sx={{
                      letterSpacing: "0.01em",
                      display: "flex",
                    }}
                  >
                    {rest.business_type ? typeLabel?.name : headline}
                  </Typography>
                  {rest.business_categories &&
                  rest.business_categories.length ? (
                    <Typography
                      variant="h2"
                      sx={{
                        display: "flex",
                        fontWeight: 30,
                      }}
                    >
                      |
                    </Typography>
                  ) : null}
                  {rest.business_categories &&
                  rest.business_categories.length ? (
                    <>
                      <Typography
                        variant="h2"
                        sx={{
                          letterSpacing: "0.01em",
                          display: "flex",
                          fontWeight: 500,
                        }}
                      >
                        {getBusinessCategoryLabel(rest.business_categories[0])}
                      </Typography>
                      {rest.business_categories.length > 1 ? (
                        <Box
                          flexDirection="row"
                          justifyContent="center"
                          height={1}
                          display="flex"
                          px={1}
                          py={0.5}
                          bgcolor="#7f7d851a"
                          borderRadius={3}
                        >
                          <Typography
                            color="#7F7D85"
                            textAlign="center"
                            lineHeight={2}
                            sx={{
                              fontSize: "12px",
                              display: "flex",
                              fontWeight: 600,
                            }}
                          >
                            +{rest.business_categories.length - 1} more
                          </Typography>
                        </Box>
                      ) : null}
                    </>
                  ) : null}

                  <Box
                    sx={{
                      paddingX: "8px",
                      background: "#F7E9F6",
                      borderRadius: 1,
                      color: "#A321B0",
                      fontSize: 10,
                      fontWeight: 700,
                    }}
                  >
                    #{`${rest.id}${rest.created_at?.substring(0, 2)}`}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    py: 0.6,
                    alignItems: "center",
                  }}
                  my={0.6}
                  height={2}
                >
                  {isHotListing && (
                    <Box
                      component="p"
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        paddingX: "8px",
                        paddingY: "4px",
                        backgroundColor: "rgba(255, 99, 58, 0.1)",
                        borderRadius: 1,
                        color: "#FD2F02",
                        fontSize: 10,
                        fontWeight: 700,
                      }}
                    >
                      🔥 Hot Listing
                    </Box>
                  )}
                  <FomoBadge
                    startupId={rest.id}
                    isUnderOffer={rest.under_offer}
                  />
                </Box>
              </Grid>
              <Grid
                item
                display="flex"
                alignItems="flex-start"
                justifyContent={{ xs: "center", md: "flex-end" }}
                sx={{
                  gap: 2,
                }}
              >
                <Button
                  variant="roundlink"
                  sx={{
                    position: "relative",
                    zIndex: 3,
                    width: 40,
                    height: 40,
                    minWidth: "inherit",
                    backgroundColor: "common.lightgray",
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}${linkTo}`,
                    );
                    NotificationService.Success("Copied to clipboard.");
                  }}
                >
                  <Box sx={{ width: 40, height: 20 }} flexDirection="column">
                    <LinkIcon />
                    <Typography
                      align="center"
                      sx={{
                        color: "common.black",
                        fontWeight: 500,
                        fontSize: "8px",
                        mt: 0.5,
                        textTransform: "none",
                      }}
                    >
                      Copy Link
                    </Typography>
                  </Box>
                </Button>
                {!disableFavorite && user?.id ? (
                  <Button
                    variant="roundlink"
                    onClick={(e) => saveFavourite(e)}
                    sx={{
                      position: "relative",
                      zIndex: 3,
                      width: 40,
                      height: 40,
                      minWidth: "inherit",
                      backgroundColor: "common.lightgray",
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: 40, height: 20 }} flexDirection="column">
                      <HeartIcon
                        color={isFavourite ? "#A321B0" : "white"}
                        filled={isFavourite ? true : false}
                      />
                      <Typography
                        align="center"
                        sx={{
                          color: isFavourite ? "#A321B0" : "common.black",
                          fontWeight: 500,
                          fontSize: "8px",
                          mt: 0.5,
                          textTransform: "none",
                        }}
                      >
                        {isFavourite ? "Remove from" : "Add to"} {"favourites"}
                      </Typography>
                    </Box>
                  </Button>
                ) : null}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item md={10} mb={1}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "common.gray",
                    fontSize: "12px",
                    textAlign: { md: "left" },
                  }}
                >
                  {/* A profitable B2B analytics platform that has an extensive
                  range of ecommerce merchants paying a recurring subscription
                  for use of their digital tools that help improve customer
                  conversions. */}
                  {rest.foundy_says ? (
                    <Box>
                      {controlled
                        ? truncateText(rest.foundy_says, 500)
                        : truncateText(rest.foundy_says, 200)}
                      &nbsp;
                      {controlled ? (
                        <Link to="#" onClick={scrollToElement}>
                          Read More
                        </Link>
                      ) : null}
                    </Box>
                  ) : (
                    <Box>
                      {controlled
                        ? truncateText(description as string, 500)
                        : truncateText(description as string, 180)}
                      &nbsp;
                      {controlled ? (
                        <Link to="#" onClick={scrollToElement}>
                          Read More
                        </Link>
                      ) : null}
                    </Box>
                  )}
                </Typography>
              </Grid>
            </Grid>
            {!controlled && (
              <Grid container spacing={2}>
                <MarketplaceItemButtonGroup
                  startUpData={rest}
                  buttonLabels={LABEL_KEYS}
                  teamSize
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
