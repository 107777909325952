export const GradientTick = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.432617"
      width="32"
      height="32"
      rx="16"
      fill="url(#paint0_linear_2550_5201)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2488 12.207C22.5027 12.4737 22.4923 12.8957 22.2256 13.1495L14.5219 20.4829C14.3904 20.6081 14.2134 20.6742 14.0319 20.666C13.8505 20.6577 13.6803 20.5758 13.5606 20.4391L10.5977 17.0545C10.3552 16.7775 10.3831 16.3563 10.6602 16.1138C10.9372 15.8713 11.3584 15.8992 11.6009 16.1763L14.106 19.0379L21.3063 12.1838C21.573 11.9299 21.995 11.9403 22.2488 12.207Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2550_5201"
        x1="2.03262"
        y1="-1.64226e-06"
        x2="37.2356"
        y2="9.11297"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C72A8E" />
        <stop offset="1" stopColor="#7815DA" />
      </linearGradient>
    </defs>
  </svg>
);
