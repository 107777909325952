import React from "react";

export type ReactComponent<Props> = (
  props: Props,
) => React.ReactNode | React.Component<Props>;

export enum CommonPath {
  SignIn = "/sign-in",
  SignUp = "/sign-up",
  Recover = "/recover",
  Password = "/password",
  Blocked = "/blocked",
  Verification = "/verification/",
  Verify = "/confirm",
}

export enum UserType {
  Seller = "seller",
  Buyer = "buyer",
  Manager = "manager",
}

export enum UserTypeSelect {
  Individual = "INDIVIDUAL",
  INSTITUTIONAL = "INSTITUTIONAL",
}

export enum StartupStatus {
  Pending = "pending",
  Approved = "approved",
  Disapproved = "disapproved",
}

export enum CompanyStatus {
  Incompleted = "Incomplete",
  Pending = "Pending",
  ApprovedPublished = "Published",
  Unpublished = "Not published",
  Disapproved = "Disapproved",
  ApprovedUnpublished = "Approved",
}

export enum BuyerStatus {
  Pending = "pending",
  Approved = "approved",
  Disapproved = "disapproved",
}

export enum AcquisitionType {
  Full = "Full",
  Majority = "Majority",
  Minority = "Minority",
}

export enum StartupSorting {
  DateAsc = "date",
  DateDesc = "-date",
  ProfitAsc = "profit",
  ProfitDesc = "-profit",
  PriceAsc = "price",
  PriceDesc = "-price",
  EmployeesAsc = "size",
  EmployeesDesc = "-size",
  PriorityAsc = "priority",
}

export enum BuyerSorting {
  DateAsc = "date",
  DateDesc = "-date",
  ValuationAsc = "valuation",
  ValuationDesc = "-valuation",
}

export enum RequestStatus {
  Pending = "pending",
  Approved = "approved",
  Rejected = "disapproved",
  Canceled = "canceled",
}

export enum ConversationClientEvents {
  ConversationJoined = "conversationJoined",
  TokenAboutToExpire = "tokenAboutToExpire",
}

export enum ConversationEvents {
  MessageAdded = "messageAdded",
  MessageRemoved = "messageRemoved",
  Updated = "updated",
  TypingStarted = "typingStarted",
  TypingEnded = "typingEnded",
}

export enum AllowedFileType {
  Image = "image",
  Video = "video",
  Pdf = "pdf",
  Docx = "docx",
  Doc = "doc",
}

export type BoolRecord = Record<string, boolean>;
export type ValueObj = {
  value: string;
};

export enum ServerErrors {
  TokenNotValid = "token_not_valid",
}

export enum MarketplaceType {
  Buyer,
  Startup,
}

export enum DealStatus {
  Pending = "pending",
  Approved = "approved",
  Disapproved = "disapproved",
  Cancelled = "cancelled",
}

export enum BuyerRoles {
  AcquisitionEntrepreneur = "acquisitionEntrepreneur",
  CorporateDevelopment = "corporateDevelopment",
  VentureCapital = "ventureCapital",
  PrivateEquity = "privateEquity",
  FundManager = "fundManager",
  Angel = "angel",
  Other = "other",
}

export type HearFromType = {
  hear_about_us?: string;
  other?: string;
  referred?: string;
};
