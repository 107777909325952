import { useMediaQuery } from "@mui/material";

import { useAppContext } from "../../../../common/context/AppContext";
import { useNavigate } from "react-router-dom";
import {
  useBuyerListingsCountUnviewed,
  useFavourites,
} from "../../../../common/hooks";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { IStartupsParams } from "../../../../common/services/StartupService";
import { IStartupType } from "../../../../common/models";
import { MarketplaceFilterComponent } from "../../../components/MarketplaceFilter";

export const CompaniesFilter = ({
  formHook,
  handleSearch,
  handleFilter,
  handleClearFilter,
  startupTypes,
  handleShowFavourites,
  showFavourites,
  filter,
  showNewListings,
  setShowNewListings,
}: {
  formHook: UseFormReturn<IStartupsParams, any>;
  handleSearch: () => void;
  handleFilter: (v: any) => void;
  handleClearFilter: () => void;
  startupTypes: IStartupType[];
  handleShowFavourites: () => void;
  showFavourites: boolean;
  filter: IStartupsParams;
  showNewListings: boolean;
  setShowNewListings: (value: boolean) => void;
}) => {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const { user } = useAppContext();
  const navigate = useNavigate();

  const { data: favourites } = useFavourites(user);
  const { data: buyerListingsCountUnviewed } =
    useBuyerListingsCountUnviewed(user);

  const handleShowNewListings = () => {
    formHook.setValue("is_not_viewed", !showNewListings);
    setShowNewListings(!showNewListings);
    handleFilter({ ...formHook.getValues(), is_not_viewed: !showNewListings });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const proBanner = document.getElementById("pro-member-banner-form");
      const dashboardFilters = document.getElementById("dashboard-filters");

      if (window.scrollY >= 150) {
        dashboardFilters?.classList.add("fixed-header");
        proBanner?.classList.remove("hidden");

        if (matches) {
          proBanner?.style.setProperty("width", "100%");
          proBanner?.style.setProperty("position", "fixed");
          proBanner?.style.setProperty("top", "0");
          proBanner?.style.setProperty("left", "0");
          proBanner?.style.setProperty("z-index", "999");
        }
      } else {
        dashboardFilters?.classList.remove("fixed-header");
        proBanner?.classList.add("hidden");

        if (matches) {
          proBanner?.style.setProperty("position", "block");
        }
      }
    });
  }, []);

  return (
    <MarketplaceFilterComponent
      {...{
        formHook,
        handleSearch,
        handleClearFilter,
        handleShowFavourites,
        handleShowNewListings,
        showFavourites,
        showNewListings,
        filter,
        buyerListingsCountUnviewed,
        favourites,
        startupTypes,
        user,
        navigate,
      }}
    />
  );
};
