interface SVGIconProps {
  color?: string;
}
export const WorkIcon = ({ color = "black" }: SVGIconProps) => (
  <svg
    viewBox="0 0 17 15"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.48145 7.25098C9.37467 7.25098 10.2542 7.20312 11.1201 7.10742C11.986 7.00716 12.8428 6.85221 13.6904 6.64258C14.5381 6.42839 15.3766 6.14811 16.2061 5.80176V5.30273C16.2061 4.57812 16.0215 4.03353 15.6523 3.66895C15.2832 3.30436 14.734 3.12207 14.0049 3.12207H12.0566V2.47949C12.0566 1.83236 11.8857 1.35384 11.5439 1.04395C11.2067 0.729492 10.7168 0.572266 10.0742 0.572266H6.875C6.25977 0.572266 5.77669 0.729492 5.42578 1.04395C5.07487 1.35384 4.89941 1.83236 4.89941 2.47949V3.12207H2.94434C2.21973 3.12207 1.67057 3.30436 1.29688 3.66895C0.927734 4.03353 0.743164 4.57812 0.743164 5.30273V5.80176C1.57715 6.14811 2.41569 6.42839 3.25879 6.64258C4.10645 6.85221 4.96322 7.00716 5.8291 7.10742C6.69954 7.20312 7.58366 7.25098 8.48145 7.25098ZM6.14355 3.12207V2.47949C6.14355 2.23796 6.20964 2.05111 6.3418 1.91895C6.47852 1.78223 6.6722 1.71387 6.92285 1.71387H10.0332C10.2839 1.71387 10.4775 1.78223 10.6143 1.91895C10.751 2.05111 10.8193 2.23796 10.8193 2.47949V3.12207H6.14355ZM2.94434 14.1484H14.0049C14.734 14.1484 15.2832 13.9661 15.6523 13.6016C16.0215 13.237 16.2061 12.6947 16.2061 11.9746V7.08691C15.5179 7.34212 14.848 7.55404 14.1963 7.72266C13.5446 7.89128 12.902 8.02572 12.2686 8.12598C11.6351 8.22168 11.0016 8.29232 10.3682 8.33789V8.88477C10.3682 9.41797 10.0924 9.68457 9.54102 9.68457H7.4082C6.86133 9.68457 6.58789 9.41797 6.58789 8.88477V8.33789C5.95443 8.29232 5.32096 8.22168 4.6875 8.12598C4.05404 8.02572 3.41146 7.89128 2.75977 7.72266C2.10807 7.55404 1.43587 7.34212 0.743164 7.08691V11.9746C0.743164 12.6947 0.927734 13.237 1.29688 13.6016C1.67057 13.9661 2.21973 14.1484 2.94434 14.1484Z"
      fill={
        color
          ? color === "bluegradient"
            ? "url(#paint0_linear_586_2224)"
            : color === "purplegradient"
              ? "url(#paint0_linear_586_2224)"
              : color
          : "black"
      }
    />
    <defs>
      {color === "purplegradient" && (
        <linearGradient
          id="paint0_linear_586_2224"
          x1="0.2"
          y1="-4"
          x2="26.6022"
          y2="2.83473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C72A8E" />
          <stop offset="1" stopColor="#7815DA" />
        </linearGradient>
      )}
      {color === "bluegradient" && (
        <linearGradient
          id="paint0_linear_586_2224"
          x1="-7.89109"
          y1="-5"
          x2="26.1628"
          y2="0.975647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E8EF2" />
          <stop offset="1" stopColor="#6911E7" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
