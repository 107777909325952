import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppContext } from "../../../common/context/AppContext";
import { isBasicPlan } from "../../../common/utils";
import { PlansModal } from "../PlansModal";
import LockIcon from "@mui/icons-material/Lock";
import MenuIcon from "@mui/icons-material/Menu";
import { RoutePaths } from "../../consts/enums";
import { EventName, trackEvent } from "../../../common/utils/helpers";
import { Close } from "@mui/icons-material";
import { JOE_CALENDLY_LINK } from "../../consts/consts";

interface MarketplaceHeaderProps {
  handleTab: (tab: string) => void;
  selectedTab: string;
}

const handleClick = (url?: string) => {
  trackEvent(EventName.INTERESTED_ADVISORY);
  window.open(url, "_blank");
};

export const MarketplaceHeader = ({
  handleTab,
  selectedTab = "companies",
}: MarketplaceHeaderProps) => {
  const [advisoryBanner, setAdvisoryBanner] = useState(false);
  const { showModal } = useModal();
  const navigate = useNavigate();
  const { user, handleMobileMenu } = useAppContext();

  const confirmationModal = () => {
    const modal = showModal(PlansModal, {
      onCancel: () => {
        modal.hide();
      },
    });
  };

  const handleAdvisoryBanner = () => {
    localStorage.setItem(
      "advisoryBanner",
      JSON.stringify({ show: false, dismissTime: DateTime.now().toJSDate() }),
    );
    setAdvisoryBanner(false);
  };

  useEffect(() => {
    if (user.active_role !== "seller") setAdvisoryBanner(false);

    const check = localStorage.getItem("advisoryBanner");
    if (!check) {
      localStorage.setItem(
        "advisoryBanner",
        JSON.stringify({ show: true, dismissTime: undefined }),
      );
      setAdvisoryBanner(true);
    } else {
      const parsed = JSON.parse(check);
      if (parsed && parsed.dismissTime) {
        if (DateTime.fromJSDate(parsed.dismissTime).diffNow().hours > 24) {
          setAdvisoryBanner(true);
        } else {
          setAdvisoryBanner(false);
        }
      } else {
        setAdvisoryBanner(true);
      }
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          minHeight: 88,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingX: 3,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: "common.lightgray",
          background: "white",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.025)",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => handleTab("companies")}
            sx={{ fontSize: { xs: 12, md: 16 } }}
            variant={
              selectedTab === "companies" ? "roundgradient" : "roundprimary"
            }
          >
            Companies for sale
          </Button>
          <Button
            onClick={() => handleTab("buyers")}
            sx={{ fontSize: { xs: 12, md: 16 } }}
            variant={
              selectedTab === "buyers" ? "roundgradient" : "roundprimary"
            }
          >
            Buyers
          </Button>
        </Stack>
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          {user.subscription_plan === ("starter" || "advanced") && (
            <Box
              sx={{
                paddingX: 1,
                paddingY: 0.5,
                color: "white",
                fontWeight: 500,
                fontSize: 14,
                background:
                  "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
                borderRadius: 30,
              }}
            >
              Pro
            </Box>
          )}

          <Avatar
            onClick={() => navigate(RoutePaths.SETTINGS)}
            variant="circular"
            sx={{
              height: 40,
              width: 40,
              cursor: "pointer",
              display: { xs: "none", md: "inline-flex" },
            }}
          />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleMobileMenu}
            sx={{ mr: { xs: 0, md: 2 }, display: { xs: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Box>

      {isBasicPlan(user) && user.active_role === "buyer" && (
        <Box
          sx={{
            background: "#6911E7",
            color: "white",
            padding: 1,
            fontWeight: 500,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LockIcon sx={{ marginRight: 1 }} />
          <Typography
            variant="body1"
            sx={{
              display: { xs: "none", md: "block" },
              fontSize: { xs: 12, md: "1rem" },
            }}
          >
            To message sellers and access other popular benefits please upgrade
            to a Pro Buyer member account
          </Typography>
          <Typography
            variant="body1"
            sx={{
              display: { xs: "block", md: "none" },
              fontSize: { xs: 12, md: "1rem" },
            }}
          >
            Message sellers today!
          </Typography>
          <Button
            onClick={() => confirmationModal()}
            variant="roundprimary"
            sx={{ marginLeft: 2, height: 32, fontSize: { xs: 10, md: 14 } }}
          >
            Become a Pro Member
          </Button>
        </Box>
      )}

      {advisoryBanner && user.active_role === "seller" && (
        <Box
          sx={{
            background:
              "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
            padding: 1,
            display: "grid",
            gridTemplateColumns: "1fr 0.4fr",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            position: "relative",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 600, color: "white" }}>
            Interested in learning how we can find you relevant prospects and
            maximise deal success?
          </Typography>
          <Button
            onClick={() => {
              handleAdvisoryBanner();
              handleClick(JOE_CALENDLY_LINK);
            }}
            sx={{ height: { xs: "auto", md: 25 }, fontSize: 14 }}
            variant="roundprimary"
          >
            Learn more
          </Button>
          <Close
            onClick={() => handleAdvisoryBanner()}
            sx={{
              cursor: "pointer",
              color: "white",
              position: "absolute",
              right: 10,
            }}
          />
        </Box>
      )}
    </>
  );
};
