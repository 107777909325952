import { Breakpoint, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ReactNode } from "react";

interface DialogProps {
  handleState: () => void;
  maxWidth?: Breakpoint;
  title?: ReactNode;
  children?: any;
  isOpen?: boolean;
}

export const DialogBase = ({
  maxWidth = "lg",
  title,
  children,
  isOpen = false,
  handleState,
}: DialogProps) => {
  return (
    <Dialog fullWidth maxWidth={maxWidth} open={isOpen} onClose={handleState}>
      {title && <DialogTitle></DialogTitle>}
      <DialogContent sx={{ position: "relative", zIndex: 1, padding: 5 }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};
