import { Grid } from "@mui/material";
import { LabelsKeys } from "../../consts/enums";
import { MarketplaceItemDetail } from "../MarketplaceItemDetail";
import { LabelsKeysMap } from "../../consts/consts";
import { DateTime } from "luxon";
import { abbreviateNumber } from "../../../common/utils";

export const MarketplaceItemButtonGroup = ({
  startUpData,
  buttonLabels,
  teamSize,
}: any) => {
  return (
    <>
      {buttonLabels.map((x: string) => {
        let value;
        if ([LabelsKeys.CREATED_AT].includes(x as LabelsKeys))
          // @ts-ignore
          value = !startUpData[x]
            ? "" // @ts-ignore
            : DateTime.fromISO(startUpData[x]).toFormat("yyyy");
        if (x === LabelsKeys.DATE_FOUNDED) {
          value =
            startUpData[x] !== ("" || null)
              ? DateTime.fromJSDate(startUpData[x]!).isValid
                ? DateTime.fromJSDate(startUpData[x]!).toFormat("yyyy")
                : DateTime.fromSQL(
                    startUpData[x]! as unknown as string,
                  ).toFormat("yyyy")
              : "";
        }
        if (x === LabelsKeys.ACQUISITION_TYPE)
          value = startUpData[x]?.toString();
        if (x === LabelsKeys.STARTUP_TEAM_SIZE && startUpData[x]) {
          const found = teamSize?.find(
            (j: any) => Number(startUpData[x]!) === Number(j.value),
          );

          value = found ? found.display_name : startUpData[x];
        }
        if (x === LabelsKeys.TTM_MULTIPLIER) {
          if (startUpData.open_to_offer) {
            value = "--";
          } else {
            value =
              startUpData.asking_price && startUpData.annual_profit
                ? `${(
                    Number(startUpData.asking_price || 0) /
                    Number(startUpData.annual_revenue || 0)
                  ).toFixed(2)}x`
                : "1.0x";
          }
        }
        if (
          [
            LabelsKeys.ANNUAL_REVENUE,
            LabelsKeys.ASKING_PRICE,
            LabelsKeys.ANNUAL_PROFIT,
          ].includes(x as LabelsKeys)
        ) {
          value =
            x === LabelsKeys.ASKING_PRICE && startUpData.open_to_offer
              ? "Open to Offers" // @ts-ignore
              : startUpData[x] || startUpData[x] !== "0.00" // @ts-ignore
                ? abbreviateNumber(Number(startUpData[x])) // @ts-ignore
                  ? `£ ${abbreviateNumber(Number(startUpData[x]))}`
                  : "--"
                : "Open to Offers";
        }
        return (
          <Grid item xs={12} md={6} key={x}>
            <MarketplaceItemDetail
              icon={LabelsKeysMap.get(x)?.icon}
              title={LabelsKeysMap.get(x)?.label}
              // @ts-ignore
              value={value}
            />
          </Grid>
        );
      })}
    </>
  );
};
