interface SVGIconProps {
  color?: string;
  filled?: boolean;
}
export const HeartIcon = ({ color, filled = true }: SVGIconProps) => (
  <svg
    height="100%"
    viewBox="-0.5 -2 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00016 12.2333L6.0335 11.3533C2.60016 8.24 0.333496 6.18 0.333496 3.66667C0.333496 1.60667 1.94683 0 4.00016 0C5.16016 0 6.2735 0.54 7.00016 1.38667C7.72683 0.54 8.84016 0 10.0002 0C12.0535 0 13.6668 1.60667 13.6668 3.66667C13.6668 6.18 11.4002 8.24 7.96683 11.3533L7.00016 12.2333Z"
      strokeWidth="10%"
      fill={
        // wtf??
        color !== ("purplegradient" || "bluegradient")
          ? color
          : color === ("purplegradient" || "bluegradient")
            ? "url(#paint0_linear_586_2224)"
            : "black"
      }
      stroke={filled === true ? "none" : "black"}
    />
    <defs>
      {color === "purplegradient" && (
        <linearGradient
          id="paint0_linear_586_2224"
          x1="0.2"
          y1="-4"
          x2="26.6022"
          y2="2.83473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C72A8E" />
          <stop offset="1" stopColor="#7815DA" />
        </linearGradient>
      )}
      {color === "bluegradient" && (
        <linearGradient
          id="paint0_linear_586_2224"
          x1="-7.89109"
          y1="-5"
          x2="26.1628"
          y2="0.975647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E8EF2" />
          <stop offset="1" stopColor="#6911E7" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
