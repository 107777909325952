import { useAppContext } from "../../../common/context/AppContext";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  Switch,
  Container,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import { UserService } from "../../../common/services/UserService";
import { UserType } from "../../../common/types";
import { AppTypography } from "../../../common/components/AppTypography/AppTypography";
import { AppCard } from "../../../common/components/AppCard/AppCard";
import { IntercomService } from "../../../common/services/IntercomService";
import { RoutePaths } from "../../consts/enums";
import { Header } from "../../components/Header";
import { PasswordForm } from "../../components/PasswordForm";
import { StartupService } from "../../../common/services/StartupService";

export const Settings = () => {
  const navigate = useNavigate();
  const { user, updateUser } = useAppContext();
  const [passwordReset, setPasswordReset] = useState(false);
  const { t } = useTranslation();

  const goToProfile = () => {
    navigate(RoutePaths.REGISTER_ACQUIRER + "/specific");
  };

  const enablePasswordReset = () => {
    setPasswordReset((curr) => !curr);
  };

  const onChangeMailing = async (event: ChangeEvent<HTMLInputElement>) => {
    await UserService.EditUser(user.id, {
      allow_news_letters: event.target.checked,
    });
    updateUser((prev) => ({
      ...prev,
      allow_news_letters: event.target.checked,
    }));
  };

  const contact = () => {
    IntercomService.Open();
  };

  const logout = async () => {
    UserService.SignOut();
    updateUser(undefined);
    navigate(RoutePaths.LOGIN);
  };

  const createNewStartup = () => {
    StartupService.CreateStartup().then((startup) => {
      user.startups?.push(startup);
      navigate(`${RoutePaths.REGISTER_SELLER}/${startup.id}/basic`);
    });
  };

  return (
    <>
      <Header>
        <Typography variant="h2">Settings</Typography>
      </Header>
      <Container maxWidth="md">
        <Grid container marginTop={4} spacing={3}>
          <Grid mb={6} item xs={12} md={6}>
            <Stack alignItems={{ xs: "center", sm: "flex-start" }}>
              {user?.buyerFull?.image?.file && (
                <Avatar
                  sx={{
                    mb: 4,
                    width: 124,
                    height: 124,
                  }}
                  src={user.buyerFull.image.file}
                />
              )}

              <Stack>
                <TextField
                  variant="filled"
                  label={t("app.email")}
                  value={user.email}
                  disabled
                  inputProps={{ readOnly: true }}
                  sx={{ mb: 3 }}
                />

                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2">
                    {t("settings.receiveNewsletters")}
                  </Typography>
                  <Switch
                    color="primary"
                    defaultChecked={user.allow_news_letters}
                    onChange={onChangeMailing}
                  />
                </Stack>

                {user.active_role === UserType.Seller && (
                  <Stack sx={{ mb: 4 }} spacing={2}>
                    <Button variant="roundsecondary" href="/your-listings">
                      Edit Your Listings
                    </Button>
                  </Stack>
                )}

                {user.active_role === UserType.Buyer && (
                  <Stack sx={{ mb: 4 }} spacing={2}>
                    <Button variant="roundsecondary" onClick={goToProfile}>
                      Edit Profile
                    </Button>
                  </Stack>
                )}

                <Divider sx={{ mb: 4 }} />

                <Stack sx={{ mb: 4 }} spacing={2}>
                  <Typography variant="h3">
                    {t("settings.troubleWithPasswordTitle")}
                  </Typography>
                  {!passwordReset && (
                    <Button
                      variant="roundsecondary"
                      onClick={enablePasswordReset}
                    >
                      {t("settings.troubleWithPasswordButton")}
                    </Button>
                  )}
                  {passwordReset && (
                    <Box sx={{ zIndex: 1 }}>
                      <PasswordForm
                        showOldPassword
                        handlePasswordState={enablePasswordReset}
                      />
                    </Box>
                  )}
                </Stack>

                <Divider sx={{ mb: 4 }} />
                <Stack spacing={2}>
                  <Button
                    variant="roundsecondary"
                    color="error"
                    onClick={logout}
                  >
                    Logout
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <AppCard>
                <Stack alignItems="flex-start">
                  <AppTypography type="gradient" sx={{ mb: 1 }} variant="h2">
                    {t("settings.supportTitle")}
                  </AppTypography>
                  <Typography
                    variant="caption"
                    color="secondary"
                    sx={{ mb: 4 }}
                  >
                    {t("settings.supportSubtitle")}
                  </Typography>
                  <Button variant="roundgradient" fullWidth onClick={contact}>
                    {t("settings.supportButton")}
                  </Button>
                </Stack>
              </AppCard>

              {user.active_role === UserType.Seller && (
                <AppCard>
                  <Stack alignItems="flex-start">
                    <AppTypography type="gradient" sx={{ mb: 1 }} variant="h2">
                      Selling more than one startup?
                    </AppTypography>
                    <Typography
                      variant="caption"
                      color="secondary"
                      sx={{ mb: 4 }}
                    >
                      You can now list up to 10 startups per account!
                    </Typography>
                    <Button
                      variant="roundgradient"
                      fullWidth
                      onClick={() => createNewStartup()}
                    >
                      List another startup
                    </Button>
                  </Stack>
                </AppCard>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
