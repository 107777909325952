import { Box } from "@mui/system";
import {
  IFavourites,
  IPagedFavourites,
  IStartup,
} from "../../../../common/models";
import { useFavourites, useStartupTypes } from "../../../../common/hooks";
import { StartupService } from "../../../../common/services/StartupService";
import { useEffect, useState } from "react";
import { MarketplaceItem } from "../../../components/MarketplaceItem";

export const Favourites = () => {
  const { data } = useFavourites();
  const [favourites, setFavourites] = useState<IStartup[]>([]);
  const { data: startupTypes } = useStartupTypes();

  const getStartups = async (data: IPagedFavourites) => {
    const allStartup: IStartup[] = [];
    await Promise.all(
      data.results.map(async (x: IFavourites) => {
        const s = await StartupService.GetStartup(x.startup, true);
        allStartup.push(s);
      }),
    );

    setFavourites(allStartup);
  };

  useEffect(() => {
    if (data && data?.results?.length) {
      getStartups(data);
    }
  }, [data]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {Boolean(favourites.length) &&
          favourites.map((x) => (
            <MarketplaceItem startupTypes={startupTypes} key={x.id} {...x} />
          ))}
        {/* <ScrollerLoader scroller={query.scroller} /> */}
      </Box>
    </>
  );
};
