import { Box, Typography } from "@mui/material";
import FoundyLogo from "../assets/logo/FoundyLogo";

const ErrorScreen = ({
  error,
  componentStack,
}: {
  error?: Error;
  componentStack?: string;
}) => {
  return (
    <Box
      sx={{
        // padding: -15/,
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        margin: "-15px",
        textAlign: "center",
        backgroundColor: "black",
        width: "calc(100vw + 7px)",
        height: "calc(100vh + 7px)a",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <FoundyLogo height="120px" />
      <Typography
        variant="h5"
        fontStyle={{ color: "white" }}
        fontWeight={700}
        fontSize={32}
      >
        Something unexpected just happened.
      </Typography>
      <Typography variant="body1" fontStyle={{ color: "white" }}>
        Please, try again in a few minutes while we work on it.
      </Typography>

      <Typography variant="body1" fontStyle={{ color: "grey" }}>
        <p>{error?.toString()}</p>
        <p>{componentStack}</p>
      </Typography>
    </Box>
  );
};

export default ErrorScreen;
