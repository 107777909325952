import {
  Box,
  Button,
  Container,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useState, PropsWithChildren, useEffect } from "react";
import { useForm } from "react-hook-form";
// import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { HookedField } from "../../components/HookedField";
import { HookedForm } from "../../components/HookedForm";
// import { RoutePaths } from "../../consts/enums";
import countries from "../../../common/assets/countries.json";
import { UserService } from "../../../common/services/UserService";
import { useAppContext } from "../../../common/context/AppContext";
import i18n from "../../../common/utils/i18n";
import { OptionField } from "../../components/OptionField";
import { HearFromType, UserType } from "../../../common/types";
import { CartIcon } from "../../../common/assets/icons/Cart";
import { SellerIcon } from "../../../common/assets/icons/Seller";
import {
  LocalStorageKey,
  StorageService,
} from "../../../common/services/StorageService";
import PrivateBanner from "../ProfileCompletion/PrivateBanner";
import { useHearAbout } from "../../../common/hooks";
import { HookedRadioGroup } from "../../components/HookedRadioGroup";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../consts/enums";

const TypeOfUser = [
  {
    Icon: CartIcon,
    label: i18n.t("app.buyer"),
    value: UserType.Buyer,
    description: i18n.t("onboarding.interestedInBuying"),
  },
  {
    Icon: SellerIcon,
    label: i18n.t("app.seller"),
    value: UserType.Seller,
    description: i18n.t("onboarding.interestedInSelling"),
  },
];

const schema = yup
  .object({
    active_role: yup
      .string()
      .required(i18n.t("error.userTypeRequired"))
      .nullable()
      .trim()
      .label(i18n.t("app.userType")),
    first_name: yup
      .string()
      .required()
      .nullable()
      .max(600)
      .trim()
      .label(i18n.t("app.firstName")),
    last_name: yup
      .string()
      .required()
      .nullable()
      .max(600)
      .trim()
      .label(i18n.t("app.lastName")),
    country: yup
      .string()
      .required()
      .nullable()
      .max(600)
      .trim()
      .label(i18n.t("app.country")),
    phone_number: yup
      .string()
      .required()
      .nullable()
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "This phone number format is invalid.",
      )
      .max(600)
      .trim()
      .label(i18n.t("app.phone")),
    location: yup
      .string()
      .required()
      .nullable()
      .max(600)
      .trim()
      .label(i18n.t("app.location")),
    website_url: yup
      .string()
      .url()
      .required()
      .nullable()
      .max(600)
      .trim()
      .label(i18n.t("app.website")),
    company_name: yup
      .string()
      .required()
      .nullable()
      .max(600)
      .trim()
      .label(i18n.t("app.startupName")),
    hear_about_us: yup.string().nullable().trim().label("Hear about us"),
    other: yup.string().nullable().trim(),
    referred: yup.string().nullable().trim(),
  })
  .required();

const FormBox = ({ children }: PropsWithChildren<unknown>) => (
  <Box
    sx={{
      width: "100%",
      flexDirection: "row",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      alignContent: "stretch",
      columnGap: 2,
    }}
  >
    {children}
  </Box>
);

const CountriesPhoneList = () => (
  <HookedField
    required
    name="country"
    component={TextField}
    select
    defaultValue="+44"
    variant="filled"
    label="Country"
    sx={{ order: 1, flex: "1 0 auto" }}
  >
    {countries.map((x) => (
      <MenuItem key={x.code} value={x.dial_code}>
        {x.code ? (
          <ListItemIcon sx={{ minWidth: 16, marginRight: 1 }}>
            <img
              src={`https://flagcdn.com/16x12/${x.code.toLocaleLowerCase()}.png`}
              srcSet={`https://flagcdn.com/32x24/${x.code.toLocaleLowerCase()}.png 2x,
    https://flagcdn.com/48x36/${x.code.toLocaleLowerCase()}.png 3x`}
              width="16"
              height="12"
              alt={x.name}
            />
          </ListItemIcon>
        ) : (
          <ListItemIcon>{x.dial_code}</ListItemIcon>
        )}
        <ListItemText sx={{ display: "inline-flex", margin: 0 }}>
          {x.name} {x.dial_code}
        </ListItemText>
      </MenuItem>
    ))}
  </HookedField>
);

const saveHearFrom = async (hearFrom: HearFromType) => {
  if (hearFrom.referred || hearFrom.other) {
    await UserService.SaveHearAboutUs(
      hearFrom.referred || hearFrom.other || "",
    );
  }
  return await UserService.PatchUser(hearFrom);
};

export const BasicInformation = () => {
  const navigate = useNavigate();
  const { user, updateUser, updateUserContext } = useAppContext();
  const [userType, setUserType] = useState<UserType | undefined>(
    (user.active_role as UserType) || undefined,
  );
  const [redirectToStartup, setRedirectToStartup] = useState(false);

  // this is because the user is not always updated so the ID fields are not populated when running patchUser
  useEffect(() => {
    if (redirectToStartup) {
      updateUserContext().then(() => {
        if (userType === UserType.Buyer) {
          navigate(`${RoutePaths.REGISTER_ACQUIRER}/specific`);
        } else {
          navigate(`${RoutePaths.REGISTER_SELLER}/${user.startup_id}/basic`);
        }
      });
    }
  }, [redirectToStartup, user]);

  const updateUserType = (userType: UserType) => {
    setUserType(userType);
    updateUser((prev) => ({
      ...prev,
      active_role: userType,
    }));
  };

  const formHook = useForm({
    defaultValues: {
      ...user,
      website_url: user.website_url ? user.website_url : "https://",
      ...({} as HearFromType),
    },
    resolver: yupResolver(schema),
  });

  const { data: hearAboutOptionsData } = useHearAbout();

  const hearAboutOptions = hearAboutOptionsData?.results || [];
  const hear_about_us = formHook.watch("hear_about_us");

  const checkOption = (option: string) => {
    const selectedOption =
      hearAboutOptions[
        hearAboutOptions.findIndex((x) => x.id === hear_about_us)
      ];
    if (!selectedOption) return false;
    return selectedOption.display_name === option;
  };

  const isReferred = checkOption("Referred");
  const isOther = checkOption("Other");

  const patchUser = async (values: any) => {
    if (values.website_url) {
      if (!values.website_url.includes("https://")) {
        values.website_url = "https://" + values.website_url;
      }
    }
    const referralKey = StorageService.Get(LocalStorageKey.ReferralKeys);

    const onboardPatchRes = await UserService.OnboardPatch({
      id: user.id,
      ...values,
      phone_number: values.country + values.phone_number,
      active_role: userType,
      ...(referralKey && { referral: { ...JSON.parse(referralKey) } }),
    });
    updateUser((prev) => ({
      ...prev,
      ...onboardPatchRes,
    }));

    if (referralKey) {
      StorageService.Remove(LocalStorageKey.ReferralKeys);
    }
    // TODO: merge that to the previous patch user call
    const onboardStep2PatchRes = await UserService.OnboardStepPatch(
      {
        ...values,
      },
      "step2/",
    );
    updateUser((prev) => ({
      ...prev,
      ...onboardStep2PatchRes,
    }));

    if (values["hear_about_us"]) {
      const res = await saveHearFrom({
        hear_about_us: values["hear_about_us"],
        referred: values["referred"],
        other: values["other"],
      });
      updateUser((prev) => ({
        ...prev,
        ...res,
      }));
    }

    setRedirectToStartup(true);
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <HookedForm formHook={formHook}>
        <Container
          maxWidth="sm"
          sx={{
            paddingTop: 3,
            gap: 3,
            // padding: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ color: "common.gray", marginBottom: 2 }}>
            <PrivateBanner />
          </Box>

          <OptionField
            question={i18n.t("onboarding.typeOfUserQuestion")}
            name="active_role"
            options={TypeOfUser}
            onChange={(v) => updateUserType(v as UserType)}
          />

          <FormBox>
            <HookedField
              required
              sx={{ order: 0, flex: "2 1 auto" }}
              component={TextField}
              label="First Name"
              name="first_name"
              variant="filled"
            />
            <HookedField
              required
              component={TextField}
              sx={{ order: 1, flex: "1 0 auto" }}
              label="Last Name"
              name="last_name"
              variant="filled"
            />
          </FormBox>
          <FormBox>
            <CountriesPhoneList />
            <HookedField
              required
              component={TextField}
              variant="filled"
              name="phone_number"
              label="Phone Number"
              sx={{ order: 1, flex: "2 1 auto" }}
            />
          </FormBox>
          <FormBox>
            <Typography variant="h3">Your Location</Typography>
            <Typography variant="body2" sx={{ color: "common.gray" }}>
              Please provide where are you located
            </Typography>
          </FormBox>
          <FormBox>
            <HookedField
              select
              defaultValue="GB"
              component={TextField}
              fullWidth
              label="Where are you located?"
              name="location"
              variant="filled"
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  <ListItemText>{country.name}</ListItemText>
                </MenuItem>
              ))}
            </HookedField>
          </FormBox>

          <FormBox>
            <Typography variant="h3">Company Name</Typography>
          </FormBox>
          <HookedField
            component={TextField}
            fullWidth
            label="Company Name"
            name="company_name"
            variant="filled"
          />
          <FormBox>
            <Typography variant="h3" sx={{ marginBottom: 1 }}>
              Website
            </Typography>
            <Typography variant="body2" sx={{ color: "common.gray" }}>
              Please provide your company&apos;s website
            </Typography>
          </FormBox>
          <FormBox>
            <HookedField
              component={TextField}
              fullWidth
              label="Website"
              name="website_url"
              variant="filled"
              helperText="https://yourwebsite.com or http://www.yourwebsite.com"
            />
          </FormBox>
          <FormBox>
            <Typography variant="h3" sx={{ marginBottom: 1 }}>
              {`${i18n.t("onboarding.howDidYouHearAboutUs")}`}
            </Typography>
          </FormBox>
          <FormBox>
            <HookedRadioGroup
              name="hear_about_us"
              options={hearAboutOptions.map((x) => ({
                label: x.display_name,
                value: x.id,
              }))}
            />
          </FormBox>
          <FormBox>
            {isOther && (
              <HookedField
                fullWidth
                variant="filled"
                component={TextField}
                name="other"
                placeholder="Type here"
                hiddenLabel
              />
            )}
            {isReferred && (
              <HookedField
                fullWidth
                variant="filled"
                component={TextField}
                name="referred"
                placeholder="Who referred you? Please provide their full name."
                hiddenLabel
              />
            )}
          </FormBox>
          <Typography variant="body2" color="common.gray">
            Clicking &quot;Continue&quot; will register your Foundy account. You
            will now be taken to create your public listing anonymously and for
            free.
          </Typography>
          <Box sx={{ width: "100%", marginBottom: 5 }}>
            <Button
              variant="roundprimary"
              sx={{ width: "max-content", backgroundColor: "common.lightgray" }}
              onClick={formHook.handleSubmit(patchUser)}
            >
              {`${i18n.t("app.continue")}`}
            </Button>
          </Box>
        </Container>
      </HookedForm>
    </Box>
  );
};
