import { Box, Button } from "@mui/material";

export const ReferBanner = () => {
  return (
    <Box sx={{ marginY: 5 }}>
      <Box
        sx={{
          borderRadius: 2,
          backgroundColor: "common.black",
          backgroundImage: "url(/images/foundy-gradient.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: 4,
          textAlign: "center",
          color: "white",
        }}
      >
        <h3 style={{ fontSize: 22, fontWeight: 700 }}>
          Earn up to £50,000 when someone you refer completes
          <br /> a deal on Foundy.
        </h3>
        <p style={{ fontSize: 12, fontWeight: 500 }}>
          Head to Foundy to create your unique link to founders and
          <br /> buyers to Foundy #partners #referral #rewards
        </p>
        <Button
          variant="roundoutlinewhite"
          sx={{ display: "inline-block", marginTop: 2 }}
        >
          Refer to earn
        </Button>
      </Box>
    </Box>
  );
};
