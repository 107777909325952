import ReactGA from "react-ga4";

export enum EventName {
  "SIGN_UP" = "sign_up",
  "PLANS_VIEW" = "plans_view",
  "COMPLETED_BUYER" = "completed_buyer",
  "COMPLETED_SELLER" = "completed_seller",
  "COMPLETED_BASIC" = "completed_basic",
  "SKIP_SIGN_UP" = "skip_sign_up",
  "BASIC_PRESS" = "basic_press",
  "STARTER_PRESS" = "starter_press",
  "ADVANCED_PRESS" = "advanced_press",
  "OPEN_HOT_LISTING" = "open_hot_listing",
  "INTERESTED_ADVISORY" = "interested_advisory",
  "SEARCH" = "search",
}
export const trackEvent = (
  event: EventName | string,
  options?: { action?: string; category?: string; label?: string },
): void => {
  switch (event) {
    case EventName.OPEN_HOT_LISTING: {
      ReactGA.event(EventName.OPEN_HOT_LISTING, {
        action: "Click On Hot Listing",
        category: "Hot-Listing",
        label: options?.label,
      });
      return;
    }
    case EventName.INTERESTED_ADVISORY: {
      ReactGA.event(EventName.INTERESTED_ADVISORY, {
        action: "Click On Interested M&A Advisor banner",
        category: "View",
        label: "Clicke in the banner for M&A Advisor",
      });
      return;
    }
    case EventName.SIGN_UP: {
      ReactGA.event(EventName.SIGN_UP, {
        action: "Click Register Sign Up",
        category: "Sign-Up",
        label: "User started sign up",
      });
      return;
    }
    case EventName.ADVANCED_PRESS: {
      ReactGA.event(EventName.ADVANCED_PRESS, {
        action: "Click Advanced Upgrade",
        category: "Upgrade",
        label: "User upgrade",
      });
      return;
    }
    case EventName.COMPLETED_BUYER: {
      ReactGA.event(EventName.COMPLETED_BUYER, {
        action: "Completed Buyer",
        category: "Sign-Up",
        label: "Full",
      });
      return;
    }
    case EventName.COMPLETED_SELLER: {
      ReactGA.event(EventName.COMPLETED_SELLER, {
        action: "Completed Seller",
        category: "Sign-Up",
        label: "Full",
      });
      return;
    }
    case EventName.COMPLETED_BASIC: {
      ReactGA.event(EventName.COMPLETED_BASIC, {
        action: "Completed Basic",
        category: "Sign-Up",
        label: "Full",
      });
      return;
    }
    case EventName.SKIP_SIGN_UP: {
      ReactGA.event(EventName.SKIP_SIGN_UP, {
        action: "Skipped Sign Up",
        category: "Sign-Up",
        label: "Skip to Marketplace",
      });
      return;
    }
    case EventName.STARTER_PRESS: {
      ReactGA.event(EventName.STARTER_PRESS, {
        action: "Click Starter Upgrade",
        category: "Upgrade",
        label: "User upgrade",
      });
      return;
    }
    case EventName.BASIC_PRESS: {
      ReactGA.event(EventName.BASIC_PRESS, {
        action: "Click Basic Upgrade",
        category: "Upgrade",
        label: "User upgrade",
      });
      return;
    }
    case EventName.PLANS_VIEW: {
      ReactGA.event(EventName.PLANS_VIEW, {
        action: "Click Plans",
        category: "View",
        label: "User view plans",
      });
      return;
    }
    case EventName.SEARCH: {
      ReactGA.event(EventName.SEARCH, {
        search_term: options?.label,
      });
      return;
    }
    default:
      if (options) {
        ReactGA.event(event, options);
      }
  }
};
