import { InputAdornment } from "@mui/material";
import { ComponentProps, ComponentType, useState } from "react";
import { useFormContext } from "react-hook-form";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";
import VisibilityIcon from "@mui/icons-material/Visibility";

export type IHookedFieldComponent = UseFormRegisterReturn;

export const HookedField = <
  TComponent extends ComponentType<TProps>,
  TProps = ComponentProps<TComponent>,
>({
  component: Component,
  ...componentProps
}: {
  component: TComponent;
  helperText?: string;
} & Partial<Omit<TProps, "component">> & {
    name: string;
    currency?: boolean;
    characterCounter?: boolean;
    minCharacters?: number;
    maxCharacters?: number;
    inputComponent?: any;
    isRequired?: boolean;
    type?: any;
  }) => {
  const {
    watch,
    register,
    getFieldState,
    formState: { errors, isDirty }, // eslint-disable-line @typescript-eslint/no-unused-vars
  } = useFormContext();
  const { error } = getFieldState(componentProps.name);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const getHelperText = () => {
    if (
      !componentProps.helperText &&
      !componentProps.helperText &&
      !componentProps.maxCharacters
    )
      return null;

    const value = watch(componentProps.name);
    if (componentProps.helperText && !componentProps.characterCounter)
      return componentProps.helperText;

    if (
      componentProps.characterCounter &&
      !componentProps.maxCharacters &&
      !componentProps.helperText
    )
      return value && `${value.length}/-`;

    const numOfEnteredChars = value ? value.length : 0;

    if (
      componentProps.characterCounter &&
      componentProps.helperText &&
      componentProps.maxCharacters
    )
      return `${
        componentProps.minCharacters
          ? "Min. " + componentProps.minCharacters + " -"
          : ""
      } ${numOfEnteredChars}/${componentProps.maxCharacters} - ${
        componentProps.helperText
      }`;

    return `${
      componentProps.minCharacters
        ? "Min. " + componentProps.minCharacters + " -"
        : ""
    } ${numOfEnteredChars}/${componentProps.maxCharacters}`;
  };

  return (
    // @ts-ignore
    <Component
      {...componentProps}
      {...register(componentProps.name, {
        required: componentProps.isRequired,
      })}
      type={
        componentProps.type
          ? componentProps.type === "password"
            ? showPassword
              ? "text"
              : "password"
            : componentProps.type
          : componentProps.type
      }
      InputProps={{
        ...(componentProps.inputComponent && {
          inputComponent: componentProps.inputComponent as any,
        }),
        ...(componentProps.currency && {
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }),
        ...(componentProps.type === "password" && {
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ cursor: "pointer" }}
              onClick={() => handleClickShowPassword()}
            >
              <VisibilityIcon />
            </InputAdornment>
          ),
        }),
      }}
      inputProps={{
        ...(componentProps.maxCharacters && {
          maxLength: componentProps.maxCharacters,
        }),
        ...(componentProps.minCharacters && {
          minLength: componentProps.minCharacters,
        }),
      }}
      error={!!error}
      helperText={error?.message || getHelperText()}
    />
  );
};
