import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import countries from "../../../common/assets/countries.json";
import {
  useStartup,
  useTeamSize,
  useWeeklyCommitment,
  useStartupTypes,
  useChatMetadata,
} from "../../../common/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { LabelsKeys, RoutePaths } from "../../consts/enums";
import { MarketplaceItemDetail } from "../../components/MarketplaceItemDetail";
import {
  LabelsKeysMap,
  LocationKeys,
  Locations,
  buyerToSellerTemplate,
} from "../../consts/consts";
import {
  abbreviateNumber,
  isBasicPlan,
  isBeforeV2,
} from "../../../common/utils";
import { HookedForm } from "../../components/HookedForm";
import { HookedField } from "../../components/HookedField";
import { useAppContext } from "../../../common/context/AppContext";
import { ChatService } from "../../../common/services/ChatService";
import { DialogBase } from "../../components/DialogBase";
import { useModal } from "mui-modal-provider";
import { PlansModal } from "../../components/PlansModal";
import { ArrowBack } from "@mui/icons-material";
import { Header } from "../../components/Header";
import { UserType } from "../../../common/types";
import { SideDialog } from "../../components/SideDialog";
import { NotificationService } from "../../../common/services/NotificationService";
import { usePrismicClient } from "@prismicio/react";
import { StartupService } from "../../../common/services/StartupService";
import { CommonQuery } from "../../../common/utils/query";
import { useMutation, useQueryClient } from "react-query";
import { MarketplaceItem } from "../../components/MarketplaceItem";
import { IBusinessCategory } from "../../../common/models";
import TagV1 from "./tagV1";
import NewTag from "./tag";

const labelsKeys = [
  "asking_price",
  "annual_profit",
  "annual_revenue",
  "clv",
  "date_founded",
  "country",
  "startup_team_size",
];

const HR = () => (
  <Box
    component="hr"
    sx={{
      width: "100%",
      borderColor: "common.lightgray",
      borderStyle: "solid",
      marginY: 3,
    }}
  />
);

export const CompanyView = () => {
  const [notLogged, setNotLogged] = useState(false);
  const [isHotListing, setIsHotListing] = useState(false);
  const { showModal } = useModal();
  const [showMessageTemplates, setMessageTemplates] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [disableContact, setDisableContact] = useState(false);
  const [submitActive, setSubmitActive] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { user, isAuthed } = useAppContext();
  const { data, isLoading } = useStartup(params.id);
  const [buisnessCategories, setBuisnessCategories] =
    useState<IBusinessCategory[]>();

  const messageFormHook = useForm();
  const { data: weeklyCommit } = useWeeklyCommitment();
  const { data: teamSize = [] } = useTeamSize();
  const prismic = usePrismicClient();
  const { data: businessTypes = [] } = useStartupTypes();
  const queryClient = useQueryClient();
  const { data: chatMetadata } = useChatMetadata(user);

  // TODO: make obsolete once v1 is removed
  const Tag = ({ text }: { text: string }) =>
    data && isBeforeV2(data.updated_at) ? (
      <TagV1 text={text} />
    ) : (
      <NewTag text={text} />
    );

  const createListingView = useMutation(StartupService.CreateListingView, {
    onSuccess: () => {
      queryClient.invalidateQueries(CommonQuery.BuyerListingsCountUnviewed);
    },
  });

  const handleMessageTemplate = () => {
    setMessageTemplates((curr) => !curr);
  };

  const confirmationModal = () => {
    const modal = showModal(PlansModal, {
      onCancel: () => {
        modal.hide();
      },
    });
  };

  const handleNotLogged = () => {
    setNotLogged((curr) => !curr);
  };

  const isAllowContact = useMemo(() => {
    if (isLoading) return false;
    if (!user) return false;
    if (data?.free_to_contact) return true;
    if (
      user.active_role === "buyer" &&
      isBasicPlan(user) &&
      chatMetadata?.length &&
      chatMetadata.length >= 1
    )
      return false;

    if (
      user.active_role === "buyer" &&
      user.buyerFull?.status === "disapproved"
    )
      return false;

    setDisableContact(false);
    return true;
  }, [user, data, isLoading, chatMetadata]);

  const handleContactWithMessage = async () => {
    if (!isAllowContact) {
      setDisableContact(true);
      confirmationModal();
      return;
    }
    const values = messageFormHook.getValues();
    try {
      await ChatService.CreateRequest({
        chatmate: data,
        message: values.message,
        startup: params.id,
      });

      navigate(RoutePaths.CONNECTIONS);
    } catch {
      handleErrorModal();
    }
  };

  const checkMembership = (v: any) => {
    if (v.message) {
      setSubmitActive(true);
      if (v.message.length >= 3 && !isAllowContact) {
        setDisableContact(true);
        confirmationModal();
      } else {
        setDisableContact(false);
      }
    } else {
      setSubmitActive(false);
    }
  };

  const getBusinessCategoryLabel = useCallback(
    (businessCategoryId: number) => {
      if (buisnessCategories && data?.business_categories) {
        const category = buisnessCategories.find(
          (x) => x.id === businessCategoryId,
        );
        if (category) {
          return category.name;
        }
      }
      return "";
    },
    [buisnessCategories, data],
  );

  useEffect(() => {
    if (data && data?.business_categories) {
      StartupService.GetBusinessCategories({ id: String(data.id) }).then(
        (res) => setBuisnessCategories(res),
      );
    }
  }, [data]);

  const handleErrorModal = () => {
    setMessageError((curr) => !curr);
  };

  useEffect(() => {
    prismic.getByUID("hot_listings", "hot-listings").then((res) => {
      if (res.data?.slices && res.data?.slices[0]) {
        const ids = res.data?.slices[0].items.map(
          (itm: { url_id: number }) => itm.url_id,
        );

        if (ids.includes(Number(params?.id))) {
          setIsHotListing(true);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (user && user.id && params.id && data && !data.is_viewed) {
      createListingView.mutateAsync(params.id);
    }
  }, [data]);

  const freeMessages_left = 1 - (chatMetadata?.length || 0);

  if (isLoading || !data)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box
      sx={{
        backgroundColor: "common.lightgray",
      }}
    >
      <Header>
        {isAuthed && (
          <Button
            variant="roundoutlinedgradient"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBack sx={{ fill: "#C32993" }} />}
            sx={{
              display: "inline-flex",
              px: 4,
            }}
          >
            <Box component="span" sx={{ display: { xs: "none", md: "block" } }}>
              Marketplace
            </Box>
            <Box component="span" sx={{ display: { xs: "block", md: "none" } }}>
              Back
            </Box>
          </Button>
        )}
      </Header>
      <Container
        sx={{
          backgroundColor: "common.lightgray",
          py: 3,
        }}
        maxWidth="xl"
      >
        <Container
          sx={{
            marginTop: { xs: 2, md: 0 },
            marginBottom: 4,
            position: "relative",
            zIndex: 1,
            backgroundColor: "common.white",
            padding: { xs: 0, md: 3 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <MarketplaceItem
              isHotListing={isHotListing}
              startupTypes={businessTypes}
              teamSize={teamSize}
              key={data.id}
              disableNewListingGlow={true}
              controlled={true}
              {...data}
            />
          </Box>

          <Box px={2}>
            <Typography variant="subtitle3" sx={{ marginBottom: 2 }} my={2}>
              Key Metrics
            </Typography>
            <Grid container spacing={2}>
              {data &&
                labelsKeys.map((x: string) => {
                  let value;
                  if ([LabelsKeys.CREATED_AT].includes(x as LabelsKeys))
                    value = !data[LabelsKeys.CREATED_AT]
                      ? ""
                      : DateTime.fromISO(data[LabelsKeys.CREATED_AT]).toFormat(
                          "yyyy",
                        );
                  if (x === LabelsKeys.DATE_FOUNDED) {
                    value =
                      data[x] !== ("" || null)
                        ? DateTime.fromJSDate(data[x]!).isValid
                          ? DateTime.fromJSDate(data[x]!).toFormat("yyyy")
                          : DateTime.fromSQL(
                              data[x]! as unknown as string,
                            ).toFormat("yyyy")
                        : "";
                  }
                  if (x === LabelsKeys.ACQUISITION_TYPE)
                    value = data[x]?.toString();
                  if (x === LabelsKeys.STARTUP_TEAM_SIZE && data[x]) {
                    const found = teamSize?.find(
                      (j) => Number(data[x]!) === Number(j.value),
                    );

                    value = found ? found.display_name : data[x];
                  }
                  if (x === LabelsKeys.TTM_MULTIPLIER) {
                    if (data.open_to_offer) {
                      value = "--";
                    } else {
                      value =
                        data.asking_price && data.annual_profit
                          ? `${(
                              Number(data.asking_price || 0) /
                              Number(data.annual_revenue || 0)
                            ).toFixed(2)}x`
                          : "1.0x";
                    }
                  }
                  if (x === LabelsKeys.COUNTRY) {
                    value = data[x]
                      ? countries.find((c) => c.code === data[x])?.name
                      : "--";
                  }
                  if (x === LabelsKeys.ASKING_PRICE) {
                    value = data.open_to_offer
                      ? "Open to Offers"
                      : `£ ${abbreviateNumber(Number(data[x]))}`;
                  }
                  if (
                    [
                      LabelsKeys.ANNUAL_REVENUE,
                      LabelsKeys.ASKING_PRICE,
                      LabelsKeys.ANNUAL_PROFIT,
                      LabelsKeys.CLV,
                    ].includes(x as LabelsKeys)
                  ) {
                    // @ts-ignore
                    const abrvNum = abbreviateNumber(Number(data[x]));
                    value = abrvNum ? `£ ${abrvNum}` : "--";
                  }
                  return (
                    <Grid item xs={12} md={6} key={x}>
                      <MarketplaceItemDetail
                        icon={LabelsKeysMap.get(x)?.icon}
                        title={LabelsKeysMap.get(x)?.label}
                        // @ts-ignore
                        value={value}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          <HR />
          {data && data.foundy_says ? (
            <div id="listing-view-description-summary">
              <Box px={3}>
                <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                  Foundy&apos;s Summary
                </Typography>
                <Typography variant="body2">{data?.foundy_says}</Typography>
              </Box>
            </div>
          ) : (
            <Box px={3} id="listing-view-description-summary">
              <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                Seller&apos;s Summary
              </Typography>
              <Typography variant="body2">{data?.description}</Typography>
            </Box>
          )}
          <HR />

          {data && data.founder_bio && (
            <>
              <Box px={3}>
                <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                  About the Founder
                </Typography>
                <Typography variant="body2">{data?.founder_bio}</Typography>
              </Box>
              <HR />
            </>
          )}

          <Box flexWrap="wrap" display="flex" gap={1} p={2}>
            {isAuthed && isBasicPlan(user) && !data.free_to_contact ? (
              <Button
                variant="roundoutlinedgradient"
                sx={{ fontSize: { xs: 14, md: 16 } }}
                onClick={() => navigate(RoutePaths.PLANS)}
              >
                <span>Unlock listing</span>
              </Button>
            ) : null}

            {!isAuthed && (
              <Button
                variant="roundoutlinedgradient"
                sx={{ fontSize: { xs: 14, md: 16 } }}
                onClick={() => navigate(RoutePaths.REGISTER)}
              >
                <span>Create your account</span>
              </Button>
            )}
          </Box>
          {!isAuthed && (
            <Box p={2}>
              <Box>
                <Typography variant="h2" sx={{ marginBottom: 2 }}>
                  <span>Contact the business owner</span>
                </Typography>
                <Button variant="roundgradient" onClick={handleNotLogged}>
                  Send a message
                </Button>
              </Box>
            </Box>
          )}

          {user.active_role !== UserType.Seller && isAuthed && (
            <Box mb={5}>
              <Container maxWidth="lg">
                <Box>
                  <Typography variant="h2" sx={{ marginBottom: 2 }}>
                    <span>Contact the business owner</span>
                  </Typography>
                  {isAllowContact && isBasicPlan(user) ? (
                    <Typography variant="h3" sx={{ marginBottom: 2 }}>
                      <span>
                        {freeMessages_left > 0
                          ? `Number of Free Messages left: ${freeMessages_left}`
                          : "You have no free messages left"}
                      </span>
                    </Typography>
                  ) : null}
                </Box>
                <Box sx={{ zIndex: 1, position: "relative" }}>
                  <Typography variant="body2">
                    If you are actively interested in this business send the
                    seller a clear and actionable message.{" "}
                  </Typography>
                  <Typography variant="h3" sx={{ marginY: 2 }}>
                    Send message
                  </Typography>
                  <HookedForm
                    formHook={messageFormHook}
                    onChange={checkMembership}
                    onSubmit={handleContactWithMessage}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={9}>
                        <HookedField
                          disabled={disableContact}
                          sx={{ marginBottom: 2 }}
                          variant="filled"
                          multiline
                          minRows={8}
                          fullWidth
                          name="message"
                          component={TextField}
                          placeholder="Hi, it’s great to connect!

                      I came across your business on the marketplace and it seems like a good fit for my interests.

                      I’d love to learn more about the business and explore a potential acquisition.

                      To get the due diligence process underway, it would be great if you could answer a few initial questions. I’m happy to sign an NDA if you’d prefer.

                      I’m also happy to answer any questions you might have for me!

                      Thanks in advance and look forward to discussing this further."
                        />
                        <Button
                          disabled={
                            !isAllowContact || disableContact || !submitActive
                          }
                          type="submit"
                          variant="roundoutlinedgradient"
                          sx={{
                            "&.Mui-disabled": {
                              backgroundColor: "#d5d5d5",
                              "& span": {
                                WebkitTextFillColor: "inherit",
                              },
                            },
                          }}
                        >
                          <span>Send message</span>
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Stack spacing={2}>
                          <Button
                            onClick={() => handleMessageTemplate()}
                            variant="roundprimary"
                            sx={{
                              borderRadius: 2,
                              textAlign: "left",
                              transition: "inherit",
                              "&:hover": {
                                color: "white",
                                background:
                                  "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
                                "&:after": { display: "none" },
                              },
                            }}
                            size="small"
                          >
                            Message templates
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </HookedForm>
                </Box>
              </Container>
              <HR />
            </Box>
          )}
          <Container sx={{ paddingBottom: 10 }}>
            {data?.business_categories && (
              <>
                <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                  Business Categories
                </Typography>
                <Box>
                  {data?.business_categories.map((x, i) => (
                    <Tag key={i} text={getBusinessCategoryLabel(x)} />
                  ))}
                </Box>

                <HR />
              </>
            )}
            {data?.operating_regions && (
              <>
                <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                  Operating Regions
                </Typography>
                <Box>
                  {data?.operating_regions.map((region: string, i) => (
                    <Tag key={i} text={Locations[region as LocationKeys]} />
                  ))}
                </Box>

                <HR />
              </>
            )}

            {data?.business_model && (
              <>
                <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                  Revenue Streams
                </Typography>
                <Box>
                  {data?.business_model
                    .split(",")
                    .map((x, i) => (
                      <Tag
                        key={i}
                        text={
                          isBeforeV2(data.updated_at)
                            ? x?.replace(/_/g, " ")
                            : x?.replace(/_/g, " ").toLocaleLowerCase()
                        }
                      />
                    ))}
                </Box>

                <HR />
              </>
            )}
            {data?.growth_opportunity && (
              <>
                <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                  Growth opportunities post acquisition
                </Typography>
                <Box>
                  {isBeforeV2(data.updated_at) ? (
                    <Typography
                      variant="body2"
                      fontWeight={isBeforeV2(data.updated_at) ? 500 : 600}
                    >
                      {data?.growth_opportunity?.replace(/_/g, " ")}
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        flexDirection: { xs: "column", md: "row" },
                      }}
                    >
                      {data?.growth_opportunity
                        .split(",")
                        .map((x, i) => (
                          <Tag
                            key={i}
                            text={x?.replace(/_/g, " ").toLocaleLowerCase()}
                          />
                        ))}
                    </Box>
                  )}
                </Box>

                <HR />
              </>
            )}
            {data?.key_assets_new && data?.key_assets_new?.length ? (
              <>
                <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                  Key assets
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  {data?.key_assets_new
                    .toString()
                    .split(",")
                    .map((x, i) => (
                      <Tag
                        key={i}
                        text={x?.replace(/_/g, " ").toLocaleLowerCase()}
                      />
                    ))}
                </Box>

                <HR />
              </>
            ) : data?.key_assets ? (
              <>
                <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                  Key assets
                </Typography>
                <Box>
                  <Typography variant="body2" fontWeight={500}>
                    {data?.key_assets}
                  </Typography>
                </Box>

                <HR />
              </>
            ) : null}
            {data?.weekly_commitment && (
              <>
                <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                  Hours required to run business successfully
                </Typography>
                <Box>
                  <Typography variant="body2" fontWeight={600}>
                    {
                      weeklyCommit?.find(
                        (x) =>
                          Number(x.value) === Number(data.weekly_commitment),
                      )?.display_name
                    }
                  </Typography>
                </Box>

                <HR />
              </>
            )}
            {data.reason && (
              <>
                <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                  Reason for selling my business
                </Typography>
                <Box>
                  <Typography variant="body2">{data.reason}</Typography>
                </Box>
                <HR />
              </>
            )}
            <Box sx={{ position: "relative" }}>
              {data.founders_post_acquisition && (
                <>
                  {" "}
                  <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                    Willing to stay on in the business post acquisition?
                  </Typography>
                  <Box>
                    <Tag
                      text={data.founders_post_acquisition?.toLocaleLowerCase()}
                    />
                  </Box>
                  <HR />
                </>
              )}

              {(data.avg_net_revenue_1m ||
                data.avg_net_cost_1m ||
                data.avg_net_profit_1m) && (
                <>
                  <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
                    3 Month Average Financials
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      gap: 2,
                    }}
                  >
                    <Tag
                      text={`£ ${abbreviateNumber(data.avg_net_revenue_1m)}`}
                    />
                    <Tag text={`£ ${abbreviateNumber(data.avg_net_cost_1m)}`} />
                    <Tag
                      text={`£ ${abbreviateNumber(data.avg_net_profit_1m)}`}
                    />
                  </Box>
                  <HR />
                </>
              )}
            </Box>
            <Typography variant="subtitle3" sx={{ marginBottom: 2 }}>
              Detailed Due diligence Information
            </Typography>
            <Box>
              <Typography variant="body2">
                To access detailed financial and performance metrics and be
                invited to data rooms, Pro buyers have to message business
                sellers via the instant chat tool above.
              </Typography>
            </Box>
          </Container>
        </Container>
        <DialogBase isOpen={messageError} handleState={handleErrorModal}>
          <Typography variant="body1">
            It was not possible to send your message. Our apologies for this
            inconvenience.
          </Typography>
          <Box component="ul">
            <li>
              This may possibly be because you included your company name,
              email, phone number or a website in your message. Or you sent more
              than 2000 characters.
            </li>
            <li>Please amend your message and try again.</li>
            <li>
              If your message still does not send then please send our support
              team a message via the help button
            </li>
          </Box>
        </DialogBase>
        <DialogBase
          maxWidth="sm"
          isOpen={notLogged}
          handleState={handleNotLogged}
        >
          <Typography variant="body1" marginBottom={2}>
            Before sending a message to a business you must create a account.
          </Typography>
          <Button
            variant="roundoutlinedgradient"
            onClick={() => navigate(RoutePaths.REGISTER)}
          >
            <span>Create your account now</span>
          </Button>
        </DialogBase>
        <SideDialog
          width="30vw"
          handleClose={handleMessageTemplate}
          open={showMessageTemplates}
        >
          <Box sx={{ zIndex: 1 }}>
            <Typography variant="h2" marginBottom={3}>
              Message Template
            </Typography>
            <Typography
              variant="body2"
              marginBottom={2}
              lineHeight={1.8}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(buyerToSellerTemplate);
                NotificationService.Success("Copied to clipboard.");
              }}
            >
              Hi, it’s great to connect!
              <br /> I came across your business on the marketplace and it seems
              like a good fit for my interests.
              <br /> I’d love to learn more about the business and explore a
              potential acquisition.
              <br /> To get the due diligence process underway, it would be
              great if you could answer a few initial questions. I’m happy to
              sign an NDA if you’d prefer.
              <br /> I’m also happy to answer any questions you might have for
              me!
              <br />
              Thanks in advance and look forward to discussing this further.
            </Typography>
            <Button
              variant="roundoutlinedgradientblue"
              sx={{ marginBottom: 3 }}
              onClick={() => {
                navigator.clipboard.writeText(buyerToSellerTemplate);
                NotificationService.Success("Copied to clipboard.");
              }}
            >
              <span>Copy Text</span>
            </Button>

            <Typography variant="body2" marginBottom={2}>
              <b>Additional tips for messaging Foundy users:</b>
              <br />{" "}
              <ul>
                <li>
                  Let the seller know who you are:{" "}
                  <ul>
                    <li>
                      Eg. ‘Our fund has a proven track record of success in the
                      industry, and we believe your business could be a valuable
                      addition to our portfolio.’
                    </li>{" "}
                    <li>
                      Talking about any previous acquisitions you have made and
                      your experience in this regard is advisable here.
                    </li>
                  </ul>
                </li>{" "}
                <li>
                  Make clear how you want to proceed, emphasise communication,
                  transparency and a smooth acquisition process{" "}
                  <ul>
                    <li>
                      Eg. ‘We understand that selling your business is a big
                      decision, and we want to assure you that we are committed
                      to working together transparently and progressively.’
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>
            <Typography variant="body2">
              <b>
                Suggested due diligence questions to ask the seller include:
              </b>
              <ul>
                <li>What percentage of your revenue is recurring?</li>{" "}
                <li>What is the average order value per client?</li>{" "}
                <li>What is your average customer acquisition cost?</li>{" "}
                <li>How much debt does your business have?</li>{" "}
                <li>What is your customer churn rate?</li>
              </ul>
            </Typography>
            <Button
              variant="roundprimary"
              onClick={() => handleMessageTemplate()}
            >
              Close template
            </Button>
          </Box>
        </SideDialog>
      </Container>
    </Box>
  );
};
