import {
  IChatMetadata,
  IRequest,
  IStartupType,
  IUser,
  IUserFull,
} from "../models";
import { ApiService } from "./ApiService";
import { RequestStatus, UserType } from "../types";
import { Maybe } from "../types/utils";
import { ClientPath } from "../../client/types";
import { UserService } from "./UserService";
import i18n from "../utils/i18n";
import { generateUniqueIdentifier } from "../utils/formUtils";

interface UserParams {
  user?: IUserFull;
}

interface ChangeStatusParams {
  requestId?: number;
  status: RequestStatus;
}

interface StartupDataParams {
  startupId?: number;
}

interface GetConversationDataParams {
  user?: IUser;
  request: IRequest;
  startupTypes?: IStartupType[];
}

export const ChatService = {
  IsUserBuyer: ({ user }: UserParams): boolean => {
    return user?.active_role === UserType.Buyer;
  },
  GetCurrentUser: ({
    user,
    request,
  }: GetConversationDataParams): IUserFull | undefined => {
    return request.users.find((item) => item.id === user?.id);
  },
  GetChatmate: ({
    user,
    request,
  }: GetConversationDataParams): IUserFull | undefined => {
    return request.users.find((item) => item.id !== user?.id);
  },
  GetUserRequestsByAdmin: ({ user }: UserParams): Promise<IRequest[]> => {
    return ApiService.Get(`/user/${user?.id}/user-chats-admin/`);
  },
  GetUserRequests: (): Promise<IRequest[]> => {
    return ApiService.Get(`/chat/user-chats/`);
  },
  GetStartupRequests: ({
    startupId,
  }: StartupDataParams): Promise<IRequest[]> => {
    return ApiService.Get(`/startup/${startupId}/startup-chats/`);
  },
  RequestChangeStatus: ({
    requestId,
    status,
  }: ChangeStatusParams): Promise<IRequest> => {
    return ApiService.Patch(`/chat/${requestId}/change-status/`, { status });
  },
  CreateRequest: async ({
    chatmate,
    message,
    startup,
    disableError = true,
  }: any): Promise<IRequest> => {
    let chatmateId = chatmate.user;

    if (chatmate.seller) {
      const seller = await UserService.GetSeller(chatmate.seller);
      chatmateId = seller.user;
    }

    return ApiService.Post(
      `/chats/start/`,
      {
        chat_mate: chatmateId,
        message,
        startup,
      },
      {
        disableError,
      },
    );
  },
  GetChatMetadataList: async (
    disableError = true,
  ): Promise<IChatMetadata[]> => {
    return ApiService.Get(`/chats/`, {
      disableError,
    });
  },
  GetConversationLabel: ({
    user,
    request,
  }: GetConversationDataParams): Maybe<string> => {
    const chatmate = ChatService.GetChatmate({ user, request });

    if (ChatService.IsUserBuyer({ user: chatmate })) {
      const { role, first_name, last_name } = chatmate?.buyerFull || {};

      if (request.status !== RequestStatus.Approved) {
        return i18n.t(`userTypes.buyer.buyerType.${role}`);
      } else {
        return `${first_name} ${last_name}`;
      }
    } else {
      const { contact_first_name, contact_last_name, headline } =
        chatmate?.startup || {};

      if (request.status !== RequestStatus.Approved) {
        return `${headline}`;
      } else {
        return `${contact_first_name} ${contact_last_name}`;
      }
    }
  },
  GetConversationIdentifier: ({
    user,
    request,
  }: GetConversationDataParams): Maybe<string> => {
    const chatmate = ChatService.GetChatmate({ user, request });

    if (request.status !== RequestStatus.Approved) {
      return generateUniqueIdentifier(chatmate!);
    } else {
      return "";
    }
  },
  GetConversationDescription: ({
    user,
    request,
  }: GetConversationDataParams) => {
    const chatmate = ChatService.GetChatmate({ user, request });

    if (ChatService.IsUserBuyer({ user: chatmate })) {
      return chatmate?.buyerFull?.description;
    } else {
      return chatmate?.startup?.description;
    }
  },
  GetConversationAvatar: ({ user, request }: GetConversationDataParams) => {
    const chatmate = ChatService.GetChatmate({ user, request });
    return chatmate?.buyerFull?.image?.file;
  },
  GetIsStartupDraft: ({
    user,
    request,
  }: GetConversationDataParams): boolean => {
    const chatmate = ChatService.GetChatmate({ user, request });

    return (
      !ChatService.IsUserBuyer({ user: chatmate }) &&
      !chatmate?.startup?.published
    );
  },
  GetConversationLink: ({
    user,
    request,
  }: GetConversationDataParams): string => {
    const chatmate = ChatService.GetChatmate({ user, request });

    if (ChatService.IsUserBuyer({ user: chatmate })) {
      return `${ClientPath.Buyer}/${chatmate?.buyerFull?.id}`;
    } else {
      return `${ClientPath.Startup}/${chatmate?.startup?.id}`;
    }
  },
};
