import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CountUp from "react-countup";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  ChartData,
  Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Container, Stack } from "@mui/system";
import { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import { MoneyIcon, PieIcon, WorkIcon } from "../../../../common/assets/icons";
import "react-alice-carousel/lib/alice-carousel.css";
import { QuotesIcon } from "../../../../common/assets/icons/QuotesIcon";
import { usePrismicClient } from "@prismicio/react";

const handleDragStart = (e: any) => e.preventDefault();

const StyledBox = ({
  borderRadius = 2,
  children,
}: {
  borderRadius?: number;
  children: any;
}) => (
  <Box
    sx={{
      background: "#F6F6FC",
      border: "1px solid #F2F2F3",
      borderRadius: borderRadius,
      padding: 2,
      width: "100%",
    }}
  >
    {children}
  </Box>
);

const StyledPaper = ({ children }: { children: any }) => (
  <Paper
    onDragStart={handleDragStart}
    role="presentation"
    sx={{
      padding: 2,
      marginLeft: 1,
      width: { xs: 320, md: 400 },
      height: 280,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      position: "relative",
      "&:before": {
        content: "' '",
        position: "absolute",
        inset: "0",
        borderRadius: "8px",
        padding: "1px",
        background: "linear-gradient(45deg,#C72A8E,#7815DA)",
        WebkitMask:
          "linear-gradient(#fff 0 0) content-box, \n     linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
      },
    }}
  >
    {children}
  </Paper>
);

const individualBuyers = [
  {
    title: "Acquisition Entrepreneur",
    description: (
      <>
        <Typography variant="body1" color="common.gray">
          Acquisition entrepreneurs are individuals who seeks to acquire an
          established business rather than start one from scratch.
        </Typography>
        <Typography variant="body1" color="common.gray" marginTop={2}>
          <b>Primary goal:</b> identify, acquire, and operate an established
          company with the intention of unlocking value and generating
          substantial returns.
        </Typography>
      </>
    ),
  },
  {
    title: "Angel",
    description: (
      <>
        <Typography variant="body1" color="common.gray">
          Angels are high net-worth individuals who provide capital and, often,
          mentorship and guidance to early-stage businesses in exchange for
          equity or convertible debt.
        </Typography>
        <Typography variant="body1" color="common.gray" marginTop={2}>
          <b>Primary goal:</b> identify, acquire, and operate an established
          company with the intention of unlocking value and generating
          substantial returns.
        </Typography>
      </>
    ),
  },
];

const institutionalBuyers = [
  {
    title: "Private Equity",
    description: (
      <>
        <Typography variant="body1" fontWeight={500} color="common.gray">
          Private equity firms manage a pool of capital from multiple investors.
          They typically have a medium to long-term investment horizon and focus
          on creating value in the acquired businesses through strategic
          initiatives, operational improvements, and financial restructuring.
        </Typography>
        <Typography
          variant="body1"
          fontWeight={500}
          color="common.gray"
          marginTop={2}
        >
          <b>Primary goal:</b> to generate strong returns on investment for
          their limited partners or investors.
        </Typography>
      </>
    ),
  },
  {
    title: "Corporate Development",
    description: (
      <>
        <Typography variant="body1" fontWeight={500} color="common.gray">
          Corporate development teams sit within a larger corporation and are
          responsible for identifying, evaluating, and executing strategic
          growth opportunities.
        </Typography>
        <Typography
          variant="body1"
          fontWeight={500}
          color="common.gray"
          marginTop={2}
        >
          <b>Primary goal:</b> to drive the corporation&apos;s long-term growth,
          competitiveness and profitability.
        </Typography>
      </>
    ),
  },
  {
    title: "Venture Capital",
    description: (
      <>
        <Typography variant="body1" fontWeight={500} color="common.gray">
          Venture capital firms primarily acquire stakes in high-growth
          companies with the expectation of achieving substantial returns.
        </Typography>
        <Typography
          variant="body1"
          fontWeight={500}
          color="common.gray"
          marginTop={2}
        >
          <b>Primary goal:</b> Fund managers are individuals or teams
          responsible for managing a pool of capital with the objective of
          generating returns..
        </Typography>
      </>
    ),
  },
  {
    title: "Fund Manager",
    description: (
      <>
        <Typography variant="body1" fontWeight={500} color="common.gray">
          Fund managers are individuals or teams responsible for managing a pool
          of capital with the objective of generating returns.
        </Typography>
        <Typography
          variant="body1"
          fontWeight={500}
          color="common.gray"
          marginTop={2}
        >
          <b>Primary goal:</b> to generate strong returns on investment for the
          fund&apos;s investors.
        </Typography>
      </>
    ),
  },
];

const dataCategory: ChartData<"doughnut"> = {
  labels: [
    "Mobile 11.3%",
    "Agency 24.5%",
    "Marketplace 20.1%",
    "eCommerce 27.8%",
    "SaaS 26.3%",
  ],
  datasets: [
    {
      borderRadius: 1000,
      label: "Business Category",
      data: [11.3, 24.5, 20.1, 27.8, 26.3],
      backgroundColor: ["#C62A8F", "#50267C", "#DFC465", "#5CDE9F", "#5C90DE"],
      borderColor: "white",
      borderWidth: 2,
      spacing: -50,
    },
  ],
};

const items = [
  <StyledPaper key="1">
    <Box>
      <QuotesIcon />
      <Typography sx={{ fontSize: 12, fontWeight: 500, fontStyle: "italic" }}>
        Foundy&apos;s digital marketplace has opened up a new source of
        prospects to acquire. We are pleased to see their seller and buyer user
        base grow so quickly in such a short space of time. We are pleased to be
        part of their buyer community.
      </Typography>
    </Box>
    <Stack paddingY={2} direction="row" justifyContent="space-between">
      <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
        M&A Lead at a publicly traded software aggregator
        <br /> with 60+ companies in their portfolio
      </Typography>
      <Box
        component="img"
        width={73}
        height={73}
        src="/images/software-aggregator.png"
      />
    </Stack>
  </StyledPaper>,
  <StyledPaper key="2">
    <Box>
      <QuotesIcon />
      <Typography sx={{ fontSize: 12, fontWeight: 500, fontStyle: "italic" }}>
        Foundy has been the ideal partner. We are thrilled to collaborate with
        their leading digital platform and team to find acquisition
        opportunities and close deals together. My company, Treasure Hunter, is
        a serial acquirer. We have over 30 content businesses in our portfolio.
      </Typography>
    </Box>
    <Stack paddingY={2} direction="row" justifyContent="space-between">
      <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
        Michael Fink, Founder & CEO of
        <br /> Treasure Hunter
        <br /> 30 businesses in their portfolio
      </Typography>
      <Box
        component="img"
        width={73}
        height={73}
        src="/images/Michael-Fink.png"
      />
    </Stack>
  </StyledPaper>,
  <StyledPaper key="3">
    <Box>
      <QuotesIcon />
      <Typography sx={{ fontSize: 12, fontWeight: 500, fontStyle: "italic" }}>
        I find Foundy a cool, innovative platform. It can definitely have a big
        impact. It really streamlines the process of buyer and seller
        communications.
      </Typography>
    </Box>
    <Stack paddingY={2} direction="row" justifyContent="space-between">
      <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
        Navinjit Dhillon,
        <br /> Paralegal at Kubrick Group
      </Typography>
      <svg
        width="67"
        height="67"
        viewBox="0 0 67 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="33.5"
          cy="33.5"
          r="32.9975"
          fill="#F9F9F9"
          stroke="#C2CED9"
          strokeWidth="1.005"
        />
        <path
          d="M33.5 24.1203C35.2197 24.1203 36.6267 25.5273 36.6267 27.247C36.6267 28.9667 35.2197 30.3737 33.5 30.3737C31.7803 30.3737 30.3733 28.9667 30.3733 27.247C30.3733 25.5273 31.7803 24.1203 33.5 24.1203ZM33.5 38.1903C37.721 38.1903 42.5673 40.207 42.88 41.317V42.8803H24.12V41.3326C24.4327 40.207 29.279 38.1903 33.5 38.1903ZM33.5 20.9937C30.045 20.9937 27.2467 23.792 27.2467 27.247C27.2467 30.702 30.045 33.5003 33.5 33.5003C36.955 33.5003 39.7533 30.702 39.7533 27.247C39.7533 23.792 36.955 20.9937 33.5 20.9937ZM33.5 35.0637C29.3259 35.0637 20.9933 37.1585 20.9933 41.317V46.007H46.0067V41.317C46.0067 37.1585 37.6741 35.0637 33.5 35.0637Z"
          fill="url(#paint0_linear_3929_8810)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3929_8810"
            x1="22.244"
            y1="20.9937"
            x2="49.761"
            y2="28.117"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C72A8E" />
            <stop offset="1" stopColor="#7815DA" />
          </linearGradient>
        </defs>
      </svg>
    </Stack>
  </StyledPaper>,
  <StyledPaper key="4">
    <Box>
      <QuotesIcon />
      <Typography sx={{ fontSize: 12, fontWeight: 500, fontStyle: "italic" }}>
        Foundy&apos;s tech platform and origination service has been very
        helpful in significantly expanding our reach when searching for
        acquisition opportunities. They have saved us a lot of time and effort
        and seem to really understand our specific search interests.
      </Typography>
    </Box>
    <Stack paddingY={2} direction="row" justifyContent="space-between">
      <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
        UK based SaaS aggregator with
        <br /> 10 companies in portfolio
      </Typography>
      <svg
        width="67"
        height="67"
        viewBox="0 0 67 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="33.5"
          cy="33.5"
          r="32.9975"
          fill="#F9F9F9"
          stroke="#C2CED9"
          strokeWidth="1.005"
        />
        <path
          d="M33.5 24.1203C35.2197 24.1203 36.6267 25.5273 36.6267 27.247C36.6267 28.9667 35.2197 30.3737 33.5 30.3737C31.7803 30.3737 30.3733 28.9667 30.3733 27.247C30.3733 25.5273 31.7803 24.1203 33.5 24.1203ZM33.5 38.1903C37.721 38.1903 42.5673 40.207 42.88 41.317V42.8803H24.12V41.3326C24.4327 40.207 29.279 38.1903 33.5 38.1903ZM33.5 20.9937C30.045 20.9937 27.2467 23.792 27.2467 27.247C27.2467 30.702 30.045 33.5003 33.5 33.5003C36.955 33.5003 39.7533 30.702 39.7533 27.247C39.7533 23.792 36.955 20.9937 33.5 20.9937ZM33.5 35.0637C29.3259 35.0637 20.9933 37.1585 20.9933 41.317V46.007H46.0067V41.317C46.0067 37.1585 37.6741 35.0637 33.5 35.0637Z"
          fill="url(#paint0_linear_3929_8810)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3929_8810"
            x1="22.244"
            y1="20.9937"
            x2="49.761"
            y2="28.117"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C72A8E" />
            <stop offset="1" stopColor="#7815DA" />
          </linearGradient>
        </defs>
      </svg>
    </Stack>
  </StyledPaper>,
];

const responsive = {
  0: { items: 1, paddingLeft: 20 },
  390: { items: 1, paddingLeft: 20 },
  720: { items: 1 },
  1200: { items: 1.8 },
  1490: { items: 2.2 },
  1550: { items: 2.4 },
  1720: { items: 3.1 },
};

export const Buyers = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [prismicData, setPrismicData] = useState<any>(undefined);
  const [paddingResize, setPaddingResize] = useState(250);
  const [selectedType, setSelectedType] = useState("institutional");
  const prismicClient = usePrismicClient();

  const slideNext = () => setActiveIndex(activeIndex + 1);
  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const syncActiveIndex = ({ item }: { item: any }) => setActiveIndex(item);

  ChartJS.register(ArcElement, Tooltip, Legend);

  const handleSelectedType = (value: string) => {
    setSelectedType(value);
  };

  const getPrismicValues = async () => {
    setIsLoading(true);
    const data = await prismicClient.getByUID("app_buyer_stats", "app-stats");
    setPrismicData(data);

    setIsLoading(false);
  };

  useEffect(() => {
    if (matches) {
      setPaddingResize(50);
    } else {
      setPaddingResize(250);
    }
    getPrismicValues();
  }, []);

  return (
    <Box sx={{ marginY: 4 }}>
      <Container maxWidth="xl">
        <Typography variant="h1" fontWeight={700}>
          Foundy Buyers
        </Typography>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12} md={3}>
            <StyledBox>
              <Box sx={{ width: 20 }}>
                <WorkIcon color="bluegradient" />{" "}
              </Box>
              <Typography color="common.black" fontSize={25} fontWeight={600}>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <CountUp end={prismicData?.data?.buyer_total} />
                )}
              </Typography>{" "}
              <Typography fontSize={17} fontWeight={500} color="common.gray">
                Foundy Buyers
              </Typography>
            </StyledBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledBox>
              <Box sx={{ width: 20 }}>
                <MoneyIcon color="bluegradient" />
              </Box>
              <Typography color="common.black" fontSize={25} fontWeight={600}>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    &gt; £
                    <CountUp
                      decimals={2}
                      end={prismicData?.data?.cumulative_acquisition_budget}
                    />{" "}
                    billion
                  </>
                )}
              </Typography>{" "}
              <Typography fontSize={17} fontWeight={500} color="common.gray">
                Cumulative Acquisition Budget
              </Typography>
            </StyledBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledBox>
              <Box sx={{ width: 20 }}>
                <PieIcon color="bluegradient" />
              </Box>
              <Typography color="common.black" fontSize={25} fontWeight={600}>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <CountUp end={prismicData?.data?.looking_to_acquire} />%
                  </>
                )}
              </Typography>{" "}
              <Typography fontSize={17} fontWeight={500} color="common.gray">
                Looking to acquire in next 12 months
              </Typography>
            </StyledBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledBox>
              <Box sx={{ width: 20 }}>
                <PieIcon color="bluegradient" />
              </Box>
              <Typography color="common.black" fontSize={25} fontWeight={600}>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <CountUp end={prismicData?.data?.buyer_external_network} />
                  </>
                )}
              </Typography>{" "}
              <Typography fontSize={17} fontWeight={500} color="common.gray">
                buyers in Foundy&apos;s external network
              </Typography>
            </StyledBox>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12} md={12}>
            <StyledBox borderRadius={5}>
              <Typography variant="h2" textAlign="center" marginBottom={2}>
                Business Category Preferences
              </Typography>
              <Box sx={{ width: "70%", height: 300 }}>
                <Doughnut
                  options={{
                    cutout: "85%",
                    maintainAspectRatio: false,
                    layout: { padding: 0 },
                    plugins: {
                      tooltip: {
                        callbacks: {
                          title: function (context) {
                            return context[0].dataset.label;
                          },
                          label: function (context) {
                            return " " + context.label;
                          },
                        },
                      },
                      legend: {
                        position: "right",
                        labels: {
                          color: "black",
                          font: { family: "Inter", weight: 500, size: 14 },
                        },
                      },
                    },
                  }}
                  data={dataCategory}
                />
              </Box>
            </StyledBox>
          </Grid>
        </Grid>
        <Box marginTop={8} sx={{ textAlign: "center" }}>
          <Typography variant="h2" textAlign="center">
            Don&apos;t want to wait for buyers to message you?
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            color="#868D9D"
            fontWeight={500}
            marginTop={2}
          >
            We can undertake a personalised search to
            <br /> find you relevant buyers by leveraging our user
            <br /> base and our extensive global network
          </Typography>
          <Button
            sx={{ marginTop: 3, paddingX: 3, paddingY: 1.5 }}
            variant="roundgradient"
            href="https://foundy.com/advisory/sellers"
          >
            Learn about dedicated buyer outreach
          </Button>
        </Box>
      </Container>
      <Box sx={{ background: "#F6F6FC", paddingY: 6, marginTop: 8 }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h1" marginBottom={4}>
              Buyer Types on Foundy
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              marginBottom={4}
            >
              <Typography
                variant="h3"
                sx={{
                  ...(selectedType === "individual" && {
                    background:
                      "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  }),
                }}
              >
                Individual Buyer
              </Typography>
              <Switch
                defaultChecked
                onChange={(e) => {
                  handleSelectedType(
                    e.target.checked ? "institutional" : "individual",
                  );
                }}
                sx={{
                  "& .Mui-checked+.MuiSwitch-track": {
                    background: "#818181",
                  },
                  "& .MuiSwitch-track": {
                    background: "#818181",
                  },
                  "& .MuiButtonBase-root": {
                    padding: "6px",
                  },
                  "& .MuiSwitch-thumb": {
                    width: 27,
                    height: 27,
                    background:
                      "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
                    boxShadow:
                      "-1.84035px 3.6807px 7.36139px rgba(0, 0, 0, 0.08)",
                  },
                }}
              />
              <Typography
                variant="h3"
                sx={{
                  ...(selectedType === "institutional" && {
                    background:
                      "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  }),
                }}
              >
                Institutional Buyer
              </Typography>
            </Stack>
            <Grid container spacing={2}>
              {selectedType === "institutional" &&
                institutionalBuyers.map((x, index) => (
                  <Grid key={x.title} item xs={12} md={4}>
                    <Fade
                      style={{
                        transitionDelay:
                          selectedType === "institutional"
                            ? `${index * 100}ms`
                            : "0ms",
                      }}
                      in={selectedType === "institutional"}
                    >
                      <Paper
                        sx={{
                          padding: 2,
                          height: 430,
                          borderRadius: 5,
                          border: "1px solid #F2F2F3",
                          boxShadow: "inherit",
                        }}
                      >
                        <Box
                          sx={{
                            marginBottom: 2,
                            paddingX: 1,
                            paddingY: 0.5,
                            width: "max-content",
                            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.05)",
                            borderRadius: "4px",
                          }}
                        >
                          <span className="purplegradient">
                            Institutional Buyer
                          </span>
                        </Box>
                        <Typography variant="h2" marginY={3}>
                          {x.title}
                        </Typography>
                        {x.description}
                      </Paper>
                    </Fade>
                  </Grid>
                ))}

              {selectedType === "individual" &&
                individualBuyers.map((x, index) => (
                  <Grid key={x.title} item xs={12} md={4}>
                    <Fade
                      style={{
                        transitionDelay:
                          selectedType === "individual"
                            ? `${index * 100}ms`
                            : "0ms",
                      }}
                      in={selectedType === "individual"}
                    >
                      <Paper
                        sx={{
                          padding: 2,
                          height: 430,
                          borderRadius: 5,
                          border: "1px solid #F2F2F3",
                          boxShadow: "inherit",
                        }}
                      >
                        <Box
                          sx={{
                            marginBottom: 2,
                            width: "max-content",
                            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.05)",
                            borderRadius: "4px",
                            paddingX: 1,
                            paddingY: 0.5,
                          }}
                        >
                          <span className="purplegradient">
                            Individual Buyer
                          </span>
                        </Box>
                        <Typography variant="h2" marginY={3}>
                          {x.title}
                        </Typography>
                        {x.description}
                      </Paper>
                    </Fade>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box sx={{ paddingY: 3, position: "relative" }}>
        <Container>
          <Typography variant="h1" textAlign="center" marginY={5}>
            Testimonials from Foundy&apos;s buyer community
          </Typography>
        </Container>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            zIndex: 999,
            position: "absolute",
            top: { xs: "70%", md: "60%" },
            transform: { xs: "translateY(-70%)", md: "translateY(-60%)" },
          }}
        >
          {activeIndex !== 0 && (
            <Button
              onClick={slidePrev}
              sx={{
                "&:hover": {
                  background: "white",
                },
                background: "white",
                borderRadius: 20,

                left: { xs: 10, md: 50 },
              }}
            >
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="30"
                  cy="30"
                  r="29"
                  fill="white"
                  fillOpacity="0.05"
                  stroke="url(#paint0_linear_3886_9126)"
                  strokeWidth="2"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M30.7093 21.4571C31.0998 21.0666 31.733 21.0666 32.1235 21.4571L32.7663 22.0999C33.1565 22.4901 33.1569 23.1227 32.7671 23.5134L26.7935 29.5L32.7671 35.4866C33.1569 35.8773 33.1565 36.5099 32.7663 36.9001L32.1235 37.5429C31.733 37.9334 31.0998 37.9334 30.7093 37.5429L22.6664 29.5L30.7093 21.4571Z"
                  fill="#C2D1D9"
                />
                <mask
                  id="mask0_3886_9126"
                  style={{ maskType: "luminance" }}
                  maskUnits="userSpaceOnUse"
                  x="22"
                  y="21"
                  width="12"
                  height="17"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.7093 21.4571C31.0998 21.0666 31.733 21.0666 32.1235 21.4571L32.7663 22.0999C33.1565 22.4901 33.1569 23.1227 32.7671 23.5134L26.7935 29.5L32.7671 35.4866C33.1569 35.8773 33.1565 36.5099 32.7663 36.9001L32.1235 37.5429C31.733 37.9334 31.0998 37.9334 30.7093 37.5429L22.6664 29.5L30.7093 21.4571Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_3886_9126)">
                  <rect
                    x="11"
                    y="12"
                    width="35"
                    height="35"
                    fill="url(#paint1_linear_3886_9126)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_3886_9126"
                    x1="3"
                    y1="-3.07924e-06"
                    x2="69.0056"
                    y2="17.0868"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#C72A8E" />
                    <stop offset="1" stopColor="#7815DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_3886_9126"
                    x1="12.75"
                    y1="12"
                    x2="51.2533"
                    y2="21.9673"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#C72A8E" />
                    <stop offset="1" stopColor="#7815DA" />
                  </linearGradient>
                </defs>
              </svg>
            </Button>
          )}
          <Button
            onClick={slideNext}
            sx={{
              "&:hover": {
                background: "white",
              },
              background: "white",
              borderRadius: 20,
              transform: "rotate(180deg)",
              left: { xs: 10, md: 50 },
            }}
          >
            <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="30"
                cy="30"
                r="29"
                fill="white"
                fillOpacity="0.05"
                stroke="url(#paint0_linear_3886_9126)"
                strokeWidth="2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.7093 21.4571C31.0998 21.0666 31.733 21.0666 32.1235 21.4571L32.7663 22.0999C33.1565 22.4901 33.1569 23.1227 32.7671 23.5134L26.7935 29.5L32.7671 35.4866C33.1569 35.8773 33.1565 36.5099 32.7663 36.9001L32.1235 37.5429C31.733 37.9334 31.0998 37.9334 30.7093 37.5429L22.6664 29.5L30.7093 21.4571Z"
                fill="#C2D1D9"
              />
              <mask
                id="mask0_3886_9126"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="22"
                y="21"
                width="12"
                height="17"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M30.7093 21.4571C31.0998 21.0666 31.733 21.0666 32.1235 21.4571L32.7663 22.0999C33.1565 22.4901 33.1569 23.1227 32.7671 23.5134L26.7935 29.5L32.7671 35.4866C33.1569 35.8773 33.1565 36.5099 32.7663 36.9001L32.1235 37.5429C31.733 37.9334 31.0998 37.9334 30.7093 37.5429L22.6664 29.5L30.7093 21.4571Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_3886_9126)">
                <rect
                  x="11"
                  y="12"
                  width="35"
                  height="35"
                  fill="url(#paint1_linear_3886_9126)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_3886_9126"
                  x1="3"
                  y1="-3.07924e-06"
                  x2="69.0056"
                  y2="17.0868"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#C72A8E" />
                  <stop offset="1" stopColor="#7815DA" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_3886_9126"
                  x1="12.75"
                  y1="12"
                  x2="51.2533"
                  y2="21.9673"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#C72A8E" />
                  <stop offset="1" stopColor="#7815DA" />
                </linearGradient>
              </defs>
            </svg>
          </Button>
        </Stack>
        <AliceCarousel
          activeIndex={activeIndex}
          onSlideChanged={syncActiveIndex}
          animationType="fadeout"
          animationDuration={800}
          disableDotsControls
          disableButtonsControls
          paddingLeft={paddingResize}
          responsive={responsive}
          mouseTracking
          items={items}
        />
      </Box>
    </Box>
  );
};
