import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";

type TypographyType = "gradient";

const typeStylesDict = (
  theme: any,
): Record<TypographyType, React.CSSProperties> => ({
  gradient: {
    background: theme.palette.gradients.title,
    display: "table",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textShadow: "0px 0px #00000000",
  },
});

const StyledTypography = styled(Typography)<AppTypographyProps>(
  ({ type, theme }) => ({
    color: theme.palette.primary.main,
    ...typeStylesDict(theme)[type],
  }),
);

interface AppTypographyProps extends TypographyProps {
  type: TypographyType;
}

export const AppTypography = ({ children, ...props }: AppTypographyProps) => {
  return <StyledTypography {...props}>{children}</StyledTypography>;
};
