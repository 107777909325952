import { Box, Typography } from "@mui/material";

const TagV1 = ({ text }: { text: string }) => (
  <Box
    sx={{
      display: "inline-flex",
      backgroundColor: "transparent",
      padding: 0,
      alignItems: "center",
      m: 1,
      ml: 0,
      marginRight: 0,
      borderRadius: 2,
      textTransform: "inherit",
    }}
  >
    <Typography variant="body2" fontWeight={500}>
      {text}
    </Typography>
  </Box>
);

export default TagV1;
