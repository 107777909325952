import { API_ENDPOINT } from "../constants";
import { isEmpty, isString } from "../utils/lodash";
import { NotificationService } from "./NotificationService";
import { captureException } from "@sentry/react";
import { LocalStorageKey, StorageService } from "./StorageService";
import { IPaginatedReq, IPaginatedRes } from "../models";
import { LocationService } from "./LocationService";
import { CommonPath, ServerErrors } from "../types";

export enum HttpMethod {
  Get = "get",
  Post = "post",
  Put = "put",
  Patch = "PATCH",
  Delete = "delete",
}

// interface IApiErrorMsg {
//   message: string;
// }

// interface IApiError {
//   code: string;
//   detail: string;
//   messages: IApiErrorMsg[];
// }

interface IApiConfig {
  disableError?: boolean;
}

interface IApiData {
  data?: any;
  config?: IApiConfig;
}

class APIServiceError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, APIServiceError.prototype);
  }
}

const getApiUrl = (path: string, params: any = {}) => {
  const updatedPath =
    path.includes("v2") || path.includes("v3") ? path : "/v1" + path;
  const url = API_ENDPOINT + updatedPath;
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(
      ([, v]) =>
        !Array.isArray(v) && (typeof v === "object" ? !isEmpty(v) : !!v),
    ),
  );
  if (isEmpty(filteredParams)) {
    return url;
  }
  // @ts-ignore
  const urlParams = new URLSearchParams(filteredParams);
  let result = `${url}?${urlParams}`;

  Object.entries(params).forEach(([key, v]) => {
    if (!Array.isArray(v)) {
      return;
    }

    v.forEach((value) => (result += `&${key}=${value}`));
  });

  return result;
};

const headers = new Headers();

export const fetcher = async <T = any>(
  method: HttpMethod,
  url: string,
  data?: any,
): Promise<{ response: Response; result: T }> => {
  const reqHeader = new Headers(headers);
  let body = data;
  const isFormData = data instanceof FormData;

  if (data && !isFormData) {
    reqHeader.append("Accept", "application/json");
    reqHeader.append("Content-Type", "application/json");
    body = JSON.stringify(data);
  }
  const response: Response = await fetch(url, {
    method,
    body,
    headers: reqHeader,
  });

  const contentType = response.headers.get("Content-Type");
  let result;

  try {
    if (contentType?.includes("text")) {
      result = await response.text();
    } else {
      result = await response.json();
    }
  } catch (e) {
    captureException(e);
  }

  return { response, result };
};

const refreshToken = async () => {
  const refresh = StorageService.Get(LocalStorageKey.JwtRefresh);
  if (!refresh) {
    throw new APIServiceError("No refresh token");
  }
  const { response, result } = await fetcher(
    HttpMethod.Post,
    getApiUrl("/token/refresh/"),
    { refresh },
  );

  if (response.status > 400 || !result.access || !result.refresh) {
    throw new APIServiceError("Failed to refresh Token");
  }

  StorageService.Set(LocalStorageKey.Jwt, result.access);
  StorageService.Set(LocalStorageKey.JwtRefresh, result.refresh);

  ApiService.SignIn(result.access);
};

const request = async (
  method: HttpMethod,
  url: string,
  { data, config }: IApiData,
): Promise<any> => {
  const { response, result } = await fetcher(method, url, data);

  if (response.status >= 400) {
    if (response.status === 401) {
      if (result?.code === ServerErrors.TokenNotValid) {
        try {
          await refreshToken();
        } catch (e) {
          LocationService.History.push(CommonPath.SignIn);
          return;
        }
        return request(method, url, { data, config });
      }
    }
    if (!config?.disableError) {
      if (result?.detail) {
        NotificationService.Error(result.detail);
      } else {
        if (typeof result !== "string") {
          const errors: any[] = Object.values(result);
          if (errors) {
            errors.forEach((item) => {
              if (isString(item)) {
                NotificationService.Error(item);
              } else if (Array.isArray(item)) {
                item.forEach(NotificationService.Error);
              }
            });
          }
        } else {
          captureException(result);
        }
      }
    }
    throw new APIServiceError(result.detail);
  } else {
    return result;
  }
};

export const ApiService = {
  Get: <T = any>(
    path: string,
    params: any = {},
    config?: IApiConfig,
  ): Promise<T> => {
    return request(HttpMethod.Get, getApiUrl(path, params), { config });
  },
  Post: <T = any>(
    path: string,
    data?: any,
    config?: IApiConfig,
  ): Promise<T> => {
    return request(HttpMethod.Post, getApiUrl(path), { data, config });
  },
  Put: <T = any>(path: string, data: any, config?: IApiConfig): Promise<T> => {
    return request(HttpMethod.Put, getApiUrl(path), { data, config });
  },
  Patch: <T = any>(
    path: string,
    data: any,
    config?: IApiConfig,
  ): Promise<T> => {
    return request(HttpMethod.Patch, getApiUrl(path), { data, config });
  },
  Delete: <T = any>(path: string, config?: IApiConfig): Promise<T> => {
    return request(HttpMethod.Delete, getApiUrl(path), { config });
  },
  SaveJson: <T>(data?: T, config?: IApiConfig): Promise<T> =>
    request(HttpMethod.Post, getApiUrl("/v3/data/"), {
      data: { value: { data } },
      config,
    }).then((res) => res.value),

  GetJson: <T>(params: any = {}, config?: IApiConfig): Promise<T> =>
    request(HttpMethod.Get, getApiUrl("/v3/data/", params), {
      config,
    }).then((res) => res.results[0]?.value.data),

  SignIn: (accessToken: string) => {
    headers.set("Authorization", "Bearer " + accessToken);
  },
  SignOut: () => {
    headers.delete("Authorization");
  },
  GetPaginatedData: <T, P extends IPaginatedReq = IPaginatedReq>(
    url: string,
    params?: P,
  ): Promise<IPaginatedRes<T>> => {
    return ApiService.Get<IPaginatedRes<T>>(url, params);
  },
};
