import { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  gradient?: boolean;
}
export const UserIcon = (props: IconProps) => (
  <svg
    {...props}
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.80469 14.5547H12.1953C12.6328 14.5547 12.9766 14.4557 13.2266 14.2578C13.4766 14.0651 13.6016 13.7943 13.6016 13.4453C13.6016 12.9505 13.4479 12.4245 13.1406 11.8672C12.8333 11.3099 12.3906 10.7917 11.8125 10.3125C11.2396 9.82812 10.5469 9.4349 9.73438 9.13281C8.92188 8.82552 8.00781 8.67188 6.99219 8.67188C5.98177 8.67188 5.07031 8.82552 4.25781 9.13281C3.44531 9.4349 2.75 9.82812 2.17188 10.3125C1.59896 10.7917 1.15885 11.3099 0.851562 11.8672C0.549479 12.4245 0.398438 12.9505 0.398438 13.4453C0.398438 13.7943 0.523438 14.0651 0.773438 14.2578C1.02344 14.4557 1.36719 14.5547 1.80469 14.5547ZM7 7.28906C7.56771 7.28906 8.09115 7.13542 8.57031 6.82812C9.04948 6.52083 9.43229 6.10677 9.71875 5.58594C10.0104 5.0599 10.1562 4.46875 10.1562 3.8125C10.1562 3.17708 10.0104 2.60417 9.71875 2.09375C9.43229 1.57812 9.04948 1.17188 8.57031 0.875C8.09115 0.578125 7.56771 0.429688 7 0.429688C6.4375 0.429688 5.91667 0.580729 5.4375 0.882812C4.95833 1.1849 4.57292 1.59375 4.28125 2.10938C3.99479 2.61979 3.85156 3.19271 3.85156 3.82812C3.85156 4.47917 3.99479 5.0651 4.28125 5.58594C4.57292 6.10677 4.95573 6.52083 5.42969 6.82812C5.90885 7.13542 6.43229 7.28906 7 7.28906Z"
      fill={props.gradient ? "url(#paint0_linear_159_1069)" : "#746D7D"}
    />
    {props.gradient && (
      <defs>
        <linearGradient
          id="paint0_linear_159_1069"
          x1="-7.89109"
          y1="-5"
          x2="26.1628"
          y2="0.975647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA336B" />
          <stop offset="1" stopColor="#6911E7" />
        </linearGradient>
      </defs>
    )}
  </svg>
);
