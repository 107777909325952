import { useCallback } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormContext } from "react-hook-form";
import { Box, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { TransformFunction, AnyObject } from "yup/lib/types";
import { DateSchema } from "yup";

const DATE_FORMAT = "yyyy-LL-dd";
export const HookedDatePicker = (props: any) => {
  const { register, setValue, getFieldState, watch } = useFormContext();

  if (!props.name) return <Box />;

  const fieldValue = watch(props.name);
  const { error: startError } = getFieldState(props.name);

  const handleChange = useCallback(
    (date: DateTime | null) => {
      const formattedDate = date ? formatDate(date) : null;
      setValue(props.name, formattedDate, { shouldValidate: true });
    },
    [props.name, setValue],
  );
  const defaultValue = formatDate(props.defaultValue) || "";

  return (
    <DatePicker
      {...register(props.name)}
      defaultValue={DateTime.fromISO(defaultValue)}
      value={DateTime.fromISO(fieldValue)}
      onChange={handleChange}
      slots={{ textField: TextField }}
      format={DATE_FORMAT}
      slotProps={{
        textField: {
          ...props,
          helperText: startError?.message || props.helperText,
        },
      }}
    />
  );
};

export const formatDate = (date: DateTime | string): string | null => {
  if (!date) {
    return null;
  }
  if (typeof date === "string") {
    return DateTime.fromFormat(date, DATE_FORMAT).toFormat(DATE_FORMAT);
  }
  return date.toFormat(DATE_FORMAT);
};

export const yupDateTransformer: TransformFunction<
  DateSchema<Date | undefined, AnyObject, Date | undefined>
> = (value, originalValue) => {
  if (originalValue) {
    return null;
  }
  return formatDate(originalValue);
};
