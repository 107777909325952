export const QuotesIcon = () => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0695 20.8378V14.887C13.0695 11.3738 13.7809 8.52632 15.2039 6.34357C16.6273 4.16238 18.1809 2.22187 19.8652 0.520996L21.7822 2.39118C20.8835 3.91838 20.0691 5.49327 19.3425 7.10884C18.6457 8.6675 18.239 10.1834 18.1229 11.6567L22.479 12.5928V20.8378H13.0695ZM0.523438 20.8378V14.887C0.523438 11.3738 1.2349 8.52632 2.65783 6.34357C4.08128 4.16238 5.6349 2.22187 7.3192 0.520996L9.23613 2.39118C8.33748 3.91838 7.52305 5.49327 6.79645 7.10884C6.09963 8.6675 5.69293 10.1834 5.57687 11.6567L9.93296 12.5928V20.8378H0.523438Z"
      stroke="url(#paint0_linear_3729_8527)"
      strokeWidth="1.09531"
      strokeMiterlimit="20"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3729_8527"
        x1="1.62122"
        y1="0.520995"
        x2="25.5224"
        y2="7.20737"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C72A8E" />
        <stop offset="1" stopColor="#7815DA" />
      </linearGradient>
    </defs>
  </svg>
);
