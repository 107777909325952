import {
  Box,
  CircularProgress,
  Container,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { object, array, number, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../../common/context/AppContext";
import i18n from "../../../../common/utils/i18n";
import {
  useCompany,
  useStartupTypes,
  useHandlePageTraversal,
  useBusinessCategoriesAll,
} from "../../../../common/hooks";
import { HookedField } from "../../../components/HookedField";
import { HookedForm } from "../../../components/HookedForm";
import { RoutePaths } from "../../../consts/enums";
import ProfileNavigationButtons from "../ProfileNavigationButtons";
// import PublicBanner from "../PublicBanner";
import { HookedCheckbox } from "../../../components/HookedCheckbox";
import WelcomeSellerModal from "./WelcomeSellerModal";

const schema = object({
  business_type: number().nullable().required().label("Business Category"),
  business_categories: array()
    .min(1)
    .nullable()
    .required()
    .label("Business Types"),
  website_url: string()
    .url()
    .required()
    .nullable()
    .max(600)
    .trim()
    .label(i18n.t("app.website")),
}).required();

export const SellerBasic = () => {
  const { id: startupId } = useParams();
  if (startupId === undefined) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  const { user, updateUserContext } = useAppContext();
  const {
    data: companyData,
    isLoading: isCompanyLoading,
    isError,
  } = useCompany(startupId);
  const {
    data: businessCategories = [],
    isLoading: isBusinessCategoriesLoading,
  } = useBusinessCategoriesAll();

  const { data: businessTypes = [] } = useStartupTypes();
  const formHook = useForm({
    defaultValues: companyData,
    resolver: yupResolver(schema),
  });
  const { handleNext } = useHandlePageTraversal(formHook, Number(startupId));
  const submitAction = formHook.handleSubmit(async () => {
    await updateUserContext();
    handleNext(RoutePaths.REGISTER_SELLER + `/${startupId}/footprint`);
  });

  useEffect(() => {
    // In this the first page after onboarding, startups start empty.
    if (!user.startups) {
      updateUserContext();
    }
  }, [user]);

  const businessTypeId = formHook.watch("business_type");
  const selectedBusinessCategoryIds: string[] | undefined = formHook.watch(
    "business_categories",
  );
  const selectedBusinessType = businessTypes.find(
    (bt) => bt.id === businessTypeId,
  );
  const applicableBusinessCategories = businessCategories.filter(
    (cat) => cat.business_type_name === selectedBusinessType?.name,
  );

  // this checks that the selected categories are of the respective business type before submitting
  const handleSubmit = () => {
    const applicableBusinessCategoriesIds = applicableBusinessCategories.map(
      (cat) => `${cat.id}`,
    );
    const selectedApplicableCategoryIds = selectedBusinessCategoryIds?.filter(
      (c) => applicableBusinessCategoriesIds.includes(c),
    );
    formHook.setValue("business_categories", selectedApplicableCategoryIds);
    submitAction();
  };

  useEffect(() => {
    if (companyData && !isCompanyLoading) {
      formHook.reset({ ...companyData });
    }
  }, [companyData, isCompanyLoading]);

  if (isError) {
    return <Navigate to="/dashboard" replace={true} />;
  }
  if (!companyData || !businessTypes.length) {
    return <CircularProgress />;
  }
  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <WelcomeSellerModal />
      <Container
        maxWidth="md"
        sx={{
          height: "100%",
          padding: 4,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        {/* <PublicBanner /> */}
        <Typography variant="h3" sx={{ marginTop: 3, color: "white" }}>
          Complete your company profile - this process takes 3 minutes
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "common.grayOpacity" }}>
          List your company profile anonymously and start receiving messages
          from interested buyers
        </Typography>
        <Paper elevation={2} sx={{ marginTop: 4, padding: 3 }}>
          <HookedForm formHook={formHook}>
            <Typography variant="h3" sx={{ marginTop: 2, marginBottom: 1 }}>
              Website
            </Typography>
            <HookedField
              component={TextField}
              fullWidth
              label="Website"
              name="website_url"
              variant="filled"
              helperText="https://yourwebsite.com or http://www.yourwebsite.com"
            />

            <Typography variant="h3" sx={{ marginTop: 3, marginBottom: 1 }}>
              What is your business category?
            </Typography>
            <HookedField
              fullWidth
              variant="outlined"
              component={Select}
              name="business_type"
              placeholder="Select your business type"
              defaultValue={companyData.business_type || "---"}
            >
              {businessTypes.map((x, i) => (
                <MenuItem key={i} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </HookedField>

            <Typography variant="h3" sx={{ marginTop: 2 }}>
              What type is your business?
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "common.gray", marginBottom: 2 }}
            >
              {applicableBusinessCategories.length > 0
                ? "Select all that apply"
                : "* Please select a Business Category first"}
            </Typography>
            <HookedCheckbox
              name="business_categories"
              loading={isBusinessCategoriesLoading}
              options={applicableBusinessCategories.map((x) => ({
                label: x.name || "",
                value: String(x.id),
              }))}
            />
          </HookedForm>
        </Paper>
        <ProfileNavigationButtons onSaveAndContinue={handleSubmit} />
      </Container>
    </Box>
  );
};
