import { checkboxClasses } from "@mui/material";
import { alpha, createTheme } from "@mui/material/styles";

const palette = {
  primary: {
    main: "#0000FF",
    light: "#FF00DC",
  },
  secondary: {
    main: "#00004B",
    dark: "#232321",
  },
  info: {
    main: "#7A7A7A",
    light: "#F0F0F0",
    dark: "#B8B8B8",
    lightest: "#FBFBFB",
  },
  gradients: {
    title: "linear-gradient(90deg, #0000FF 0%, #8B00C7  103.07%)",
    card: "linear-gradient(90deg, rgba(0,0,255,1) 0%, rgba(0,255,209,1) 50%, rgba(255,0,220,1) 100%)",
    dark: "linear-gradient(90deg, #21C9DC 0%, #0000FF 103.07%)",
    turqoise: "linear-gradient(90deg, #00FFD1, #21C9DC 100%, #ECECEC 0%)",
    turqoiseDark: "linear-gradient(180deg, #00FFD1 -24.23%, #0000FF 128.35%)",
  },
  common: {
    black: "#150E1F",
    blackBlue: "#150E1F",
    white: "#fff",
    turqoise: "#00FFD1",
    lightgray: "#F3F3F2",
    gray: "#746D7D",
    ghostWhite: "#FBF9FE",
    grayOpacity: "rgba(255, 255, 255, 0.5)",
    grayLighterOpacity: "rgba(255, 255, 255, 0.1)",
    orange: "#ff8200",
    pink: "#ff02dc",
    lightpurple: "#F2EEF6",
  },
};

const theme = createTheme({
  palette,
  typography: {
    allVariants: { fontFamily: "Inter" },
    button: {
      textTransform: "none",
    },
    h1: {
      fontSize: 30,
      fontWeight: 600,
      fontFamily: "Inter",
      "& span": {
        background: "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      },
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
      fontFamily: "Inter",
      "& span": {
        background: "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      },
    },
    h3: {
      fontSize: 16,
      fontWeight: 700,
      fontFamily: "Inter",
      "& span": {
        background: "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      },
    },
    h4: {
      fontSize: 14,
      fontWeight: 600,
      fontFamily: "Inter",
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Inter",
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      fontFamily: "Inter",
      lineHeight: "inherit",
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Inter",
    },
    subtitle3: {
      fontSize: 14,
      fontWeight: 600,
      fontFamily: "Inter",
      backgroundColor: "#EA336B",
      background: "linear-gradient(99.95deg, #EA336B -24.43%, #6911E7 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      display: "inline-block",
    },
    caption: {
      fontSize: 14,
      fontFamily: "Inter",
      letterSpacing: "normal",
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Inter",
      "& .gradient": {
        background: "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      },
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: palette.secondary.main,
          borderRadius: 0,
          padding: "12px 0",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
        filled: {
          background: palette.primary.light,
          color: palette.common.white,
        },
        outlined: {
          color: palette.primary.light,
          borderColor: palette.primary.light,
          fontWeight: 600,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar1Determinate: {
          background: palette.gradients.dark,
          borderRadius: 40,
        },
        root: {
          background: palette.info.lightest,
          height: 6,
          borderRadius: 40,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          border: "1px solid #F2F2F3",
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
        },
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          fontSize: 16,
        },
        contained: {
          fontSize: 16,
          fontWeight: 600,
        },
      },
      variants: [
        {
          props: { variant: "roundlink" },
          style: {
            fontWeight: 600,
            fontFamily: "Inter",
            textTransform: "capitalize",
            background: "transparent",
            color: "common.gray",
            borderRadius: 30,
            "&:hover": {
              background: "rgba(255,255,255,0.1)",
            },
          },
        },
        {
          props: { variant: "roundapple" },
          style: {
            padding: [2, 2],
            fontWeight: 600,
            fontFamily: "Inter",
            background: "black",
            color: "white",
            borderRadius: 30,
            borderWidth: "3px",
            borderColor: "transparent",
            borderStyle: "solid",
            textTransform: "capitalize",

            "&:hover": {
              borderColor: "rgba(255,255,255,0.3)",
              borderWidth: "3px",
              color: "white",
              background: "#8E8EF2",
            },
          },
        },
        {
          props: { variant: "roundsecondary" },
          style: {
            padding: [2, 2],
            fontWeight: 600,
            fontFamily: "Inter",
            background: "#F3F3F2",
            color: "#150E1F",
            borderRadius: 30,
            borderWidth: "3px",
            borderColor: "transparent",
            borderStyle: "solid",
            textTransform: "capitalize",
            "&.MuiButton-roundsecondaryError": {
              backgroundColor: "rgb(255 0 0 / 44%)",
              color: "white",
              "&:hover": {
                backgroundColor: "rgb(255 0 0 / 80%)",
              },
            },
            "&:hover": {
              borderColor: "rgba(255,255,255,0.3)",
              borderWidth: "3px",
              color: "white",
              background: "#8E8EF2",
            },
          },
        },
        {
          props: { variant: "roundoutlinedgradient" },
          style: {
            fontWeight: 600,
            fontFamily: "Inter",
            background: "white",
            color: "#0F0B14",
            borderRadius: 30,
            textTransform: "capitalize",
            position: "relative",
            display: "block",
            "& span": {
              background:
                "linear-gradient(99.95deg, #C32993 -24.43%, #A15AF4 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            },
            "&:after": {
              display: "inline-block",
              content: "''",
              transition: "ease-in-out 500ms",

              background:
                "linear-gradient(99.95deg, #C32993 -24.43%, #8018D4 100%)",
              position: "absolute",
              margin: "-2px",
              borderRadius: "36px",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              zIndex: -1,
            },
            "&:hover": {
              background: "white",
            },
          },
        },
        {
          props: { variant: "roundoutlinewhite" },
          style: {
            fontWeight: 600,
            fontFamily: "Inter",
            background: "transparent",
            color: "white",
            borderRadius: 30,
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "white",
            textTransform: "capitalize",
            display: "block",
            "& span": {
              background:
                "linear-gradient(99.95deg, #C32993 -24.43%, #A15AF4 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            },
          },
        },
        {
          props: { variant: "roundoutlinedgradientblue" },
          style: {
            fontWeight: 600,
            fontFamily: "Inter",
            background: "white",
            color: "#0F0B14",
            borderRadius: 30,
            textTransform: "capitalize",
            position: "relative",
            display: "block",
            "& span": {
              background:
                "linear-gradient(99.95deg, #8E8EF2 -24.43%, #6911E7 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            },
            "&:after": {
              display: "inline-block",
              content: "''",
              transition: "ease-in-out 500ms",

              background:
                "linear-gradient(99.95deg, #8E8EF2 -24.43%, #6911E7 100%)",
              position: "absolute",
              margin: "-2px",
              borderRadius: "36px",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              zIndex: -1,
            },
            "&:hover": {
              background: "white",
            },
          },
        },
        {
          props: { variant: "roundprimary" },
          style: {
            fontWeight: 600,
            fontFamily: "Inter",
            background: "white",
            color: "#0F0B14",
            borderRadius: 30,
            border: "1px solid #F3F3F2",
            textTransform: "capitalize",
            position: "relative",
            display: "inline-flex",
            "& span": {
              background:
                "linear-gradient(99.95deg, #C32993 -24.43%, #A15AF4 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: 700,
            },
            "&:after": {
              display: "inline-block",
              content: "''",
              transition: "ease-in-out 500ms",
              opacity: 0,
              background:
                "linear-gradient(99.95deg, #EA336B -24.43%, #6911E7 100%);",
              position: "absolute",
              margin: "-3px",
              borderRadius: "36px",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              zIndex: -1,
            },
            "&:hover": {
              background: "white",
              "&:after": {
                opacity: 1,
              },
            },
          },
        },
        {
          props: { variant: "roundgradient" },
          style: {
            fontWeight: 600,
            fontFamily: "Inter",
            fontSize: 16,

            background:
              "linear-gradient(99.95deg, #EA336B -24.43%, #6911E7 100%)",
            color: "white",
            borderRadius: 30,
            textTransform: "capitalize",
            transition: "background 500ms ease-in-out",
            "&:hover": {
              boxShadow: "inset 0px 2px 0px rgba(255, 255, 255, 0.1)",
              background:
                "linear-gradient(99.95deg, #6911E7 -24.43%, #EA336B 100%)",
            },
          },
        },
        {
          props: { variant: "roundgradientblue" },
          style: {
            fontWeight: 600,
            fontFamily: "Inter",
            fontSize: 16,
            background:
              "linear-gradient(99.95deg, #8E8EF2 -24.43%, #6911E7 100%)",
            color: "white",
            borderRadius: 30,
            textTransform: "capitalize",
            transition: "background 500ms ease-in-out",
            "&:hover": {
              boxShadow: "inset 0px 2px 0px rgba(255, 255, 255, 0.1)",
              background:
                "linear-gradient(99.95deg, #6911E7 -24.43%, #8E8EF2 100%)",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            color: "common.black",
            border: "2px solid #F3F3F2",
            "&:hover": {
              border: "2px solid",
              borderColor: "#C72A8E !important",
              boxShadow: "0px 0px 10px rgba(203, 44, 138, 0.4) !important",
              borderRadius: "8px",
            },
          },
        },
      ],
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            "&.MuiInputBase-root": {
              background: "#F8F8F8",
              border: "1px solid #F3F3F2",
              borderRadius: "8px",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "2px solid",
              borderColor: "#C72A8E !important",
              boxShadow: "0px 0px 10px rgba(203, 44, 138, 0.4) !important",
              borderRadius: "8px",
            },
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            "& .MuiInputBase-root": {
              background: "#F8F8F8",
              border: "1px solid #F3F3F2",
              borderRadius: "8px",
            },
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "2px solid",
              borderColor: "#C72A8E !important",
              boxShadow: "0px 0px 10px rgba(203, 44, 138, 0.4) !important",
              borderRadius: "8px",
            },
          },
        },
        {
          props: { variant: "filled" },
          style: {
            "& .MuiFilledInput-root.Mui-focused": {
              border: "2px solid",
              borderColor: "#C72A8E !important",
              boxShadow: "0px 0px 10px rgba(203, 44, 138, 0.4) !important",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              background:
                "linear-gradient(99.95deg, #C32993 -24.43%, #A15AF4 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            },
            "& .MuiFormHelperText-root": {
              backgroundColor: "#F3F3F2",
              borderRadius: 8,
              padding: 12,
              margin: 0,
              marginTop: 10,
            },

            "& .MuiFormLabel-root": {
              fontSize: "14px",
              lineHeight: "2em",
              fontFamily: "Inter",
              color: "#7F7D85",
              fontWeight: 500,
            },
            "& .MuiFilledInput-root:before": {
              display: "none",
            },
            "& .MuiFilledInput-root:after": {
              display: "none",
            },
            "& .MuiFilledInput-root": {
              fontWeight: 500,
              border: "2px solid #F3F3F2",
              overflow: "hidden",
              borderRadius: 16,
              borderColor: "#F3F3F2",
              backgroundColor: "#FFFFFF",
              // transition: theme.transitions.create([
              //   "border-color",
              //   "background-color",
              //   "box-shadow",
              // ]),
              "&:hover": {
                backgroundColor: "#FFFFFF",
              },
              "&.Mui-focused": {
                backgroundColor: "#FFFFFF",
                boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
                borderColor: palette.primary.main,
              },
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#150E1F",
          borderRadius: 8,
          [`&.${checkboxClasses.checked}`]: {
            color: "#34A853",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: "Roboto",
          fontSize: 12,
        },
      },
    },
  },
});

export default theme;
