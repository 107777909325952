import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntercom } from "react-use-intercom";
import { hotjar } from "react-hotjar";
import ReactGA from "react-ga4";
import { IStartup, IUserFull } from "../models";
import { AppService } from "../services/AppService";
import { AppLoader } from "../components/AppLoader";
import { MarketplaceType, UserType } from "../types";

type UserUpdater = (
  v: UserPayload | ((prevState: IUserFull) => IUserFull),
) => void;

type TabUpdater = (v: string | ((prevState: string) => string)) => void;

type StartupGetter = (startupId: number) => IStartup | undefined;
type StartupUpdater = (startup: IStartup) => void;

interface IAppContext {
  user: IUserFull;
  updateUser: UserUpdater;
  isAuthed: boolean;
  usersSelectedRole: UserType;
  marketplaceType: MarketplaceType;
  setUsersSelectedRole: (v: UserType) => void;
  setMarketplaceType: (v: MarketplaceType) => void;
  handleMobileMenu: () => void;
  mobileOpen: boolean;
  updateTab: TabUpdater;
  lastTab: string;
  getCurrentStartup: StartupGetter;
  updateStartup: StartupUpdater;
  updateUserContext: () => Promise<IUserFull | undefined>;
  getStartupId: () => number | undefined;
}

const placeholderStartup: IStartup = {
  id: 0,
  updated_at: "1970-01-01",
  business_type: 1,
  has_financial_summary: false,
  open_to_offer: true,
  under_offer: false,
  seller: 0,
  files: [],
  created_at: "1970-01-01",
  is_validated: true,
  chat_auto_approve: false,
  published: true,
  industry: null,
  business_model: null,
  custom_highlights: null,
  highlights: null,
  key_assets: null,
  key_assets_new: null,
  headline: null,
  finished_at: null,
  acquisition_type: null,
  description: null,
  annual_recurring_revenue: null,
  annual_cost: null,
  number_of_customers: null,
  date_founded: null,
  asking_price: null,
  startup_team_size: null,
  growth_opportunity: null,
  keywords: null,
  reason: null,
  financing: null,
  last_month_revenue: null,
  last_month_profit: null,
  annual_revenue: null,
  annual_profit: null,
  contact_first_name: null,
  contact_last_name: null,
  name: null,
  website_url: null,
  country: null,
  city: null,
  contact_email: null,
  country_of_incorporation: null,
  registering_body: null,
  company_registration_number: null,
  registering_url: null,
  sold: false,
};

const AppContext = createContext<IAppContext | null>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("hook must be used within a context");
  }

  return context;
};

interface ISignUpProviderProps {
  children: ReactNode;
}

const initialUser: IUserFull = {
  id: 0,
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  active_role: "",
  buyer: 0,
  seller: 0,
  allow_news_letters: false,
  is_email_verified: false,
  is_staff: false,
};

type UserPayload = IUserFull | undefined;

export const AppProvider = ({ children }: ISignUpProviderProps) => {
  const intercom = useIntercom();
  const [user, setUser] = useState<IUserFull>(initialUser);
  const [lastTab, setLastTab] = useState("companies");
  const isAuthed = useMemo(() => !!user.id, [user]);
  const [loading, setLoading] = useState<boolean>(true);
  const [usersSelectedRole, setUsersSelectedRole] = useState<UserType>(
    UserType.Buyer,
  );
  const [mobileOpen, setMobileOpen] = useState(false);
  const [marketplaceType, setMarketplaceType] = useState<MarketplaceType>(
    MarketplaceType.Startup,
  );

  const handleMobileMenu = () => {
    setMobileOpen((curr) => !curr);
  };

  const getStartup: StartupGetter = (startupId: number) => {
    return user.startups?.find((startup) => startup.id === startupId);
  };

  const updateStartup: StartupUpdater = (partialStartup: Partial<IStartup>) => {
    if (!user.startups || !partialStartup.id) {
      throw Error("No startups found, but trying to update one!");
    }
    const newStartups = user.startups.map((existingStartup) =>
      existingStartup?.id === partialStartup.id
        ? { ...existingStartup, ...partialStartup }
        : existingStartup,
    );
    const newUser = { ...user, startups: newStartups };
    setUser(newUser);
  };

  const updateUser: UserUpdater = useCallback((v) => {
    if (typeof v !== "object") {
      if (v === undefined) {
        v = initialUser;
      }
    }

    if (typeof v === "function") {
      setUser(v);
      return;
    }
    // Only here for legacy files to not freak out. Please do not use user.startup anywhere,
    // and get the current startup wanted by calling getCurrentStartup defined above.
    v.startup = placeholderStartup;
    setUser(v);
  }, []);

  const updateTab: TabUpdater = useCallback((v) => {
    if (!v) setLastTab(user.active_role === "seller" ? "buyers" : "companies");

    setLastTab(v);
  }, []);

  useEffect(() => {
    if (!isAuthed) return;

    updateUser(user);
    intercom.update({
      customAttributes: {
        type: user.active_role,
        role_id: user.buyer || user.seller,
      },
      userId: String(user.id),
      email: user.email,
      name: user.first_name,
      phone: user.phone_number || undefined,
    });

    if (hotjar.initialized()) {
      hotjar.identify("USER_ID", {
        first_name: user.first_name,
        last_name: user.last_name,
        user_id: user.id,
        phone: user.phone_number,
        role: user.active_role,
      });
    }
    if (user.active_role) {
      const defaultTab = user.active_role === "seller" ? "buyers" : "companies";
      updateTab(defaultTab);
    }
    ReactGA.set({
      userId: user.id,
      profileId: user.sellerFull ? user.startups?.[0].id : user.buyerFull?.id,
      role: user.active_role,
    });
  }, [
    user.active_role,
    user.buyerFull,
    user.sellerFull,
    user.startups,
    isAuthed,
  ]);

  const updateUserContext = async () => {
    setLoading(true);
    const user = await AppService.Init();
    updateUser(user);
    if (user) {
      const defaultTab = user.active_role === "seller" ? "buyers" : "companies";
      updateTab(defaultTab);
    }
    setLoading(false);
    return user;
  };

  useEffect(() => {
    updateUserContext();
  }, [updateUser]);

  if (loading) {
    return <AppLoader />;
  }

  return (
    <AppContext.Provider
      value={{
        user,
        updateUser,
        isAuthed,
        usersSelectedRole,
        setUsersSelectedRole,
        marketplaceType,
        setMarketplaceType,
        handleMobileMenu,
        mobileOpen,
        updateTab,
        lastTab,
        getCurrentStartup: getStartup,
        updateStartup,
        updateUserContext,
        // FIXME: this is a hack around broken type definitions that need to be updated.
        getStartupId: () => user.sellerFull?.startups[0] as unknown as number,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
