import { useState } from "react";
import { ProBuyerStepNoSecret } from "./ProBuyer";
import { BasicBuyerStep } from "./BasicBuyer";

export const CheckoutForm = () => {
  const [activeForm, setActiveForm] = useState("pro");

  const handleTab = (value: string) => {
    setActiveForm(value);
  };

  return (
    <>
      {activeForm === "pro" && (
        <ProBuyerStepNoSecret handleTab={handleTab} selectedTab={activeForm} />
      )}
      {activeForm === "basic" && (
        <BasicBuyerStep handleTab={handleTab} selectedTab={activeForm} />
      )}
    </>
  );
};
