import { DateTime } from "luxon";
import { truncate } from "lodash";
import {
  AcquisitionTypeMap,
  NUMBER_ABBREVIATIONS,
  PRESENT_DATE,
} from "../constants";
import { IUser } from "../models";
import { LAUNCH_DATE } from "../../v2/consts/consts";

export const getLast = <T>(arr: T[]): T => {
  return arr[arr.length - 1];
};

export const getObjFields = <T>(obj: T, fields: Array<keyof T>): Partial<T> => {
  return fields.reduce((acc, item) => {
    acc[item] = obj[item];
    return acc;
  }, {} as Partial<T>);
};

export const truncateText = (string?: string, length?: number) => {
  return truncate(string, {
    length: length || 150, // maximum 30 characters
    separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
  }).trim();
};
export const supportsEmoji = (emoji: string): boolean => {
  const ctx = document.createElement("canvas").getContext("2d");
  if (!ctx) return false;
  ctx.canvas.width = ctx.canvas.height = 1;
  ctx.fillText(emoji, -4, 4);
  // Not a transparent pixel
  return ctx.getImageData(0, 0, 1, 1).data[3] > 0;
};

export const isBasicPlan = (user: IUser) => {
  if (user.active_role === "seller") return false;
  if (!user.subscription_plan || user.subscription_plan === "free") return true;
  if (user.subscription_plan === "basic") return true;
  return false;
};

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const greetingsPhrase = () => {
  const time = new Date().getHours();
  return (
    "Good " + (time < 12 ? "Morning" : time < 18 ? "Afternoon" : "Evening")
  );
};
export const getAge = (from: Date, to: Date = PRESENT_DATE) => {
  if (from > to) return 0;
  const diff = +to - +from;
  // miliseconds from epoch
  const ageDate = new Date(diff);
  return Math.abs(ageDate.getUTCFullYear() - 1970) || 1;
};

export const showValue = (
  value: any = undefined,
  {
    placeholder = "-",
    prefix,
    suffix,
  }: {
    prefix?: string;
    suffix?: string;
    placeholder?: string;
  } = {},
) => {
  if (!value) return placeholder;
  const lvalue = Array.isArray(value) ? value.join(", ") : value;

  return `${prefix ? `${prefix} ` : ""}${lvalue}${suffix ? ` ${suffix}` : ""}`;
};

export const showAcquisitionTypeValue = (value: any = undefined) => {
  if (!value) return "-";
  if (value.length >= 3) return "Any";
  const result = value.map((x: string) => AcquisitionTypeMap.get(x)).join(", ");
  return result;
};

export function abbreviateNumber(num: any, digit?: number): string | undefined {
  if (!num || isNaN(num)) return;

  const sign = Math.sign(num) >= 0;
  const defaultValue = num.toString();

  num = Math.round(Math.abs(num));

  const tier = (Math.log10(num) / 3) | 0;
  if (tier === 0) return defaultValue;

  const suffix = NUMBER_ABBREVIATIONS[tier];
  if (!suffix) return defaultValue;

  const scale = Math.pow(10, tier * 3);
  const scaled = num / scale;

  let value: number = scaled;

  if (suffix && suffix === NUMBER_ABBREVIATIONS[1]) {
    value = +value.toFixed(1);
  }

  if (suffix && suffix !== NUMBER_ABBREVIATIONS[0]) {
    value = +value.toFixed(2);
  }

  if (!isNaN(digit as number)) {
    value = +value.toFixed(digit);
  }

  return (!sign ? "-" : "") + value + suffix;
}

export const showCurrency = (
  v: string | number | null | undefined,
  currency = "£",
) => showValue(v, { prefix: currency });

export const isURL = (string: string) => {
  let url;

  try {
    url = new URL(string);
  } catch {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const getFlagEmoji = (countryCode: string): string => {
  const codePoints = countryCode
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export const getSendbirdLink = (user: IUser) => {
  return `https://dashboard.sendbird.com/${process.env.REACT_APP_SENDBIRD_ID}/users/${user.chat_id}`;
};

export const getLast12Months = () => {
  const now = DateTime.now();
  const twelveMonthsBefore = DateTime.now().minus({ months: 12 });
  const months = [];
  let tracker = 0;

  while (
    now.diff(twelveMonthsBefore.plus({ month: tracker }), "months").months >= 0
  ) {
    months.push(
      twelveMonthsBefore.plus({ month: tracker }).toFormat("MMMM yyyy"),
    );
    tracker += 1;
  }

  return months.reverse().slice(1);
};

export const humanFileSize = (size: number) => {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    // @ts-ignore
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
};

export const isBeforeV2 = (date: string) => {
  const checkDate = DateTime.fromISO(date)
    .diff(DateTime.fromFormat(LAUNCH_DATE, "dd/LL/yyyy"), "days")
    .toObject();

  if (checkDate.days) {
    return checkDate.days < 0;
  }

  return false;
};
