export const DigitalAgency = () => (
  <svg
    height="100%"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2497_5155"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="3"
      width="81"
      height="82"
    >
      <path
        d="M34.8166 58.4288C20.8791 58.4288 9.58057 47.1302 9.58057 33.1928C9.58057 19.2553 20.8791 7.95673 34.8166 7.95673C48.7541 7.95673 60.0526 19.2553 60.0526 33.1928"
        stroke="white"
        strokeWidth="9.61372"
        strokeLinejoin="round"
      />
      <path
        d="M79.8811 33.1927H33.0142V80.0597H79.8811V33.1927Z"
        fill="white"
        stroke="white"
        strokeWidth="9.61372"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#mask0_2497_5155)">
      <path
        d="M0.567871 0.74646H87.0914V87.27H0.567871V0.74646Z"
        fill="url(#paint0_linear_2497_5155)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2497_5155"
        x1="4.89405"
        y1="0.746456"
        x2="100.078"
        y2="25.3867"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C72A8E" />
        <stop offset="1" stopColor="#7815DA" />
      </linearGradient>
    </defs>
  </svg>
);
