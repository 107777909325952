import { Box, Grid } from "@mui/material";

type Props = {
  logos: { id: string; alt: string; image: string }[];
};

const LogosInner = ({ logos }: Props) => {
  return (
    <Grid container spacing={2}>
      {logos.map((logo) => (
        <Grid
          item
          xs={6}
          md={12 / logos.length}
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            width: "100%",
          }}
          key={logo.id}
        >
          <Box
            component="img"
            src={logo.image}
            sx={{ width: "max-content" }}
            alt={logo.alt}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default LogosInner;
