import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import ConfettiExplosion from "react-confetti-explosion";
import { UserCompletedIcon } from "../../../../common/assets/icons/UserCompleted";
import { RoutePaths } from "../../../consts/enums";
import { useAppContext } from "../../../../common/context/AppContext";
import { useEffect } from "react";
import { FillingPercentsService } from "../../../../common/services/FillingPercentsService";
import { EventName, trackEvent } from "../../../../common/utils/helpers";
import { Navigate, useParams } from "react-router-dom";
import WelcomeSellerModal from "./WelcomeSellerModal";
import FundraisingBlock from "../FundraisingBlock";

export const SellerCompleted = () => {
  const { id: startupId } = useParams();
  if (startupId === undefined) {
    return <Navigate to="/dashboard" replace={true} />;
  }
  const { getCurrentStartup } = useAppContext();
  const startup = getCurrentStartup(Number(startupId));
  const navigate = useNavigate();

  useEffect(() => {
    const percentage = FillingPercentsService.GetPercentageCompany(startup);

    if (percentage === 100) {
      trackEvent(EventName.COMPLETED_SELLER);
    }
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100%",
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "common.blackBlue",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top right",
      }}
    >
      <WelcomeSellerModal />
      <ConfettiExplosion width={1600} particleCount={250} />

      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 10,
            marginBottom: 4,
            paddingX: 10,
            paddingY: 5,
            backgroundColor: "common.grayLighterOpacity",
            borderRadius: 2,
          }}
        >
          <UserCompletedIcon />
          <Typography
            sx={{
              marginTop: 3,
              marginBottom: 2,
              color: "white",
              fontWeight: 500,
              fontFamily: "Inter",
              fontSize: 24,
            }}
          >
            Your Seller listing application has now been completed
          </Typography>
          <Typography variant="body2" color="white">
            Your application has been submitted for approval. The approval
            process is usually same day (unless it is a weekend).
          </Typography>
        </Box>
        <FundraisingBlock />
        <Button
          variant="roundgradient"
          onClick={() => navigate(RoutePaths.YOU_ACQUISITION)}
        >
          Continue to Marketplace
        </Button>
        <Box
          component="div"
          my={2}
          sx={{
            width: "100%",
          }}
        />
      </Container>
    </Box>
  );
};
