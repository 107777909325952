import { ApiService } from "./ApiService";
import { IBuyer, IUserFull } from "../models";
import { pick, omit } from "lodash";

export enum BuyerPlanStatus {
  "FREE" = "Basic",
  "STARTER" = "Starter Pro",
  "ADVANCED" = "Advanced Pro",
}

interface JsonBucketFields {
  debt_finance_value?: number;
  social_media?: string;
  trailing_profit?: Array<number>; // v2-v3 migration [min, max]
  trailing_revenue?: Array<number>; // v2-v3 migration [min, max]
}
const jsonDataKeys: (keyof JsonBucketFields)[] = [
  "debt_finance_value",
  "social_media",
  "trailing_profit",
  "trailing_revenue",
];

export const BuyerService = {
  GetBuyer: async (): Promise<IBuyer> => {
    const buyerV2 = ApiService.Get<IBuyer>("/v2/buyer/");
    const buyerV3 = ApiService.GetJson<JsonBucketFields>();

    const data = await Promise.all([buyerV2, buyerV3]);
    const buyer: IBuyer = {
      ...data[0],
      ...data[1],
    };
    return buyer;
  },
  PatchBuyer: (data: IBuyer) => {
    return Promise.all([
      ApiService.SaveJson(pick(data, ...jsonDataKeys)),
      ApiService.Patch("/v2/buyer/", omit(data, ...jsonDataKeys)).then(
        (res) => res,
      ),
    ]);
  },
  GetBuyerStatus: (user: IUserFull): BuyerPlanStatus | undefined => {
    const userPlan = user.subscription_plan;

    if (userPlan === "free") {
      return BuyerPlanStatus.FREE;
    }
    if (userPlan === "starter") {
      return BuyerPlanStatus.STARTER;
    }
    if (userPlan === "advanced") {
      return BuyerPlanStatus.ADVANCED;
    }
    return BuyerPlanStatus.FREE;
  },
  GetListingViewsCountUnviewed: () => {
    return ApiService.Get("/v2/buyer/listing-views-count/").then((res) => res);
  },
};
