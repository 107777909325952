import { Typography } from "@mui/material";

const PrivateBanner = ({
  color,
  secondaryColor,
}: {
  color?: string;
  secondaryColor?: string;
}) => (
  <>
    <Typography fontWeight={700} sx={{ color }}>
      PRIVATE INFORMATION
    </Typography>
    <Typography sx={{ color: secondaryColor }}>
      Other Foundy users will NOT be able to see this information
    </Typography>
  </>
);

export default PrivateBanner;
