import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { RoutePaths } from "../../consts/enums";
import { MenuItemsInterface, Menus, routes } from "../../consts/routes";
import { SideNavigation } from "../SideNavigation";
import MenuIcon from "@mui/icons-material/Menu";
import { useAppContext } from "../../../common/context/AppContext";
import { matchPath } from "react-router";
import FoundyLogo from "../../../common/assets/logo/FoundyLogo";
import { UserType } from "../../../common/types";

interface LayoutProps {
  sellerRegister?: boolean;
  buyerRegister?: boolean;
  basicSignUp?: boolean;
  dashboard?: boolean;
}

export const Layout = ({
  sellerRegister,
  buyerRegister,
  basicSignUp,
  dashboard,
}: LayoutProps) => {
  const { id: startupIdStr } = useParams();
  const startupId = Number(startupIdStr);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();
  const { user, handleMobileMenu } = useAppContext();
  const where = location.pathname;
  const route = routes.find((x) => {
    return x.path === where || matchPath(x.path, where);
  });

  const getMatchingMenu = () => {
    if (!route?.menu) return;
    if (sellerRegister) return Menus[RoutePaths.REGISTER_SELLER];
    if (buyerRegister) return Menus[RoutePaths.REGISTER_ACQUIRER];
    if (dashboard) return Menus[RoutePaths.DASHBOARD];
    if (basicSignUp) {
      const baseRegisterMenus = Menus[RoutePaths.REGISTER];
      let nextStepsItems: MenuItemsInterface[] = [];
      if (user.active_role) {
        nextStepsItems =
          Menus[
            user.active_role === UserType.Seller
              ? RoutePaths.REGISTER_SELLER
              : RoutePaths.REGISTER_ACQUIRER
          ].items;
      }
      return {
        ...baseRegisterMenus,
        items: [...baseRegisterMenus.items, ...nextStepsItems],
      };
    }
    return route?.menu;
  };

  const menuItems = getMatchingMenu();

  const getSideMenu = () => {
    if (!menuItems) return;

    const nav = basicSignUp ? (
      <SideNavigation
        startupId={startupId}
        disableGradient={false}
        items={[]}
      />
    ) : (
      <SideNavigation
        startupId={startupId}
        disableGradient={menuItems?.disableGradient}
        items={menuItems?.items}
      />
    );

    return (
      <Box
        sx={{
          gridArea: "1 / 1 / 6 / 2",
        }}
      >
        {nav}
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Foundy {route?.title ? `- ${route.title}` : ""}</title>
      </Helmet>
      {!matches && (basicSignUp || sellerRegister || buyerRegister) && (
        <AppBar
          position="fixed"
          sx={{
            "&.MuiAppBar-root": {
              backgroundColor: "transparent",
              boxShadow: "none",
              width: basicSignUp ? "100%" : "max-content",
              borderRadius: "500px",
            },
            width: { sm: `calc(100% - 60vw)` },
            ml: { sm: `60vw` },
          }}
        >
          <Toolbar
            sx={{
              display: basicSignUp ? "flex" : "inherit",
              justifyContent: "space-between",
            }}
          >
            {basicSignUp && <FoundyLogo />}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleMobileMenu}
              sx={{
                display: { sm: "none" },
                background: basicSignUp ? "#bf0ba7" : "inherit",
              }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Box
        sx={{
          display: !matches ? "flex" : "grid",
          gridTemplateColumns: menuItems?.items
            ? "320px repeat(4, 1fr)"
            : "repeat(5, 1fr)",
          gridTemplateRows: "repeat(5, 1fr)",
          gridColumnGap: "0px",
          gridRowGap: "0px",
          height: "100vh",
        }}
      >
        {getSideMenu()}
        <Box
          sx={{
            width: "100%",
            minHeight: "100%",
            gridArea: route && route.menu ? "1 / 2 / 6 / 6" : "1 / 1 / 6 / 6",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
