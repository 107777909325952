import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useNavigate } from "react-router";
import { useAppContext } from "../../../common/context/AppContext";
import { isBasicPlan } from "../../../common/utils";
import { RoutePaths } from "../../consts/enums";
import { PlansModal } from "../PlansModal";
import LockIcon from "@mui/icons-material/Lock";
import MenuIcon from "@mui/icons-material/Menu";

interface MarketplaceHeaderProps {
  handleTab?: (tab: string) => void;
  selectedTab?: string;
  children?: any;
  raw?: boolean;
}

export const Header = ({ children, raw = false }: MarketplaceHeaderProps) => {
  const { showModal } = useModal();
  const { user, handleMobileMenu } = useAppContext();

  const navigate = useNavigate();

  const confirmationModal = () => {
    const modal = showModal(PlansModal, {
      onCancel: () => {
        modal.hide();
      },
    });
  };

  return !raw ? (
    <>
      <Box
        sx={{
          minHeight: 88,
          display: "flex",
          justifyContent: children ? "space-between" : "flex-end",
          alignItems: "center",
          paddingX: 3,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: "common.lightgray",
          background: "white",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.025)",
          position: "relative",
          zIndex: 1,
          gap: 2,
        }}
      >
        {children}
        <Box
          sx={{
            display: "flex",
            minWidth: { xs: 0, md: "180px" },
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 3,
          }}
        >
          {!isBasicPlan(user) && (
            <Box
              sx={{
                paddingX: 2,
                paddingY: 1,
                color: "white",
                fontWeight: 700,
                textTransform: "uppercase",

                fontSize: 14,
                background:
                  "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
                borderRadius: 30,
              }}
            >
              Pro
            </Box>
          )}

          <Avatar
            onClick={() => navigate(RoutePaths.SETTINGS)}
            variant="circular"
            sx={{
              height: 40,
              width: 40,
              cursor: "pointer",
              display: { xs: "none", md: "inline-flex" },
            }}
          />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleMobileMenu}
            sx={{ mr: { xs: 0, md: 2 }, display: { xs: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Box>
      {user.active_role === "seller" && (
        <Box
          sx={{
            background:
              "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
            padding: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 600, color: "white" }}>
            You can now create up to 50 listings
          </Typography>
          <Button
            onClick={() => navigate("/your-listings")}
            sx={{ height: 25, fontSize: 14 }}
            variant="roundprimary"
          >
            Add now
          </Button>
        </Box>
      )}
      {isBasicPlan(user) && user.active_role === "buyer" && (
        <Box
          sx={{
            background: "#6911E7",
            color: "white",
            padding: 1,
            fontWeight: 500,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LockIcon sx={{ marginRight: 1 }} />
          <Typography
            variant="body1"
            sx={{
              display: { xs: "none", md: "block" },
              fontSize: { xs: 12, md: "1rem" },
            }}
          >
            To message sellers and access other popular benefits please upgrade
            to a Pro Buyer member account
          </Typography>
          <Typography
            variant="body1"
            sx={{
              display: { xs: "block", md: "none" },
              fontSize: { xs: 12, md: "1rem" },
            }}
          >
            Message sellers today!
          </Typography>
          <Button
            onClick={() => confirmationModal()}
            variant="roundprimary"
            sx={{ marginLeft: 2, height: 32, fontSize: { xs: 10, md: 14 } }}
          >
            Become a Pro Member
          </Button>
        </Box>
      )}
    </>
  ) : (
    <Box>{children}</Box>
  );
};
