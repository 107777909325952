import { Box, Typography } from "@mui/material";

interface MIDetailProps {
  icon?: any;
  title?: string;
  value?: string;
  variant?: "small" | "medium";
  type?: "buyer" | "company";
}
export const MarketplaceItemDetail = ({
  icon: Icon,
  title,
  value,
  variant = "small",
  type = "company",
}: MIDetailProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F9F9FF",
        color: "common.black",
        display: "flex",
        height: "100%",
        justifyContent: variant === "medium" ? "flex-start" : "space-between",
        // minWidth: variant === "medium" ? "inherit" : 320,
        paddingX: 2,
        paddingY: 1,
        borderRadius: 2,
        alignItems: variant === "medium" ? "flex-start" : "center",
        flexDirection: variant === "medium" ? "column" : "row",
        border: type === "buyer" ? "1px solid #E2E2F1" : "1px solid #E2E2F1",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: variant === "medium" ? "flex-start" : "center",

          flexDirection: variant === "medium" ? "column" : "row",
        }}
      >
        <Box
          sx={{ width: 15, height: 15, display: "flex", alignItems: "center" }}
        >
          <Icon
            color={type === "buyer" ? "bluegradient" : "purplegradient"}
            sx={{
              color: type === "buyer" ? "#6911E7" : "#C72A8E",
            }}
          />
        </Box>{" "}
        <Typography variant="body2" sx={{ color: "common.gray" }}>
          {title}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{ fontWeight: 600, textTransform: "capitalize" }}
      >
        {value ? value : ""}
      </Typography>
    </Box>
  );
};
