export const DisabledTick = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#F7F8F9" />
    <path
      d="M20.2421 20.2426C20.5024 19.9823 20.5024 19.5602 20.2421 19.2998L16.9427 16.0004L20.243 12.7001C20.5033 12.4398 20.5033 12.0177 20.243 11.7573C19.9826 11.497 19.5605 11.497 19.3002 11.7573L15.9999 15.0576L12.6996 11.7573C12.4392 11.497 12.0171 11.497 11.7568 11.7573C11.4964 12.0177 11.4964 12.4398 11.7568 12.7001L15.0571 16.0004L11.7577 19.2998C11.4973 19.5602 11.4973 19.9823 11.7577 20.2426C12.018 20.503 12.4402 20.503 12.7005 20.2426L15.9999 16.9432L19.2993 20.2426C19.5596 20.503 19.9817 20.503 20.2421 20.2426Z"
      fill="#191D23"
    />
  </svg>
);
