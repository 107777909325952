export const App = () => (
  <svg
    height="100%"
    viewBox="0 0 87 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.4974 72.8496H50.918V69.2445H36.4974V72.8496ZM25.682 83.665C23.6991 83.665 22.0011 82.9584 20.5879 81.5452C19.1747 80.132 18.4693 78.4352 18.4717 76.4548V11.5621C18.4717 9.57928 19.1783 7.88125 20.5915 6.46803C22.0047 5.05482 23.7016 4.34941 25.682 4.35181H61.7334C63.7163 4.35181 65.4143 5.05842 66.8275 6.47164C68.2407 7.88486 68.9461 9.58168 68.9437 11.5621V76.4548C68.9437 78.4376 68.2371 80.1356 66.8239 81.5488C65.4107 82.962 63.7139 83.6674 61.7334 83.665H25.682ZM25.682 58.429H61.7334V22.3775H25.682V58.429Z"
      fill="url(#paint0_linear_2497_5184)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2497_5184"
        x1="20.9953"
        y1="4.3518"
        x2="78.6748"
        y2="13.8536"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C72A8E" />
        <stop offset="1" stopColor="#7815DA" />
      </linearGradient>
    </defs>
  </svg>
);
