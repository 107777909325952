import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppContext } from "../../../../common/context/AppContext";
import {
  useCurrentBuyer,
  useDataLocations,
  useStartupTypes,
} from "../../../../common/hooks";
import { BuyerService } from "../../../../common/services/BuyerService";

import { HookedCheckbox } from "../../../components/HookedCheckbox";
import { HookedForm } from "../../../components/HookedForm";
import { HookedNumberField } from "../../../components/HookedNumberField";

import { RoutePaths } from "../../../consts/enums";
import PublicBanner from "../PublicBanner";
import ProfileNavigationButtons from "../ProfileNavigationButtons";

const schema = yup
  .object({
    startup_business_types: yup
      .array()
      .required()
      .min(1)
      .nullable()
      .label("Categories"),
    buyer_location: yup.array().min(1).nullable().required().label("Locations"),
    acquisitions_previously: yup
      .number()
      .min(0)
      .nullable()
      .required()
      .label("Number of past acquisitions"),
    acquisitions_projected: yup
      .number()
      .min(0)
      .nullable()
      .required()
      .label("Number of planned acquisitions"),
  })
  .required();

const BuyerPreferences = () => {
  const { user, updateUser } = useAppContext();
  const { data: buyerData, isLoading: buyerDataLoading } = useCurrentBuyer();
  const { data: BusinessTypes = [], isLoading: isLoadingBusinessTypes } =
    useStartupTypes();
  const { data: locations, isLoading: isLoadingDataLocations } =
    useDataLocations();

  const formHook = useForm({
    defaultValues: buyerData,
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const handleNext = (values: any) => {
    BuyerService.PatchBuyer({ ...values });
    updateUser({ ...user, buyerFull: { ...user.buyerFull, ...values } });
    navigate(RoutePaths.REGISTER_ACQUIRER + "/budget");
  };

  useEffect(() => {
    if (buyerData && !buyerDataLoading) {
      formHook.reset({ ...buyerData });
    }
  }, [buyerData, buyerDataLoading]);

  if (!BusinessTypes.length) return <CircularProgress />;
  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          padding: 4,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box>
          <PublicBanner />
          <Paper elevation={2} sx={{ marginTop: 4, padding: 3 }}>
            <HookedForm formHook={formHook}>
              <Typography variant="h3" sx={{ marginBottom: 1 }}>
                Which categories are you interested in buying businesses in?
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "common.gray", marginBottom: 2 }}
              >
                Please select up to 4
              </Typography>
              <HookedCheckbox
                limit={4}
                name="startup_business_types"
                loading={isLoadingBusinessTypes}
                options={BusinessTypes.map((x) => ({
                  label: x.name || "",
                  value: x.id,
                }))}
              />
              <Typography variant="h3" sx={{ marginBottom: 2, marginTop: 3 }}>
                What locations are you interested in acquiring businesses in?
              </Typography>
              <HookedCheckbox
                loading={isLoadingDataLocations}
                name="buyer_location"
                options={locations?.map((x) => ({
                  label: x.display_name,
                  value: x.value,
                }))}
              />

              <Stack
                direction="row"
                spacing={3}
                alignItems="center"
                marginBottom={2}
                marginTop={3}
              >
                <Typography
                  variant="h3"
                  fontWeight={600}
                  sx={{ width: "100%" }}
                >
                  How many acquisitions have you made in the last 5 years?
                </Typography>
                <HookedNumberField
                  hiddenLabel
                  variant="outlined"
                  name="acquisitions_previously"
                />
              </Stack>
              <Stack
                direction="row"
                spacing={3}
                alignItems="center"
                marginBottom={2}
                marginTop={3}
              >
                <Typography
                  variant="h3"
                  fontWeight={600}
                  sx={{ width: "100%" }}
                >
                  How many acquisitions do you plan to make in the next year?
                </Typography>
                <HookedNumberField
                  hiddenLabel
                  variant="outlined"
                  name="acquisitions_projected"
                />
              </Stack>
            </HookedForm>
          </Paper>
          <ProfileNavigationButtons
            onBack={() => navigate(RoutePaths.REGISTER_ACQUIRER + "/specific")}
            onSaveAndContinue={formHook.handleSubmit(handleNext)}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default BuyerPreferences;
