import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  Paper,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { Variant } from "@mui/material/styles/createTypography";
import { useReward } from "react-rewards";
import Lottie from "lottie-react";
import greenCheckboxData from "../../../common/assets/lottie/green-checkbox.json";

interface ExpandableRowProps {
  id?: string;
  step?: number;
  title: string;
  titleVariant?: Variant;
  children?: React.ReactNode;
  effect?: boolean;
  stepAction?: () => void;
  stepProgress?: boolean;
}
export const ExpandableRow = ({
  id,
  step,
  title,
  titleVariant = "h3",
  children,
  effect,
  stepAction,
  stepProgress = false,
}: ExpandableRowProps) => {
  const { reward } = useReward(`rewardId-${step}`, "confetti", {
    angle: 180,
    position: "absolute",
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((curr) => !curr);
  };

  useEffect(() => {
    if (isOpen && effect) {
      reward();
    }
  }, [effect, isOpen]);

  return (
    <Paper
      sx={{
        "&:last-child": { marginBottom: 0 },
        marginBottom: 1,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        height={isOpen ? "auto" : 70}
        id={id}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
          borderBottomWidth: 1,
          borderColor: "common.lightgray",
          borderBottomStyle: "solid",
        }}
        onClick={() => handleOpen()}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {step && !stepProgress ? (
            <Chip
              sx={{
                height: 16,
                background: "#F7E9F6",
                color: "#A321B0",
                fontSize: 10,
                fontFamily: "Inter",
                fontWeight: 700,
                marginRight: 3,
                "& .MuiChip-label": {
                  padding: 0.5,
                },
              }}
              label={`STEP ${step}`}
            />
          ) : null}
          {step && stepProgress ? (
            <Box mr={3} p={0.5}>
              <Lottie
                animationData={greenCheckboxData}
                loop={false}
                style={{ width: 34, height: 34 }}
              />
            </Box>
          ) : null}
          <Typography
            variant={titleVariant}
            sx={{ display: "inline-block", color: "common.black" }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {effect && <span id={`rewardId-${step}`} />}
          {stepAction && (
            <Checkbox
              checked={stepProgress}
              onClick={(e) => {
                e.stopPropagation();
                if (stepAction) stepAction();
              }}
            />
          )}
          <ExpandMoreIcon />
        </Box>
      </Box>

      <Collapse in={isOpen} unmountOnExit>
        <Box
          sx={{
            width: "100%",
            minHeight: 36,
            alignItems: "center",
            fontSize: 18,
            padding: 2,
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Paper>
  );
};
