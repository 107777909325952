import { Button, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ScrollerLoader } from "../../../../common/components/ScrollerLoader";
import { IStartup } from "../../../../common/models";
import {
  IStartupsParams,
  StartupService,
} from "../../../../common/services/StartupService";
import {
  CommonQuery,
  IInfiniteQuery,
  useInfiniteDataQuery,
} from "../../../../common/utils/query";
import { MarketplaceItem } from "../../../components/MarketplaceItem";
import { StartupSorting } from "../../../../common/types";
import { mapAmountFilters } from "../../../../common/models/mappers";
import { Favourites } from "./Favourites";
import { useStartupTypes, useTeamSize } from "../../../../common/hooks";
import { usePrismicClient } from "@prismicio/react";
import { EventName, trackEvent } from "../../../../common/utils/helpers";
import { CompaniesFilter } from "./CompaniesFilter";
import {
  LocalStorageKey,
  StorageService,
} from "../../../../common/services/StorageService";

const defaultOrdering = StartupSorting.PriorityAsc;

export const defaultFilter: IStartupsParams = {
  page_size: 10,
  ordering: `${defaultOrdering},${StartupSorting.ProfitDesc}`,
  is_validated: true,
  search: "",
  is_not_viewed: false,
};

export const fromFormToFilter = (v: IStartupsParams): IStartupsParams => {
  const {
    ttm_revenue,
    search,
    business_type,
    price_min,
    price_max,
    revenue_min,
    revenue_max,
    ordering,
    is_not_viewed,
  } = v;
  return {
    ...defaultFilter,
    ttm_revenue,
    search,
    business_type,
    price_min,
    price_max,
    revenue_min,
    revenue_max,
    ordering,
    is_not_viewed,
  };
};

export const Companies = () => {
  const [hotListings, setHotListings] = useState<number[]>([]);
  const { data: startupTypes = [] } = useStartupTypes();
  const { data: teamSize = [] } = useTeamSize();

  const [filter, setFilter] = useState<IStartupsParams>(
    fromFormToFilter(defaultFilter),
  );

  const [showFavourites, setShowFavourites] = useState(false);
  const [showNewListings, setShowNewListings] = useState(false);
  const formHook = useForm({ defaultValues: fromFormToFilter(defaultFilter) });

  const prismic = usePrismicClient();

  const handleFilter = (v: IStartupsParams) => {
    StorageService.Set(
      LocalStorageKey.CompanyFilters,
      JSON.stringify({ ...defaultFilter, ...v, is_not_viewed: false }),
    );
    const filter = {
      ...defaultFilter,
      search: v.search,
      revenue_min: v.ttm_revenue
        ? mapAmountFilters(v.ttm_revenue)[0]
        : undefined,
      revenue_max: v.ttm_revenue
        ? mapAmountFilters(v.ttm_revenue)[1]
        : undefined,
      ordering: `${defaultOrdering},${v.ordering}`,
      business_type: v.business_type,
      is_not_viewed: v.is_not_viewed ? v.is_not_viewed : undefined,
    };
    setFilter(fromFormToFilter(filter));
  };

  const handleSearch = () => {
    const formValues = formHook.getValues();
    if (formValues?.search) {
      trackEvent(EventName.SEARCH, { label: formValues.search });
    }
    handleFilter(formValues);
  };

  const handleClearFilter = () => {
    setShowNewListings(false);
    handleFilter(fromFormToFilter(defaultFilter));
    formHook.reset(fromFormToFilter(defaultFilter));
    localStorage.removeItem("companyFilters");
  };

  const query: IInfiniteQuery<IStartup> = useInfiniteDataQuery(
    CommonQuery.Startups,
    StartupService.GetStartups,
    filter,
  );

  const handleShowFavourites = () => {
    setShowFavourites((curr) => !curr);
  };

  useEffect(() => {
    const oldFilters = StorageService.Get(LocalStorageKey.CompanyFilters);
    if (oldFilters) {
      const parse = JSON.parse(oldFilters);
      formHook.reset({ ...parse });
      handleFilter(parse);
    }

    prismic.getByUID("hot_listings", "hot-listings").then((res) => {
      if (res.data?.slices && res.data?.slices[0]) {
        const ids = res.data?.slices[0].items.map(
          (itm: { url_id: number }) => itm.url_id,
        );
        setHotListings(ids);
      }
    });
  }, []);

  return (
    <>
      <CompaniesFilter
        startupTypes={startupTypes}
        formHook={formHook}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
        handleClearFilter={handleClearFilter}
        handleShowFavourites={handleShowFavourites}
        showFavourites={showFavourites}
        filter={filter}
        setShowNewListings={setShowNewListings}
        showNewListings={showNewListings}
      />
      {query.loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {showFavourites ? (
            <Favourites />
          ) : (
            <>
              {query && Boolean(query.items.length) ? (
                <>
                  {(query.items as IStartup[]).map((x) => (
                    <MarketplaceItem
                      isHotListing={hotListings.includes(Number(x.id))}
                      startupTypes={startupTypes}
                      teamSize={teamSize}
                      key={x.id}
                      disableNewListingGlow={!showNewListings}
                      {...x}
                    />
                  ))}
                  <ScrollerLoader scroller={query.scroller} />
                </>
              ) : (
                <div>
                  <Typography variant="h2" marginBottom={2}>
                    Can&apos;t find what you are looking for?
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    Try making your search terms broader. We are onboarding lots
                    of new listings each month. We encourage you to send the
                    Foundy team a message to provide any constructive feedback
                    and to tell us the types of businesses you are interested
                    in. This way, we can continually learn what deal flow our
                    buyer community are looking for.
                  </Typography>
                  <Button
                    variant="roundoutlinedgradient"
                    onClick={() => {
                      formHook.setValue("search", "");
                      handleFilter(formHook.getValues());
                    }}
                  >
                    <span>Try searching again</span>
                  </Button>
                </div>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};
