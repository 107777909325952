import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Link, useParams } from "react-router-dom";
import { HookedForm } from "../../components/HookedForm";
import { HookedField } from "../../components/HookedField";
import { RoutePaths } from "../../consts/enums";
import { useNavigate } from "react-router";
import { UserService } from "../../../common/services/UserService";
import { useAppContext } from "../../../common/context/AppContext";
import { LinkedinService } from "../../../common/services/LinkedinService";
import {
  PRIVACY_POLICY_LINK,
  TERMS_CONDITIONS_LINK,
} from "../../../common/constants";
import { EventName, trackEvent } from "../../../common/utils/helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleLogin } from "@react-oauth/google";
import { ApiService } from "../../../common/services/ApiService";
import { GoogleIcon } from "../../../common/assets/icons/GoogleIcon";
import { QuotesIcon } from "../../../common/assets/icons/QuotesIcon";
import { useSearchQuery } from "../../../common/hooks/useSearchQuery";
import { useEffect } from "react";
import {
  LocalStorageKey,
  StorageService,
} from "../../../common/services/StorageService";

const schema = yup
  .object({
    email: yup.string().email().nullable().required().trim().label("Email"),
    password: yup.string().nullable().required().trim().label("Password"),
  })
  .required();

export const Referral = () => {
  const searchQuery = useSearchQuery();
  const params = useParams();
  const navigate = useNavigate();
  const { updateUser } = useAppContext();
  const formHook = useForm({
    defaultValues: { email: params.id, role: "" },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (searchQuery && searchQuery.pscd) {
      StorageService.Set(
        LocalStorageKey.ReferralKeys,
        JSON.stringify(searchQuery),
      );
    }
    const redirectParam = searchQuery.redirect;
    if (redirectParam) {
      try {
        const decodedRedirect = decodeURIComponent(redirectParam);
        new URL(decodedRedirect);
        window.location.assign(decodedRedirect);
      } catch (e) {
        window.location.assign("https://foundy.com/");
      }
    }
  }, [searchQuery]);

  const handleNext = (existing?: boolean) => {
    if (existing) {
      navigate(RoutePaths.DASHBOARD);
    } else {
      navigate(RoutePaths.REGISTER + "/basic");
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      const user = await UserService.SignUp({
        email: values.email,
        password1: values.password,
        password2: values.password,
        accepted_terms: true,
        allow_news_letter: false,
      });

      // registerCampaing(user);
      updateUser(user);
      trackEvent(EventName.SIGN_UP);
      handleNext();
      LinkedinService.Track();
    } catch (e) {}
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) =>
      ApiService.Post("/v2/auth/google/", { code: codeResponse.code }).then(
        async (res) => {
          const user = await UserService.GoogleSignIn(res);
          updateUser(user);
          if (user.active_role) {
            handleNext(true);
          }

          handleNext();
        },
      ),
    flow: "auth-code",
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container sx={{ minHeight: "100vh" }}>
        <Grid
          item
          md={6}
          sx={{
            overflow: "hidden",
            padding: 7,
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            backgroundColor: "common.black",
            backgroundImage: "url(/images/BiggerGradient.png)",
            backgroundSize: "cover",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 56,
              left: 56,
              display: "flex",
              marginBottom: 5,
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src="/images/FoundyLogoMenu.svg"
              sx={{
                width: 98,
              }}
            />
          </Box>
          <Box
            sx={{
              height: "100%",
              width: "80%",
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: 28, md: 50 },
                fontWeight: 600,
                color: "white",
                textAlign: "center",
              }}
            >
              Your end-to-end platform to buy or sell a business
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 18, marginTop: 6 }}>
              Instantly access everything you need to complete a successful
              acquisition
            </Typography>
            <ul style={{ listStyle: "none", padding: 0, marginTop: 35 }}>
              <li style={{ display: "flex", gap: 10, marginBottom: 15 }}>
                <Box>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99316 15.2832C8.98665 15.2832 9.91862 15.0941 10.7891 14.7158C11.6595 14.3421 12.4251 13.8226 13.0859 13.1572C13.7513 12.4964 14.2731 11.7308 14.6514 10.8604C15.0296 9.98991 15.2188 9.05794 15.2188 8.06445C15.2188 7.07552 15.0296 6.14583 14.6514 5.27539C14.2731 4.40495 13.7513 3.63704 13.0859 2.97168C12.4251 2.30632 11.6572 1.78678 10.7822 1.41309C9.91178 1.03483 8.9821 0.845703 7.99316 0.845703C7.00423 0.845703 6.07227 1.03483 5.19727 1.41309C4.32682 1.78678 3.55892 2.30632 2.89355 2.97168C2.23275 3.63704 1.71322 4.40495 1.33496 5.27539C0.961263 6.14583 0.774414 7.07552 0.774414 8.06445C0.774414 9.05794 0.961263 9.98991 1.33496 10.8604C1.71322 11.7308 2.23503 12.4964 2.90039 13.1572C3.56576 13.8226 4.33366 14.3421 5.2041 14.7158C6.07454 15.0941 7.00423 15.2832 7.99316 15.2832ZM7.24121 11.4893C7.08626 11.4893 6.94727 11.4574 6.82422 11.3936C6.70117 11.3252 6.58496 11.2249 6.47559 11.0928L4.87598 9.17871C4.7985 9.08301 4.74154 8.98958 4.70508 8.89844C4.66862 8.80729 4.65039 8.71159 4.65039 8.61133C4.65039 8.40169 4.72559 8.22396 4.87598 8.07812C5.02637 7.92773 5.2041 7.85254 5.40918 7.85254C5.53678 7.85254 5.64844 7.8776 5.74414 7.92773C5.8444 7.97786 5.9401 8.0599 6.03125 8.17383L7.22754 9.63672L9.88672 5.40527C10.069 5.11361 10.2946 4.96777 10.5635 4.96777C10.7686 4.96777 10.9486 5.03613 11.1035 5.17285C11.263 5.30957 11.3428 5.48275 11.3428 5.69238C11.3428 5.77897 11.3245 5.86784 11.2881 5.95898C11.2562 6.05013 11.2129 6.139 11.1582 6.22559L7.97949 11.0791C7.88835 11.2113 7.77897 11.3138 7.65137 11.3867C7.52832 11.4551 7.3916 11.4893 7.24121 11.4893Z"
                      fill="url(#paint0_linear_4417_11785)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_4417_11785"
                        x1="0.8"
                        y1="-4"
                        x2="19.0378"
                        y2="-0.852523"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#C72A8E" />
                        <stop offset="1" stopColor="#7815DA" />
                      </linearGradient>
                    </defs>
                  </svg>
                </Box>
                <Typography variant="body2">
                  <b>For business owners:</b> sell your business for the highest
                  price & access expert advisory
                </Typography>
              </li>
              <li style={{ display: "flex", gap: 10 }}>
                <Box>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99316 15.2832C8.98665 15.2832 9.91862 15.0941 10.7891 14.7158C11.6595 14.3421 12.4251 13.8226 13.0859 13.1572C13.7513 12.4964 14.2731 11.7308 14.6514 10.8604C15.0296 9.98991 15.2188 9.05794 15.2188 8.06445C15.2188 7.07552 15.0296 6.14583 14.6514 5.27539C14.2731 4.40495 13.7513 3.63704 13.0859 2.97168C12.4251 2.30632 11.6572 1.78678 10.7822 1.41309C9.91178 1.03483 8.9821 0.845703 7.99316 0.845703C7.00423 0.845703 6.07227 1.03483 5.19727 1.41309C4.32682 1.78678 3.55892 2.30632 2.89355 2.97168C2.23275 3.63704 1.71322 4.40495 1.33496 5.27539C0.961263 6.14583 0.774414 7.07552 0.774414 8.06445C0.774414 9.05794 0.961263 9.98991 1.33496 10.8604C1.71322 11.7308 2.23503 12.4964 2.90039 13.1572C3.56576 13.8226 4.33366 14.3421 5.2041 14.7158C6.07454 15.0941 7.00423 15.2832 7.99316 15.2832ZM7.24121 11.4893C7.08626 11.4893 6.94727 11.4574 6.82422 11.3936C6.70117 11.3252 6.58496 11.2249 6.47559 11.0928L4.87598 9.17871C4.7985 9.08301 4.74154 8.98958 4.70508 8.89844C4.66862 8.80729 4.65039 8.71159 4.65039 8.61133C4.65039 8.40169 4.72559 8.22396 4.87598 8.07812C5.02637 7.92773 5.2041 7.85254 5.40918 7.85254C5.53678 7.85254 5.64844 7.8776 5.74414 7.92773C5.8444 7.97786 5.9401 8.0599 6.03125 8.17383L7.22754 9.63672L9.88672 5.40527C10.069 5.11361 10.2946 4.96777 10.5635 4.96777C10.7686 4.96777 10.9486 5.03613 11.1035 5.17285C11.263 5.30957 11.3428 5.48275 11.3428 5.69238C11.3428 5.77897 11.3245 5.86784 11.2881 5.95898C11.2562 6.05013 11.2129 6.139 11.1582 6.22559L7.97949 11.0791C7.88835 11.2113 7.77897 11.3138 7.65137 11.3867C7.52832 11.4551 7.3916 11.4893 7.24121 11.4893Z"
                      fill="url(#paint0_linear_4417_11785)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_4417_11785"
                        x1="0.8"
                        y1="-4"
                        x2="19.0378"
                        y2="-0.852523"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#C72A8E" />
                        <stop offset="1" stopColor="#7815DA" />
                      </linearGradient>
                    </defs>
                  </svg>
                </Box>
                <Typography variant="body2">
                  <b>For business buyers:</b> access regular deal flow of
                  profitable businesses
                </Typography>
              </li>
            </ul>
            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ height: "100%", padding: 2 }}>
                  <Box sx={{ display: "flex", gap: 2, marginBottom: 1 }}>
                    <QuotesIcon />
                    <Box
                      sx={{
                        fontSize: 12,
                        paddingY: 0.5,
                        paddingX: 1,
                        background: "#F9F9F9",
                        "& span": {
                          background:
                            "linear-gradient(99.95deg, #EA336B -24.43%, #6911E7 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          backgroundClip: "text",
                          TextFillColor: "transparent",
                        },
                      }}
                    >
                      <span>Seller</span>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",

                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        fontStyle: "italic",
                        fontSize: 12,
                      }}
                    >
                      I sold my business on Foundy in 25 days. We received
                      messages from 8 interested buyers within a week of
                      listing.
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        fontWeight: 400,
                        fontSize: 10,
                      }}
                    >
                      George Stock, Exited Founder of Ecomoo
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{ minHeight: "100%", padding: 2 }}>
                  <Box sx={{ display: "flex", gap: 2, marginBottom: 1 }}>
                    <QuotesIcon />
                    <Box
                      sx={{
                        fontSize: 12,
                        paddingY: 0.5,
                        paddingX: 1,
                        background: "#F9F9F9",
                        "& span": {
                          background:
                            "linear-gradient(99.95deg, #EA336B -24.43%, #6911E7 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          backgroundClip: "text",
                          TextFillColor: "transparent",
                        },
                      }}
                    >
                      <span>Buyer</span>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      height: "100%",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        fontStyle: "italic",
                        fontSize: 12,
                      }}
                    >
                      I was able to acquire a business that met all my criteria
                      in less than a month, and the team were really helpful and
                      great to work with.
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        fontWeight: 400,
                        fontSize: 10,
                      }}
                    >
                      Jamil Khan, Director, Vertex Peak Marketing Group
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            position: "relative",
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            padding: 8,
            zIndex: 2,
          }}
        >
          <Typography variant="h1" sx={{ marginBottom: 2 }}>
            Sign up now for free
          </Typography>
          <Typography variant="body2" sx={{ color: "#746D7D" }}>
            Create your anonymous account for unlimited free browsing
          </Typography>
          <HookedForm
            formHook={formHook}
            // @ts-ignore
            onSubmit={formHook.handleSubmit(handleSubmit)}
          >
            <HookedField
              sx={{ marginTop: 5 }}
              component={TextField}
              fullWidth
              type="email"
              label="Work Email"
              name="email"
              variant="filled"
            />
            <Box
              sx={{
                width: "100%",
                flexDirection: { xs: "column", md: "row" },
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignContent: "stretch",
                columnGap: 2,
                marginTop: 2,
              }}
            >
              <HookedField
                fullWidth
                component={TextField}
                type="password"
                label="Password"
                name="password"
                variant="filled"
              />
              {/* <HookedField
                component={TextField}
                sx={{ marginTop: { xs: 2, md: 0 }, order: 1, flex: "1 1 auto" }}
                type="password"
                label="Confirm Password"
                name="passwordConfirmation"
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <VisibilityIcon />
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Box>
            <Typography
              textAlign="center"
              marginTop={2}
              variant="body2"
              color="common.gray"
            >
              By continuing I agree to Foundy&apos;s user{" "}
              <Link target="_blank" to={TERMS_CONDITIONS_LINK}>
                Terms and Conditions
              </Link>{" "}
              and{" "}
              <Link target="_blank" to={PRIVACY_POLICY_LINK}>
                Privacy Policy
              </Link>
              .
            </Typography>

            <Box sx={{ width: "100%", marginTop: 5 }}>
              <Button fullWidth variant="roundgradient" type="submit">
                Create Account
              </Button>
            </Box>
          </HookedForm>
          <Button
            fullWidth
            sx={{
              "&.MuiButtonBase-root": {
                textTransform: "inherit",
              },
              marginTop: 2,
              "& span": {
                marginLeft: 2,
              },
            }}
            variant="roundoutlinedgradient"
            onClick={() => googleLogin()}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GoogleIcon />
              <span>Sign up with Google</span>
            </Box>
          </Button>
          <Typography
            variant="body2"
            sx={{
              marginTop: 1,
              textAlign: "right",
              color: "common.gray",
            }}
          >
            Already have an account? <Link to="/login">Sign in</Link>
          </Typography>
          <Typography
            variant="body2"
            fontSize={9}
            textAlign="center"
            sx={{ position: "absolute", left: 0, bottom: 15, width: "100%" }}
          >
            © Copyright © 2023 Foundy Ltd.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
