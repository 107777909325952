import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { DisabledTick } from "../../../common/assets/icons/DisabledTick";
import { GradientTick } from "../../../common/assets/icons/GradientTick";
import { WhiteTick } from "../../../common/assets/icons/WhiteTick";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { EventName, trackEvent } from "../../../common/utils/helpers";
import { useEffect, useMemo } from "react";
import { RoutePaths } from "../../consts/enums";
import { useAppContext } from "../../../common/context/AppContext";

const handleClick = (url?: string) => {
  window.open(url, "_blank");
};

interface ModalProps extends DialogProps {
  onCancel: (event: any) => void;
}
export const PlansModal = (props: ModalProps) => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const STRIPE_PAYMENT_APP_URL = process.env
    .REACT_APP_STRIPE_PAYMENT_APP_URL as string;

  useEffect(() => {
    trackEvent(EventName.PLANS_VIEW);
  }, []);

  const plans = useMemo(
    () => [
      {
        click: () => {
          trackEvent(EventName.BASIC_PRESS);
          navigate(RoutePaths.DASHBOARD);
        },
        title: "Basic",

        button: <Button variant="roundgradient">Get started now</Button>,
        hightlight: false,
        items: [{ title: "Browse all listings", active: true }],
      },
      {
        click: () => {
          trackEvent(EventName.STARTER_PRESS);
          handleClick(
            `${STRIPE_PAYMENT_APP_URL}?prefilled_email=${encodeURI(
              user.email,
            )}${
              user.customer_key
                ? "&client_reference_id=" + user.customer_key
                : ""
            }`,
          );
        },
        title: "Pro",

        price: 49,
        button: (
          <Button sx={{ maxWidth: 200 }} fullWidth variant="roundprimary">
            Pay now
          </Button>
        ),
        hightlight: true,
        items: [
          { title: "Browse all listings", active: true },
          { title: "Message business owners directly", active: true },
          {
            title: "Unlock additional company info",
            active: true,
          },
          {
            title: "Free acquisition payment transfer",
            active: true,
          },
        ],
      },
    ],
    [],
  );

  return (
    <Dialog fullWidth maxWidth="md" {...props} scroll="body">
      <DialogTitle
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Box sx={{ cursor: "pointer" }} onClick={(e) => props.onCancel(e)}>
          <CloseIcon />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: { xs: 2, md: 6 } }}>
        <Typography
          textAlign="center"
          variant="h2"
          sx={{ fontSize: { xs: 24, md: 40 }, fontWeight: 700, marginTop: 3 }}
          marginBottom={1}
        >
          <span>Upgrade to Pro Buyer</span>
        </Typography>
        <Container maxWidth="md">
          <Typography
            variant="body1"
            fontSize={16}
            fontWeight={500}
            lineHeight="20px"
            textAlign="center"
            marginBottom={1}
          >
            To start messaging sellers and access other popular buyer perks, you
            will
            <br /> need to upgrade to a Pro Buyer membership
          </Typography>
        </Container>
        <Grid
          direction={{ xs: "column-reverse", md: "row" }}
          container
          spacing={3}
          marginTop={2}
        >
          {plans.map((plan) => (
            <Grid key={plan.title} item md={6}>
              <Paper
                className="cp-button"
                onClick={plan.click}
                elevation={1}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "0px 0px 25px #A15AF4",
                  },
                  ...(plan.hightlight
                    ? {
                        padding: 3,
                        position: "relative",
                        zIndex: 1,
                        background:
                          "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",

                        border: 0,
                        color: "white",
                        height: "100%",
                      }
                    : { padding: 3, height: "100%" }),
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight={700}
                  marginBottom={1}
                  sx={{ fontSize: { xs: 24, md: 50 }, textAlign: "center" }}
                >
                  {plan.title}
                </Typography>

                {plan.price ? (
                  <Typography
                    fontWeight={600}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      marginY: 2,
                      fontSize: { xs: 36, md: 48 },
                    }}
                  >
                    {typeof plan.price === "number" ? "£" : ""}
                    {plan.price}
                    {typeof plan.price === "number" ? (
                      <Box
                        component="span"
                        sx={{
                          marginLeft: 1,
                          fontSize: { xs: 18, md: 28 },
                          fontWeight: 300,
                        }}
                      >
                        / 6 months
                      </Box>
                    ) : (
                      ""
                    )}
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={600}
                    sx={{
                      textAlign: "center",
                      marginY: 2,
                      fontSize: { xs: 36, md: 48 },
                    }}
                  >
                    Free
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {plan.button}
                </Box>
                <Box
                  component="ul"
                  fontSize={16}
                  fontWeight={500}
                  sx={{
                    padding: 0,
                    listStyle: "none",
                    "& li": {
                      display: "inline-flex",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      marginTop: 1,
                    },
                  }}
                >
                  {plan.items.map((itm: any) => (
                    <li key={itm.title}>
                      <Box sx={{ marginRight: 1, width: 32, height: 32 }}>
                        {itm.active ? (
                          plan.hightlight ? (
                            <WhiteTick />
                          ) : (
                            <GradientTick />
                          )
                        ) : (
                          <DisabledTick />
                        )}
                      </Box>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        sx={{
                          textDecorationLine: itm.active
                            ? "inherit"
                            : "line-through",
                          color: itm.active
                            ? plan.hightlight
                              ? "white"
                              : "common.black"
                            : "common.gray",
                        }}
                      >
                        {itm.title}
                      </Typography>
                    </li>
                  ))}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
