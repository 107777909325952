import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const mapSearch = (search: string) => {
  const url = new URLSearchParams(search);
  const result: Record<string, string> = {};
  url.forEach((v, k) => {
    result[k] = v;
  });
  return result;
};

export const useSearchQuery = () => {
  const { search } = useLocation();
  return useMemo(() => mapSearch(search), [search]);
};
