interface SVGIconProps {
  color?: string;
}
export const MapIcon = ({ color = "black" }: SVGIconProps) => (
  <svg
    viewBox="0 0 16 14"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.743164 12.7725C0.743164 13.0641 0.827474 13.2874 0.996094 13.4424C1.16471 13.6019 1.38346 13.6816 1.65234 13.6816C1.84831 13.6816 2.06022 13.6201 2.28809 13.4971L5.02246 12.0205V0.433594C4.94499 0.460938 4.86296 0.495117 4.77637 0.536133C4.68978 0.572591 4.60319 0.618164 4.5166 0.672852L1.41309 2.44336C1.18522 2.57096 1.0166 2.72819 0.907227 2.91504C0.797852 3.09733 0.743164 3.31608 0.743164 3.57129V12.7725ZM6.02051 11.8428L9.29492 13.6748C9.37695 13.7158 9.45215 13.7523 9.52051 13.7842C9.59342 13.8161 9.6709 13.8411 9.75293 13.8594V2.52539L6.56055 0.59082C6.46484 0.536133 6.37142 0.488281 6.28027 0.447266C6.18913 0.40625 6.10254 0.374349 6.02051 0.351562V11.8428ZM10.751 13.8252C10.7874 13.8161 10.8262 13.8024 10.8672 13.7842C10.9082 13.766 10.9469 13.7477 10.9834 13.7295L14.4561 11.7676C14.693 11.6354 14.8662 11.4759 14.9756 11.2891C15.085 11.1022 15.1396 10.8789 15.1396 10.6191V1.46582C15.1396 1.16504 15.0531 0.937174 14.8799 0.782227C14.7113 0.627279 14.4811 0.549805 14.1895 0.549805C13.9798 0.549805 13.7565 0.615885 13.5195 0.748047L10.751 2.28613V13.8252Z"
      fill={
        color
          ? color === "bluegradient"
            ? "url(#paint0_linear_586_2224)"
            : color === "purplegradient"
              ? "url(#paint0_linear_586_2224)"
              : color
          : "black"
      }
    />
    <defs>
      {color === "purplegradient" && (
        <linearGradient
          id="paint0_linear_586_2224"
          x1="0.2"
          y1="-4"
          x2="26.6022"
          y2="2.83473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C72A8E" />
          <stop offset="1" stopColor="#7815DA" />
        </linearGradient>
      )}
      {color === "bluegradient" && (
        <linearGradient
          id="paint0_linear_586_2224"
          x1="0.2"
          y1="-4"
          x2="26.6022"
          y2="2.83473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E8EF2" />
          <stop offset="1" stopColor="#6911E7" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
