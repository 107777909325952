import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const AlreadyMember = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Box sx={{ paddingY: 4 }}>
        <Typography variant="h2" py={2}>
          You already have a Pro Buyer subscription.
        </Typography>
        <Button
          sx={{ textTransform: "inherit" }}
          variant="roundgradient"
          onClick={() => navigate("/dashboard")}
        >
          Go to Dashboard
        </Button>
      </Box>
    </Container>
  );
};
