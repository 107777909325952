export const FoundyLoading = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    width={width || "16"}
    height={height || "32"}
    viewBox="0 0 16 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animate__animated animate__pulse animate__duration-infinite"
  >
    <path
      d="M11.8351 16.0613C11.8351 16.0613 10.672 17.5069 8.81251 17.5069C6.85384 17.5069 5.28998 16.5965 4.61648 14.6936C4.61648 14.6936 2.08309 17.5868 5.29691 20.0858C5.69239 19.8321 6.12489 19.615 6.59238 19.4414C10.143 18.1227 11.1787 17.3686 11.8351 16.0613Z"
      fill="url(#paint0_linear_3737_3539)"
    />
    <path
      d="M6.59151 19.4416C10.1421 18.123 11.1778 17.3688 11.8342 16.0615C11.8342 16.0615 14.3054 19.0011 12.639 21.6152C11.477 23.438 8.22905 24.0186 6.92527 24.802C5.60276 25.5966 3.66348 28.1093 6.63452 32.0001C0.557122 28.2068 1.55462 21.3123 6.59151 19.4416Z"
      fill="url(#paint1_linear_3737_3539)"
    />
    <path
      d="M12.2465 0C12.2465 2.19243 10.2716 5.24324 5.93039 6.28925C4.47662 6.63957 0 7.93011 0 12.8863C0 18.274 5.29347 20.0847 5.2967 20.0859C2.0831 17.5869 4.61641 14.6938 4.61641 14.6938C7.32264 11.5721 15.5725 12.8582 15.9794 6.762C16.3231 1.61386 12.2465 0 12.2465 0Z"
      fill="url(#paint2_linear_3737_3539)"
    />
    <path
      opacity="0.2"
      d="M15.9797 6.762C16.3234 1.61386 12.2468 0 12.2468 0C13.8381 1.16444 15.1569 3.38229 15.1569 5.92679C15.1569 7.93935 14.2399 9.75107 13.1713 10.9953C14.698 10.1644 15.835 8.93018 15.9797 6.762ZM4.28039 20.8967C1.95919 23.1759 1.67715 27.0364 4.33406 30.0712C4.1778 29.6807 3.06218 28.094 3.06218 25.2942C3.06218 23.6984 3.65991 22.2847 4.28039 20.8967ZM12.6401 21.6151C14.3065 19.001 11.8353 16.0614 11.8353 16.0614C11.9848 16.343 13.834 20.2051 10.1665 23.4551C11.1688 23.0059 12.1164 22.4367 12.6401 21.6151Z"
      fill="#150E1F"
    />
    <path
      d="M6.92549 24.8018C6.92549 24.8018 2.17343 26.8964 6.63474 31.9999C3.66371 28.109 5.60295 25.5964 6.92549 24.8018Z"
      fill="#B400FF"
    />
    <path
      opacity="0.2"
      d="M4.33099 6.79492C2.46519 7.54929 0 9.20828 0 12.8863C0 15.5389 1.28317 17.3239 2.58636 18.4533C1.3846 17.183 0.623565 15.2739 0.623565 13.1864C0.623565 10.4082 2.48671 7.9104 4.33099 6.79492Z"
      fill="#150E1F"
    />
    <path
      d="M12.2472 0C12.2472 2.07928 10.4705 4.93035 6.58398 6.1114H6.58402C14.2409 4.19245 12.2472 0 12.2472 0Z"
      fill="#00C8DC"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3737_3539"
        x1="5.12344"
        y1="14.9383"
        x2="8.5533"
        y2="18.5917"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00004B" />
        <stop offset="0.072" stopColor="#00005E" />
        <stop offset="0.31" stopColor="#000097" />
        <stop offset="0.53" stopColor="#0000C4" />
        <stop offset="0.726" stopColor="#0000E4" />
        <stop offset="0.889" stopColor="#0000F8" />
        <stop offset="1" stopColor="#0000FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3737_3539"
        x1="3.70188"
        y1="30.8512"
        x2="10.6205"
        y2="13.9181"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0000FF" />
        <stop offset="0.154" stopColor="#3C00F7" />
        <stop offset="0.321" stopColor="#7700EF" />
        <stop offset="0.483" stopColor="#A800E8" />
        <stop offset="0.636" stopColor="#CD00E3" />
        <stop offset="0.776" stopColor="#E900DF" />
        <stop offset="0.9" stopColor="#F900DD" />
        <stop offset="0.994" stopColor="#FF00DC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3737_3539"
        x1="1.06115"
        y1="17.337"
        x2="13.4973"
        y2="0.763213"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.001" stopColor="#FF00DC" />
        <stop offset="0.077" stopColor="#CB34DA" />
        <stop offset="0.19" stopColor="#847BD7" />
        <stop offset="0.293" stopColor="#4BB4D4" />
        <stop offset="0.38" stopColor="#22DDD2" />
        <stop offset="0.449" stopColor="#09F6D1" />
        <stop offset="0.49" stopColor="#00FFD1" />
        <stop offset="0.561" stopColor="#00FBD2" />
        <stop offset="0.626" stopColor="#00F0D4" />
        <stop offset="0.689" stopColor="#00DED7" />
        <stop offset="0.75" stopColor="#00C4DC" />
        <stop offset="0.81" stopColor="#00A2E2" />
        <stop offset="0.869" stopColor="#0079E9" />
        <stop offset="0.928" stopColor="#0048F2" />
        <stop offset="0.985" stopColor="#0010FC" />
        <stop offset="1" stopColor="#0000FF" />
      </linearGradient>
    </defs>
  </svg>
);
