import { IInfiniteScroller } from "../../utils/query";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

interface ScrollerLoaderProps {
  scroller?: IInfiniteScroller;
}

export const ScrollerLoader = ({ scroller }: ScrollerLoaderProps) => {
  if (!scroller) return null;

  return (
    <Box
      sx={{ my: "5px", display: "flex", justifyContent: "center" }}
      ref={scroller.scrollerRef}
    >
      <CircularProgress />
    </Box>
  );
};
