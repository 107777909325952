import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { RoutePaths } from "../../consts/enums";
import { IUser } from "../../../common/models";
import { NavigateFunction } from "react-router-dom";

const ChatNoConversationsBanner = (user: IUser, navigate: NavigateFunction) => {
  return (
    <Dialog
      maxWidth="md"
      style={{ position: "absolute" }}
      BackdropProps={{ style: { position: "absolute" } }}
      container={() => document.getElementById("chatContainer")}
      open={true}
    >
      <DialogContent sx={{ zIndex: 1 }}>
        <Box
          sx={{
            cursor: "pointer",
            position: "absolute",
            right: 15,
            top: 20,
          }}
          onClick={() => navigate(RoutePaths.DASHBOARD)}
        >
          <CloseIcon />
        </Box>
        <Typography variant="h2" sx={{ textAlign: "center", marginBottom: 3 }}>
          <span>Time To Start Making Connections</span>
        </Typography>
        <Typography
          variant="body2"
          fontWeight={700}
          fontSize={16}
          marginBottom={2}
        >
          Hey {user.first_name}
        </Typography>

        <Typography variant="body2" marginBottom={2}>
          Welcome to Foundy&apos;s connections tool, where Pro Buyers and
          approved sellers can instant message one another, conduct efficient
          due diligence, share key documents and finalise negotiations. Soon you
          will even be able to book in video meetings with this tool.
        </Typography>

        <Typography variant="body2" marginBottom={2}>
          Head over to the marketplace to find profiles that are aligned with
          your interests and send messages to your favourite listings. Your
          messages will then appear in your private Connections inbox here.
        </Typography>

        <Typography variant="body2" marginBottom={2}>
          Our expert team are here to help you from the start of your
          acquisition process through to deal completion, including legal
          documentation and transferring your acquisition payment.
          <br />
          If you have any questions or issues throughout the process, please do
          let us know.
        </Typography>

        <Typography variant="body2" marginBottom={2}>
          Thank you,
        </Typography>

        <Typography variant="body2" fontWeight={700}>
          JP Lewin
        </Typography>
        <Typography variant="body2" fontWeight={700}>
          Founder & CEO of Foundy
        </Typography>
        <Button
          onClick={() => navigate(RoutePaths.DASHBOARD)}
          sx={{ marginTop: 3 }}
          variant="roundoutlinedgradient"
        >
          <span>Browse Marketplace to find listings</span>
        </Button>
        <img
          style={{ position: "absolute", right: 15, bottom: 15 }}
          src="/images/JPLewin.png"
          alt="JP Lewin"
        />
      </DialogContent>
    </Dialog>
  );
};

export default ChatNoConversationsBanner;
