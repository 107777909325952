import { Box, Container, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppContext } from "../../../../common/context/AppContext";
import { useCurrentBuyer } from "../../../../common/hooks";
import { BuyerService } from "../../../../common/services/BuyerService";
import { HookedForm } from "../../../components/HookedForm";
import { HookedNumberField } from "../../../components/HookedNumberField";
import { HookedRadioGroup } from "../../../components/HookedRadioGroup";
import { RoutePaths } from "../../../consts/enums";
import { HookedSliderInput } from "../../../../common/components/HookedSliderInput";
import PublicBanner from "../PublicBanner";
import ProfileNavigationButtons from "../ProfileNavigationButtons";
import { DebtFinanceOption } from "../../../consts/consts";
import { SliderRange } from "../../../../common/components/SliderInput/DoubleSlider";

const RevenueMarks = [
  { label: "£0", value: 0 },
  { label: "£1m+", value: 1000000 },
  { label: "£5m", value: 5000000 },
];

const ProfitMarks = [
  { label: "£-5m", value: -5000000 },
  { label: "£-1m", value: -1000000 },
  { label: "£0", value: 0 },
  { label: "£1m+", value: 1000000 },
  { label: "£5m", value: 5000000 },
];

const debtOptions = [
  { label: "Yes", value: DebtFinanceOption.Yes },
  { label: "No", value: DebtFinanceOption.No },
  { label: "Undecided", value: DebtFinanceOption.Undecided },
];

const schema = yup
  .object({
    valuation: yup.number().min(10000).nullable().required().label("Budget"),

    trailing_revenue: yup
      .array()
      .length(2)
      .nullable()
      .required()
      .label("TTM Revenue"),
    trailing_profit: yup
      .array()
      .length(2)
      .nullable()
      .required()
      .label("TTM Profit"),
    debt_finance: yup.string().nullable().required().label("Debt Finance"),
    debt_finance_value: yup.number().nullable().label("Debt Finance Value"),
  })
  .required();

const HookedMoneySlider = ({
  name,
  marks,
  value,
}: {
  name: string;
  marks: { label: string; value: number }[];
  value: SliderRange | number[] | number | undefined;
}) => {
  const numValue: number[] | undefined =
    typeof value === "number" ? [0, value] : value;
  return (
    <HookedSliderInput
      marks={marks}
      min={marks[0].value}
      max={marks[marks.length - 1].value}
      placeholderMin={"Minimum value"}
      placeholderMax={"Maximum value"}
      value={numValue ? [numValue[0], numValue[1]] : undefined}
      name={name}
      currency
    />
  );
};

const BuyerBudget = () => {
  const { user, updateUser } = useAppContext();
  const { data, isLoading } = useCurrentBuyer();
  const [takesDebt, setTakesDebt] = useState(false);

  const formHook = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  const debtChoice = formHook.watch("debt_finance");
  useEffect(() => {
    setTakesDebt(debtChoice === DebtFinanceOption.Yes);
  }, [debtChoice]);

  const profit = formHook.watch("trailing_profit");
  const revenue = formHook.watch("trailing_revenue");
  const navigate = useNavigate();

  const handleNext = (values: any) => {
    BuyerService.PatchBuyer({ ...values });
    updateUser({ ...user, buyerFull: { ...user.buyerFull, ...values } });
    navigate(RoutePaths.REGISTER_ACQUIRER + "/additional-info");
  };

  useEffect(() => {
    if (data && !isLoading) {
      formHook.reset({ ...data });
    }
  }, [data, isLoading]);

  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <Container maxWidth="md" sx={{ padding: 4 }}>
        <PublicBanner />
        <Paper
          elevation={2}
          sx={{ marginTop: 4, padding: 3, position: "relative", zIndex: 1 }}
        >
          <HookedForm formHook={formHook}>
            <Typography variant="h3" sx={{ marginBottom: 1 }}>
              What is your total available budget for buying businesses?
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: 2, color: "common.gray" }}
            >
              You may need to provide official confirmation of available funds
              or debt facility when speaking to sellers.
            </Typography>
            <HookedNumberField
              hiddenLabel
              fullWidth
              currency
              name="valuation"
              placeholder="It is important that this information is accurate so that we provide the best possible suggestions."
            />
            <Typography variant="h3" sx={{ marginBottom: 1, marginTop: 3 }}>
              Minimum Last 12 Months (LTM) revenue requirement
            </Typography>
            <HookedMoneySlider
              marks={RevenueMarks}
              value={revenue}
              name="trailing_revenue"
            />

            <Typography variant="h3" sx={{ marginBottom: 1, marginTop: 3 }}>
              Minimum Last 12 Months (LTM) profit requirement
            </Typography>
            <HookedMoneySlider
              marks={ProfitMarks}
              value={profit}
              name="trailing_profit"
            />

            <Typography variant="h3" sx={{ marginBottom: 2, marginTop: 3 }}>
              Are you interested in using debt finance to buy businesses?
            </Typography>
            <HookedRadioGroup
              name="debt_finance"
              options={debtOptions}
              defaultValue={data?.debt_finance}
            />
            {takesDebt && (
              <Box sx={{ marginTop: 2 }}>
                <HookedNumberField
                  fullWidth
                  placeholder="How much debt would you take on?"
                  label="Debt value"
                  name="debt_finance_value"
                  currency
                />
              </Box>
            )}
          </HookedForm>
        </Paper>
        <ProfileNavigationButtons
          onBack={() => navigate(RoutePaths.REGISTER_ACQUIRER + "/categories")}
          onSaveAndContinue={formHook.handleSubmit(handleNext)}
        />
      </Container>
    </Box>
  );
};

export default BuyerBudget;
