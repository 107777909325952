import { Avatar, Box, Typography } from "@mui/material";
import { IBuyer } from "../../../common/models";
import { useStartupTypes } from "../../../common/hooks";
import { getCompanyCategoryIcon } from "../../../common/models/mappers";

export const TinyBuyers = ({ id, startup_business_types }: IBuyer) => {
  const { data = [] } = useStartupTypes();
  const type = startup_business_types.length
    ? data.find((x) => x.id === startup_business_types[0])
    : undefined;
  const Icon = getCompanyCategoryIcon(Number(type?.id), true);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Avatar
        variant="rounded"
        sx={{
          width: 40,
          height: 40,
          backgroundColor: "common.lightpurple",
          color: "#C62A8F",
        }}
      >
        <Box sx={{ height: 20, display: "flex", alignItems: "center" }}>
          <Icon gradient width={20} />
        </Box>
      </Avatar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textTransform: "lowercase",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            letterSpacing: "0.01em",
            fontWeight: 700,
            fontSize: 14,
            display: "flex",
            alignItems: "center",
            gap: 2,
            textTransform: "capitalize",
          }}
        >
          {type?.name ?? "New"} Buyer #{id}
        </Typography>
      </Box>
    </Box>
  );
};
