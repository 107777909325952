import { SVGProps } from "react";

export const UserCompletedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="134"
    height="128"
    viewBox="0 0 134 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.4375 122.438H105.562C109.062 122.438 111.812 121.646 113.812 120.062C115.812 118.521 116.812 116.354 116.812 113.562C116.812 109.604 115.583 105.396 113.125 100.938C110.667 96.4792 107.125 92.3333 102.5 88.5C97.9167 84.625 92.375 81.4792 85.875 79.0625C79.375 76.6042 72.0625 75.375 63.9375 75.375C55.8542 75.375 48.5625 76.6042 42.0625 79.0625C35.5625 81.4792 30 84.625 25.375 88.5C20.7917 92.3333 17.2708 96.4792 14.8125 100.938C12.3958 105.396 11.1875 109.604 11.1875 113.562C11.1875 116.354 12.1875 118.521 14.1875 120.062C16.1875 121.646 18.9375 122.438 22.4375 122.438ZM64 64.3125C68.5417 64.3125 72.7292 63.0833 76.5625 60.625C80.3958 58.1667 83.4583 54.8542 85.75 50.6875C88.0833 46.4792 89.25 41.75 89.25 36.5C89.25 31.4167 88.0833 26.8333 85.75 22.75C83.4583 18.625 80.3958 15.375 76.5625 13C72.7292 10.625 68.5417 9.4375 64 9.4375C59.5 9.4375 55.3333 10.6458 51.5 13.0625C47.6667 15.4792 44.5833 18.75 42.25 22.875C39.9583 26.9583 38.8125 31.5417 38.8125 36.625C38.8125 41.8333 39.9583 46.5208 42.25 50.6875C44.5833 54.8542 47.6458 58.1667 51.4375 60.625C55.2708 63.0833 59.4583 64.3125 64 64.3125Z"
      fill="url(#paint0_linear_160_1870)"
    />
    <circle cx="101" cy="64" r="27" fill="white" />
    <circle
      cx="101"
      cy="64"
      r="30"
      stroke="white"
      strokeOpacity="0.1"
      strokeWidth="6"
    />
    <path
      d="M99.0176 72.8066C99.6882 72.8066 100.202 72.543 100.561 72.0156L109.652 58.0996C109.776 57.9043 109.867 57.7155 109.926 57.5332C109.984 57.3509 110.014 57.1751 110.014 57.0059C110.014 56.5501 109.861 56.1725 109.555 55.873C109.249 55.5736 108.861 55.4238 108.393 55.4238C108.074 55.4238 107.803 55.4889 107.582 55.6191C107.367 55.7428 107.159 55.9577 106.957 56.2637L98.9785 68.8711L94.916 63.8418C94.571 63.4186 94.138 63.207 93.6172 63.207C93.1484 63.207 92.7578 63.36 92.4453 63.666C92.1393 63.9655 91.9863 64.3464 91.9863 64.8086C91.9863 65.0104 92.0221 65.2057 92.0938 65.3945C92.1654 65.5833 92.2891 65.7786 92.4648 65.9805L97.5039 72.084C97.9076 72.5658 98.4121 72.8066 99.0176 72.8066Z"
      fill="#150E1F"
    />
    <defs>
      <linearGradient
        id="paint0_linear_160_1870"
        x1="6.4"
        y1="-6.56905e-06"
        x2="147.212"
        y2="36.4519"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C72A8E" />
        <stop offset="1" stopColor="#7815DA" />
      </linearGradient>
    </defs>
  </svg>
);
