import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useFormContext, UseFormRegister, FieldValues } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";

interface SelectItemProps {
  name: string;
  Icon?: React.FC<any>;
  title: string;
  value: string | number | boolean;
  description?: string;
  selected: boolean;
  register: UseFormRegister<FieldValues>;
  image?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
}

const SelectItem = ({
  name,
  title,
  selected,
  value,
  register,
  image,
  isRequired,
  isDisabled,
}: SelectItemProps) => {
  return (
    <Box sx={{ position: "relative", zIndex: 1, boxSizing: "border-box" }}>
      <FormControlLabel
        className={selected ? "selected" : ""}
        sx={{
          boxSizing: "border-box",
          marginLeft: 0,
          marginRight: 0,
          position: "relative",
          display: "inline-flex",
          padding: "16px",
          textAlign: "left",
          borderRadius: 2,

          backgroundColor: "common.lightgray",
          color: selected ? "common.blackBlue" : "common.gray",
          cursor: "pointer",
          transition: "ease-in-out 600ms",
          "& .MuiCheckbox-root": {
            display: "none",
          },
          "& span": {
            fontWeight: 500,
            fontSize: "14px",
            fontFamily: "Inter",
          },
          "&.selected": {
            "&.MuiFormControlLabel-root": {
              "& span": {
                fontWeight: 600,
              },
            },
            "&:after": {
              display: "inline-block",
              content: "''",
              background:
                "linear-gradient(99.95deg, #C72A8E -24.43%, #7815DA 100%);",
              position: "absolute",
              margin: "-2px",
              borderRadius: "10px",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              zIndex: -1,
            },
          },
        }}
        control={
          <Checkbox
            {...register(name, { required: isRequired })}
            disabled={isDisabled && !selected}
            value={value}
            disableRipple
            sx={{ marginRight: 0.5 }}
          />
        }
        label={
          !image ? (
            title
          ) : (
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <Box component="img" src={image} sx={{ marginRight: 1 }} />
              {title}
            </Typography>
          )
        }
      />
    </Box>
  );
};

interface AccountTypeFieldProps {
  loading?: boolean;
  name: string;
  question?: string;
  options?: {
    label: string;
    value: string | number | boolean;
    description?: string;
    image?: string;
  }[];
  isRequired?: boolean;
  limit?: number;
  simplified?: boolean;
}

export const HookedCheckbox = ({
  loading,
  name,
  question,
  options,
  isRequired,
  limit,
  simplified,
}: AccountTypeFieldProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    register,
    watch,
    getFieldState,
    formState: { errors }, // eslint-disable-line @typescript-eslint/no-unused-vars
  } = useFormContext();

  const { error } = getFieldState(name);

  const selectedValue = watch(name);

  useEffect(() => {
    if (selectedValue && selectedValue.length && limit) {
      if (selectedValue.length === limit) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [selectedValue]);

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      {!simplified && question && (
        <Typography
          sx={{ fontWeight: 700, marginBottom: 3, fontFamily: "Inter" }}
        >
          {question}
        </Typography>
      )}
      {simplified && question && (
        <Typography
          variant="body2"
          fontWeight={600}
          color="common.gray"
          marginBottom={1}
        >
          {question}
        </Typography>
      )}
      <Box
        sx={{ display: "inline-flex", flexWrap: "wrap", gap: 2, width: "100%" }}
      >
        {loading ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          options?.map((opt) => {
            return (
              <SelectItem
                isDisabled={isDisabled}
                isRequired={isRequired}
                image={opt.image}
                register={register}
                name={name}
                key={String(opt.value)}
                title={opt.label}
                value={opt.value}
                description={opt.description}
                selected={
                  selectedValue
                    ? Array.isArray(selectedValue)
                      ? selectedValue.findIndex((x) => {
                          return String(x) === String(opt.value);
                        }) !== -1
                      : selectedValue.includes(
                          String(opt.value) || Number(opt.value),
                        )
                    : selectedValue
                }
              />
            );
          })
        )}
      </Box>
      <FormHelperText error={!!error}>
        {error?.message?.toString()}
      </FormHelperText>
    </Box>
  );
};
