export const PlansIcon = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3327_6974)">
        <path
          d="M10.2083 70H4.375C1.96292 70 0 68.0371 0 65.625V4.375C0 1.96292 1.96292 0 4.375 0H10.2083C12.6204 0 14.5833 1.96292 14.5833 4.375V65.625C14.5833 68.0371 12.6204 70 10.2083 70ZM4.375 2.91667C3.57 2.91667 2.91667 3.57292 2.91667 4.375V65.625C2.91667 66.4271 3.57 67.0833 4.375 67.0833H10.2083C11.0133 67.0833 11.6667 66.4271 11.6667 65.625V4.375C11.6667 3.57292 11.0133 2.91667 10.2083 2.91667H4.375Z"
          fill="white"
        />
        <path
          d="M65.6248 70H59.7915C57.3794 70 55.4165 68.0371 55.4165 65.625V4.375C55.4165 1.96292 57.3794 0 59.7915 0H65.6248C68.0369 0 69.9998 1.96292 69.9998 4.375V65.625C69.9998 68.0371 68.0369 70 65.6248 70ZM59.7915 2.91667C58.9865 2.91667 58.3332 3.57292 58.3332 4.375V65.625C58.3332 66.4271 58.9865 67.0833 59.7915 67.0833H65.6248C66.4298 67.0833 67.0832 66.4271 67.0832 65.625V4.375C67.0832 3.57292 66.4298 2.91667 65.6248 2.91667H59.7915Z"
          fill="white"
        />
        <path
          d="M45.2082 70H24.7915C22.3794 70 20.4165 68.0371 20.4165 65.625V4.375C20.4165 1.96292 22.3794 0 24.7915 0H45.2082C47.6203 0 49.5832 1.96292 49.5832 4.375V65.625C49.5832 68.0371 47.6203 70 45.2082 70ZM24.7915 2.91667C23.9865 2.91667 23.3332 3.57292 23.3332 4.375V65.625C23.3332 66.4271 23.9865 67.0833 24.7915 67.0833H45.2082C46.0132 67.0833 46.6665 66.4271 46.6665 65.625V4.375C46.6665 3.57292 46.0132 2.91667 45.2082 2.91667H24.7915Z"
          fill="white"
        />
        <rect x="2" y="2" width="10" height="66" fill="white" />
        <rect x="58" y="2" width="10" height="66" fill="white" />
        <rect x="23" y="2" width="24" height="66" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_3327_6974">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
