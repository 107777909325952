import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { UserService } from "../../../common/services/UserService";
import { HookedField } from "../../components/HookedField";
import { HookedForm } from "../../components/HookedForm";

export const ForgotPassword = () => {
  const [sent, setSent] = useState(false);
  const formHook = useForm();

  const handleSubmit = async (values: any) => {
    try {
      UserService.SendResetEmail(values).then(() => {
        setSent(true);
      });
    } catch (e) {
      setSent(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container sx={{ minHeight: "100vh" }}>
        <Grid
          item
          md={6}
          sx={{
            padding: 7,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "common.black",
            backgroundImage: "url(/images/BiggerGradient.png)",
            backgroundSize: "cover",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            component="img"
            src="/images/FoundyLogoMenu.svg"
            sx={{
              width: 98,
              marginBottom: 5,
              position: "absolute",
              top: 56,
              left: 56,
            }}
          />
          <Box>
            <Typography
              variant="h1"
              sx={{ fontSize: 54, fontWeight: 600, color: "white" }}
            >
              Sign in to the UK&apos;s leading platform to{" "}
              <span>buy or sell</span> a business.
            </Typography>
            <Typography variant="h3" sx={{ color: "white", marginTop: 2 }}>
              Find out why thousands of other business buyers and sellers use
              Foundy for a streamlined, transparent acquisition process
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            padding: 8,
          }}
        >
          <Box>
            <Typography variant="h1" sx={{ marginBottom: 2 }}>
              Reset password
            </Typography>
            <HookedForm formHook={formHook} onSubmit={handleSubmit}>
              <HookedField
                component={TextField}
                name="email"
                variant="filled"
                margin="normal"
                label="Work Email"
                type="email"
                InputLabelProps={{ shrink: true }}
                fullWidth
                autoFocus
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 2,
                }}
              >
                <Button type="submit" fullWidth variant="roundgradient">
                  Send password reset link
                </Button>
              </Box>
            </HookedForm>
            {sent && <Box>Your password reset was sent to your email.</Box>}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
