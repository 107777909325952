export enum ClientPath {
  Home = "/",
  Templates = "/templates/",
  Introduction = "/introduction/",
  StartupExplore = "/startup-explore/",
  StartupInfo = "/startup-info/",
  StartupInfoHighlights = "/startup-info/highlights/",
  StartupInfoReasons = "/startup-info/reasons/",
  StartupInfoPerformance = "/startup-info/performance/",
  Requests = "/requests/",
  Settings = "/settings/",
  Profile = "/profile/",
  ChangePassword = "/change-password/",
  Premium = "/premium/",
  Startup = "/startup",
  PaymentSuccess = "/a",
  PaymentFailed = "/b",
  Subscription = "/subscription",
  Buyer = "/buyer",
  Scouts = "/scouts",
  Sendbird = "/sendbird",
}
