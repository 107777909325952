import { Maybe } from "../types/utils";
import { BoolRecord, ValueObj } from "../types";
import { IStartup, IUser, IUserFull } from "../models";
import { get } from "lodash";

export const generateUniqueIdentifier = (user: IUserFull) => {
  const { email, id } = user;
  if (user.sellerFull) {
    return `${email.charAt(0).toLocaleUpperCase()}${id
      .toString()
      .charAt(0)}${user.sellerFull.city?.charAt(
      0,
    )}${user.sellerFull.country?.charAt(0)}`;
  }
  if (user.buyerFull) {
    return `${email.charAt(0).toLocaleUpperCase()}${id
      .toString()
      .charAt(0)}${user.buyerFull.role?.charAt(
      0,
    )}${user.buyerFull.country?.charAt(0)}`;
  }
  return `${email.charAt(0).toLocaleUpperCase()}${id.toString().charAt(0)}`;
};

export const strToBoolRec = (value: Maybe<string>) => {
  if (!value) return {};
  const keyNames = value.split("\n").filter((v) => v);

  const result: { [key: string]: boolean } = {};
  keyNames.forEach((key: string) => {
    result[key] = true;
  });

  return result;
};

export const strToValueObjArr = (value: Maybe<string>): ValueObj[] => {
  if (!value) return [];
  const keyNames = value.split("\n").filter((v) => v);

  return keyNames.map((item: string) => ({
    value: item,
  }));
};

export const valueObjToStr = (v: ValueObj[]) => {
  return v.map((item: ValueObj) => item.value).join("\n");
};

export const boolRecToStr = (v: BoolRecord) => {
  return Object.keys(v)
    .filter((key) => v[key])
    .join("\n");
};

export const getFillingCompletion = (
  anyObject: IUserFull | IStartup,
  fields: string[],
) => {
  const totalFields = fields.length;
  let totalCompleted = 0;

  for (let i = 0; i < fields.length; i += 1) {
    if (get(anyObject, fields[i])) {
      if (Array.isArray(get(anyObject, fields[i]))) {
        if (get(anyObject, fields[i]).length) {
          totalCompleted += 1;
        } else {
          continue;
        }
        continue;
      }
      totalCompleted += 1;
    }
  }

  return (totalCompleted / totalFields) * 100;
};

export const getFillingPercent = (value: object, excludedFields?: string[]) => {
  let length = 0;
  let filledLength = 0;
  Object.entries(value).forEach(([key, value]): any => {
    if (excludedFields?.includes(key)) {
      return;
    }

    if (value === false) {
      return;
    }

    const isObject =
      typeof value === "object" && !Array.isArray(value) && value !== null;
    const isBoolean = typeof value === "boolean";
    const isEmptyObject = isObject && Object.keys(value).length === 0;
    const isDate =
      isObject && Object.prototype.toString.call(value) === "[object Date]";

    if (isEmptyObject && !isDate) {
      length++;
      return;
    }

    if (isObject || isBoolean) {
      length++;
      filledLength++;
      return;
    }

    const isArray = Array.isArray(value);
    if (isArray) {
      value.forEach((value) => {
        const itemIsObject = typeof value === "object";
        length++;

        if (itemIsObject && value.value) {
          filledLength++;
        } else if (!itemIsObject && value) {
          filledLength++;
        }
      });
      return;
    }

    length++;
    if (value || value === 0) {
      filledLength++;
    }
  });

  return (filledLength / length) * 100;
};

export const registerCampaing = (user?: IUser) => {
  const localUser = localStorage.getItem("user");

  if (user || localUser) {
    if ((window as any).campaign) {
      if (localUser) {
        const userParsed = JSON.parse(localUser) as IUser;
        (window as any).campaign.identify({
          email: userParsed.email,
        });
      }
      if (user) {
        (window as any).campaign.identify({
          email: user.email,
        });
      }
    }
  }
};
