import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { SideDialog } from "../../components/SideDialog";

const ProBuyerAdditionalInfoDrawer = ({
  handleDialog,
  open,
}: {
  handleDialog: () => void;
  open: boolean;
}) => (
  <SideDialog handleClose={handleDialog} open={open}>
    <Typography variant="h3" sx={{ marginBottom: 1 }}>
      Buyer Biographies
    </Typography>
    <Typography variant="body2" sx={{ marginBottom: 3, color: "common.gray" }}>
      We encourage you to include as much detail as possible, and we believe
      it’s important for buyers to express themselves and their interests as
      they see fit.
    </Typography>
    <Typography variant="body2" sx={{ marginBottom: 3, color: "common.gray" }}>
      That said, consider including the following:
    </Typography>
    <List sx={{ width: "100%", color: "common.gray", lineHeight: 1.5 }}>
      {[
        "What sort of business(es) you’re looking to acquire - size, area of operation, etc.",
        "How this acquisition meets your current goals as an individual/institutional acquirer?",
        "Your background.",
        "Your acquisition experience to date, including any positive testimonials from businesses you have evaluated in the past.",
        "What your acquisition process looks like.",
      ].map((item, i) => {
        return (
          <ListItem key={`list-${i}`}>
            <ListItemText>
              <Typography
                variant="body2"
                sx={{ marginBottom: 3, color: "common.gray" }}
              >
                • {item}
              </Typography>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  </SideDialog>
);

export default ProBuyerAdditionalInfoDrawer;
