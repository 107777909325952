import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { UserService } from "../../../common/services/UserService";
import { HookedField } from "../../components/HookedField";
import { HookedForm } from "../../components/HookedForm";
import { RoutePaths } from "../../consts/enums";

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const formHook = useForm();

  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const handleSubmit = (values: any) => {
    if (!email || !token) return;
    UserService.ResetPassword({
      email,
      token,
      new_password: values.password,
    }).then(() => {
      navigate(RoutePaths.LOGIN);
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container sx={{ minHeight: "100vh" }}>
        <Grid
          item
          md={6}
          sx={{
            padding: 7,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "common.black",
            backgroundImage: "url(/images/BiggerGradient.png)",
            backgroundSize: "cover",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            component="img"
            src="/images/FoundyLogoMenu.svg"
            sx={{
              width: 98,
              marginBottom: 5,
              position: "absolute",
              top: 56,
              left: 56,
            }}
          />
          <Box>
            <Typography
              variant="h1"
              sx={{ fontSize: 54, fontWeight: 600, color: "white" }}
            >
              Sign in to the UK&apos;s leading platform to{" "}
              <span>buy or sell</span> a business.
            </Typography>
            <Typography variant="h3" sx={{ color: "white", marginTop: 2 }}>
              Find out why thousands of other business buyers and sellers use
              Foundy for a streamlined, transparent acquisition process
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            padding: 8,
          }}
        >
          {!token && !email ? (
            <Box>
              <Typography variant="h2">
                Something went wrong while trying to reset your password.
              </Typography>{" "}
              <Typography variant="body2">
                You can go back to the <Link to="/login">login page</Link> and
                try again.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="h1" sx={{ marginBottom: 2 }}>
                Create your new password
              </Typography>
              <HookedForm formHook={formHook} onSubmit={handleSubmit}>
                <HookedField
                  component={TextField}
                  name="password"
                  variant="filled"
                  margin="normal"
                  label="New password"
                  type="password"
                  InputLabelProps={{ shrink: true }}
                  helperText="Must contain 8 characters and one number at least"
                  fullWidth
                  autoFocus
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 2,
                  }}
                >
                  <Button type="submit" fullWidth variant="roundgradient">
                    Reset password
                  </Button>
                </Box>
              </HookedForm>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
