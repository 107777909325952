import { Box, Typography, Button } from "@mui/material";
import { FUNDRAISING_LINK } from "../../consts/consts";

const FundraisingBlock = () => (
  <Box
    sx={{
      marginBottom: 4,
      paddingX: 10,
      paddingY: 5,
      backgroundColor: "common.grayLighterOpacity",
      borderRadius: 2,
    }}
  >
    <Typography
      sx={{
        marginBottom: 2,
        color: "white",
        fontWeight: 500,
        fontFamily: "Inter",
        fontSize: 20,
      }}
    >
      Fundraising?
    </Typography>
    <Button variant="roundgradient" href={FUNDRAISING_LINK} target="_blank">
      Get Funded Here
    </Button>
  </Box>
);

export default FundraisingBlock;
