import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormState } from "react-hook-form";
import { ButtonProps, CircularProgress } from "@mui/material";

interface HookedButtonProps extends ButtonProps {
  children: React.ReactNode;
  disableDirty?: boolean;
  circularProgressColor?: string;
}

export const HookedButton = ({
  children,
  disableDirty = true,
  circularProgressColor = "white",
  ...rest
}: HookedButtonProps) => {
  const { isSubmitting, isDirty } = useFormState();

  return (
    <LoadingButton
      {...rest}
      type="submit"
      loading={isSubmitting}
      disabled={!isDirty && disableDirty}
      loadingIndicator={
        <CircularProgress
          thickness={8}
          sx={{ color: circularProgressColor }}
          size={16}
        />
      }
    >
      {children}
    </LoadingButton>
  );
};
