import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { ComponentProps, ComponentType } from "react";
import { useFormContext } from "react-hook-form";

export const HookedRadioGroup = <
  TComponent extends ComponentType<TProps>,
  TProps = ComponentProps<TComponent>,
>({
  ...componentProps
}: {
  helperText?: string;
} & Partial<Omit<TProps, "component">> & {
    name: string;
    loading?: boolean;
    defaultValue?: string;
    options?: { label?: string; value: string }[];
    isRequired?: boolean;
    label?: string;
  }) => {
  const { register, getFieldState, watch } = useFormContext();
  const { error } = getFieldState(componentProps.name);

  const fieldValue = watch(componentProps.name);

  return (
    <Box>
      <FormControl sx={{ position: "relative", zIndex: 1 }}>
        {componentProps.label && (
          <Typography
            variant="body2"
            fontWeight={600}
            color="common.gray"
            marginBottom={1}
          >
            {componentProps.label}
          </Typography>
        )}
        <RadioGroup
          defaultValue={componentProps.defaultValue}
          sx={{ gap: 2 }}
          row
          name={componentProps.name}
        >
          {componentProps.loading ? (
            <CircularProgress />
          ) : (
            componentProps.options?.map((itm, index) => {
              const { options, ...rest } = componentProps;

              return (
                <FormControlLabel
                  className={
                    fieldValue && String(fieldValue) === String(itm.value)
                      ? "selected"
                      : ""
                  }
                  key={`${index}-${itm.value}`}
                  sx={{
                    marginX: 0,
                    background: "#F3F3F3",
                    borderRadius: "8px",
                    padding: "16px",
                    display: "block",
                    position: "relative",

                    "& .MuiRadio-root": {
                      display: "none",
                    },
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "Inter",
                      fontSize: "14px",
                      color: "common.gray",
                      fontWeight: 500,
                    },
                    "&.selected": {
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 600,
                        color: "common.black",
                      },
                      "&:after": {
                        display: "inline-block",
                        content: "''",
                        background:
                          "linear-gradient(99.95deg, #EA336B -24.43%, #6911E7 100%);",
                        position: "absolute",
                        margin: "-2px",
                        borderRadius: "10px",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        top: 0,
                        zIndex: -1,
                      },
                    },
                  }}
                  value={itm.value}
                  control={
                    <Radio
                      {...rest}
                      {...register(componentProps.name, {
                        required: componentProps.isRequired,
                      })}
                      error={!!error}
                    />
                  }
                  label={itm.label}
                />
              );
            })
          )}
        </RadioGroup>
      </FormControl>
      <FormHelperText error={!!error}>
        {error?.message?.toString()}
      </FormHelperText>
    </Box>
  );
};
