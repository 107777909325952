import { Box, Button, Collapse, TableCell, TableRow } from "@mui/material";
import { DateTime } from "luxon";
import { openInNewTab } from "../../../common/utils";
import { useState } from "react";
import { ISeller } from "../../../common/models";

export const SellerRow = ({ row }: { row: ISeller }) => {
  const [open, setOpen] = useState(false);

  const expandRow = () => {
    setOpen((curr) => !curr);
  };

  return (
    <>
      <TableRow
        key={row.id}
        onClick={() => expandRow()}
        sx={{
          cursor: "pointer",
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.status}
        </TableCell>
        <TableCell align="right">{row.user?.first_name}</TableCell>
        <TableCell align="left">{row.user?.last_name}</TableCell>
        <TableCell align="left">{row.user?.email || ""}</TableCell>
        <TableCell align="right">
          {DateTime.fromISO(row.user?.date_joined ?? "").toFormat(
            "dd/LL/yyyy HH:mm",
          )}
        </TableCell>
        <TableCell align="right">
          {DateTime.fromISO(row.user?.last_login ?? "").toFormat(
            "dd/LL/yyyy HH:mm",
          )}
        </TableCell>
      </TableRow>

      <TableRow
        sx={{
          borderBottom: open ? 1 : 0,
          borderBottomColor: "rgba(224, 224, 224, 1)",
        }}
      >
        <TableCell
          colSpan={10}
          sx={{ border: 0 }}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ display: "flex", gap: 2, paddingY: 1 }}>
              <Button
                onClick={() =>
                  openInNewTab(
                    process.env.REACT_APP_API_ADMIN +
                      "/profiles/seller/" +
                      row.id,
                  )
                }
                variant="roundgradient"
                sx={{ border: 0, "&:hover": { border: 0 } }}
                color="error"
              >
                View in Django Admin
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
