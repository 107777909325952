import { Button, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import * as yup from "yup";
import { UserService } from "../../../common/services/UserService";
import { useAppContext } from "../../../common/context/AppContext";
import { PASSWORD_REGEX } from "../../../common/constants";
import { HookedForm } from "../HookedForm";
import { HookedField } from "../HookedField";
import { useState } from "react";

type TFormFields = {
  old_password?: string;
  password: string;
  password_confirmation: string;
};

interface PasswordFormProps {
  handlePasswordState?: () => void;
  submitText?: string;
  showOldPassword?: boolean;
}

const getSchema = ({
  showOldPassword,
}: Omit<PasswordFormProps, "onSubmit">): yup.SchemaOf<TFormFields> =>
  yup
    .object({
      old_password: showOldPassword
        ? yup
            .string()
            .required()
            .matches(PASSWORD_REGEX, t("auth.password.invalid"))
            .label("Old Password")
        : yup.string().label("Old Password"),
      password: yup
        .string()
        .required()
        .matches(PASSWORD_REGEX, t("auth.password.invalid"))
        .label("New Password"),
      password_confirmation: yup
        .string()
        .required()
        .oneOf([yup.ref("password"), null], t("auth.passwordMatch"))
        .label("Password Confirmation"),
    })
    .required();

export const PasswordForm = ({
  handlePasswordState,
  submitText,
  showOldPassword,
}: PasswordFormProps) => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { user } = useAppContext();
  const { t } = useTranslation();

  const onSubmit = async (values: TFormFields) => {
    await UserService.ChangePassword(user.id, values);
    setPasswordChanged((curr) => !curr);
  };

  return (
    <HookedForm<any>
      onSubmit={onSubmit}
      schema={getSchema({ showOldPassword })}
    >
      {showOldPassword && (
        <HookedField
          component={TextField}
          sx={{ mb: 4 }}
          name="old_password"
          variant="filled"
          label="Old Password"
          type="password"
          fullWidth
        />
      )}
      <HookedField
        component={TextField}
        name="password"
        variant="filled"
        label="New Password"
        type="password"
        fullWidth
      />
      <HookedField
        component={TextField}
        sx={{ mt: 4 }}
        name="password_confirmation"
        variant="filled"
        label="Password Confirmation"
        type="password"
        fullWidth
      />
      {passwordChanged && (
        <Typography variant="body2">Password changed.</Typography>
      )}
      <Stack marginTop={2} direction="row" spacing={2}>
        <Button fullWidth onClick={handlePasswordState} variant="roundlink">
          Cancel
        </Button>
        <Button type="submit" fullWidth variant="roundgradientblue">
          {submitText || t("app.finish")}
        </Button>
      </Stack>
    </HookedForm>
  );
};
