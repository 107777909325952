import { useAppContext } from "../../../common/context/AppContext";
import { YourAcquisitionsBuyer } from "./Buyer";
import { YourAcquisitionsSeller } from "./Seller";

export const YourAcquisitions = () => {
  const { user } = useAppContext();

  return user.buyerFull ? (
    <YourAcquisitionsBuyer />
  ) : (
    <YourAcquisitionsSeller />
  );
};
