import { ApiService } from "./ApiService";
import {
  CompanyJsonBucketFields,
  IBusinessCategory,
  IPaginatedReq,
  IStartup,
  IStartupType,
  IUserFull,
  SellerJsonBucket,
} from "../models";
import { UserService } from "./UserService";
import { AcquisitionType, CompanyStatus, StartupStatus } from "../types";
import { mapList, mapStartupIn, mapStartupOut } from "../models/mappers";
import { IApiStartup } from "../models/api";
import { Maybe } from "../types/utils";
import { FillingPercentsService } from "./FillingPercentsService";
import { pick, omit } from "lodash";

export interface IStartupsParams extends IPaginatedReq {
  ordering?: string; // Could be a CSV of orderings
  age_min?: number;
  age_max?: number;
  customers?: number;
  profit_min?: number;
  profit_max?: number;
  revenue_min?: number;
  revenue_max?: number;
  team_size?: number;
  price_min?: number;
  price_max?: number;
  hide_under_offer?: number;
  search?: string;
  business_type?: number;
  is_validated?: boolean;
  acquisition_type?: AcquisitionType;
  startup_team_size?: any | any[];
  asking_price?: any | any[];
  country?: string[];
  ttm_revenue?: number[];
  is_not_viewed?: boolean;
}

export interface ICompany extends CompanyJsonBucketFields, IPaginatedReq {
  type?: number;
  business_model?: string;
  growth_opportunity?: Maybe<string>;
  date_founded?: string;
  startup_team_size?: Maybe<number>;
  reason?: string;
  annual_revenue?: string | number;
  annual_cost?: string | number;
  annual_profit?: string | number;
  avg_net_revenue_1m?: string;
  avg_net_profit_1m?: string;
  key_assets?: string;
  founders_post_acquisition?: string;
  weekly_commitment?: string;
  description?: string;
  asking_price?: number;
  open_to_offer?: boolean;
  open_to_offers?: boolean;
  operating_regions: string[];
  key_assets_new: string[];
  business_models: number[];
}

const jsonDataKeys: (keyof CompanyJsonBucketFields)[] = [
  "founder_linkedin",
  "metric_notification",
  "cvl",
  "founder_bio",
];

export const StartupService = {
  GetUserCompany: (): Promise<IStartup[]> => {
    return ApiService.Get("/v2/startups/").then((res) => res.results);
  },
  GetSellerBucketData: async (): Promise<SellerJsonBucket> => {
    return (
      (await ApiService.GetJson<SellerJsonBucket>()) || {
        startups: {},
      }
    );
  },
  // FIXME: remove any and find how to merge ICopmany and IStartupsParams
  PatchCompany: async (id: number | string, data: ICompany | any) => {
    const sellerV3 = await StartupService.GetSellerBucketData();
    const v3StartupData = pick(data, ...jsonDataKeys);
    sellerV3.startups[Number(id)] = v3StartupData;
    const v3SaveReq = ApiService.SaveJson(sellerV3);

    const v2Data = omit(data, ...jsonDataKeys);
    const v2SaveReq = ApiService.Patch(`/v2/startups/${id}/`, v2Data);

    return Promise.all([v2SaveReq, v3SaveReq]).then(() => ({
      ...v2Data,
      ...v3StartupData,
    }));
  },
  // FIXME: remove any and find how to merge ICopmany and IStartupsParams
  GetCompany: async ({ id }: { id: string }): Promise<ICompany | any> => {
    const v2Data = await ApiService.Get(`/v2/startups/${id}/`);
    const sellerV3 = await StartupService.GetSellerBucketData();
    const startupJsonBucketData = sellerV3.startups[Number(id)] || {};
    return {
      ...v2Data,
      ...startupJsonBucketData,
    };
  },
  GetSellerStartup: async (sellerID: number): Promise<IStartup | undefined> => {
    const seller = await UserService.GetSeller(sellerID);
    const [startupID] = seller.startups;
    return startupID ? await StartupService.GetStartup(startupID) : undefined;
  },
  CreateStartup: (data?: ICompany) => {
    return ApiService.Post(`/startup/`, data).then((res) => res);
  },
  GetStartup: (
    id: number | string,
    disableError?: boolean,
  ): Promise<IStartup> =>
    ApiService.Get(
      `/startup/${id}/`,
      {},
      {
        disableError: disableError ? disableError : false,
      },
    ).then(mapStartupIn),
  PatchStartup: ({ id, values }: { id: number; values: Partial<IStartup> }) => {
    return ApiService.Patch(`/startup/${id}/`, mapStartupOut(values)).then(
      mapStartupIn,
    );
  },
  DeleteStartup: (id: number | string) => {
    return ApiService.Delete(`/startup/${id}/`);
  },
  GetStartups: (params: IStartupsParams) => {
    return ApiService.GetPaginatedData<IApiStartup>(`/startup/`, params).then(
      (v) => mapList<IApiStartup, IStartup>(v, mapStartupIn),
    );
  },
  GetTypes: () => {
    return ApiService.GetPaginatedData<IStartupType>(
      "/v2/startups/business-types/",
      {
        page_size: 100,
      },
    ).then((v) => v.results);
  },
  GetBusinessCategories: ({ id }: { id: string }) => {
    return ApiService.GetPaginatedData<IBusinessCategory>(
      `/v2/startups/${id}/business-categories/`,
      {
        page_size: 100,
      },
    ).then((v) => v.results);
  },
  GetBusinessCategoriesAll: () => {
    return ApiService.GetPaginatedData<IBusinessCategory>(
      `/v2/startups/business-categories/`,
      {
        page_size: 100,
      },
    ).then((v) => v.results);
  },
  DraftStartup: (id: number | string) => {
    return ApiService.Patch(`/startup/${id}/draft/`, {});
  },
  PublishStartup: (id: number | string) => {
    return ApiService.Patch(`/startup/${id}/publish/`, {});
  },
  ChangeAutoChatApprove: async ({
    id,
    chatAutoApprove,
  }: {
    id: number;
    chatAutoApprove: boolean;
  }) => {
    return ApiService.Patch(`/startup/${id}/chat-auto-approve/`, {
      chat_auto_approve: chatAutoApprove,
    });
  },
  GetCompanyStatus: (user: IUserFull): CompanyStatus | undefined => {
    const startup = user.startups?.[0];
    const total = FillingPercentsService.GetPercentageCompany(startup);
    if (total < 100 && startup?.status === StartupStatus.Pending) {
      return CompanyStatus.Incompleted;
    }
    if (total === 100 && startup?.status === StartupStatus.Pending) {
      return CompanyStatus.Pending;
    }
    if (startup?.status === StartupStatus.Approved && startup.published) {
      return CompanyStatus.ApprovedPublished;
    }
    if (startup?.status === StartupStatus.Approved && !startup.published) {
      return CompanyStatus.ApprovedUnpublished;
    }
    if (!startup?.published) {
      return CompanyStatus.Unpublished;
    }
    if (startup?.status === StartupStatus.Disapproved) {
      return CompanyStatus.Disapproved;
    }
  },
  CreateListingView: (id: number | string) => {
    return ApiService.Post(`/v2/startups/${id}/listing-views/`, {}).then(
      (res) => res,
    );
  },
  GetListingViewsCount: (id: number) => {
    return ApiService.Get(`/v2/startups/${id}/listing-views/count`).then(
      (res) => res,
    );
  },
  GetStartupChatsCount: (id: number) => {
    return ApiService.Get(`/v2/startups/${id}/chats/count`).then((res) => res);
  },
  GetStartupFavouritesCount: (id: number) => {
    return ApiService.Get(`/v2/startups/${id}/favourites/count`).then(
      (res) => res,
    );
  },
};
