import { Box, Container, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { FTextField } from "../../../components/FTextField";
import { HookedField } from "../../../components/HookedField";
import { HookedForm } from "../../../components/HookedForm";
import { RoutePaths } from "../../../consts/enums";
import { useParams } from "react-router-dom";
import ProfileNavigationButtons from "../ProfileNavigationButtons";

// FIXME: this needs proper routing and it shouldn't save on "Back".
export const SellerFunding = () => {
  const navigate = useNavigate();
  const { id: startupId } = useParams();

  const handleNext = (path: string) => {
    navigate(path);
  };

  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <Container maxWidth="md" sx={{ padding: 4 }}>
        <Typography variant="h3" sx={{ color: "white" }}>
          Complete Professional Profile
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "common.grayOpacity" }}>
          Complete your Seller Profile to access thousands of business buyers
        </Typography>
        <Paper elevation={2} sx={{ marginTop: 4, padding: 3 }}>
          <Typography variant="h3" sx={{ marginBottom: 1 }}>
            External Funding
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "common.gray", marginBottom: 2 }}
          >
            How much external funding (debt or equity finance) have you raised?
          </Typography>
          <HookedForm>
            <HookedField
              fullWidth
              variant="filled"
              component={FTextField}
              name="price"
              currency
            />
          </HookedForm>
        </Paper>
        <ProfileNavigationButtons
          onSaveAndContinue={() =>
            handleNext(RoutePaths.REGISTER_SELLER + `/${startupId}/financials`)
          }
          onBack={() => () =>
            handleNext(RoutePaths.REGISTER_SELLER + `/${startupId}/keyassets`)
          }
        />
      </Container>
    </Box>
  );
};
