import { useMediaQuery, useTheme } from "@mui/material";
import "@sendbird/uikit-react/dist/index.css";
import { MessageMobile } from "./Mobile";
import { MessageDesktop } from "./Desktop";
import { GroupChannel, Member } from "@sendbird/chat/groupChannel";
import { useEffect, useState } from "react";
import { useChatMetadata, useStartupTypes } from "../../../common/hooks";
import { useAppContext } from "../../../common/context/AppContext";
import { StartupService } from "../../../common/services/StartupService";
import { UserService } from "../../../common/services/UserService";
import { IStartup, IBuyer, ISeller } from "../../../common/models";
// import { ChatFreemiumModal } from "../../components/ChatFreemiumModal";

export type ChatMateType = {
  user_type: string;
} & (IBuyer & ISeller);

export class SBCustomMember extends Member {
  metaData: any | object;
}

export const Messages = () => {
  const theme = useTheme();
  const matchBreakpoint = useMediaQuery(() => theme.breakpoints.down("sm"));
  const { user, handleMobileMenu } = useAppContext();
  const { data: chatMetadata } = useChatMetadata(user);
  const { data: startupTypes = [] } = useStartupTypes();
  const [channelInformation, setChannelInformation] = useState<GroupChannel>();

  const [startup, setStartup] = useState<IStartup>();
  const [otherUserInfo, setOtherUserInfo] = useState<ChatMateType>();
  const [otherUserSBInfo, setOtherUserSBInfo] = useState<SBCustomMember>();
  // const [showChat, setShowChat] = useState<boolean>(true);

  // Check if free buyer; Get all startups; check which ones are free to contact, pass them to the chat component; use them in the query

  useEffect(() => {
    if (channelInformation && channelInformation.url) {
      const otherUserSBInfo: SBCustomMember | undefined =
        channelInformation.members.find(
          (member: SBCustomMember) =>
            member.metaData.user_type !== user.active_role,
        );

      if (otherUserSBInfo) {
        const profileId = otherUserSBInfo.metaData.profile_id;
        setOtherUserSBInfo(otherUserSBInfo);
        if (otherUserSBInfo.metaData.user_type === "seller") {
          UserService.GetSeller(profileId).then((x) =>
            setOtherUserInfo({ ...x, user_type: "seller" }),
          );
        } else {
          // TODO: expose the buyer name via the backend
          UserService.GetBuyer(profileId).then((buyer) => {
            UserService.GetUserOnly(buyer.user).then((user) =>
              setOtherUserInfo({
                ...buyer,
                first_name: user.first_name,
                last_name: user.last_name,
                user_type: "buyer",
              }),
            );
          });
        }
      }
      if (chatMetadata && chatMetadata.length) {
        const chatMetadataItem = chatMetadata.find(
          (metadata) => metadata.conversation_sid === channelInformation.url,
        );
        if (chatMetadataItem) {
          StartupService.GetStartup(chatMetadataItem.startup_id, true).then(
            (x) => {
              setStartup(x);
            },
          );
        }
      }
    }
  }, [channelInformation, chatMetadata]);

  const setChannel = (channel: GroupChannel) => {
    if (channel && channel.url !== channelInformation?.url) {
      setChannelInformation(channel);
    }
  };

  const dropAllChannelData = () => {
    setChannelInformation(undefined);
    setStartup(undefined);
    setOtherUserInfo(undefined);
    setOtherUserSBInfo(undefined);
  };
  return (
    <>
      {/* {!showChat ? <ChatFreemiumModal /> : null} */}
      {matchBreakpoint ? (
        <MessageMobile
          setChannel={setChannel}
          handleMobileMenu={handleMobileMenu}
          dropAllChannelData={dropAllChannelData}
          otherUserSBInfo={otherUserSBInfo}
          otherUser={otherUserInfo}
          startUp={startup}
          channelInformation={channelInformation}
          startupTypes={startupTypes}
          // blurred={!showChat}
        />
      ) : (
        <MessageDesktop
          setChannel={setChannel}
          otherUserSBInfo={otherUserSBInfo}
          otherUser={otherUserInfo}
          startUp={startup}
          user={user}
          channelInformation={channelInformation}
          startupTypes={startupTypes}
          // blurred={!showChat}
        />
      )}
    </>
  );
};
