import {
  Avatar,
  Box,
  Button,
  Divider,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import { isMobile } from "react-device-detect";
import SBConversation from "@sendbird/uikit-react/Channel";
import SBChannelList from "@sendbird/uikit-react/ChannelList";
import "@sendbird/uikit-react/dist/index.css";
import { useEffect, useState } from "react";

import { Header } from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";

import { RoutePaths } from "../../consts/enums";
import { abbreviateNumber } from "../../../common/utils";

import CloseIcon from "@mui/icons-material/Close";
import { LinkIcon } from "../../../common/assets/icons/LinkIcon";

import { GroupChannel } from "@sendbird/chat/groupChannel";
import { IStartup, IStartupType, IUser } from "../../../common/models";

import ChatNoConversationsBanner from "../../components/ChatNoConversationsBanner";
import { ChatMateType, SBCustomMember } from ".";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import { UserIcon } from "../../../common/assets/icons/User";
import { SellerIcon } from "../../../common/assets/icons/Seller";
import { CharUserPopover } from "../../components/CharUserPopover";

type MessagePropsType = {
  channelInformation?: GroupChannel;
  startUp?: IStartup;
  user: IUser;
  otherUser?: ChatMateType;
  otherUserSBInfo?: SBCustomMember;
  setChannel: (channel: GroupChannel) => void;
  startupTypes?: IStartupType[];
  blurred?: boolean;
};

type DesktopHeaderProps = {
  userDetails: boolean;
  channelInformation?: GroupChannel;
  otherUser?: ChatMateType;
  user: IUser;
  navigate: (path: string) => void;
  otherUserSBInfo?: SBCustomMember;
  startUp?: IStartup;
  handleUserDetails: () => void;
};

const DesktopHeader = ({
  userDetails,
  channelInformation,
  otherUser,
  navigate,
  otherUserSBInfo,
  user,
  startUp,
  handleUserDetails,
}: DesktopHeaderProps) => {
  const startupName =
    startUp &&
    (startUp?.seller == user.seller ? startUp?.name : `Startup ${startUp?.id}`);

  const chatMateName =
    otherUser?.user_type === "buyer"
      ? `${otherUser.first_name} ${otherUser.last_name}`
      : otherUserSBInfo?.nickname;
  return (
    <Header raw>
      <Box
        sx={{
          background: "white",
          minHeight: 88,
          display: "grid",
          gridTemplateColumns: userDetails
            ? "320px repeat(4, 1fr)"
            : "320px repeat(3, 1fr)",
          gridTemplateRows: "1fr",
          gridColumnGap: "0px",
          gridRowGap: "0px",
          zIndex: 20,
          position: "relative",
          boxShadow: "0px 1px 2px rgba(2, 17, 37, 0.12)",
        }}
      >
        <Box
          sx={{
            paddingLeft: 2,
            gridArea: "1 / 1 / 2 / 2",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Connections</Typography>
        </Box>
        {channelInformation && otherUser && channelInformation.url && (
          <Box
            sx={{
              gridArea: userDetails ? "1 / 2 / 2 / 5" : "1 / 2 / 2 / 4",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography
                onClick={() =>
                  navigate(
                    `/${
                      otherUserSBInfo?.metaData.user_type === "seller"
                        ? "company"
                        : "buyer"
                    }/${otherUserSBInfo?.metaData.profile_id}`,
                  )
                }
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  color: "#0E1114",
                  cursor: "pointer",
                }}
              >
                <UserIcon gradient style={{ marginRight: 5 }} /> {chatMateName}
                {startupName && (
                  <>
                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                      -&gt;
                    </span>
                    <SellerIcon
                      color="bluegradient"
                      width={16}
                      height={16}
                      style={{ marginRight: 5 }}
                    />
                    {startupName}
                  </>
                )}
              </Typography>

              <Typography
                fontWeight={400}
                fontSize={13}
                variant="body2"
                color="#616c76"
              >
                {!otherUserSBInfo?.lastSeenAt
                  ? ""
                  : `Last seen at ${DateTime.fromMillis(
                      otherUserSBInfo.lastSeenAt,
                    ).toFormat("dd LLL yyyy HH:mm")}`}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            gridArea: userDetails ? "1 / 5 / 2 / 6" : "1 / 4 / 2 / 5",
            paddingRight: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => handleUserDetails()}
            variant="roundprimary"
            sx={{ marginRight: 2, display: !userDetails ? "block" : "none" }}
          >
            {!userDetails ? "Show" : "Hide"} details
          </Button>
          <Avatar
            onClick={() => navigate(RoutePaths.SETTINGS)}
            variant="circular"
            sx={{
              height: 40,
              width: 40,
              cursor: "pointer",
              display: { xs: "none", md: "inline-flex" },
            }}
          />
        </Box>
      </Box>
    </Header>
  );
};

export const MessageDesktop = ({
  channelInformation,
  startUp,
  user,
  otherUserSBInfo,
  otherUser,
  setChannel,
  startupTypes,
  blurred,
}: MessagePropsType) => {
  const theme = useTheme();
  const matchBreakpoint = useMediaQuery(() => theme.breakpoints.down("sm"));
  const [selectedContent, setSelectedContent] = useState<string>();

  const [userDetails, setUserDetails] = useState(
    matchBreakpoint ? false : true,
  );

  const navigate = useNavigate();
  // FIXME: why is it here?
  const sendbird = useSendbirdStateContext();
  useEffect(() => {
    if (sendbird?.stores?.sdkStore?.sdk?.reconnect) {
      sendbird?.stores?.sdkStore?.sdk?.reconnect();
    }
  }, []);

  const handleUserDetails = () => {
    setUserDetails((curr) => !curr);
  };

  const chatMateName =
    otherUser?.user_type === "buyer"
      ? `${otherUser.first_name} ${otherUser.last_name}`
      : otherUserSBInfo?.nickname;
  const startupName =
    startUp &&
    (startUp?.seller == user.seller ? startUp?.name : `Startup ${startUp?.id}`);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        background: "#F8F8F8",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DesktopHeader
        userDetails={userDetails}
        channelInformation={channelInformation}
        otherUser={otherUser}
        user={user}
        navigate={navigate}
        otherUserSBInfo={otherUserSBInfo}
        startUp={startUp}
        handleUserDetails={handleUserDetails}
      />

      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          height: "calc(100% - 88px)",
          "& .sendbird-channel-list__header": {
            display: "none;",
          },
          "& .sendbird-channel-preview__action": {
            display: "none",
          },
          "& .sendbird-chat-header.sendbird-conversation__channel-header": {
            display: "none",
          },
          "& .sendbird-channel-list": {
            boxShadow: "14px 0px 20px #f1f1f1",
            zIndex: 1,
          },
          "& .sendbird-message-input": {
            width: "93%",
            zIndex: 0,
            boxSizing: "border-box",
            border: "1px solid transparent",
            backgroundClip: "padding-box",
            position: "relative",
            borderRadius: 3.5,
            "& .sendbird-message-input--textarea": {
              border: 0,
            },
            "&:before": {
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: -1,
              margin: "-1px",
              borderRadius: "inherit",
              background:
                "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
            },
          },

          "& .sendbird-message-input .sendbird-message-input--textarea": {
            lineBreak: "anywhere",
          },
          " .sendbird-channel-preview__content__lower": {
            filter: blurred ? "blur(3px)" : "none",
          },
          " .sendbird-text-message-item-body": {
            filter: blurred ? "blur(3px)" : "none",
          },
        }}
      >
        <SBChannelList
          // FIXME: returns everything if the filter is empty, need to feed it something ludicrous to not return anything.
          // queries={
          //   isBasicPlan(user)
          //     ? {
          //         channelListQuery: {
          //           channelUrlsFilter: filteredChatsUrls?.length
          //             ? filteredChatsUrls
          //             : ["absolutelyNotValidUrl"],
          //         },
          //       }
          //     : undefined
          // }
          allowProfileEdit={false}
          disableUserProfile
          renderPlaceHolderEmptyList={() =>
            ChatNoConversationsBanner(user, navigate)
          }
          onChannelSelect={(channel) => {
            if (channel !== null) setChannel(channel);
          }}
        />
        <SBConversation
          channelUrl={channelInformation?.url ?? ""}
          renderUserProfile={() => (
            <CharUserPopover
              otherUser={otherUser}
              otherUserSendbirdInfo={otherUserSBInfo}
              startUp={startUp}
            />
          )}
        />
      </Box>

      {userDetails && otherUser && otherUserSBInfo && (
        <Slide
          style={{
            position: "absolute",
            zIndex: 20,
            right: 0,
            maxWidth: "320px",
            ...(isMobile && { overflow: "auto" }),
          }}
          direction="left"
          in={userDetails}
          mountOnEnter
          unmountOnExit
        >
          <Box
            sx={{
              padding: 2,
              zIndex: 10,
              width: "100%",
              height: "100%",
              boxShadow: "-10px -1px 10px rgba(0, 0, 0, 0.05)",
              background: "white",
              borderLeft: "1px solid #CDD5DE",
            }}
          >
            {!selectedContent && (
              <Box
                sx={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <Box
                  onClick={() => handleUserDetails()}
                  sx={{
                    position: "absolute",
                    right: 0,
                    width: "max-content",
                    top: -5,
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon sx={{ color: "gray" }} />
                </Box>
                <Typography variant="h3" sx={{ fontWeight: 600 }}>
                  {`${chatMateName} - ${startupName}`}
                </Typography>
                <Divider sx={{ marginY: 1 }} />
                {otherUser.user_type === "seller" && (
                  <>
                    <Typography
                      variant="h3"
                      sx={{ textTransform: "capitalize" }}
                    >
                      <span>{`${
                        startupTypes?.find(
                          (x: IStartupType) =>
                            x.id === Number(startUp?.business_type),
                        )?.name
                      } ${
                        otherUser.industry
                          ? `- ${otherUser.industry
                              ?.replace(/_/g, " ")
                              .toLocaleLowerCase()}`
                          : ""
                      }`}</span>
                    </Typography>
                    <Typography variant="body2" marginTop={1}>
                      {startUp && (
                        <>
                          <b>Asking Price </b>
                          {startUp?.open_to_offer
                            ? "Open to Offers"
                            : `£${abbreviateNumber(
                                Number(startUp.asking_price),
                              )}`}
                        </>
                      )}
                    </Typography>
                    {startUp?.avg_net_profit_1m && (
                      <Typography variant="body2" marginTop={1}>
                        <b>Profit</b>{" "}
                        {abbreviateNumber(Number(startUp?.avg_net_profit_1m))}
                      </Typography>
                    )}
                    {startUp?.avg_net_revenue_1m && (
                      <Typography variant="body2" marginTop={1}>
                        <b>Revenue</b>{" "}
                        {abbreviateNumber(Number(startUp?.avg_net_revenue_1m))}
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      sx={{ marginTop: 2, fontWeight: 600 }}
                    >
                      Description
                    </Typography>
                    <Typography variant="body2" fontWeight={400}>
                      {startUp?.description}
                    </Typography>
                  </>
                )}

                {otherUser?.user_type !== "seller" && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ marginTop: 2, fontWeight: 600 }}
                    >
                      ID
                    </Typography>
                    <Typography variant="body2" fontWeight={400}>
                      <span>{otherUser.id}</span>
                    </Typography>
                    <Divider sx={{ marginY: 1 }} />

                    <Typography
                      variant="body2"
                      sx={{ marginTop: 2, fontWeight: 600 }}
                    >
                      Category
                    </Typography>
                    <Typography variant="body2" fontWeight={400}>
                      <span>{otherUser.role}</span>
                    </Typography>
                    <Divider sx={{ marginY: 1 }} />

                    {otherUser.valuation && (
                      <Typography variant="body2" marginTop={1}>
                        <b>Budget</b>{" "}
                        {`£ ${abbreviateNumber(Number(otherUser.valuation))}`}
                      </Typography>
                    )}
                    {otherUser.bio && (
                      <>
                        <Divider sx={{ marginY: 1 }} />
                        <Typography
                          variant="body2"
                          sx={{ marginTop: 2, fontWeight: 600 }}
                        >
                          Biography
                        </Typography>
                        <Typography variant="body2" fontWeight={400}>
                          {otherUser.description}
                        </Typography>
                      </>
                    )}
                  </>
                )}
                <Divider sx={{ marginY: 1 }} />
                <Typography
                  variant="body2"
                  sx={{ marginTop: 2, fontWeight: 600 }}
                >
                  Links
                </Typography>
                <Box
                  sx={{ cursor: "pointer" }}
                  marginTop={1}
                  onClick={() =>
                    navigate(
                      `/${
                        otherUserSBInfo?.metaData.user_type === "seller"
                          ? `company/${startUp?.id}`
                          : `buyer/${otherUser.id}`
                      }`,
                    )
                  }
                >
                  <LinkIcon />
                </Box>

                <Divider sx={{ marginY: 2 }} />
                <Typography variant="body2" fontWeight={600} marginTop={1}>
                  Templates & Tips
                </Typography>
                <Stack spacing={1} fontSize={14} fontWeight={400} marginTop={1}>
                  {user.active_role === "buyer" && (
                    <Typography
                      variant="body2"
                      fontWeight={400}
                      sx={{ cursor: "pointer" }}
                      onClick={() => setSelectedContent(() => "buyerToSeller")}
                    >
                      <span className="gradient">Buyer to Seller Messages</span>
                    </Typography>
                  )}

                  <Link
                    target="_blank"
                    to="https://foundy.trumpet.app/pods/63ece8e13bd3e0ac24d8c39a/live"
                  >
                    <span className="gradient">NDA Template</span>
                  </Link>
                </Stack>
                <Box
                  sx={{
                    background: "#F9F9F9",
                    borderRadius: 2,
                    padding: 2,
                    textAlign: "center",
                    position: isMobile ? "inherit" : "absolute",
                    bottom: isMobile ? 0 : 15,
                    left: isMobile ? 0 : 15,
                  }}
                >
                  <Typography
                    fontSize={10}
                    variant="body2"
                    fontWeight={400}
                    color="common.gray"
                  >
                    All buyers and sellers are subject to NDA.
                    <br /> All conversations are private and secure
                  </Typography>
                </Box>
              </Box>
            )}
            {selectedContent === "buyerToSeller" && (
              <Box sx={{ height: "87.5vh", overflowY: "scroll" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="roundoutlinedgradientblue"
                    onClick={() => setSelectedContent(undefined)}
                    sx={{ margin: 1 }}
                  >
                    Back
                  </Button>
                </Box>
                <Typography variant="h3" marginBottom={1}>
                  Example message to Sellers:
                </Typography>
                <Typography variant="body2" marginBottom={2}>
                  Hi, it’s great to connect!
                  <br />
                  I came across your business on the marketplace and it seems
                  like a good fit for my interests.
                  <br />
                  I’d love to learn more about the business and explore a
                  potential acquisition.
                  <br />
                  To get the due diligence process underway, it would be great
                  if you could answer a few initial questions. I’m happy to sign
                  an NDA if you’d prefer.
                  <br />
                  I’m also happy to answer any questions you might have for me!
                  <br />
                  Thanks in advance and look forward to discussing this further.
                </Typography>
                <Typography variant="h3" marginBottom={1}>
                  Additional tips for messaging Foundy users:
                </Typography>
                <Typography variant="body2" marginBottom={2}>
                  Let the seller know who you are:
                  <br />
                  Eg. ‘Our fund has a proven track record of success in the
                  industry, and we believe your business could be a valuable
                  addition to our portfolio.’
                  <br />
                  <br />
                  Talking about any previous acquisitions you have made and your
                  experience in this regard is advisable here.
                  <br />
                  <br />
                  Make clear how you want to proceed, emphasise communication,
                  transparency and a smooth acquisition process
                  <br />
                  Eg. ‘We understand that selling your business is a big
                  decision, and we want to assure you that we are committed to
                  working together transparently and progressively.’
                </Typography>
                <Typography variant="h3" marginBottom={1}>
                  Suggested due diligence questions to ask the seller include:
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body2">
                      What percentage of your revenue is recurring?
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      What is the average order value per client?
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      What is your average customer acquisition cost?
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      How much debt does your business have?
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      What is your customer churn rate?
                    </Typography>
                  </li>
                </ul>
              </Box>
            )}
            {selectedContent === "sellerToBuyer" && <Box></Box>}
          </Box>
        </Slide>
      )}
    </Box>
  );
};
