import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  SliderInput,
  SliderInputProps,
  SliderRange,
} from "../SliderInput/DoubleSlider";
import { FormHelperText } from "@mui/material";

interface HookedSliderInputProps extends Omit<SliderInputProps, "onChange"> {
  name: string;
  value?: SliderRange;
}

export const HookedSliderInput = ({
  name,
  ...rest
}: HookedSliderInputProps) => {
  const { register, setValue, watch, getFieldState } = useFormContext();

  const { error } = getFieldState(name);
  const watchValue = watch(name);
  const value: SliderRange = useMemo(() => watchValue || null, [watchValue]);

  useEffect(() => {
    register(name);
  }, [register, name]);

  const handleChange = useCallback(
    (v: SliderRange) => {
      setValue(name, v);
    },
    [name, setValue],
  );

  return (
    <>
      <SliderInput onChange={handleChange} value={value} {...rest} />
      <FormHelperText error={!!error}>
        {error?.message?.toString()}
      </FormHelperText>
    </>
  );
};
