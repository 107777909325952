import { Box, BoxProps, styled } from "@mui/material";

type BoxType = "default" | "rounded";

const typeStylesDict = (theme: any): Record<BoxType, React.CSSProperties> => ({
  default: {
    background: theme.palette.info.lightest,
    borderRadius: "12px",
    border: `2px solid ${theme.palette.info.light}`,
  },
  rounded: {
    background: theme.palette.common.white,
    borderRadius: 14,
    padding: 34,
  },
});

const StyledBox = styled(Box)<AppCardProps>(({ theme, type }) => ({
  ...typeStylesDict(theme)[type || "default"],
  padding: "24px 32px",
  position: "relative",
  "&:before": {
    position: "absolute",
    margin: -2,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    content: "''",
    backgroundImage:
      "radial-gradient(circle at 100% 100%, transparent 10px, #0000ff 10px, #0000ff 12px, transparent 12px), linear-gradient(to right, #0000ff, #00ffd1), radial-gradient(circle at 0% 100%, transparent 10px, #00ffd1 10px, #00ffd1 12px, transparent 12px), linear-gradient(to bottom, #00ffd1, #ff00dc), radial-gradient(circle at 0% 0%, transparent 10px, #ff00dc 10px, #ff00dc 12px, transparent 12px), linear-gradient(to left, #ff00dc, #18dcb8), radial-gradient(circle at 100% 0%, transparent 10px, #18dcb8 10px, #18dcb8 12px, transparent 12px), linear-gradient(to top, #18dcb8, #0000ff)",
    backgroundSize:
      "12px 12px, calc(100% - 24px) 2px, 12px 12px, 2px calc(100% - 24px)",
    backgroundPosition:
      "top left,top center,top right,center right, bottom right,bottom center,bottom left,center left",
    backgroundRepeat: " no-repeat",
    border: 0,
    opacity: 0,
    transition: "ease-in-out 300ms",
  },
  "&:hover:before": {
    opacity: 1,
  },
}));

interface AppCardProps extends BoxProps {
  type?: BoxType;
}

export const AppCard = ({ children, ...props }: AppCardProps) => {
  return (
    <StyledBox {...props}>
      <div className="app-card-inner">{children}</div>
    </StyledBox>
  );
};
