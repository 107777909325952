import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MarketplaceItem } from "../MarketplaceItem";
import {
  useStartup,
  useStartupTypes,
  useTeamSize,
} from "../../../common/hooks";
import { IStartup } from "../../../common/models";
import { usePrismicClient } from "@prismicio/react";
import { useEffect, useState } from "react";

interface ModalProps extends DialogProps {
  companyId: number;
  onCancel: () => void;
}

export const HotDealModal = (props: ModalProps) => {
  const [hotListings, setHotListings] = useState<number[]>([]);
  const { data: startupTypes = [] } = useStartupTypes();
  const { data: teamSize = [] } = useTeamSize();
  const { data: company, isLoading } = useStartup(props.companyId);
  const prismic = usePrismicClient();

  const getPrismic = () => {
    prismic.getByUID("hot_listings", "hot-listings").then((res) => {
      if (res.data?.slices && res.data?.slices[0]) {
        const ids = res.data?.slices[0].items.map(
          (itm: { url_id: number }) => itm.url_id,
        );
        setHotListings(ids);
      }
    });
  };

  useEffect(() => {
    getPrismic();
  }, []);

  return (
    <Dialog fullWidth maxWidth="lg" {...props} scroll="body">
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          backgroundImage: "url(/images/MenuGradient.png)",
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontSize: { xs: 26, md: 35 } }}
          fontSize={35}
        >
          <span>🔥 Hot deal - Act Fast</span>
        </Typography>
        <Box
          sx={{
            height: { xs: "100%", md: "auto" },
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => props.onCancel()}
        >
          <CloseIcon />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: 0, paddingBottom: 2, textAlign: "center" }}>
        <Container maxWidth="lg" sx={{ paddingTop: 2 }}>
          {!isLoading && (
            <MarketplaceItem
              isHotListing={hotListings.includes(Number(props.companyId))}
              startupTypes={startupTypes}
              teamSize={teamSize}
              key={props.companyId}
              {...(company as IStartup)}
            />
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
};
