import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Navigate } from "react-router";
import { UserService } from "../../../admin/services/UserService";
import { useAppContext } from "../../../common/context/AppContext";
import { IBuyer, ISeller, IStartup } from "../../../common/models";
import { BuyerStatus, StartupStatus, UserType } from "../../../common/types";
import { AdminQuery, useInfiniteDataQuery } from "../../../common/utils/query";
import { Header } from "../../components/Header";
import { RoutePaths } from "../../consts/enums";
import { ScrollerLoader } from "../../../common/components/ScrollerLoader";
import { useStartupTypes } from "../../../common/hooks";
import { SellerRow } from "./SellerRow";
import { BuyerRow } from "./BuyerRow";
import { BuyerService } from "../../../common/services/BuyerService";
import { StartupService } from "../../../common/services/StartupService";
import { useQueryClient } from "react-query";
import { StartupRow } from "./StartupRow";

interface IFilter {
  search: string;
  page_size: number;
}

const defaultFilter: IFilter = {
  search: "",
  page_size: 10,
};

export const Management = () => {
  const [searchTextSeller, setSearchSeller] = useState("");
  const [searchTextBuyer, setSearchBuyer] = useState("");
  const [searchTextStartup, setSearchStartup] = useState("");
  const [selectedTab, setSelectedTab] = useState("seller");
  const { data = [] } = useStartupTypes();
  const { user } = useAppContext();
  const queryClient = useQueryClient();

  if (!user.is_staff) return <Navigate to={RoutePaths.DASHBOARD} />;

  const handleExportClick = async (role: UserType) => {
    await UserService.GetExportData(role);
  };

  const filterBuyer = useMemo<IFilter>(
    () => ({
      ...defaultFilter,
      search: searchTextBuyer,
    }),
    [searchTextBuyer],
  );

  const filterSeller = useMemo<IFilter>(
    () => ({
      ...defaultFilter,
      search: searchTextSeller,
    }),
    [searchTextSeller],
  );

  const filterStartup = useMemo<IFilter>(
    () => ({
      ...defaultFilter,
      search: searchTextStartup,
    }),
    [searchTextStartup],
  );

  const buyers = useInfiniteDataQuery<IBuyer>(
    AdminQuery.Buyers,
    UserService.GetBuyers,
    filterBuyer,
  );

  const sellers = useInfiniteDataQuery<ISeller>(
    AdminQuery.Sellers,
    UserService.GetSellers,
    filterSeller,
  );

  const startups = useInfiniteDataQuery<IStartup>(
    AdminQuery.Startups,
    StartupService.GetStartups,
    filterStartup,
  );

  const handleTab = (value: string) => {
    setSelectedTab(value);
  };

  const approveBuyer = (row: IBuyer) => {
    BuyerService.PatchBuyer({ ...row, status: BuyerStatus.Approved }).then(() =>
      queryClient.invalidateQueries(["Buyers"]),
    );
  };

  const disapproveBuyer = (row: IBuyer) => {
    BuyerService.PatchBuyer({ ...row, status: BuyerStatus.Disapproved }).then(
      () => queryClient.invalidateQueries(["Buyers"]),
    );
  };

  const approveCompany = (row: IStartup) => {
    StartupService.PatchStartup({
      id: row.id,
      values: { status: StartupStatus.Approved },
    }).then(() => queryClient.invalidateQueries(["Sellers"]));
  };

  const disapproveCompany = (row: IStartup) => {
    StartupService.PatchStartup({
      id: row.id,
      values: { status: StartupStatus.Disapproved },
    }).then(() => queryClient.invalidateQueries(["Sellers"]));
  };

  const handleFilterBuyer = (value: string) => {
    setSearchBuyer(value);
  };
  const handleFilterSeller = (value: string) => {
    setSearchSeller(value);
  };

  const handleFilterStartup = (value: string) => {
    setSearchStartup(value);
  };

  if (user && !user.is_staff) return <Navigate to="/dashboard" />;

  return (
    <Box>
      <Header>
        <Typography
          variant="h2"
          fontFamily="Inter"
          sx={{ fontSize: { xs: 16, md: 24 }, margin: 0 }}
        >
          Management
        </Typography>
      </Header>
      <Container maxWidth="lg" sx={{ paddingTop: 5 }}>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          Hi, {user.email}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          marginBottom={2}
          spacing={2}
        >
          <Stack direction="row" spacing={2}>
            <Button
              variant={
                selectedTab === UserType.Seller
                  ? "roundgradient"
                  : "roundprimary"
              }
              onClick={() => handleTab(UserType.Seller)}
            >
              Sellers
            </Button>
            <Button
              variant={
                selectedTab === "startups" ? "roundgradient" : "roundprimary"
              }
              onClick={() => handleTab("startups")}
            >
              Startups
            </Button>
            <Button
              variant={
                selectedTab === UserType.Buyer
                  ? "roundgradient"
                  : "roundprimary"
              }
              onClick={() => handleTab(UserType.Buyer)}
            >
              Buyers
            </Button>
            <TextField
              name="search"
              onChange={(e) => {
                if (selectedTab === "seller")
                  handleFilterSeller(e.target.value);
                if (selectedTab === "buyer") handleFilterBuyer(e.target.value);
                if (selectedTab === "startups")
                  handleFilterStartup(e.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button
              variant="roundprimary"
              onClick={() => handleExportClick(UserType.Buyer)}
            >
              Export Buyers
            </Button>
            <Button
              variant="roundprimary"
              onClick={() => handleExportClick(UserType.Seller)}
            >
              Export Sellers
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Container maxWidth="xl">
        {selectedTab === "startups" && (
          <TableContainer sx={{ minWidth: 650, height: 650 }} component={Paper}>
            <Table aria-label="Startup table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>ID #</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="right">%</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 700 }}>
                    Email
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    Name
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    Cat. + Ind.
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    Location
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    Ask. Price
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    Open to Offer
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: 700 }}>
                    Business Model
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    Annual Revenue
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {startups.items.map((row) => {
                  return (
                    <StartupRow
                      key={row.id}
                      row={row}
                      startupTypes={data}
                      approveCompany={approveCompany}
                      disapproveCompany={disapproveCompany}
                    />
                  );
                })}
              </TableBody>
            </Table>
            <ScrollerLoader scroller={startups.scroller} />
          </TableContainer>
        )}
        {selectedTab === "seller" && (
          <TableContainer sx={{ minWidth: 650, height: 650 }} component={Paper}>
            <Table aria-label="Seller Table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>ID #</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="right">First Name</TableCell>
                  <TableCell align="left">Last Name</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 700 }}>
                    Email
                  </TableCell>
                  <TableCell align="right">Date Joined</TableCell>
                  <TableCell align="right">Last Login</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sellers.items?.map((row) => {
                  return <SellerRow key={row.id} row={row} />;
                })}
              </TableBody>
            </Table>
            <ScrollerLoader scroller={sellers.scroller} />
          </TableContainer>
        )}
        {selectedTab === "buyer" && (
          <TableContainer component={Paper} sx={{ minWidth: 650, height: 650 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>IDs</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    Status
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    %
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    Email
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: 700 }}>
                    Full Name
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    Role
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    Subs. Plan
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    Cat. Interests
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700 }}>
                    Budget
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buyers.items.map((row) => (
                  <BuyerRow
                    key={row.id}
                    row={row}
                    startupTypes={data}
                    approveBuyer={approveBuyer}
                    disapproveBuyer={disapproveBuyer}
                  />
                ))}
              </TableBody>
            </Table>
            <ScrollerLoader scroller={buyers.scroller} />
          </TableContainer>
        )}
      </Container>
    </Box>
  );
};
