import { useEffect, useState } from "react";
import {
  useListingViewsCount,
  useStartupChatsCount,
  useStartupFavouritesCount,
} from "../../../common/hooks";
import { Box } from "@mui/material";

type FomoType = {
  type: "favourites" | "chats" | "listingViews" | "underOffer";
  value: number;
};

type FomoBadgeProps = {
  startupId: number;
  isUnderOffer: boolean;
};

export const FomoBadge = ({ startupId, isUnderOffer }: FomoBadgeProps) => {
  const { data: chatsCount } = useStartupChatsCount(startupId);
  const { data: favouritesCount } = useStartupFavouritesCount(startupId);
  const { data: listingViews } = useListingViewsCount(startupId);
  const [fomoObject, setFomoObject] = useState<FomoType>();

  useEffect(() => {
    if (isUnderOffer) {
      setFomoObject({
        type: "underOffer",
        value: 0,
      });
      return;
    }
    if (chatsCount && chatsCount.count > 10) {
      setFomoObject({
        type: "chats",
        value: chatsCount.count,
      });
      return;
    }
    if (favouritesCount && favouritesCount.count > 10) {
      setFomoObject({
        type: "favourites",
        value: favouritesCount.count,
      });
      return;
    }
    if (listingViews && listingViews.count > 50) {
      setFomoObject({
        type: "listingViews",
        value: listingViews.count,
      });
      return;
    }
  }, [chatsCount, favouritesCount, listingViews, isUnderOffer]);

  return (
    <>
      {fomoObject?.type === "underOffer" && (
        <Box
          component="p"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            paddingX: "8px",
            paddingY: "4px",
            backgroundColor: "rgba(255, 99, 58, 0.1)",
            borderRadius: 1,
            color: "#FD2F02",
            fontSize: 10,
            fontWeight: 700,
          }}
        >
          🚀 Act Fast - Under Offer
        </Box>
      )}
      {fomoObject?.type === "chats" && (
        <Box
          component="p"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            paddingX: "8px",
            paddingY: "4px",
            backgroundColor: "#F7E9F6",
            borderRadius: 1,
            color: "#B743F6",
            fontSize: 10,
            fontWeight: 700,
          }}
        >
          💟 {fomoObject.value} users have contacted this seller
        </Box>
      )}
      {fomoObject?.type === "favourites" && (
        <Box
          component="p"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            paddingX: "8px",
            paddingY: "4px",
            backgroundColor: "#F7E9F6",
            borderRadius: 1,
            color: "#B743F6",
            fontSize: 10,
            fontWeight: 700,
          }}
        >
          💜 Favourited by {fomoObject.value} users
        </Box>
      )}
      {fomoObject?.type === "listingViews" && (
        <Box
          component="p"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            paddingX: "8px",
            paddingY: "4px",
            backgroundColor: "rgba(255, 99, 58, 0.1)",
            borderRadius: 1,
            color: "#FD2F02",
            fontSize: 10,
            fontWeight: 700,
          }}
        >
          🔥 Popular listing
        </Box>
      )}
    </>
  );
};
