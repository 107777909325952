interface SVGIconProps {
  color?: string;
}
export const DocumentIcon = ({ color = "black" }: SVGIconProps) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.82812 17.875H11.1719C12.026 17.875 12.6771 17.651 13.125 17.2031C13.5729 16.7552 13.7969 16.099 13.7969 15.2344V8.32031H7.8125C6.85938 8.32031 6.38281 7.84115 6.38281 6.88281V0.84375H2.82812C1.96875 0.84375 1.3151 1.07031 0.867188 1.52344C0.424479 1.97656 0.203125 2.63281 0.203125 3.49219V15.2344C0.203125 16.099 0.424479 16.7552 0.867188 17.2031C1.3151 17.651 1.96875 17.875 2.82812 17.875ZM8.03906 7.11719H13.7188C13.6927 6.9349 13.6146 6.75 13.4844 6.5625C13.3542 6.375 13.1823 6.17188 12.9688 5.95312L8.72656 1.66406C8.52344 1.45573 8.32812 1.28646 8.14062 1.15625C7.95312 1.02083 7.76562 0.942708 7.57812 0.921875V6.66406C7.57812 6.96615 7.73177 7.11719 8.03906 7.11719Z"
      fill={
        color
          ? color === "bluegradient"
            ? "url(#paint0_linear_586_2224)"
            : color === "purplegradient"
              ? "url(#paint0_linear_586_2224)"
              : color
          : "black"
      }
    />
  </svg>
);
