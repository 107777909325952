import {
  Container,
  Box,
  Grid,
  Button,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import { GradientTick } from "../../../common/assets/icons/GradientTick";
import { QuotesIcon } from "../../../common/assets/icons/QuotesIcon";
import { EventName, trackEvent } from "../../../common/utils/helpers";
import { useAppContext } from "../../../common/context/AppContext";
import { AlreadyMember } from "./AlreadyMember";
import { isBasicPlan } from "../../../common/utils";

const items = [
  "Browse all listings",
  "Message business owners directly",
  "Unlock additional company info",
  "Free acquisition payment transfer",
];

const handleClick = (url?: string) => {
  window.open(url, "_blank");
};

export const ProBuyerStepNoSecret = ({
  selectedTab,
  handleTab,
}: {
  selectedTab: string;
  handleTab: (value: string) => void;
}) => {
  const { user } = useAppContext();
  const STRIPE_PAYMENT_APP_URL = process.env
    .REACT_APP_STRIPE_PAYMENT_APP_URL as string;

  if (!isBasicPlan(user)) return <AlreadyMember />;
  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Box
              sx={{
                transition: "ease-in-out 500ms",
                border: "1px solid gray",
                borderRadius: "100px",
                width: "100%",
                display: "flex",
                marginBottom: 3,
              }}
            >
              <Button
                sx={{
                  paddingX: { xs: 5, md: 10 },
                  fontSize: { xs: 12, md: 16 },
                  flex: "1 1 auto",
                }}
                variant={selectedTab === "pro" ? "roundgradient" : "roundlink"}
                onClick={() => handleTab("pro")}
              >
                Pro Buyer
              </Button>
              <Button
                sx={{
                  paddingX: { xs: 5, md: 10 },
                  fontSize: { xs: 12, md: 16 },
                  flex: "1 1 auto",
                }}
                variant={
                  selectedTab === "basic" ? "roundgradient" : "roundlink"
                }
                onClick={() => handleTab("basic")}
              >
                Basic Buyer
              </Button>
            </Box>
            <Typography variant="h2">
              Upgrade to Pro Buyer to message business owners
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontWeight: 600,
                marginY: { xs: 1, md: 4 },
                fontSize: { xs: 40, md: 60 },
                "& span": { fontSize: { xs: 18, md: 25 } },
              }}
            >
              £49 <span>(+VAT) /6 months</span>
            </Typography>
            <Typography
              variant="h3"
              color="common.gray"
              fontWeight={600}
              sx={{ marginTop: { xs: 2, md: 4 } }}
              fontSize={20}
            >
              That’s less than £8.50/month!
              <br />
              We do not charge success fees to Pro Buyers
            </Typography>
            <Stack spacing={2} marginY={5}>
              <Button
                fullWidth
                variant="roundapple"
                sx={{ "&:hover": { backgroundColor: "black" } }}
                onClick={() => {
                  trackEvent(EventName.STARTER_PRESS);
                  handleClick(
                    `${STRIPE_PAYMENT_APP_URL}?prefilled_email=${encodeURI(
                      user.email,
                    )}${
                      user.customer_key
                        ? "&client_reference_id=" + user.customer_key
                        : ""
                    }`,
                  );
                }}
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5492 20.28C16.5692 21.23 15.4992 21.08 14.4692 20.63C13.3792 20.17 12.3792 20.15 11.2292 20.63C9.78919 21.25 9.02919 21.07 8.16919 20.28C3.28919 15.25 4.00919 7.59 9.54919 7.31C10.8992 7.38 11.8392 8.05 12.6292 8.11C13.8092 7.87 14.9392 7.18 16.1992 7.27C17.7092 7.39 18.8492 7.99 19.5992 9.07C16.4792 10.94 17.2192 15.05 20.0792 16.2C19.5092 17.7 18.7692 19.19 17.5392 20.29L17.5492 20.28ZM12.5292 7.25C12.3792 5.02 14.1892 3.18 16.2692 3C16.5592 5.58 13.9292 7.5 12.5292 7.25Z"
                    fill="white"
                  />
                </svg>
                Pay
              </Button>
              <Button
                fullWidth
                variant="roundprimary"
                onClick={() => {
                  trackEvent(EventName.STARTER_PRESS);
                  handleClick(
                    `${STRIPE_PAYMENT_APP_URL}?prefilled_email=${encodeURI(
                      user.email,
                    )}${
                      user.customer_key
                        ? "&client_reference_id=" + user.customer_key
                        : ""
                    }`,
                  );
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_3649_8597)">
                    <path
                      d="M23.7148 12.2244C23.7148 11.2412 23.635 10.5236 23.4623 9.77954H12.2344V14.2175H18.8249C18.6921 15.3204 17.9746 16.9814 16.3801 18.0975L16.3577 18.246L19.9078 20.9962L20.1537 21.0208C22.4126 18.9346 23.7148 15.8652 23.7148 12.2244Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12.234 23.9176C15.4628 23.9176 18.1734 22.8545 20.1533 21.0209L16.3797 18.0976C15.3698 18.8018 14.0145 19.2934 12.234 19.2934C9.07158 19.2934 6.38751 17.2074 5.43072 14.324L5.29048 14.3359L1.59906 17.1927L1.55078 17.3269C3.51732 21.2334 7.55674 23.9176 12.234 23.9176Z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.4309 14.324C5.17844 13.58 5.03234 12.7826 5.03234 11.9589C5.03234 11.135 5.17844 10.3378 5.41762 9.59366L5.41093 9.43519L1.67325 6.53247L1.55096 6.59064C0.740459 8.21174 0.275391 10.0322 0.275391 11.9589C0.275391 13.8855 0.740459 15.7059 1.55096 17.327L5.4309 14.324Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12.234 4.62403C14.4795 4.62403 15.9943 5.59402 16.858 6.40461L20.233 3.10928C18.1602 1.1826 15.4628 0 12.234 0C7.55674 0 3.51732 2.68406 1.55078 6.59057L5.41744 9.59359C6.38751 6.7102 9.07158 4.62403 12.234 4.62403Z"
                      fill="#EB4335"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3649_8597">
                      <rect
                        width="23.4504"
                        height="24"
                        fill="white"
                        transform="translate(0.275391)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <span
                  style={{
                    marginLeft: 10,
                    background: "inherit",
                    color: "gray",
                    WebkitTextFillColor: "inherit",
                  }}
                >
                  Google Pay
                </span>
              </Button>
              <Button
                fullWidth
                variant="roundprimary"
                sx={{
                  backgroundColor: "#FEB4C7",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  trackEvent(EventName.STARTER_PRESS);
                  handleClick(
                    `${STRIPE_PAYMENT_APP_URL}?prefilled_email=${encodeURI(
                      user.email,
                    )}${
                      user.customer_key
                        ? "&client_reference_id=" + user.customer_key
                        : ""
                    }`,
                  );
                }}
              >
                Pay in 3 instalments with{" "}
                <svg
                  style={{ marginLeft: 6 }}
                  width="54"
                  height="13"
                  viewBox="0 0 54 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.43204 0.176514H6.80472C6.80472 2.45548 5.81465 4.54721 4.08847 5.91569L3.04756 6.74067L7.08072 12.561H10.3967L6.68571 7.2054C8.44481 5.3516 9.43204 2.85755 9.43204 0.176514ZM13.7162 12.5571H11.18V0.178883H13.7162V12.5571ZM21.3773 3.9946V4.54234C20.693 4.04827 19.867 3.75793 18.9761 3.75793C16.6183 3.75793 14.7069 5.78075 14.7069 8.27604C14.7069 10.7713 16.6183 12.7942 18.9761 12.7942C19.867 12.7942 20.693 12.5038 21.3773 12.0099V12.5571H23.7997V3.9946H21.3773ZM21.3717 8.27585C21.3717 9.49254 20.3889 10.4789 19.1767 10.4789C17.9645 10.4789 16.9817 9.49254 16.9817 8.27585C16.9817 7.05916 17.9645 6.07303 19.1767 6.07303C20.3889 6.07303 21.3717 7.05916 21.3717 8.27585ZM46.9341 4.54234V3.9946H49.3564V12.5571H46.9341V12.0099C46.2497 12.5038 45.4237 12.7942 44.5327 12.7942C42.1749 12.7942 40.2636 10.7713 40.2636 8.27604C40.2636 5.78075 42.1749 3.75793 44.5327 3.75793C45.4237 3.75793 46.2497 4.04827 46.9341 4.54234ZM44.7332 10.4789C45.9455 10.4789 46.9281 9.49254 46.9281 8.27585C46.9281 7.05916 45.9455 6.07303 44.7332 6.07303C43.5209 6.07303 42.5383 7.05916 42.5383 8.27585C42.5383 9.49254 43.5209 10.4789 44.7332 10.4789ZM50.4054 11.1291C50.4054 10.2405 51.086 9.52032 51.9255 9.52032C52.765 9.52032 53.4457 10.2405 53.4457 11.1291C53.4457 12.0175 52.765 12.7378 51.9255 12.7378C51.086 12.7378 50.4054 12.0175 50.4054 11.1291ZM35.9582 3.76409C34.9905 3.76409 34.0748 4.08202 33.4625 4.95918V3.99489H31.0506V12.5569H33.4921V8.05727C33.4921 6.7552 34.3172 6.11758 35.3105 6.11758C36.3751 6.11758 36.9872 6.79065 36.9872 8.03954V12.5569H39.4067V7.11183C39.4067 5.11921 37.9097 3.76409 35.9582 3.76409ZM27.5479 3.99489V5.11009C28.0335 4.44114 28.9383 3.99539 29.922 3.99539V6.48694L29.9144 6.48669L29.9085 6.48638C29.9035 6.4861 29.8984 6.48581 29.8932 6.48581C28.9347 6.48581 27.5534 7.21081 27.5534 8.55957V12.5573H25.0678V3.99489H27.5479ZM2.72658 12.5606H0.0410156V0.176629H2.72658V12.5606Z"
                    fill="#17120F"
                  />
                </svg>
              </Button>
            </Stack>
            <Box
              sx={{
                position: "relative",
                marginBottom: 5,
                borderBottom: "1px solid #EEEEEE",
                "&::before": {
                  position: "absolute",
                  content: '"Or"',
                  backgroundColor: "transparent",
                  color: "gray",
                  width: "100%",
                  height: "30px",
                  top: "-15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            />
            <Button
              fullWidth
              variant="roundprimary"
              sx={{
                backgroundColor: "common.lightgray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "gray",
              }}
              onClick={() => {
                trackEvent(EventName.STARTER_PRESS);
                handleClick(
                  `${STRIPE_PAYMENT_APP_URL}?prefilled_email=${encodeURI(
                    user.email,
                  )}${
                    user.customer_key
                      ? "&client_reference_id=" + user.customer_key
                      : ""
                  }`,
                );
              }}
            >
              Pay by Card
            </Button>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                borderRadius: 3,
                backgroundColor: "common.lightgray",
                padding: 4,
              }}
            >
              <Typography variant="h2">Why Become a Pro Buyer?</Typography>
              <Box
                component="ul"
                fontSize={16}
                fontWeight={500}
                sx={{
                  padding: 0,
                  listStyle: "none",
                  marginTop: 2,
                  "& li": {
                    display: "inline-flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    marginTop: 2,
                    "&:first-of-type": {
                      marginTop: 0,
                    },
                  },
                }}
              >
                {items.map((itm: any) => (
                  <li key={itm}>
                    <Box sx={{ marginRight: 1, width: 32, height: 32 }}>
                      <GradientTick />
                    </Box>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      sx={{
                        color: "common.black",
                        marginTop: 0.5,
                      }}
                    >
                      {itm}
                    </Typography>
                  </li>
                ))}
              </Box>
              <Divider />
              <Box marginTop={3}>
                <QuotesIcon />
                <Typography
                  color="common.black"
                  fontSize={12}
                  sx={{ fontStyle: "italic" }}
                  fontWeight={500}
                >
                  Foundy has been the ideal partner. We are thrilled to
                  collaborate with their leading digital platform and team to
                  find acquisition opportunities and close deals together. My
                  company, Treasure Hunter, is a serial acquirer. We have over
                  30 content businesses in our portfolio.
                </Typography>
                <Box sx={{ textAlign: "right" }}>
                  <QuotesIcon />
                </Box>
                <Stack direction="row" spacing={2} marginTop={2}>
                  <Typography fontSize={12}>
                    Michael Fink, Founder & CEO of Treasure Hunter
                    <br />
                    30 businesses in their portfolio
                  </Typography>
                  <Box
                    component="img"
                    width={73}
                    height={73}
                    src="/images/Michael-Fink.png"
                  />
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
