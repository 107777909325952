import { Typography, Box } from "@mui/material";
import { Header } from "../../components/Header";
import { CheckoutForm } from "./CheckoutForm";
import { useAppContext } from "../../../common/context/AppContext";
import { Navigate } from "react-router-dom";

export const PlansPage = () => {
  const { user } = useAppContext();

  if (user.active_role === "seller") {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return (
    <Box>
      <Header>
        <Typography variant="h2">Subscription Plans</Typography>
      </Header>

      <Box>
        <CheckoutForm />
      </Box>
    </Box>
  );
};
