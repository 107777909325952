import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { PrismicImage } from "@prismicio/react";

import {
  IPrismicImage,
  IStartup,
  IStartupType,
  IUser,
} from "../../../common/models";

import { truncateText } from "../../../common/utils";
import { Link } from "react-router-dom";
import { HeartIcon } from "../../../common/assets/icons";
import { EventName, trackEvent } from "../../../common/utils/helpers";
import { MarketplaceItemButtonGroup } from "./MarketplaceItemButtonGroup";
import { FomoBadge } from "../FomoBadges";
import { LinkIcon } from "../../../common/assets/icons/LinkIcon";

const LABEL_KEYS = [
  "asking_price",
  "annual_revenue",
  "annual_profit",
  // "date_founded",
  "ttm_multiplier",
  // "startup_team_size",
];

export const MarketplaceItemMobile = ({
  headline,
  description,
  disableFavorite,
  disableNewListingGlow,
  isHotListing,
  editLink,
  user,

  saveFavourite,
  typeLabel,
  industry,
  isFavourite,
  categoryImage,
  controlled,
  getBusinessCategoryLabel,
  ...rest
}: IStartup & {
  disableFavorite?: boolean;
  disableNewListingGlow?: boolean;
  isHotListing?: boolean;
  editLink?: boolean;
  description: string | null;
  //
  user?: IUser;

  saveFavourite: (e: React.MouseEvent) => void;
  typeLabel?: IStartupType;
  industry?: string;
  isFavourite?: boolean;
  linkTo?: string;
  categoryImage: IPrismicImage | null;
  controlled?: boolean;
  getBusinessCategoryLabel: (id: number) => string;
}) => {
  const linkTo = editLink
    ? `/register/seller/${rest.id}/basic`
    : `/company/${rest.id}`;
  const matches = useMediaQuery("(min-width:400px)");

  const scrollToElement = () => {
    const element = document.getElementById("listing-view-description-summary");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Box
      component={controlled ? Box : Link}
      sx={{ color: "inherit" }}
      width={controlled ? "100%" : "auto"}
      to={linkTo}
      onClick={() => {
        if (!controlled) {
          trackEvent(EventName.OPEN_HOT_LISTING, { label: String(rest.id) });
        }
      }}
    >
      <Box
        component="div"
        sx={
          controlled
            ? undefined
            : {
                cursor: "pointer",
                background: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "common.lightgray",
                borderRadius: 2,
                transition: "200ms ease-in-out",
                boxShadow:
                  rest?.is_new_listing !== true ||
                  user?.active_role !== "buyer" ||
                  disableNewListingGlow
                    ? "0px 1px 2px rgba(0, 0, 0, 0.05)"
                    : "0px 0px 5px rgba(203, 44, 138, 10) !important",
                "&:hover": {
                  boxShadow:
                    rest?.is_new_listing !== true ||
                    user?.active_role !== "buyer" ||
                    disableNewListingGlow
                      ? "0px 0px 10px #c3c3c3"
                      : "0px 0px 10px rgba(203, 44, 138, 20) !important",
                  transform: "scale(1.01)",
                },
                position: "relative",
              }
        }
      >
        <Grid
          container
          sx={{ marginBottom: 1, display: "flex" }}
          direction="column"
          gap={2}
        >
          <Grid
            item
            sx={{
              overflow: "hidden",
              width: "100%",
              display: "flex",
              height: "307px",
            }}
            borderRadius={2}
          >
            {!categoryImage ? (
              <CircularProgress />
            ) : (
              <Box
                sx={{
                  minWidth: "100%",
                  objectFit: "cover",
                  transform: !matches ? "translateX(-50%)" : "none",
                }}
              >
                {/* @ts-ignore*/}
                <PrismicImage
                  style={{ borderRadius: 2 }}
                  field={categoryImage}
                  height={307}
                  widths={[600, 1000]}
                  imgixParams={{
                    fit: "crop",
                    rect: [
                      "center",
                      "middle",
                      categoryImage.dimensions.width,
                      Math.round(categoryImage.dimensions.height * 0.5),
                    ],
                    cornerRadius: 8,
                    mask: "corners",
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                right: 10,
                top: 10,
                position: "absolute",
                display: "flex",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Box
                component="p"
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  paddingY: "4px",
                  paddingX: "8px",
                  background: "#F7E9F6",
                  borderRadius: 1,
                  color: "#A321B0",
                  fontSize: 10,
                  fontWeight: 700,
                }}
              >
                #{`${rest.id}${rest.created_at?.substring(0, 2)}`}
              </Box>
              <Button
                variant="roundlink"
                onClick={(e) => saveFavourite(e)}
                sx={{
                  position: "relative",
                  zIndex: 3,
                  width: 40,
                  height: 40,
                  minWidth: "inherit",
                  backgroundColor: "common.lightgray",
                  padding: 0,
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ width: 20, height: 20 }}
                >
                  <LinkIcon />
                </Box>
              </Button>

              {!disableFavorite && user?.id ? (
                <Button
                  variant="roundlink"
                  onClick={(e) => saveFavourite(e)}
                  sx={{
                    position: "relative",
                    zIndex: 3,
                    width: 40,
                    height: 40,
                    minWidth: "inherit",
                    backgroundColor: "common.lightgray",
                    padding: 0,
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: 20, height: 20 }}>
                    <HeartIcon
                      color={isFavourite ? "#A321B0" : "white"}
                      filled={isFavourite ? true : false}
                    />
                  </Box>
                </Button>
              ) : null}
            </Box>
          </Grid>
          <Grid container padding={2} direction="column" gap={2}>
            <Grid item>
              <Box
                flexDirection="row"
                display="flex"
                alignItems="center"
                gap={1}
                flexWrap="wrap"
              >
                <Typography
                  variant="h2"
                  fontWeight={600}
                  lineHeight={1}
                  alignItems="center"
                  sx={{
                    letterSpacing: "0.01em",
                    display: "flex",
                  }}
                >
                  {rest.business_type ? typeLabel?.name : headline}
                </Typography>
                {rest.business_categories && rest.business_categories.length ? (
                  <Typography
                    variant="h2"
                    sx={{
                      display: "flex",
                      fontWeight: 30,
                    }}
                  >
                    |
                  </Typography>
                ) : null}

                <Typography
                  variant="h2"
                  sx={{
                    letterSpacing: "0.01em",
                    display: "flex",
                    fontWeight: 500,
                  }}
                >
                  {rest.business_categories && rest.business_categories.length
                    ? getBusinessCategoryLabel(rest.business_categories[0])
                    : ""}
                </Typography>
                {rest.business_categories &&
                rest.business_categories.length > 1 ? (
                  <Box
                    flexDirection="row"
                    justifyContent="center"
                    height={1}
                    display="flex"
                    px={1}
                    py={0.5}
                    bgcolor="#7f7d851a"
                    borderRadius={3}
                  >
                    <Typography
                      color="#7F7D85"
                      textAlign="center"
                      lineHeight={2}
                      sx={{
                        fontSize: "12px",
                        display: "flex",
                        fontWeight: 600,
                      }}
                    >
                      +{rest.business_categories.length - 1} more
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Grid>

            <Grid item display="flex">
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  my: 1,
                }}
                height={2}
              >
                {isHotListing && (
                  <Box
                    component="p"
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      paddingX: "8px",
                      paddingY: "4px",
                      backgroundColor: "rgba(255, 99, 58, 0.1)",
                      borderRadius: 1,
                      color: "#FD2F02",
                      fontSize: 10,
                      fontWeight: 700,
                    }}
                  >
                    🔥 Hot Listing
                  </Box>
                )}
                <FomoBadge
                  startupId={rest.id}
                  isUnderOffer={rest.under_offer}
                />
              </Box>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  maxWidth: "100%",
                  color: "common.gray",
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                {/* A profitable B2B analytics platform that has an extensive range
                of ecommerce merchants paying a recurring subscription for use
                of their digital tools that help improve customer conversions. */}
                {rest.foundy_says ? (
                  <Box>
                    {controlled
                      ? truncateText(rest.foundy_says, 500)
                      : truncateText(rest.foundy_says, 200)}
                    &nbsp;
                    {controlled ? (
                      <Link to="#" onClick={scrollToElement}>
                        Read More
                      </Link>
                    ) : null}
                  </Box>
                ) : (
                  <Box>
                    {controlled
                      ? truncateText(description as string, 500)
                      : truncateText(description as string, 180)}
                    &nbsp;
                    {controlled ? (
                      <Link to="#" onClick={scrollToElement}>
                        Read More
                      </Link>
                    ) : null}
                  </Box>
                )}
              </Typography>
            </Grid>
            {!controlled && (
              <Grid container spacing={2}>
                <MarketplaceItemButtonGroup
                  startUpData={rest}
                  buttonLabels={LABEL_KEYS}
                  teamSize
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
