import { LocalStorageKey, StorageService } from "./StorageService";
import { ApiService } from "./ApiService";
import { IBuyer, IGuideStep, ISeller, IUser, IUserFull } from "../models";
import { UserType } from "../types";
import { Modify } from "../types/utils";
import { StartupService } from "./StartupService";
import { BuyerService } from "./BuyerService";

interface ISignUpParams {
  email: string;
  password1: string;
  password2: string;

  accepted_terms: boolean;
  allow_news_letter: boolean;
}

interface ISignInParams {
  email: string;
  password: string;
}

interface ISendResetEmailParams {
  email: string;
}

interface IResetPasswordParams {
  new_password: string;
  token: string;
  email: string;
}

interface ISignInResponse {
  access: string;
  refresh: string;
  user: IUser;
}

type IGetCurrentUserRes = Modify<
  IUser,
  {
    seller?: ISeller;
    buyer?: IBuyer;
  }
>;

interface IVerifyParams {
  token: string;
  email: string;
}

interface IChangePasswordParams {
  old_password?: string;
  password: string;
}

export const UserService = {
  Init: async (): Promise<IUserFull | undefined> => {
    const access = StorageService.Get(LocalStorageKey.Jwt);
    const refresh = StorageService.Get(LocalStorageKey.JwtRefresh);

    if (!access || !refresh) return;

    ApiService.SignIn(access);

    let user: IUserFull | undefined;

    try {
      user = await UserService.GetCurrentUser();
    } catch (e) {
      ApiService.SignOut();
      return;
    }

    if (user.buyer) {
      const newData = await BuyerService.GetBuyer();
      user.buyerFull = { ...user.buyerFull, ...newData };
      return user;
    }

    if (user.sellerFull) {
      const startupID = user.sellerFull.startups[0];
      if (startupID) {
        const newData = await StartupService.GetUserCompany();
        user.startups = newData;
        return user;
      }
      user.startups = undefined;
    }

    return user;
  },
  SendResetEmail: (data: ISendResetEmailParams) => {
    return ApiService.Post("/auth/send-reset-email/", data);
  },
  ResetPassword: (data: IResetPasswordParams) => {
    return ApiService.Post("/auth/reset-password/", data);
  },
  GoogleSignIn: async (data: ISignInResponse): Promise<IUserFull> => {
    ApiService.SignIn(data.access);

    const userFull = await UserService.GetCurrentUser();
    StorageService.Set(LocalStorageKey.Jwt, data.access);
    StorageService.Set(LocalStorageKey.JwtRefresh, data.refresh);
    StorageService.Set(LocalStorageKey.User, JSON.stringify({ ...userFull }));

    if (userFull.sellerFull) {
      const startupID = userFull.sellerFull.startups[0];
      userFull.startups = startupID
        ? await StartupService.GetUserCompany()
        : undefined;
    }

    return userFull;
  },
  SignIn: async (data: ISignInParams): Promise<IUserFull> => {
    const { access, refresh, user } = await ApiService.Post<ISignInResponse>(
      "/auth/login/",
      data,
    );
    StorageService.Set(LocalStorageKey.Jwt, access);
    StorageService.Set(LocalStorageKey.JwtRefresh, refresh);
    StorageService.Set(LocalStorageKey.User, JSON.stringify(user));
    ApiService.SignIn(access);

    const userFull = await UserService.GetCurrentUser();

    if (userFull.sellerFull) {
      const startupID = userFull.sellerFull.startups[0];
      userFull.startups = startupID
        ? await StartupService.GetUserCompany()
        : undefined;
    }

    return userFull;
  },
  SignOut: () => {
    StorageService.Remove(LocalStorageKey.Jwt);
    StorageService.Remove(LocalStorageKey.User);
    StorageService.Remove(LocalStorageKey.CompanyFilters);
    ApiService.SignOut();
  },
  PatchBuyer: (id: number | undefined, data: IBuyer) => {
    if (!data.country) {
      delete data.country;
    }
    if (data.image) {
      // @ts-ignore
      data.image = data.image.id;
    }

    return ApiService.Patch(`/buyer/${id}/`, data);
  },
  SignUp: (data: ISignUpParams): Promise<IUserFull> => {
    return ApiService.Post("/v2/registration/", data).then(() => {
      return UserService.SignIn({
        email: data.email,
        password: data.password1,
      });
    });
  },
  OnboardPatch: (data: any, extraPath?: string): Promise<IUserFull> => {
    if (extraPath) {
      return ApiService.Patch(`/v2/user/${data.id}/onboard/${extraPath}`, data);
    }
    return ApiService.Patch(`/v2/user/${data.id}/onboard/`, data);
  },
  OnboardStepPatch: (data: any, extraPath?: string): Promise<IUserFull> => {
    return ApiService.Patch(`/v2/user/onboard/${extraPath}`, data);
  },
  AddBuyerStep: (data: { buyer: number; step: number; value: boolean }) => {
    return ApiService.Post(`/v2/buyer/education-steps/completion/`, data);
  },
  AddSellerStep: (data: { seller: number; step: number; value: boolean }) => {
    return ApiService.Post(`/v2/seller/education-steps/completion/`, data);
  },
  SetBuyerStep: (data: { buyer: number; step: number; value: boolean }) => {
    return ApiService.Patch(`/v2/buyer/education-steps/completion/`, data);
  },
  SetSellerStep: (data: { seller: number; step: number; value: boolean }) => {
    return ApiService.Patch(`/v2/seller/education-steps/completion/`, data);
  },
  GetBuyerStepProgress: (): Promise<IGuideStep[]> => {
    return ApiService.Get(`/v2/buyer/education-steps/completion/`).then(
      (res) => res.results,
    );
  },
  GetSellerStepProgress: (): Promise<IGuideStep[]> => {
    return ApiService.Get(`/v2/seller/education-steps/completion/`).then(
      (res) => res.results,
    );
  },
  GetUser: async (id: number | string): Promise<IUserFull> => {
    const user = await ApiService.Get(`/user/${id}/`);

    if (user.active_role === UserType.Buyer) {
      user.buyerFull = await BuyerService.GetBuyer();
    } else {
      user.sellerFull = await UserService.GetSeller(user.seller);

      if (user.sellerFull) {
        const startupID = user.sellerFull.startups[0];
        if (startupID) {
          const newData = await StartupService.GetUserCompany();
          user.startups = newData;
        } else {
          user.startups = undefined;
        }
      }
    }

    return user;
  },
  GetCurrentUser: async (): Promise<IUserFull> => {
    return ApiService.Get("/user/profile/").then((res: IGetCurrentUserRes) => {
      const user: IUserFull = {
        ...res,
        buyerFull: res.buyer,
        sellerFull: res.seller,
        buyer: res.buyer?.id ?? 0,
        seller: res.seller?.id ?? 0,
      };
      return user;
    });
  },
  GetUserOnly: async (id: number | string) => {
    return ApiService.Get(`/user/${id}/`);
  },
  GetBuyer: (id: number | string) => {
    return ApiService.Get(`/buyer/${id}/`);
  },
  GetNewBuyer: (id?: string | number | undefined) => {
    if (id) return ApiService.Get(`/buyer/${id}`);
    return ApiService.Get(`/v2/buyer/`);
  },
  GetSeller: (id: number) => {
    return ApiService.Get(`/seller/${id}/`);
  },
  EditUser: (id: number, data: Partial<IUser>) => {
    return ApiService.Patch(`/user/${id}/`, data);
  },
  SendVerification: () => {
    return ApiService.Post("/auth/create-confirm-email-link/");
  },
  Verify: (data: IVerifyParams) => {
    return ApiService.Post("/auth/confirm-email/", data);
  },
  ChangePassword: (userID: number, values: IChangePasswordParams) => {
    return ApiService.Patch(`/user/${userID}/change-password/`, values);
  },
  HearAboutUs: (answer: string) => {
    return ApiService.Patch(`/common/hearaboutus/`, { answer });
  },
  SaveHearAboutUs: (display_name: string) => {
    return ApiService.Post(`/common/hearaboutus/`, { display_name });
  },
  PatchUser: (data: any) => {
    return ApiService.Patch("/v2/user/", data);
  },
  SaveFavourite: (data: any) => {
    return ApiService.Post("/v2/startups/favourite/", data);
  },
  DeleteFavourite: (id: any) => {
    return ApiService.Delete(`/v2/startups/favourite/${id}`);
  },
  GetFavourites: () => {
    return ApiService.Get("/v2/startups/favourite/");
  },
};
