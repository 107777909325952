import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useCompany } from "../../../../common/hooks";
import { HookedForm } from "../../../components/HookedForm";
import { RoutePaths } from "../../../consts/enums";
import { HookedFile } from "../../../components/HookedFile";
import ProfileNavigationButtons from "../ProfileNavigationButtons";
import WelcomeSellerModal from "./WelcomeSellerModal";

export const SellerDataRoom = () => {
  const { id: startupId } = useParams();
  if (startupId === undefined) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  const navigate = useNavigate();
  const { data, isLoading, isError } = useCompany(startupId);
  const formHook = useForm();

  useEffect(() => {
    if (data && !isLoading) {
      formHook.reset({ ...data });
    }
  }, [data, isLoading]);

  if (isError) {
    return <Navigate to="/dashboard" replace={true} />;
  }
  if (!data) return <CircularProgress />;

  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <WelcomeSellerModal />
      <Container
        maxWidth="md"
        sx={{
          height: "100%",
          padding: 4,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography variant="h3" sx={{ color: "white" }}>
            Complete your company profile - this process takes 3 minutes
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "common.grayOpacity" }}>
            List your company profile anonymously and start receiving messages
            from interested buyers
          </Typography>
          <Paper elevation={2} sx={{ marginTop: 4, padding: 3 }}>
            <Typography variant="h3" sx={{ marginBottom: 1 }}>
              Key Documents
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "common.gray", marginBottom: 2 }}
            >
              Upload and share your Investment memorandum, financials and other
              documents with buyers who&apos;ve been granted access to your
              Private Data Room.
            </Typography>

            <Typography variant="body2" sx={{ marginTop: 1 }}>
              <Link
                to="https://foundy.com/resources/insights/what-is-a-data-room"
                target="_blank"
              >
                Learn what to include in a Data Room
              </Link>
            </Typography>

            <Box
              component="hr"
              sx={{
                width: "100%",
                borderColor: "common.lightgray",
                borderStyle: "solid",
                marginY: 3,
              }}
            />
            <HookedForm formHook={formHook}>
              <HookedFile startupId={Number(startupId)} files={data.files} />
            </HookedForm>
          </Paper>

          <ProfileNavigationButtons
            onBack={() =>
              navigate(`${RoutePaths.REGISTER_SELLER}/${startupId}/founder`)
            }
            onSaveAndContinue={formHook.handleSubmit(() =>
              navigate(`${RoutePaths.REGISTER_SELLER}/${startupId}/completed`),
            )}
          />
        </Box>
      </Container>
    </Box>
  );
};
