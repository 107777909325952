import { IFile, IPaginatedRes, IStartup } from "./index";
import { DateTime } from "luxon";
import { IApiStartup } from "./api";
import { getAge } from "../utils";
import { BudgeOptions } from "../../v2/consts/consts";
import {
  App,
  DigitalAgency,
  eCommerce,
  Marketplace,
  Other,
  SaaS,
  Web3,
} from "../assets/icons/categories";
import { UserIcon } from "../assets/icons/User";

const amountFiltersValues = [
  {
    id: 1,
    value: [10000, 50000],
  },
  {
    id: 2,
    value: [50000, 250000],
  },
  {
    id: 3,
    value: [250000, 1000000],
  },
  {
    id: 4,
    value: [1000000, 5000000],
  },
  {
    id: 5,
    value: [5000000, 30000000],
  },
  {
    id: 6,
    value: [30000000, 100000000],
  },
  {
    id: 7,
    value: [100000000, 999999999],
  },
];

export const mapList = <I, O>(
  origin: IPaginatedRes<I>,
  mapper: (v: I) => O,
): IPaginatedRes<O> => ({
  count: origin.count,
  results: origin.results.map(mapper),
});

export const mapAmountFilters = (value: number[]) => {
  const selectOptions: number[] = [];
  if (!value || !value.length) return [0, 0];
  value.forEach((x) => {
    const amounts = amountFiltersValues.find((i) => i.id === x);
    if (amounts && amounts.value) {
      selectOptions.push(...amounts.value);
    }
  });

  return [Math.min(...selectOptions), Math.max(...selectOptions)];
};
export const getExpectedRevenue = (selected?: number) => {
  if (!selected) return "";
  const label = BudgeOptions.find((x) => Number(x.value) === Number(selected));

  return label?.label;
};

export const getCompanyCategoryIcon = (category: number, useUser?: boolean) => {
  if (category === 8) return SaaS;
  if (category === 9) return eCommerce;
  if (category === 10) return DigitalAgency;
  if (category === 11) return App;
  if (category === 12) return Web3;
  if (category === 13) return Marketplace;
  if (category === 14) return Other;

  return useUser ? UserIcon : Other;
};
export const getLocations = (locations?: string[]) => {
  return locations?.join(", ").toString().replace(/_/g, " ");
};

export const mapStartupIn = (origin: IApiStartup): IStartup => {
  Object.entries(origin).forEach(([key, value]) => {
    if (
      value &&
      typeof value !== "object" &&
      typeof value !== "boolean" &&
      typeof value !== "function" &&
      !isNaN(value)
    ) {
      // @ts-ignore
      origin[key] = Math.round(+value).toString();
    }
  });

  const date_founded = origin.date_founded
    ? new Date(origin.date_founded)
    : null;
  return {
    ...origin,
    highlights: origin.highlights,
    custom_highlights: origin.custom_highlights,
    date_founded,
    age: date_founded ? getAge(date_founded) : null,
  };
};

export const mapStartupOut = ({ age, ...origin }: Partial<IStartup>): any => {
  return {
    ...origin,
    ...(origin.highlights && { highlights: origin.highlights }),
    ...(origin.custom_highlights && {
      custom_highlights: origin.custom_highlights,
    }),
    ...(origin.date_founded && {
      date_founded: DateTime.fromJSDate(origin.date_founded).toFormat(
        "YYYY-MM-DD",
      ),
    }),
    ...(origin.files && { files: origin.files.map((file: IFile) => file?.id) }),
  };
};
