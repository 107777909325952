import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserCompletedIcon } from "../../../../common/assets/icons/UserCompleted";
import { useAppContext } from "../../../../common/context/AppContext";
import { RoutePaths } from "../../../consts/enums";
import { useEffect } from "react";
import { FillingPercentsService } from "../../../../common/services/FillingPercentsService";
import { EventName, trackEvent } from "../../../../common/utils/helpers";
import FundraisingBlock from "../FundraisingBlock";

const BasicBuyerCompleted = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    const percentage = FillingPercentsService.GetPercentageBuyer(
      user.buyerFull,
    );

    if (percentage === 100) {
      trackEvent(EventName.COMPLETED_BUYER);
    }
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100%",
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "common.blackBlue",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top right",
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 10,
            marginBottom: 4,
            paddingX: 10,
            paddingY: 5,
            backgroundColor: "common.grayLighterOpacity",
            borderRadius: 2,
          }}
        >
          <UserCompletedIcon />
          <Typography
            sx={{
              marginTop: 3,
              marginBottom: 2,
              color: "white",
              fontWeight: 500,
              fontFamily: "Inter",
              fontSize: 24,
            }}
          >
            Congratulations! You’re now a Foundy Basic Buyer.
          </Typography>
          <Typography variant="body1" color="white">
            If you’d like Sellers to be able to see your profile and reach out
            to you, you’ll need to have a Pro Buyer account. To go Pro, you’ll
            need to answer a few more questions.
          </Typography>
        </Box>
        <FundraisingBlock />
        <Box>
          <Button
            variant="roundgradient"
            onClick={() =>
              navigate(RoutePaths.REGISTER_ACQUIRER + "/pro-buyer-interests")
            }
          >
            Continue
          </Button>
        </Box>
        <Box marginTop={4}>
          <Button
            variant="roundsecondary"
            onClick={() =>
              navigate(RoutePaths.REGISTER_ACQUIRER + "/additional-info")
            }
          >
            Back
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default BasicBuyerCompleted;
