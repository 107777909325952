import { DateTime } from "luxon";
import {
  IBuyer,
  IHistory,
  IPaginatedRes,
  ISeller,
  IUser,
} from "../../common/models";
import { ApiService } from "../../common/services/ApiService";
import { BuyerStatus, StartupStatus, UserType } from "../../common/types";

interface IOneItemParams {
  id?: number;
  data?: object;
}

interface AddAdminParams {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

export const UserService = {
  GetBuyers: async (params: any): Promise<IPaginatedRes<IBuyer>> => {
    const response = await ApiService.Get(`/buyer/list-full-info/`, params);
    response.results.forEach((item: IBuyer) => {
      item.user_id = item.user?.id;
    });
    return response;
  },

  GetSellers: async (params: any): Promise<IPaginatedRes<ISeller>> => {
    const response = await ApiService.Get(`/seller/list-full-info/`, params);

    return response;
  },
  GetManagers: (params: any): Promise<IPaginatedRes<IUser>> => {
    return ApiService.Get(`/user/list-managers/`, params);
  },
  DeleteBuyer: ({ id }: IOneItemParams) => {
    return ApiService.Delete(`/buyer/${id}/`);
  },
  DeleteSeller: ({ id }: IOneItemParams) => {
    return ApiService.Delete(`/seller/${id}/`);
  },
  EditUser: (id: number, data: Partial<IUser>) => {
    return ApiService.Patch(`/user/${id}/`, data);
  },
  DeleteUser: ({ id }: IOneItemParams) => {
    return ApiService.Delete(`/user/${id}/`);
  },
  SellerChangeStatus: ({
    id,
    status,
  }: {
    id: number;
    status: StartupStatus;
  }) => {
    return ApiService.Patch(`/seller/${id}/change-status/`, { status });
  },
  BuyerChangeStatus: ({ id, status }: { id: number; status: BuyerStatus }) => {
    return ApiService.Patch(`/buyer/${id}/change-status/`, { status });
  },
  GetHistory: async (params: any): Promise<IPaginatedRes<IHistory>> => {
    const userId = params.id;
    delete params.id;

    const response = await ApiService.Get(
      `/user/${userId}/login-history/`,
      params,
    );
    response.results.forEach((item: IHistory) => {
      item.login_datetime = DateTime.fromISO(item.login_datetime).toFormat(
        "YYYY-MM-DD hh:mm:ss",
      );
    });

    return response;
  },
  GetBuyerExportData: (): Promise<string> => {
    return ApiService.Get("/user/csv-export-buyers/");
  },
  GetSellerExportData: (): Promise<string> => {
    return ApiService.Get("/user/csv-export-sellers/");
  },
  GetExportData: async (role: UserType): Promise<void> => {
    let data: string;
    let filename: string;
    if (role === UserType.Buyer) {
      data = await UserService.GetBuyerExportData();
      filename = `buyers_${Date.now()}.csv`;
    } else {
      data = await UserService.GetSellerExportData();
      filename = `sellers_${Date.now()}.csv`;
    }

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(data),
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
  AddAdmin: (data: AddAdminParams) => {
    return ApiService.Post("/user/create-admin/", data);
  },
};
