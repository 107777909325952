import {
  ClientQuery,
  CommonQuery,
  QueryDict,
  useQueryData,
} from "../utils/query";
import { UserService } from "../services/UserService";
import { StartupService } from "../services/StartupService";
import { UseQueryResult, useMutation, useQueryClient } from "react-query";
import { ChatService } from "../services/ChatService";
import { AppService } from "../services/AppService";
import { BuyerService } from "../services/BuyerService";
import { useAppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { UseFormReturn } from "react-hook-form";
import { DateTime } from "luxon";
import { usePrismicClient } from "@prismicio/react";
import { useEffect, useState } from "react";
import { IUser } from "../models";

export const useUser = (id?: number | string) => {
  return useQueryData(CommonQuery.User, UserService.GetUser, id, !!id);
};

export const useSeller = (id?: number) => {
  return useQueryData(CommonQuery.Seller, UserService.GetSeller, id, !!id);
};

export const useBuyer = (id?: number | string) => {
  return useQueryData(CommonQuery.Buyer, UserService.GetNewBuyer, id, !!id);
};

export const useCurrentBuyer = () => {
  return useQueryData(CommonQuery.Buyer, BuyerService.GetBuyer);
};

export const useStartupBySeller = (sellerID: number) => {
  return useQueryData(
    CommonQuery.Startup,
    StartupService.GetSellerStartup,
    sellerID,
    !!sellerID,
  );
};

export const useStartup = (id?: number | string) => {
  return useQueryData(CommonQuery.Startup, StartupService.GetStartup, id, !!id);
};

export const useCompany = (id: string) => {
  return useQueryData(CommonQuery.Company, StartupService.GetCompany, { id });
};

export const useSellerBucketData = (id: string) => {
  return useQueryData(
    CommonQuery.JsonSellerBucketData,
    StartupService.GetSellerBucketData,
    {
      id,
    },
  );
};

export const usePatchStartup = (startupID: number) => {
  const queryClient = useQueryClient();
  return useMutation(StartupService.PatchStartup, {
    onSuccess: (data) => {
      queryClient.setQueryData([CommonQuery.Startup, startupID], () => data);
    },
  });
};

export const useStartupTypes = () => {
  return useQueryData(CommonQuery.StartupTypes, StartupService.GetTypes);
};

export const useBusinessCategories = (id: string) => {
  return useQueryData(
    CommonQuery.BusinessCategories,
    StartupService.GetBusinessCategories,
    { id },
  );
};

export const useBusinessCategoriesAll = () => {
  return useQueryData(
    CommonQuery.BusinessCategoriesAll,
    StartupService.GetBusinessCategoriesAll,
  );
};

export const useHearAbout = () => {
  return useQueryData(CommonQuery.HearAbout, AppService.GetHearAbout);
};

export const usePreviousAcquisitions = () => {
  return useQueryData(
    CommonQuery.PreviousAcquisitions,
    AppService.GetPreviousAcquisitions,
  );
};

export const useAcquisitionsProjected = () => {
  return useQueryData(
    CommonQuery.AcquisitionsProjected,
    AppService.GetAcquisitionsProjected,
  );
};

export const useBusinessModels = (id: string) => {
  return useQueryData(
    CommonQuery.BusinessModels,
    AppService.GetBusinessModels,
    { id },
  );
};

export const useGrowthOpportunity = () => {
  return useQueryData(
    CommonQuery.GrowthOpportunity,
    AppService.GetGrowthOpportunity,
  );
};

export const useTeamSize = () => {
  return useQueryData(CommonQuery.TeamSize, AppService.GetTeamSize);
};

export const useKeyAssets = () => {
  return useQueryData(CommonQuery.KeyAssets, AppService.GetKeyAssets);
};

export const useWeeklyCommitment = () => {
  return useQueryData(
    CommonQuery.WeeklyCommitment,
    AppService.GetWeeklyCommitment,
  );
};

export const useIndustryChoices = () => {
  return useQueryData(
    CommonQuery.IndustryChoices,
    AppService.GetIndustryChoices,
  );
};

export const useDataLocations = () => {
  return useQueryData(CommonQuery.Locations, AppService.GetLocations);
};

export const useFavourites = (
  user?: IUser,
): UseQueryResult<QueryDict[ClientQuery.Favourites]> | any => {
  if (!user || !user?.id) return { data: undefined };
  return useQueryData(ClientQuery.Favourites, UserService.GetFavourites);
};

export const useBusinessChoices = () => {
  return useQueryData(
    ClientQuery.BusinessChoices,
    AppService.GetBusinessChoices,
  );
};

export const useBuyerRoles = () => {
  return useQueryData(CommonQuery.BuyerRoles, AppService.GetBuyerRoles);
};

export const useHandlePageTraversal = (
  formHook: UseFormReturn,
  startupId: number,
) => {
  const { updateStartup } = useAppContext();

  const { refetch } = useCompany(`${startupId}`);
  const navigate = useNavigate();

  const handleNext = async (path: string) => {
    const { data: startup } = await refetch();
    if (!startup) {
      throw Error("No startup to change found!");
    }
    const values = formHook.getValues();
    // Some data munging needed to normalize values from form.
    if (values.date_founded && typeof values.date_founded !== "string") {
      values.date_founded = DateTime.fromObject(values.date_founded.c).toFormat(
        "yyyy-LL-dd",
      );
    }
    values.open_to_offer = Boolean(values?.open_to_offer);
    values.metric_notification = Boolean(values?.metric_notification);
    values.operating_regions = values?.operating_regions || [];
    values.key_assets_new = values?.key_assets_new || [];
    await StartupService.PatchCompany(startupId, { ...values });
    updateStartup({ ...startup, ...values });

    // refetch();
    navigate(path);
  };
  return { handleNext };
};

export const useChatMetadata = (user?: IUser) => {
  if (!user || !user?.id) return { data: undefined };
  return useQueryData(
    CommonQuery.ChatMetadata,
    ChatService.GetChatMetadataList,
  );
};

export const useBuyerListingsCountUnviewed = (
  user?: IUser,
): Record<"data", any> => {
  if (!user || !user?.id) return { data: undefined };
  return useQueryData(
    CommonQuery.BuyerListingsCountUnviewed,
    BuyerService.GetListingViewsCountUnviewed,
  );
};

export const usePromoListings = () => {
  const prismic = usePrismicClient();
  const [promoStartupID, setPromoStartupID] = useState();

  useEffect(() => {
    prismic.getByUID("promo_listings", "promo-listings").then((res) => {
      if (res.data?.slices && res.data?.slices[0]) {
        setPromoStartupID(res.data?.slices[0].items[0].url_id);
      }
    });
  }, []);
  return promoStartupID;
};

export const useCategoriesImages = () => {
  // const query = `
  //     {
  //       listing-category-images {
  //         slices {
  //           ...on listing-category-images {
  //             variation {
  //               ...on default {
  //                 items {
  //                   image
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   `;

  const prismicClient = usePrismicClient();
  const [categoryImages, setCategoryImages] = useState([]);
  useEffect(() => {
    prismicClient.getByType("listing_category_images").then((res) => {
      if (res.results.length) {
        setCategoryImages(res.results[0].data.slices[0].items);
      }
    });
  }, []);

  return categoryImages;
};

export const useListingViewsCount = (id: number) => {
  return useQueryData(
    CommonQuery.ListingViewsCount,
    StartupService.GetListingViewsCount,
    id,
  );
};

export const useStartupChatsCount = (id: number) => {
  return useQueryData(
    CommonQuery.StartupChatsCount,
    StartupService.GetStartupChatsCount,
    id,
  );
};
export const useStartupFavouritesCount = (id: number) => {
  return useQueryData(
    CommonQuery.StartupFavouritesCount,
    StartupService.GetStartupFavouritesCount,
    id,
  );
};
