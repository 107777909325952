import { ALLOWED_FILE_TYPES } from "../constants";
import { ApiService } from "./ApiService";

export const UploadService = {
  GetFileInputResult: (allowedTypes?: string): Promise<File> => {
    const inputEl = document.createElement("input");
    inputEl.type = "file";
    if (allowedTypes) {
      inputEl.accept = allowedTypes;
    }

    return new Promise((res, rej) => {
      inputEl.onchange = (ev: Event) => {
        const fileList = (ev.target as HTMLInputElement).files;
        if (!fileList) {
          rej();
          return;
        }
        const files: File[] = Array.from(fileList);

        if (!files.length) {
          rej();
          return;
        }

        res(files[0]);
      };

      inputEl.click();
    });
  },
  Upload: async (allowedTypes?: string) => {
    let allowedShortTypes = ALLOWED_FILE_TYPES;
    if (allowedTypes) {
      allowedShortTypes = allowedShortTypes.filter((allowedShortType) =>
        allowedTypes.includes(allowedShortType),
      );
    }

    const file = await UploadService.GetFileInputResult(allowedTypes);
    const fileType = allowedShortTypes.find((allowedShortType) =>
      file.type.includes(allowedShortType),
    );

    if (!fileType) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    formData.append("type", fileType);
    formData.append("owner_object", "user");

    return ApiService.Post("/file/", formData);
  },
  Delete: async (id: number) => {
    return ApiService.Delete(`/file/${id}/`);
  },
};
