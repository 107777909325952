import { Box, Container, Paper, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppContext } from "../../../../common/context/AppContext";
import { useCurrentBuyer } from "../../../../common/hooks";
import { BuyerService } from "../../../../common/services/BuyerService";
import { HookedField } from "../../../components/HookedField";
import { HookedForm } from "../../../components/HookedForm";
import { RoutePaths } from "../../../consts/enums";
import PrivateBanner from "../PrivateBanner";
import ProfileNavigationButtons from "../ProfileNavigationButtons";

const schema = yup
  .object({
    keywords: yup
      .string()
      .nullable()
      .required()
      .max(600)
      .trim()
      .label("Interests"),
  })
  .required();

const ProBuyerInterests = () => {
  const { user, updateUser } = useAppContext();
  const { data, isLoading } = useCurrentBuyer();
  const navigate = useNavigate();
  const formHook = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  const handleNext = (values: any) => {
    BuyerService.PatchBuyer({ ...values });

    updateUser({ ...user, buyerFull: { ...user.buyerFull, ...values } });
    navigate(RoutePaths.REGISTER_ACQUIRER + "/completed");
  };

  useEffect(() => {
    if (data && !isLoading) {
      formHook.reset({ ...data });
    }
  }, [data, isLoading]);

  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <Container maxWidth="md" sx={{ padding: 4 }}>
        <PrivateBanner color="white" secondaryColor="common.grayOpacity" />
        <Paper elevation={2} sx={{ marginTop: 4, padding: 3 }}>
          <Typography variant="h3" sx={{ marginBottom: 1 }}>
            Please list any other key words or phrases relating to your search
            criteria. This will help Foundy to provide you more relevant
            opportunities, where possible.
          </Typography>
          <HookedForm formHook={formHook}>
            <HookedField
              hiddenLabel
              fullWidth
              variant="filled"
              component={TextField}
              name="keywords"
              multiline
              minRows={3}
              helperText="Skip this step if you don’t have more detail to provide."
              placeholder="
              For example,
              Python tech stack, B2B SaaS, London based"
            />
          </HookedForm>
        </Paper>

        <ProfileNavigationButtons
          onBack={() =>
            navigate(RoutePaths.REGISTER_ACQUIRER + "/basic-completed")
          }
          onSaveAndContinue={formHook.handleSubmit(handleNext)}
        />
      </Container>
    </Box>
  );
};

export default ProBuyerInterests;
