export const Other = () => (
  <svg
    height="100%"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.4595 38.6007C21.0253 38.6007 19.6498 39.1704 18.6356 40.1846C17.6215 41.1987 17.0518 42.5742 17.0518 44.0084C17.0518 45.4426 17.6215 46.8181 18.6356 47.8323C19.6498 48.8464 21.0253 49.4161 22.4595 49.4161C23.8937 49.4161 25.2692 48.8464 26.2833 47.8323C27.2975 46.8181 27.8672 45.4426 27.8672 44.0084C27.8672 42.5742 27.2975 41.1987 26.2833 40.1846C25.2692 39.1704 23.8937 38.6007 22.4595 38.6007ZM38.6826 44.0084C38.6826 42.5742 39.2524 41.1987 40.2665 40.1846C41.2807 39.1704 42.6561 38.6007 44.0904 38.6007C45.5246 38.6007 46.9 39.1704 47.9142 40.1846C48.9283 41.1987 49.4981 42.5742 49.4981 44.0084C49.4981 45.4426 48.9283 46.8181 47.9142 47.8323C46.9 48.8464 45.5246 49.4161 44.0904 49.4161C42.6561 49.4161 41.2807 48.8464 40.2665 47.8323C39.2524 46.8181 38.6826 45.4426 38.6826 44.0084ZM60.3135 44.0084C60.3135 42.5742 60.8833 41.1987 61.8974 40.1846C62.9115 39.1704 64.287 38.6007 65.7212 38.6007C67.1555 38.6007 68.5309 39.1704 69.5451 40.1846C70.5592 41.1987 71.129 42.5742 71.129 44.0084C71.129 45.4426 70.5592 46.8181 69.5451 47.8323C68.5309 48.8464 67.1555 49.4161 65.7212 49.4161C64.287 49.4161 62.9115 48.8464 61.8974 47.8323C60.8833 46.8181 60.3135 45.4426 60.3135 44.0084Z"
      fill="url(#paint0_linear_2497_5176)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2497_5176"
        x1="19.7556"
        y1="38.6007"
        x2="43.4822"
        y2="69.3112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C72A8E" />
        <stop offset="1" stopColor="#7815DA" />
      </linearGradient>
    </defs>
  </svg>
);
