import { alpha, TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FTextField = styled(
  (props: TextFieldProps & { currency?: boolean }) => {
    return <TextField {...props} InputLabelProps={{ shrink: true }} />;
  },
)(({ currency, label, theme }) => ({
  ...(!label &&
    currency && {
      "& .MuiInputAdornment-root.MuiInputAdornment-filled": {
        marginTop: "0px !important",
      },
    }),
  ...(!label && {
    ".MuiFilledInput-input": { paddingTop: "15px", paddingBottom: "15px" },
  }),
  "& .MuiFormLabel-root": {
    fontSize: "14px",
    lineHeight: "2em",
    fontFamily: "Inter",
    color: "#7F7D85",
    fontWeight: 500,
  },
  "& .MuiFilledInput-root:before": {
    display: "none",
  },
  "& .MuiFilledInput-root:after": {
    display: "none",
  },
  "& .MuiFilledInput-root": {
    fontWeight: 500,
    border: "2px solid #F3F3F2",
    overflow: "hidden",
    borderRadius: 16,
    borderColor: "common.lightgray",
    backgroundColor: "#FFFFFF",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&.Mui-focused": {
      backgroundColor: "#FFFFFF",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
