import { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  gradient?: boolean;
}

export const CartIcon = (props: IconProps) => (
  <svg
    {...props}
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.59375 0.882812C0.59375 1.08594 0.664062 1.26042 0.804688 1.40625C0.950521 1.55208 1.1224 1.625 1.32031 1.625H3.77344L4.98438 9.91406C5.07292 10.4974 5.27604 10.9583 5.59375 11.2969C5.91667 11.6354 6.3776 11.8047 6.97656 11.8047H16.0703C16.2526 11.8047 16.4115 11.7396 16.5469 11.6094C16.6875 11.4792 16.7578 11.3151 16.7578 11.1172C16.7578 10.9141 16.6875 10.75 16.5469 10.625C16.4115 10.4948 16.2526 10.4297 16.0703 10.4297H7.14844C6.96094 10.4297 6.80729 10.3672 6.6875 10.2422C6.57292 10.1172 6.5026 9.95312 6.47656 9.75L6.35938 8.94531H16.125C16.724 8.94531 17.1849 8.77604 17.5078 8.4375C17.8307 8.09896 18.0365 7.63021 18.125 7.03125L18.7109 3.10938C18.7214 3.05729 18.7292 3.0026 18.7344 2.94531C18.7396 2.88281 18.7422 2.82812 18.7422 2.78125C18.7422 2.5625 18.6667 2.38281 18.5156 2.24219C18.3698 2.09635 18.1615 2.02344 17.8906 2.02344H5.35938L5.22656 1.16406C5.17969 0.830729 5.07031 0.580729 4.89844 0.414062C4.73177 0.242188 4.42969 0.15625 3.99219 0.15625H1.32031C1.1224 0.15625 0.950521 0.229167 0.804688 0.375C0.664062 0.520833 0.59375 0.690104 0.59375 0.882812ZM6.25 14.3594C6.25 14.6094 6.3099 14.8359 6.42969 15.0391C6.55469 15.2474 6.71875 15.4115 6.92188 15.5312C7.13021 15.651 7.35938 15.7109 7.60938 15.7109C7.98438 15.7109 8.30469 15.5781 8.57031 15.3125C8.83594 15.0521 8.96875 14.7344 8.96875 14.3594C8.96875 13.9792 8.83594 13.6562 8.57031 13.3906C8.30469 13.1302 7.98438 13 7.60938 13C7.35938 13 7.13021 13.0599 6.92188 13.1797C6.71875 13.3047 6.55469 13.4688 6.42969 13.6719C6.3099 13.8802 6.25 14.1094 6.25 14.3594ZM13.4766 14.3594C13.4766 14.7344 13.6094 15.0521 13.875 15.3125C14.1406 15.5781 14.4609 15.7109 14.8359 15.7109C15.2161 15.7109 15.5365 15.5781 15.7969 15.3125C16.0625 15.0521 16.1953 14.7344 16.1953 14.3594C16.1953 13.9792 16.0625 13.6562 15.7969 13.3906C15.5365 13.1302 15.2161 13 14.8359 13C14.5859 13 14.3568 13.0599 14.1484 13.1797C13.9453 13.3047 13.7812 13.4688 13.6562 13.6719C13.5365 13.8802 13.4766 14.1094 13.4766 14.3594Z"
      fill={props.gradient ? "url(#paint0_linear_157_970)" : "#746D7D"}
    />
    {props.gradient && (
      <defs>
        <linearGradient
          id="paint0_linear_157_970"
          x1="-7.89109"
          y1="-5"
          x2="26.1628"
          y2="0.975647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA336B" />
          <stop offset="1" stopColor="#6911E7" />
        </linearGradient>
      </defs>
    )}
  </svg>
);
