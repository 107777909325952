import { Box, Button, Collapse, TableCell, TableRow } from "@mui/material";
import { abbreviateNumber, openInNewTab } from "../../../common/utils";
import { useState } from "react";
import { IStartup } from "../../../common/models";
import { FillingPercentsService } from "../../../common/services/FillingPercentsService";
import { useModal } from "mui-modal-provider";
import { EditSellerModal } from "../../components/EditSellerModal";

export const StartupRow = ({
  row,
  startupTypes,
  approveCompany,
  disapproveCompany,
}: {
  row: IStartup;
  startupTypes: any[];
  approveCompany: (row: IStartup) => void;
  disapproveCompany: (row: IStartup) => void;
}) => {
  const { showModal } = useModal();
  const [open, setOpen] = useState(false);

  const typeLabel = startupTypes?.find(
    (x) => x.id === Number(row?.business_type),
  );

  const handleModal = (userId: number) => {
    const modal = showModal(EditSellerModal, {
      userId,
      onCancel: () => {
        modal.hide();
      },
    });
  };
  const expandRow = () => {
    setOpen((curr) => !curr);
  };

  return (
    <>
      <TableRow
        key={row.id}
        onClick={() => expandRow()}
        sx={{
          cursor: "pointer",
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell component="th" scope="row">
          <b>{row.id}</b>
        </TableCell>
        <TableCell align="left">{row.status}</TableCell>
        <TableCell align="right">
          {FillingPercentsService.GetPercentageCompany(row).toFixed(1)}
        </TableCell>
        <TableCell align="left">{row.contact_email || ""}</TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">
          {row.business_type
            ? `${typeLabel?.name} ${
                row.industry
                  ? `| ${row.industry?.replace(/_/g, " ").toLocaleLowerCase()}`
                  : ""
              }`
            : row.headline}
        </TableCell>
        <TableCell align="right">{row.country}</TableCell>
        <TableCell align="right">
          {`£ ${
            row.asking_price ? abbreviateNumber(Number(row.asking_price)) : "--"
          }`}
        </TableCell>
        <TableCell align="right">{String(row.open_to_offer)}</TableCell>
        <TableCell align="left">{row.business_model}</TableCell>
        <TableCell align="right">
          {`£ ${
            row.annual_revenue
              ? abbreviateNumber(Number(row.annual_revenue))
              : "--"
          }`}
        </TableCell>
      </TableRow>

      <TableRow
        sx={{
          borderBottom: open ? 1 : 0,
          borderBottomColor: "rgba(224, 224, 224, 1)",
        }}
      >
        <TableCell
          colSpan={10}
          sx={{ border: 0 }}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ display: "flex", gap: 2, paddingY: 1 }}>
              <Button
                onClick={() => handleModal(row.id)}
                variant="roundgradientblue"
                sx={{ border: 0, "&:hover": { border: 0 } }}
                color="error"
              >
                Edit
              </Button>
              <Button
                variant="roundgradient"
                onClick={() => approveCompany(row)}
              >
                Approve
              </Button>
              <Button
                onClick={() => disapproveCompany(row)}
                variant="roundsecondary"
                sx={{ border: 0, "&:hover": { border: 0 } }}
                color="error"
              >
                Disapprove
              </Button>
              <Button
                onClick={() =>
                  openInNewTab(
                    process.env.REACT_APP_API_ADMIN +
                      "/start_ups/startup/" +
                      row.id,
                  )
                }
                variant="roundgradient"
                sx={{ border: 0, "&:hover": { border: 0 } }}
                color="error"
              >
                View in Django Admin
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
