import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";
import { ApiService } from "../../../common/services/ApiService";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ImageIcon from "@mui/icons-material/Image";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { openInNewTab } from "../../../common/utils";
import { ALLOWED_FILE_TYPES } from "../../../common/constants";
import { usePatchStartup } from "../../../common/hooks";
import { useAppContext } from "../../../common/context/AppContext";
import { IFile } from "../../../common/models";
import { DateTime } from "luxon";
import { UploadService } from "../../../common/services/UploadService";

const ALLOWED_TYPES = "image/*, video/*, application/pdf, .docx, .doc";

export type IHookedFileComponent = UseFormRegisterReturn;

export const HookedFile = ({
  startupId,
  files,
}: {
  startupId: number;
  files: IFile[];
}) => {
  const [dragActive, setDragActive] = useState(false);
  const { updateStartup } = useAppContext();
  const { mutateAsync } = usePatchStartup(startupId);
  const [localFileList, setLocalFileList] = useState(files);

  const handleChange = async (browserFiles?: FileList | null) => {
    const listOfFiles: File[] = Array.prototype.slice.call(browserFiles);
    const allowedShortTypes = ALLOWED_FILE_TYPES;
    const uploadedFiles: IFile[] = [];

    if (listOfFiles.length) {
      for (let i = 0; i < listOfFiles.length; i += 1) {
        const fileType = allowedShortTypes.find((allowedShortType) =>
          listOfFiles[i].type.includes(allowedShortType),
        );

        if (!fileType) {
          return;
        }
        const formData = new FormData();
        formData.append("file", listOfFiles[i]);
        formData.append("name", listOfFiles[i].name);
        formData.append("type", fileType);
        formData.append("owner_object", "user");

        const fileCreated = await ApiService.Post("/file/", formData);

        if (fileCreated) {
          uploadedFiles.push(fileCreated);
        }
      }
    }

    if (uploadedFiles.length) {
      const allFiles = [...localFileList, ...uploadedFiles];
      await mutateAsync({
        id: startupId,
        values: {
          files: allFiles,
        },
      });
      setLocalFileList(allFiles);
      // @ts-ignore
      updateStartup({ id: startupId, files: allFiles });
    }
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files) {
      handleChange(e.dataTransfer.files);
    }
  };

  const handleDelete = (id: number) => {
    UploadService.Delete(id);
    const newLocalFileList = localFileList.filter((itm) => itm.id != id);
    setLocalFileList(newLocalFileList);
    // @ts-ignore
    updateStartup({ id: startupId, files: newLocalFileList });
  };

  return (
    <>
      <Box
        component="form"
        onDragEnter={handleDrag}
        onSubmit={(e: any) => e.preventDefault()}
        sx={{
          overflow: "hidden",
          borderRadius: 8,
          padding: 8,
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 3,
          background:
            "linear-gradient(#fff, #fff) padding-box, linear-gradient(95deg, #C72A8E, #7815DA) border-box",
          border: "5px dashed white",
        }}
      >
        <Box
          onClick={() => {
            // @ts-ignore
            document.querySelector(".input-field").click();
          }}
        >
          <Box
            component="input"
            multiple
            type="file"
            accept={ALLOWED_TYPES}
            className="input-field"
            hidden // @ts-ignore
            onChange={({ target: { files } }) => {
              handleChange(files);
            }}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <UploadFileIcon
              sx={{
                color: "#C32993",
                background:
                  "linear-gradient(99.95deg, #C32993 -24.43%, #A15AF4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                background:
                  "linear-gradient(99.95deg, #C32993 -24.43%, #A15AF4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 600,
              }}
            >
              Upload documents
            </Typography>
            <Typography variant="body2" color="common.gray">
              Drop files to be uploaded here, or click to browse and select them
            </Typography>
          </Box>
        </Box>
        {dragActive && (
          <Box
            sx={{
              position: "absolute",
              background: "rgb(135 135 135 / 14%)",
              width: "100%",
              height: "100%",
            }}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </Box>

      {localFileList && localFileList.length ? (
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: 2 }}>
          {localFileList.map((singleFile) => (
            <Box
              key={`${singleFile.id}-${singleFile.name}`}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: 2,
                borderBottom: "1px solid lightgray",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => openInNewTab(singleFile.file)}
              >
                {[".png", ".jpg", ".jpeg", ".gif"].some((j) =>
                  singleFile.name.toLocaleLowerCase().includes(j),
                ) && <ImageIcon sx={{ marginRight: 1, color: "#C32993" }} />}
                {[".pdf"].some((j) =>
                  singleFile.name.toLocaleLowerCase().includes(j),
                ) && (
                  <PictureAsPdfIcon sx={{ marginRight: 1, color: "#C32993" }} />
                )}
                {[".ppt", ".pptx", ".docx"].some((j) =>
                  singleFile.name.toLocaleLowerCase().includes(j),
                ) && (
                  <TextSnippetIcon sx={{ marginRight: 1, color: "#C32993" }} />
                )}
                <Box>
                  <Typography variant="body2">{singleFile.name}</Typography>
                  <Typography sx={{ fontSize: 10 }}>
                    {DateTime.fromISO(singleFile.created_at).toFormat(
                      "dd/MMMM/yyyy hh:mm",
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* Bytes uploaded calculation. Maybe return it when it's working. */}
                {/* <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    border: "1px solid",
                    borderColor: "#CDD3D8",
                    fontSize: 11,
                    padding: "4px 8px",
                    borderRadius: "2px",
                    fontWeight: 600,
                  }}
                >
                  {humanFileSize(0)}
                </Box> */}
                <DeleteForeverIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleDelete(singleFile.id)}
                />
              </Box>
            </Box>
          ))}
        </Box>
      ) : null}
      <Box
        sx={{
          background: "#ECDBA0",
          padding: 2,
          borderRadius: 2,
          marginTop: 3,
        }}
      >
        <Typography variant="body2">
          <b>Image guidelines:</b> Accepted file types include: .jpg, .png,
          .pdf, .gif, .pptx and .doc.
        </Typography>
      </Box>
    </>
  );
};
