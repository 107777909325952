import {
  Box,
  Checkbox,
  FormControl,
  Input,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useFormContext } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface HookedSelectProps {
  label: string;
  className?: string;
  values?: { label: string; value: any }[];
  selection?: any[];
  flex?: string;
  icon?: any;
  handleSearch?: () => void;
  name: string;
}

export const HookedSelect = ({
  label,
  values,
  className,
  flex = "1 1 auto",
  selection,
  icon: Icon,
  handleSearch,
  name,
}: HookedSelectProps) => {
  const {
    watch,
    register,
    formState: { errors }, // eslint-disable-line @typescript-eslint/no-unused-vars
  } = useFormContext();

  const selected = watch(name);

  const handleChange = (event: SelectChangeEvent) => {
    register(name).onChange(event);
    if (handleSearch) {
      handleSearch();
    }
  };

  return (
    <FormControl
      className={className}
      sx={{
        flex: flex,
        background: "white",
        borderRadius: 2,
        border: "1px solid #F3F3F2",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Select
        {...register(name)}
        name={name}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        sx={{
          "&.MuiInput-root.Mui-focused": {
            border: "2px solid",
            borderColor: "#C72A8E !important",
            boxShadow: "0px 0px 10px rgba(203, 44, 138, 0.4) !important",
            borderRadius: "8px",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            background:
              "linear-gradient(99.95deg, #C32993 -24.43%, #A15AF4 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          },
          "&.MuiInput-root": {
            border: "2px solid transparent",
            padding: 1,
            marginTop: "0px !important",
          },
          "& .MuiSelect-select": {
            margin: 0,
            padding: 0,
            display: "flex",
            justifyContent: "flex-end",
          },
        }}
        multiple
        disableUnderline
        onChange={handleChange}
        size="small"
        value={selected || []}
        input={
          <Input
            size="small"
            startAdornment={
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: -1,
                }}
              >
                {Icon && (
                  <Box
                    sx={{
                      width: 15,
                      height: 15,
                      marginRight: 0.5,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box component={Icon} />
                  </Box>
                )}
                <InputAdornment
                  disablePointerEvents
                  position="start"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                      color: "common.black",
                      fontWeight: 500,
                    },
                  }}
                >
                  {label}
                </InputAdornment>
              </Box>
            }
          />
        }
        renderValue={(selected) => (
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#EBEBFE",
              fontSize: 12,
              borderRadius: 2,
              height: 24,
              width: 24,
              fontWeight: 700,
              color: "#161660",
            }}
          >
            {selected.length}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {values?.map((item) => {
          return (
            <MenuItem key={item.label} value={item.value}>
              <Checkbox
                checked={
                  selection &&
                  Boolean(
                    selection.findIndex((x: any) => {
                      if (x.id && item.value.id) return x.id === item.value.id;
                      return item.value === x;
                    }) > -1,
                  )
                }
              />
              <ListItemText primary={item.label} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
