import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useFormContext } from "react-hook-form";

interface SelectItemProps {
  Icon?: React.FC<any>;
  title: string;
  value: string | number | boolean;
  description?: string;
  selected: boolean;
  onSelect: (v: string | number | boolean) => void;
}

const SelectItem = ({
  Icon,
  title,
  selected,
  value,
  description,
  onSelect,
}: SelectItemProps) => {
  return (
    <Box
      className={selected ? "selected" : ""}
      sx={{
        position: "relative",
        flex: 1,
        textAlign: "left",
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "common.lightgray",
        padding: [1, 3],
        backgroundColor: selected ? "common.ghostWhite" : "white",
        color: selected ? "common.blackBlue" : "common.gray",
        cursor: "pointer",
        transition: "ease-in-out 300ms",

        "&:hover": {
          backgroundColor: "common.ghostWhite",
          boxShadow: "0px 0px 10px #ededed",
        },
        "&.selected": {
          boxShadow: "0px 0px 20px rgba(203, 44, 138, 0.4)",
          "&:after": {
            display: "inline-block",
            content: "''",
            background:
              "linear-gradient(99.95deg, #C72A8E -24.43%, #7815DA 100%);",
            position: "absolute",
            margin: "-3px",
            borderRadius: "10px",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            zIndex: -1,
          },
        },
      }}
      onClick={() => onSelect(value)}
    >
      <Box sx={{ display: "inline-flex", alignItems: "flex-start" }}>
        {Icon && (
          <Box sx={{ marginTop: 0.2 }}>
            <Icon gradient={selected} fontSize="large" />
          </Box>
        )}
        <Box>
          <Typography
            sx={{
              marginLeft: 1,
              fontWeight: 600,
              fontFamily: "Inter",
              ...(selected && {
                background:
                  "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
              }),
            }}
          >
            {title}
          </Typography>
          {description && (
            <Typography
              variant="body2"
              sx={{
                marginLeft: 1,
                fontFamily: "Inter",
                ...(selected && {
                  background:
                    "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                }),
              }}
            >
              {description}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

interface AccountTypeFieldProps {
  name: string;
  question?: string;
  options?: {
    Icon?: React.FC<any>;
    label: string;
    value: string | number | boolean;
    description?: string;
  }[];
  onChange: (v: string | number | boolean) => void;
}

export const OptionField = ({
  name,
  question,
  options,
  onChange,
}: AccountTypeFieldProps) => {
  const {
    register,
    setValue,
    getFieldState,
    getValues,
    formState: { errors }, // eslint-disable-line @typescript-eslint/no-unused-vars
  } = useFormContext();

  const { error } = getFieldState(name);

  useEffect(() => {
    register(name);
  }, [register, name]);

  const handleClick = (v: string | number | boolean) => {
    setValue(name, v, { shouldValidate: true, shouldDirty: true });
    onChange(v);
  };

  return (
    <Box sx={{ width: "100%", position: "relative", zIndex: 1 }}>
      <Typography
        sx={{ fontWeight: 700, marginBottom: 3, fontFamily: "Inter" }}
      >
        {question}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          columnGap: 2.5,
        }}
      >
        {options?.map((opt) => {
          return (
            <SelectItem
              Icon={opt.Icon}
              key={String(opt.value)}
              title={opt.label}
              value={opt.value}
              description={opt.description}
              onSelect={handleClick}
              selected={opt.value === getValues(name)}
            />
          );
        })}
      </Box>
      {error && (
        <Box
          sx={{
            backgroundColor: "#F3F3F2",
            borderRadius: "8px",
            padding: "12px",
            margin: "0",
            marginTop: "10px",
            fontSize: "12px",
            color: "red",
          }}
        >
          {error?.message?.toString()}
        </Box>
      )}
    </Box>
  );
};
