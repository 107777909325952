import { Box, Button, Collapse, TableCell, TableRow } from "@mui/material";
import { abbreviateNumber } from "../../../common/utils";
import { useState } from "react";
import { IBuyer } from "../../../common/models";
import { FillingPercentsService } from "../../../common/services/FillingPercentsService";

export const BuyerRow = ({
  row,
  startupTypes,
  approveBuyer,
  disapproveBuyer,
}: {
  row: IBuyer;
  startupTypes: any[];
  approveBuyer: (row: IBuyer) => void;
  disapproveBuyer: (row: IBuyer) => void;
}) => {
  const [open, setOpen] = useState(false);

  const expandRow = () => {
    setOpen((curr) => !curr);
  };
  return (
    <>
      <TableRow
        onClick={() => expandRow()}
        key={row.id}
        sx={{
          cursor: "pointer",
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell component="th" scope="row">
          {row.user_id}
          <br />
          <b>{row.id}</b>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.status}
        </TableCell>
        <TableCell component="th" scope="row">
          {FillingPercentsService.GetPercentageBuyer(row).toFixed(1)}
        </TableCell>
        <TableCell align="center">{row.buyer_email}</TableCell>
        <TableCell align="left">
          {row.first_name} {row.last_name}
        </TableCell>
        <TableCell align="right">{row.role}</TableCell>
        <TableCell align="right">{row.user?.subscription_plan}</TableCell>
        <TableCell align="right">
          {row.startup_types && row.startup_types!.length > 4
            ? row.startup_types
                ?.map((x) => startupTypes?.find((i) => x === i.id)?.name)
                .slice(0, 3)
                .join(", ") + ", more "
            : row.startup_types
                ?.map((x) => startupTypes?.find((i) => x === i.id)?.name)
                .join(", ")}
        </TableCell>
        <TableCell align="right">{`£ ${
          row.valuation ? abbreviateNumber(Number(row.valuation)) : "--"
        }`}</TableCell>
      </TableRow>

      <TableRow
        sx={{
          borderBottom: open ? 1 : 0,
          borderBottomColor: "rgba(224, 224, 224, 1)",
        }}
      >
        <TableCell
          colSpan={9}
          sx={{ border: 0 }}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ display: "flex", gap: 2, paddingY: 1 }}>
              <Button variant="roundgradient" onClick={() => approveBuyer(row)}>
                Approve
              </Button>
              <Button
                onClick={() => disapproveBuyer(row)}
                variant="roundsecondary"
                sx={{ border: 0, "&:hover": { border: 0 } }}
                color="error"
              >
                Disapprove
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
