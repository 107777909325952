export const WhiteTick = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.865234" width="32" height="32" rx="16" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4314 12.207C22.6853 12.4737 22.6749 12.8957 22.4082 13.1495L14.7045 20.4829C14.573 20.6081 14.396 20.6742 14.2146 20.666C14.0331 20.6577 13.8629 20.5758 13.7433 20.4391L10.7803 17.0545C10.5378 16.7775 10.5658 16.3563 10.8428 16.1138C11.1198 15.8713 11.541 15.8992 11.7835 16.1763L14.2887 19.0379L21.4889 12.1838C21.7556 11.9299 22.1776 11.9403 22.4314 12.207Z"
      fill="url(#paint0_linear_2550_5237)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2550_5237"
        x1="11.2152"
        y1="12"
        x2="23.6974"
        y2="16.474"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C72A8E" />
        <stop offset="1" stopColor="#7815DA" />
      </linearGradient>
    </defs>
  </svg>
);
