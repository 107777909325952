interface SVGIconProps {
  color?: string;
  width?: string;
  height?: string;
}
export const SellIcon = ({ width, height = "100%", color }: SVGIconProps) => (
  <svg
    viewBox="0 0 17 17"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.83691 15.502L15.5361 8.7959C15.7139 8.61361 15.8438 8.44954 15.9258 8.30371C16.0078 8.15332 16.0602 7.99154 16.083 7.81836C16.1104 7.64518 16.124 7.42871 16.124 7.16895V4.57812C16.124 4.32747 16.1012 4.12012 16.0557 3.95605C16.0101 3.79199 15.9326 3.63932 15.8232 3.49805C15.7184 3.35677 15.5749 3.19499 15.3926 3.0127L13.8271 1.44727C13.6403 1.26497 13.4762 1.12142 13.335 1.0166C13.1982 0.907227 13.0479 0.829753 12.8838 0.78418C12.7197 0.738607 12.5124 0.71582 12.2617 0.71582H9.66406C9.40885 0.71582 9.19466 0.729492 9.02148 0.756836C8.84831 0.779622 8.68652 0.83431 8.53613 0.920898C8.3903 1.00293 8.22852 1.13281 8.05078 1.31055L1.33789 8.00977C0.832031 8.51562 0.576823 9.03516 0.572266 9.56836C0.567708 10.1016 0.825195 10.6257 1.34473 11.1406L5.70605 15.4951C6.22559 16.0101 6.74967 16.2653 7.27832 16.2607C7.81152 16.2607 8.33105 16.0078 8.83691 15.502ZM11.0654 6.40332C10.7829 6.40332 10.5482 6.3099 10.3613 6.12305C10.1745 5.93164 10.0811 5.69922 10.0811 5.42578C10.0811 5.14779 10.1745 4.91536 10.3613 4.72852C10.5482 4.53711 10.7829 4.44141 11.0654 4.44141C11.3434 4.44141 11.5758 4.53711 11.7627 4.72852C11.9495 4.91536 12.043 5.14779 12.043 5.42578C12.043 5.69922 11.9495 5.93164 11.7627 6.12305C11.5758 6.3099 11.3434 6.40332 11.0654 6.40332Z"
      fill={
        color !== ("purplegradient" || "bluegradient")
          ? color
          : color === ("purplegradient" || "bluegradient")
            ? "url(#paint0_linear_586_2224)"
            : "black"
      }
    />
    <defs>
      {color === "purplegradient" && (
        <linearGradient
          id="paint0_linear_586_2224"
          x1="0.2"
          y1="-4"
          x2="26.6022"
          y2="2.83473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C72A8E" />
          <stop offset="1" stopColor="#7815DA" />
        </linearGradient>
      )}
      {color === "bluegradient" && (
        <linearGradient
          id="paint0_linear_586_2224"
          x1="-7.89109"
          y1="-5"
          x2="26.1628"
          y2="0.975647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E8EF2" />
          <stop offset="1" stopColor="#6911E7" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
