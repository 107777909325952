import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiSlider from "@mui/material/Slider";

import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import { Grid } from "@mui/material";
import { HookedNumberField } from "../HookedNumberField";

const Slider = styled(MuiSlider)({
  '& .MuiSlider-markLabel[data-index="0"]': {
    transform: "translateX(0%)",
  },
  '& .MuiSlider-markLabel[data-index="1"]': {
    transform: "translateX(-100%)",
  },
});

export type SliderRange = [number, number];

export interface SliderInputProps {
  marks?: { value: number; label: string }[] | boolean;
  placeholderMin?: string | number;
  placeholderMax?: string | number;
  min: number;
  max: number;
  step?: number;
  currency?: boolean;
  TextFieldProps?: BaseTextFieldProps;
  onChange: (v: SliderRange) => void;
  value?: SliderRange;
}

export const SliderInput = ({ onChange, ...props }: SliderInputProps) => {
  const [sliderV, setSliderV] = useState<SliderRange>([props.min, props.max]);
  const [minTextV, setMinTextV] = useState<string | number>("");
  const [maxTextV, setMaxTextV] = useState<string | number>("");

  const handleSliderChange = useCallback((v: number[]) => {
    setSliderV(v as SliderRange);
    setMinTextV(v[0]);
    setMaxTextV(v[1]);
  }, []);

  useEffect(() => {
    if (props.value) {
      handleSliderChange(props.value);
    }
  }, []);

  const handleChangeCommit = (
    event: React.SyntheticEvent | Event,
    value: number | number[],
  ) => {
    onChange(value as SliderRange);
  };

  const handleMinTextBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      let value: number = +e.target.value;

      if (value <= 0 || value > sliderV[1]) {
        value = props.min;
        setMinTextV("");
      }

      if (value !== sliderV[0]) {
        setSliderV([value, sliderV[1]]);
        onChange([value, sliderV[1]]);
      }
    },
    [onChange, props.min, sliderV],
  );

  const handleMaxTextBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      let value: number = +e.target.value;

      if (value === 0 || sliderV[0] > value) {
        value = props.max;
        setMaxTextV("");
      }
      if (value > props.max) {
        value = props.max;
      }

      if (value !== sliderV[1]) {
        setSliderV([sliderV[0], value]);
        onChange([sliderV[0], value]);
      }
    },
    [onChange, props.max, sliderV],
  );

  const handleMinTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMinTextV(e.target.value);
    },
    [],
  );

  const handleMaxTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMaxTextV(e.target.value);
    },
    [],
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={12} md={6}>
          <HookedNumberField
            {...props.TextFieldProps}
            {...(props.currency ? { currency: "currency" } : {})}
            value={minTextV}
            size="small"
            fullWidth
            placeholder={`${props.placeholderMin}`}
            onBlur={handleMinTextBlur}
            onChange={handleMinTextChange}
          />
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <HookedNumberField
            {...props.TextFieldProps}
            {...(props.currency ? { currency: "currency" } : {})}
            value={maxTextV}
            size="small"
            fullWidth
            placeholder={`${props.placeholderMax}`}
            onBlur={handleMaxTextBlur}
            onChange={handleMaxTextChange}
          />
        </Grid>
      </Grid>
      <Slider
        marks={props.marks}
        min={props.min}
        max={props.max}
        step={props.step}
        size="small"
        value={sliderV}
        onChange={(e, value) => handleSliderChange(value as number[])}
        onChangeCommitted={handleChangeCommit}
        disableSwap
      />
    </>
  );
};
