import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { openInNewTab } from "../../../common/utils";
import { MenuItemsInterface } from "../../consts/routes";
import DoneIcon from "@mui/icons-material/Done";
import { useAppContext } from "../../../common/context/AppContext";
import { FillingPercentsService } from "../../../common/services/FillingPercentsService";
import { useEffect, useState } from "react";
import { Management } from "../../containers/Management";
import { RoutePaths, RouteType } from "../../consts/enums";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {
  sendbirdSelectors,
  useSendbirdStateContext,
} from "@sendbird/uikit-react";
import UserEventHandler from "@sendbird/uikit-react/handlers/UserEventHandler";
import { useBuyerListingsCountUnviewed } from "../../../common/hooks";
import { IUserFull } from "../../../common/models";

interface SideNavProps {
  disableGradient?: boolean;
  items?: MenuItemsInterface[];
  startupId: number;
}

const userHasBasicDetails = (user: IUserFull) =>
  user.first_name !== null && user.last_name !== null;

export const SideNavigation = ({
  disableGradient,
  items,
  startupId,
}: SideNavProps) => {
  const [userMessageCount, setUserMessageCount] = useState<number>(0);
  const sendbirdContext = useSendbirdStateContext();
  const { user, handleMobileMenu, mobileOpen, isAuthed } = useAppContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const intercom = useIntercom();

  const { data: buyerListingsCountUnviewed } =
    useBuyerListingsCountUnviewed(user);

  const updateCounter = (amount: number) => {
    setUserMessageCount(amount);
  };

  const sbSdk = sendbirdSelectors.getSdk(sendbirdContext);

  if (
    sbSdk?.appInfo &&
    sbSdk?.currentUser?.userId &&
    sbSdk.currentUser?.userId !== "null"
  ) {
    // This is to get the total unread message count when user just logged to the system
    sbSdk?.groupChannel
      .getTotalUnreadMessageCount()
      .then((x) => updateCounter(x));

    // This is a watcher to update the total unread message count when user receives a new message
    const UserEvent = new UserEventHandler({
      onTotalUnreadMessageCountUpdated(totalCount) {
        updateCounter(totalCount);
      },
      onTotalUnreadMessageCountChanged(count) {
        updateCounter(count.groupChannelCount);
      },
    });
    sbSdk?.addUserEventHandler("CountMessageHandler", UserEvent);
  }

  useEffect(() => {
    if (items) {
      const index = items?.findIndex((x) => x.path === "/management");
      if (user && user.is_staff) {
        if (index === -1) {
          items?.push({
            label: "Management",
            path: "/management",
            target: Management,
            icon: AdminPanelSettingsIcon,
            type: RouteType.AUTHENTICATED,
          });
        }
      } else {
        if (index !== -1) items?.splice(index, 1);
      }
    }
  }, [user]);

  const drawerContent = (
    <List sx={{ marginBottom: 3 }}>
      <Link to="/dashboard">
        <Box
          component="img"
          src="/images/FoundyLogoMenu.svg"
          sx={{ width: 98, marginBottom: 5 }}
        />
      </Link>
      {items &&
        items.map(
          ({ label, path, icon: Icon, checkUser, fields, hideFrom }, index) => {
            if (startupId) {
              path = path.replace(/:id/g, String(startupId));
            }
            if (hideFrom === user.active_role) return null;
            return (
              <ListItem
                key={label}
                disablePadding
                sx={{
                  background: pathname.includes(path)
                    ? "rgba(255, 255, 255, 0.2)"
                    : "transparent",
                  color: !pathname.includes(path)
                    ? "rgba(255, 255, 255, 0.75)"
                    : "white",
                  marginBottom: 1,
                  borderRadius: "8px",
                  transition: "background-color .1s ease-in-out",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                }}
              >
                <ListItemButton
                  onClick={() => {
                    if (checkUser && !userHasBasicDetails(user)) {
                      // make sure the user fills out the basic details before being able to do onboarding
                      return;
                    }
                    if (label === "Access Expert Advisory") {
                      path && path.includes("http")
                        ? user.active_role === "buyer"
                          ? openInNewTab(path)
                          : openInNewTab("https://foundy.com/advisory/sellers")
                        : navigate(path);
                    }
                    path && path.includes("http")
                      ? openInNewTab(path)
                      : path.includes("intercom")
                        ? intercom.show()
                        : navigate(path);
                  }}
                  disableRipple
                  sx={{ padding: "14px 16px" }}
                >
                  <>
                    {checkUser &&
                    fields &&
                    FillingPercentsService.GetPercentage(
                      user,
                      startupId,
                      fields,
                    ) === 100 ? (
                      <ListItemIcon
                        sx={{
                          background: "#34A853",
                          color: "white",
                          borderRadius: 25,
                          minWidth: "inherit",
                          marginRight: 1,
                          width: 25,
                          height: 25,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <DoneIcon sx={{ width: 15, height: 15 }} />
                      </ListItemIcon>
                    ) : Icon ? (
                      <ListItemIcon
                        sx={{
                          color: !pathname.includes(path)
                            ? "rgba(255, 255, 255, 0.75)"
                            : "white",
                          minWidth: "inherit",
                          marginRight: 1,
                          width: 20,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <Icon
                          color={
                            !pathname.includes(path)
                              ? "rgba(255, 255, 255, 0.75)"
                              : "white"
                          }
                        />
                      </ListItemIcon>
                    ) : (
                      <Typography
                        sx={{
                          marginRight: 1,
                          width: "25px",
                          height: "25px",
                          fontSize: 10,
                          fontWeight: 700,
                          fontFamily: "Inter",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 25,
                          border: "1px solid",
                          borderColor: !pathname.includes(path)
                            ? "rgba(255, 255, 255, 0.5)"
                            : "white",
                          backgroundColor: !pathname.includes(path)
                            ? "transparent"
                            : "white",
                          color: !pathname.includes(path)
                            ? "rgba(255, 255, 255, 0.7)"
                            : "common.black",
                        }}
                      >
                        {index + 1}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        fontFamily: "Inter",
                      }}
                    >
                      {label}
                      {path === "/messages" && userMessageCount > 0 && (
                        <Box
                          component="span"
                          mr={1}
                          sx={{
                            right: 0,
                            position: "absolute",

                            display: "inline-flex",
                            width: 20,
                            height: 20,
                            background: "#EC5962",
                            borderRadius: 50,
                            fontWeight: 700,
                            fontSize: userMessageCount > 9 ? 9 : 11,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {userMessageCount}
                        </Box>
                      )}
                      {path === "/dashboard" &&
                      buyerListingsCountUnviewed?.count ? (
                        <Box
                          component="span"
                          mr={1}
                          sx={{
                            right: 0,
                            position: "absolute",

                            display: "inline-flex",
                            width: 20,
                            height: 20,
                            background: "#EC5962",
                            borderRadius: 50,
                            fontWeight: 700,
                            fontSize:
                              buyerListingsCountUnviewed.count > 9 ? 9 : 11,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {buyerListingsCountUnviewed.count}
                        </Box>
                      ) : null}
                    </Typography>
                  </>
                </ListItemButton>
              </ListItem>
            );
          },
        )}
    </List>
  );

  const nonAuthedDrawer = (
    <List sx={{ marginBottom: 3 }}>
      <Link to="/dashboard">
        <Box
          component="img"
          src="/images/FoundyLogoMenu.svg"
          sx={{ width: 98, marginBottom: 5 }}
        />
      </Link>
      <ListItem
        key="Login"
        disablePadding
        sx={{
          background: "transparent",
          color: "white",
          marginBottom: 1,
          borderRadius: "8px",
          transition: "background-color .1s ease-in-out",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        <ListItemButton
          onClick={() => navigate(RoutePaths.LOGIN)}
          disableRipple
          sx={{ padding: "14px 16px" }}
        >
          Login
        </ListItemButton>
      </ListItem>
      <ListItem
        key="Register"
        disablePadding
        sx={{
          background: "transparent",
          color: "white",
          marginBottom: 1,
          borderRadius: "8px",
          transition: "background-color .1s ease-in-out",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        <ListItemButton
          onClick={() => navigate(RoutePaths.REGISTER)}
          disableRipple
          sx={{ padding: "14px 16px" }}
        >
          Register
        </ListItemButton>
      </ListItem>
    </List>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleMobileMenu}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            borderRadius: 0,
            padding: 3,
            boxSizing: "border-box",
            minWidth: "30vh",
            backgroundColor: "common.black",
            backgroundSize: { xs: "cover", md: "auto" },
            backgroundRepeat: "no-repeat",
            backgroundPosition: "revert",
            backgroundImage: !disableGradient
              ? "url(/images/MenuGradient.png)"
              : "none",
          },
        }}
      >
        {!isAuthed ? nonAuthedDrawer : drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },

          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 320,
            backgroundColor: "common.black",
            backgroundImage: !disableGradient
              ? "url(/images/MenuGradient.png)"
              : "none",
            backgroundSize: "cover",
            borderRadius: 0,
            padding: 3,
            color: "white",
            justifyContent: "space-between",
          },
        }}
        open
      >
        {!isAuthed ? nonAuthedDrawer : drawerContent}
        <Box>
          <Typography variant="body2" fontWeight={600} textAlign="center">
            Need help?
          </Typography>
          <Stack spacing={2}>
            <p>
              Email:{" "}
              <a
                style={{ color: "white", fontWeight: "bold" }}
                href="mailto:joe@foundy.com"
                target="_blank"
                rel="noreferrer"
              >
                joe@foundy.com
              </a>
            </p>
            <Button
              variant="roundprimary"
              href="https://calendly.com/joe-7yw/30min?back=1&month=2024-03"
              target="_blank"
              rel="noreferrer"
            >
              <span>Schedule a call</span>
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
