import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import reportWebVitals from "./common/utils/reportWebVitals";
import theme from "./common/utils/theme";
import "./common/utils/i18n";
import { createRoot } from "react-dom/client";
import {
  browserTracingIntegration,
  replayIntegration,
  ErrorBoundary,
  init,
} from "@sentry/react";
import { IS_DEV, IS_PROD, SENTRY_DSN } from "./common/constants";
import ErrorScreen from "./common/components/ErrorScreen";
import "./App.css";
import "animate.css";

init({
  enabled: IS_PROD,
  debug: IS_DEV,
  dsn: SENTRY_DSN,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  ignoreErrors: [
    "Sendbird",
    "SendbirdError",
    "IDBDatabase",
    "Connection is canceled.",
  ],
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 1.0,
});
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ErrorBoundary
      fallback={({ error, componentStack }) => (
        <ErrorScreen
          error={new Error(`${error}`)}
          componentStack={componentStack}
        />
      )}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
