import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { RoutePaths, RouteType } from "../../consts/enums";
import { routes } from "../../consts/routes";
import { useAppContext } from "../../../common/context/AppContext";
import { Layout } from "../Layout";

export const APP_ID = process.env.REACT_APP_SENDBIRD_ID as string;

const myColorSet = {
  "--sendbird-light-primary-500": "#0000FF",
  "--sendbird-light-primary-400": "#FF00DC",
  "--sendbird-light-primary-300": "#00004B",
  "--sendbird-light-primary-200": "#232321",
  "--sendbird-light-primary-100": "#F3F3F3",
  "--sendbird-font-family-default": "Inter",
};

const protectedRoutes = (listOfRoutes: typeof routes) => {
  const { isAuthed } = useAppContext();
  const location = useLocation();

  const checkAuth = (children: JSX.Element) => {
    if (!isAuthed) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  };

  return listOfRoutes.map(({ path, target: Target }) => {
    return (
      <Route
        key={path}
        element={Target ? checkAuth(<Target />) : undefined}
        path={path}
      />
    );
  });
};

export const AppRoutes = () => {
  // const { showModal } = useModal();
  const { user } = useAppContext();
  const location = useLocation();

  if (location.pathname.includes("/startup/")) {
    const id = location.pathname.split("/");
    if (id[2]) {
      return <Navigate to={`/company/${id[2]}`} />;
    }
  }

  if (
    location.pathname === RoutePaths.REGISTER_SELLER ||
    location.pathname === `${RoutePaths.REGISTER_SELLER}/`
  ) {
    return <Navigate to="/your-listings" />;
  }

  if (location.pathname.includes("/sign-in")) {
    return <Navigate to="/login" />;
  }

  if (location.pathname.includes("/sign-up")) {
    return <Navigate to="/register" />;
  }

  const protectedRoutesList = routes.filter(
    (x) => x.type === RouteType.AUTHENTICATED,
  );

  const buyerRegisterRoutes = routes.filter((x) =>
    x.path.includes("register/buyer"),
  );
  const sellerRegisterRoutes = routes.filter((x) =>
    x.path.includes("register/seller"),
  );
  const basicSignUpRoutes = routes.filter(
    (x) =>
      x.path.includes("register") &&
      !x.path.includes("buyer") &&
      !x.path.includes("seller"),
  );
  const publicRoutes = routes.filter(
    (x) =>
      x.type === RouteType.PUBLIC &&
      !basicSignUpRoutes.some((j) => x.path === j.path) &&
      !sellerRegisterRoutes.some((j) => x.path === j.path) &&
      !buyerRegisterRoutes.some((j) => x.path === j.path),
  );

  return (
    <SendbirdProvider
      isVoiceMessageEnabled
      colorSet={myColorSet}
      appId={APP_ID}
      userId={String(user.chat_id)}
    >
      <Routes>
        <Route element={<Layout dashboard />}>
          {protectedRoutes(protectedRoutesList)}
        </Route>
        <Route element={<Layout sellerRegister />}>
          {protectedRoutes(sellerRegisterRoutes)}
        </Route>
        <Route element={<Layout buyerRegister />}>
          {protectedRoutes(buyerRegisterRoutes)}
        </Route>
        <Route element={<Layout basicSignUp />}>
          {basicSignUpRoutes.map(({ path, target: Target }) => (
            <Route
              key={path}
              element={Target ? <Target /> : undefined}
              path={path}
            />
          ))}
        </Route>
        <Route element={<Layout />}>
          {publicRoutes.map(({ path, target: Target }) => (
            <Route
              key={path}
              element={Target ? <Target /> : undefined}
              path={path}
            />
          ))}
        </Route>
      </Routes>
    </SendbirdProvider>
  );
};
