import { ReactNode, createContext, useContext } from "react";
import { IPrismicImageObject } from "../models";
import { useCategoriesImages } from "../hooks";
interface IStartupContext {
  categoryImages: IPrismicImageObject[];
}

const StartupContext = createContext<IStartupContext | null>(null);

export const useStartupContext = () => {
  const context = useContext(StartupContext);

  if (!context) {
    throw new Error("hook must be used within a context");
  }

  return context;
};

interface ISignUpProviderProps {
  children: ReactNode;
}

// TODO: slowly migrate all Startup related context to this provider and remove it from user object.
export const StartupProvider = ({ children }: ISignUpProviderProps) => {
  const images = useCategoriesImages();
  return (
    <StartupContext.Provider
      value={{
        categoryImages: images,
      }}
    >
      {children}
    </StartupContext.Provider>
  );
};
