import { Error } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useState } from "react";
import theme from "../../utils/theme";

interface CustomTooltipProps extends TooltipProps {
  large?: boolean;
}
interface AppTooltipProps extends Omit<CustomTooltipProps, "children"> {
  display?: string;
  children?: React.ReactElement<any, any>;
}

export const TooltipComponent = styled(
  ({ className, ...props }: CustomTooltipProps) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ),
)(({ large }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "15px",
    backgroundColor: "#464154",
    whiteSpace: "pre-line",
    ...(large && { maxWidth: "600px" }),
  },
}));

export const AppTooltip = (props: AppTooltipProps) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={() => handleTooltipClose()}>
      <div style={{ display: props.display || "block" }}>
        <TooltipComponent
          {...props}
          onClose={handleTooltipClose}
          open={open}
          disableHoverListener
        >
          <IconButton
            disableRipple
            onClick={handleTooltipOpen}
            sx={{ color: theme.palette.primary.main }}
          >
            {props.children || (
              <Error sx={{ cursor: "pointer", transform: "rotate(180deg)" }} />
            )}
          </IconButton>
        </TooltipComponent>
      </div>
    </ClickAwayListener>
  );
};
