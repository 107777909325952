import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarKey } from "notistack";
import { NotificationService } from "../../services/NotificationService";

interface DissmissProps {
  key: SnackbarKey;
}

const NotificationDismissButton = ({ key }: DissmissProps) => {
  return (
    <IconButton
      sx={{ color: "common.white" }}
      onClick={() => NotificationService.Close(key)}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default NotificationDismissButton;
