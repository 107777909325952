import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useCompany, useHandlePageTraversal } from "../../../../common/hooks";
import { HookedForm } from "../../../components/HookedForm";
import { RoutePaths } from "../../../consts/enums";
import ProfileNavigationButtons from "../ProfileNavigationButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import PublicBanner from "../PublicBanner";
import { HookedNumberField } from "../../../components/HookedNumberField";
import { HookedField } from "../../../components/HookedField";
import { HookedRadioGroup } from "../../../components/HookedRadioGroup";
import WelcomeSellerModal from "./WelcomeSellerModal";

const schema = yup
  .object({
    asking_price: yup
      .number()
      .min(0)
      .nullable()
      .required()
      .label("Asking Price"),
    founder_bio: yup
      .string()
      .max(10000)
      .trim()
      .nullable()
      .required()
      .label("Founder intro"),
    reason: yup
      .string()
      .max(10000)
      .trim()
      .nullable()
      .required()
      .label("Reason for selling"),
    founders_post_acquisition: yup
      .string()
      .nullable()
      .label("Stay post-acquisition"),
  })
  .required();

const Founder = () => {
  const { id: startupId } = useParams();
  if (startupId === undefined) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  const navigate = useNavigate();

  const { data, isLoading, isError } = useCompany(startupId);

  const formHook = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  const { handleNext } = useHandlePageTraversal(formHook, Number(startupId));

  useEffect(() => {
    if (data && !isLoading) {
      formHook.reset({ ...data });
    }
  }, [data, isLoading]);

  if (isError) {
    return <Navigate to="/dashboard" replace={true} />;
  }
  if (!data) return <CircularProgress />;

  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <WelcomeSellerModal />
      <Container maxWidth="md" sx={{ padding: 4 }}>
        <PublicBanner />
        <Paper elevation={2} sx={{ marginTop: 4, padding: 3 }}>
          <HookedForm formHook={formHook}>
            <Typography variant="h3" sx={{ marginBottom: 2 }}>
              What is your asking price?
            </Typography>
            <HookedNumberField
              sx={{ marginBottom: 2 }}
              hiddenLabel
              fullWidth
              variant="filled"
              name="asking_price"
              currency
            />

            <FormControlLabel
              name="open_to_offer"
              sx={{
                "&.MuiFormControlLabel-root": {
                  backgroundColor: "common.lightgray",
                  padding: 2,
                  marginLeft: 0,
                  borderRadius: 2,
                  width: "100%",
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,

                    "&.MuiCheckbox-root": {
                      color: "black",
                    },
                    "&.MuiTypography-root": {
                      color: "common.gray",
                    },
                  },
                },
              }}
              control={
                <Controller
                  name="open_to_offer"
                  control={formHook.control}
                  defaultValue={false}
                  render={({ field: { onChange } }) => (
                    <Checkbox
                      name="open_to_offer"
                      checked={formHook.watch("open_to_offer") || false}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={
                <>
                  <Typography variant="body1" color="common.black">
                    Open to Offers
                  </Typography>
                  <Typography variant="body2" fontSize={12}>
                    By ticking “Open to offers”, you are choosing to hide your
                    company&apos;s valuation.
                  </Typography>
                </>
              }
            />

            <Typography variant="h3" sx={{ marginBottom: 2, marginTop: 2 }}>
              About the Founder
            </Typography>
            <HookedField
              fullWidth
              component={TextField}
              variant="outlined"
              name="founder_bio"
              placeholder="Your opportunity to introduce yourself (or the founder) to buyers. You may want to talk about why you founded the business and your vision for the company’s future."
              multiline={true}
              minRows="10"
            />

            <Typography variant="h3" sx={{ marginBottom: 2, marginTop: 2 }}>
              Why are you selling the business?
            </Typography>
            <HookedField
              fullWidth
              component={TextField}
              variant="outlined"
              name="reason"
              placeholder='For example, "I have loved building my business but now I would like to move to another project."'
              multiline={true}
              minRows="10"
            />

            <Typography variant="h3" sx={{ marginBottom: 2, marginTop: 2 }}>
              Would you be interested in continuing to work for the business
              post-sale? (Optional)
            </Typography>
            <HookedRadioGroup
              name="founders_post_acquisition"
              options={[
                { label: "Stay on Full-Time", value: "FULL_TIME" },
                { label: "Stay on Part-Time", value: "PART_TIME" },
                { label: "Possibly", value: "POSSIBLY" },
                { label: "No", value: "NO" },
              ]}
            />
          </HookedForm>
        </Paper>

        <ProfileNavigationButtons
          onBack={() =>
            navigate(
              RoutePaths.REGISTER_SELLER + `/${startupId}/financial-info/`,
            )
          }
          onSaveAndContinue={formHook.handleSubmit(() =>
            handleNext(RoutePaths.REGISTER_SELLER + `/${startupId}/data-room`),
          )}
        />
      </Container>
    </Box>
  );
};

export default Founder;
