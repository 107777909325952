import {
  QueryClient,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { Updater } from "react-query/types/core/utils";
import {
  IAcquisitionsProjected,
  IBusinessModelChoices,
  IBusinessModel,
  IBuyer,
  IDeal,
  IEscrow,
  IGrowthOpportunity,
  IHearAbout,
  IHistory,
  IIndustryChoices,
  IKeyAssets,
  ILocations,
  IPagedFavourites,
  IPaginatedReq,
  IPaginatedRes,
  IPreviousAcquisitions,
  IRequest,
  ISeller,
  IStartup,
  IStartupType,
  IBusinessCategory,
  ISubscriptionPlan,
  ITeamSize,
  IUser,
  IWeeklyCommitment,
  IBuyerRoles,
  IChatMetadata,
  ICount,
  SellerJsonBucket,
} from "../models";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useEffect, useMemo, useRef } from "react";
import { getLast } from "./index";
import { ICompany } from "../services/StartupService";

export enum AdminQuery {
  Buyers = "Buyers",
  Sellers = "Sellers",
  Startups = "Startups",
  Managers = "Managers",
  History = "History",
}

export enum ClientQuery {
  Buyers = "Buyers",
  Favourites = "Favourites",
  SaveFavourite = "SaveFavourite",
  DeleteFavourite = "DeleteFavourite",
  SellingReason = "SellingReason",
  BusinessChoices = "BusinessChoices",
  CreateListingView = "CreateListingView",
}

export enum CommonQuery {
  CurrentUser = "CurrentUser",
  Buyer = "Buyer",
  Seller = "Seller",
  User = "User",
  Startup = "Startup",
  Startups = "Startups",
  Company = "Company",
  Plan = "Plan",
  Requests = "Requests",
  Counties = "Counties",
  StartupTypes = "StartupTypes",
  UserDeals = "UserDeals",
  Transaction = "Transaction",
  HearAbout = "HearAbout",
  PreviousAcquisitions = "PreviousAcquisitions",
  AcquisitionsProjected = "AcquisitionsProjected",
  BusinessModels = "BusinessModels",
  GrowthOpportunity = "GrowthOpportunity",
  TeamSize = "TeamSize",
  KeyAssets = "KeyAssets",
  WeeklyCommitment = "WeeklyCommitment",
  IndustryChoices = "IndustryChoices",
  Locations = "Locations",
  BuyerRoles = "BuyerRoles",
  BusinessCategories = "BusinessCategories",
  ChatMetadata = "ChatMetadata",
  ViewsCountStartup = "ViewsCountStartup",
  BuyerListingsCountUnviewed = "BuyerListingsCountUnviewed",
  BusinessCategoriesAll = "BusinessCategoriesAll",
  ListingViewsCount = "ListingViewsCount",
  StartupChatsCount = "StartupChatsCount",
  StartupFavouritesCount = "StartupFavouritesCount",
  JsonSellerBucketData = "JsonSellerBucketData",
}

type QueryKey = AdminQuery | ClientQuery | CommonQuery;

export type QueryDict = {
  [AdminQuery.Buyers]: IBuyer[];
  [AdminQuery.Sellers]: ISeller[];
  [AdminQuery.Managers]: IUser[];
  [AdminQuery.History]: IHistory[];
  [ClientQuery.Favourites]: IPagedFavourites;
  [ClientQuery.BusinessChoices]: IBusinessModelChoices[];
  [CommonQuery.User]: IUser;
  [CommonQuery.Startup]: IStartup;
  [CommonQuery.Startups]: IStartup[];
  [CommonQuery.Company]: ICompany;
  [CommonQuery.Seller]: ISeller;
  [CommonQuery.Buyer]: IBuyer;
  [CommonQuery.Plan]: ISubscriptionPlan;
  [CommonQuery.Requests]: IRequest[];
  [CommonQuery.Counties]: string[];
  [CommonQuery.StartupTypes]: IStartupType[];
  [CommonQuery.BusinessCategories]: IBusinessCategory[];
  [CommonQuery.HearAbout]: IHearAbout[];
  [CommonQuery.UserDeals]: IDeal[];
  [CommonQuery.Transaction]: IEscrow;
  [CommonQuery.PreviousAcquisitions]: IPreviousAcquisitions[];
  [CommonQuery.AcquisitionsProjected]: IAcquisitionsProjected[];
  [CommonQuery.BusinessModels]: IBusinessModel[];
  [CommonQuery.GrowthOpportunity]: IGrowthOpportunity[];
  [CommonQuery.TeamSize]: ITeamSize[];
  [CommonQuery.KeyAssets]: IKeyAssets[];
  [CommonQuery.WeeklyCommitment]: IWeeklyCommitment[];
  [CommonQuery.IndustryChoices]: IIndustryChoices[];
  [CommonQuery.Locations]: ILocations[];
  [CommonQuery.BuyerRoles]: IBuyerRoles[];
  [CommonQuery.ChatMetadata]: IChatMetadata[];
  [CommonQuery.BuyerListingsCountUnviewed]: ICount;
  [CommonQuery.BusinessCategoriesAll]: IBusinessCategory[];
  [CommonQuery.ListingViewsCount]: ICount;
  [CommonQuery.StartupChatsCount]: ICount;
  [CommonQuery.StartupFavouritesCount]: ICount;
  [CommonQuery.JsonSellerBucketData]: SellerJsonBucket;
};

export function useQueryData<
  TQueryKey extends keyof QueryDict,
  TData = QueryDict[TQueryKey],
  TVariables = unknown,
>(
  queryKey: TQueryKey,
  queryFn: (args: TVariables) => Promise<TData>,
  params?: TVariables | undefined,
  enabled?: boolean,
  retry = false,
): UseQueryResult<TData> {
  return useQuery([queryKey, params], () => queryFn(params as TVariables), {
    enabled,
    retry: retry,
  });
}

export function setQueryData<
  TQueryKey extends [keyof QueryDict] | [keyof QueryDict, TQueryParam],
  TDictKey extends TQueryKey[0],
  TQueryData = QueryDict[TDictKey] | undefined,
  TQueryParam = any,
>(
  queryClient: QueryClient,
  queryKey: TQueryKey,
  updater: Updater<TQueryData | undefined, TQueryData>,
) {
  return queryClient.setQueryData(queryKey, updater);
}

export interface IInfiniteScroller {
  scrollerRef: (v: any) => void;
  rootRef: (v: any) => void;
}

export interface IInfiniteQuery<T> {
  loading: boolean;
  items: T[];
  count: number;
  scroller: IInfiniteScroller | undefined;
}

export function useInfiniteDataQuery<
  T,
  TQueryKey extends QueryKey = QueryKey,
  TFnParams extends IPaginatedReq = IPaginatedReq,
  TFnData extends IPaginatedRes<T> = IPaginatedRes<T>,
>(
  queryKey: TQueryKey,
  queryFn: (params: TFnParams) => Promise<TFnData>,
  params?: Omit<TFnParams, "page">,
): IInfiniteQuery<T> {
  const query = useInfiniteQuery<TFnData>(
    [queryKey, params],
    (queryParams) =>
      // @ts-ignore
      queryFn({
        ...params,
        page: queryParams.pageParam || 1,
        page_size: params?.page_size || 5,
      }),
    {
      getNextPageParam: (lastPage: TFnData, allPages: TFnData[]) => {
        const pagesL = allPages.length;
        const total = pagesL * (params?.page_size ?? 5);
        if (total >= lastPage.count) return;
        return pagesL + 1;
      },
    },
  );

  const [scrollerRef, { rootRef }] = useInfiniteScroll({
    loading: query.isFetchingNextPage,
    hasNextPage: query.hasNextPage || false,
    onLoadMore: query.fetchNextPage,
  });

  const infiniteScrollerRef = useRef<IInfiniteScroller>({
    scrollerRef,
    rootRef,
  });

  return useMemo(() => {
    let items: T[] = [],
      count = 0;
    if (query.data) {
      items = query.data.pages.map((item) => item.results).flat();
      count = getLast(query.data.pages).count;
    }

    let scroller: IInfiniteScroller | undefined;

    if (query.hasNextPage) {
      scroller = infiniteScrollerRef.current;
    }

    return {
      loading: query.isLoading,
      items,
      count,
      scroller,
    };
  }, [query]);
}

export function useScrollerQuery<
  TQueryKey extends keyof QueryDict,
  TData = QueryDict[TQueryKey],
  TVariables = unknown,
>(
  queryKey: TQueryKey,
  queryFn: (args: TVariables) => Promise<TData>,
  params: TVariables | undefined,
  enabled?: boolean,
) {
  const queryClient = useQueryClient();
  const query = useQuery([queryKey, params], { enabled, initialData: [] });
  // const hasNextPageRef = useRef(false);
  // const currentPageRef = useRef<number>(1);

  useEffect(() => {
    if (enabled && params) {
      queryFn(params).then((res: TData) => {
        console.log(res);
      });
    }
  }, [queryFn, enabled, params, queryClient]);

  /*const [scrollerRef, { rootRef }] = useInfiniteScroll({
    loading: query.isLoading,
    hasNextPage: query.hasNextPage || false,
    onLoadMore: query.fetchNextPage,
  });*/

  return useMemo(
    () => ({
      isLoading: query.isLoading,
      items: query.data,
      // scroller:
    }),
    [query.isLoading, query.data],
  );
}
