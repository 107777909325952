export const CompletedIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2523_7164)">
      <path
        d="M18.686 24.8553C19.126 24.6643 19.308 24.3843 19.44 24.2193C20.626 22.7393 18.097 18.3443 14.486 15.8013C10.659 12.4693 6.44599 11.3333 5.25999 12.8143C5.05699 13.0683 4.89699 13.4143 4.83099 13.8043L2.58099 26.7503C2.50299 28.3283 3.10599 28.9282 3.40799 29.2202C3.95499 29.7522 5.01799 30.2032 6.12799 29.8132C10.409 28.1642 14.42 26.7153 18.686 24.8563V24.8553Z"
        fill="url(#paint0_linear_2523_7164)"
      />
    </g>
    <path
      d="M18.686 24.8553C19.126 24.6643 19.308 24.3843 19.44 24.2193C20.626 22.7393 18.097 18.3443 14.486 15.8013C10.659 12.4693 6.44599 11.3333 5.25999 12.8143C5.05699 13.0683 4.89699 13.4143 4.83099 13.8043L2.58099 26.7503C2.50299 28.3283 3.10599 28.9282 3.40799 29.2202C3.95499 29.7522 5.01799 30.2032 6.12799 29.8132C10.409 28.1642 14.42 26.7153 18.686 24.8563V24.8553Z"
      fill="url(#paint1_linear_2523_7164)"
    />
    <path
      d="M18.686 24.8553C19.126 24.6643 19.308 24.3843 19.44 24.2193C20.626 22.7393 18.097 18.3443 14.486 15.8013C10.659 12.4693 6.44599 11.3333 5.25999 12.8143C5.05699 13.0683 4.89699 13.4143 4.83099 13.8043L2.58099 26.7503C2.50299 28.3283 3.10599 28.9282 3.40799 29.2202C3.95499 29.7522 5.01799 30.2032 6.12799 29.8132C10.409 28.1642 14.42 26.7153 18.686 24.8563V24.8553Z"
      fill="url(#paint2_linear_2523_7164)"
    />
    <path
      d="M18.686 24.8553C19.126 24.6643 19.308 24.3843 19.44 24.2193C20.626 22.7393 18.097 18.3443 14.486 15.8013C10.659 12.4693 6.44599 11.3333 5.25999 12.8143C5.05699 13.0683 4.89699 13.4143 4.83099 13.8043L2.58099 26.7503C2.50299 28.3283 3.10599 28.9282 3.40799 29.2202C3.95499 29.7522 5.01799 30.2032 6.12799 29.8132C10.409 28.1642 14.42 26.7153 18.686 24.8563V24.8553Z"
      fill="url(#paint3_linear_2523_7164)"
    />
    <g filter="url(#filter1_f_2523_7164)">
      <path
        d="M10.0832 21.2876C13.8082 24.3852 17.8195 25.7038 19.0427 24.2328C20.2659 22.7619 18.2378 19.0583 14.5129 15.9607C10.7879 12.8631 6.77657 11.5445 5.55336 13.0155C4.33014 14.4864 6.35822 18.19 10.0832 21.2876Z"
        fill="url(#paint4_linear_2523_7164)"
      />
    </g>
    <g filter="url(#filter2_f_2523_7164)">
      <path
        d="M10.6912 20.4284C14.1435 23.2993 17.7767 24.6231 18.806 23.3853C19.8354 22.1474 17.8711 18.8166 14.4188 15.9458C10.9664 13.0749 7.33327 11.751 6.30392 12.9889C5.27456 14.2267 7.2388 17.5575 10.6912 20.4284Z"
        fill="url(#paint5_linear_2523_7164)"
      />
      <path
        d="M10.6912 20.4284C14.1435 23.2993 17.7767 24.6231 18.806 23.3853C19.8354 22.1474 17.8711 18.8166 14.4188 15.9458C10.9664 13.0749 7.33327 11.751 6.30392 12.9889C5.27456 14.2267 7.2388 17.5575 10.6912 20.4284Z"
        fill="url(#paint6_radial_2523_7164)"
      />
      <path
        d="M10.6912 20.4284C14.1435 23.2993 17.7767 24.6231 18.806 23.3853C19.8354 22.1474 17.8711 18.8166 14.4188 15.9458C10.9664 13.0749 7.33327 11.751 6.30392 12.9889C5.27456 14.2267 7.2388 17.5575 10.6912 20.4284Z"
        fill="url(#paint7_radial_2523_7164)"
      />
    </g>
    <path
      d="M14.956 17.2029C15.6204 17.2029 16.159 16.6643 16.159 15.9999C16.159 15.3355 15.6204 14.7969 14.956 14.7969C14.2916 14.7969 13.753 15.3355 13.753 15.9999C13.753 16.6643 14.2916 17.2029 14.956 17.2029Z"
      fill="url(#paint8_radial_2523_7164)"
    />
    <path
      d="M14.956 17.2029C15.6204 17.2029 16.159 16.6643 16.159 15.9999C16.159 15.3355 15.6204 14.7969 14.956 14.7969C14.2916 14.7969 13.753 15.3355 13.753 15.9999C13.753 16.6643 14.2916 17.2029 14.956 17.2029Z"
      fill="url(#paint9_radial_2523_7164)"
    />
    <path
      d="M24.831 24.1091C25.4954 24.1091 26.034 23.5705 26.034 22.9061C26.034 22.2417 25.4954 21.7031 24.831 21.7031C24.1666 21.7031 23.628 22.2417 23.628 22.9061C23.628 23.5705 24.1666 24.1091 24.831 24.1091Z"
      fill="url(#paint10_radial_2523_7164)"
    />
    <path
      d="M24.831 24.1091C25.4954 24.1091 26.034 23.5705 26.034 22.9061C26.034 22.2417 25.4954 21.7031 24.831 21.7031C24.1666 21.7031 23.628 22.2417 23.628 22.9061C23.628 23.5705 24.1666 24.1091 24.831 24.1091Z"
      fill="url(#paint11_radial_2523_7164)"
    />
    <path
      d="M24.831 24.1091C25.4954 24.1091 26.034 23.5705 26.034 22.9061C26.034 22.2417 25.4954 21.7031 24.831 21.7031C24.1666 21.7031 23.628 22.2417 23.628 22.9061C23.628 23.5705 24.1666 24.1091 24.831 24.1091Z"
      fill="url(#paint12_radial_2523_7164)"
    />
    <path
      d="M23.823 8.21899C24.4874 8.21899 25.026 7.68039 25.026 7.01599C25.026 6.35159 24.4874 5.81299 23.823 5.81299C23.1586 5.81299 22.62 6.35159 22.62 7.01599C22.62 7.68039 23.1586 8.21899 23.823 8.21899Z"
      fill="url(#paint13_radial_2523_7164)"
    />
    <path
      d="M23.823 8.21899C24.4874 8.21899 25.026 7.68039 25.026 7.01599C25.026 6.35159 24.4874 5.81299 23.823 5.81299C23.1586 5.81299 22.62 6.35159 22.62 7.01599C22.62 7.68039 23.1586 8.21899 23.823 8.21899Z"
      fill="url(#paint14_radial_2523_7164)"
    />
    <path
      d="M9.99096 4.57006C10.6554 4.57006 11.194 4.03146 11.194 3.36706C11.194 2.70266 10.6554 2.16406 9.99096 2.16406C9.32657 2.16406 8.78796 2.70266 8.78796 3.36706C8.78796 4.03146 9.32657 4.57006 9.99096 4.57006Z"
      fill="url(#paint15_radial_2523_7164)"
    />
    <path
      d="M9.99096 4.57006C10.6554 4.57006 11.194 4.03146 11.194 3.36706C11.194 2.70266 10.6554 2.16406 9.99096 2.16406C9.32657 2.16406 8.78796 2.70266 8.78796 3.36706C8.78796 4.03146 9.32657 4.57006 9.99096 4.57006Z"
      fill="url(#paint16_radial_2523_7164)"
    />
    <path
      d="M9.99096 4.57006C10.6554 4.57006 11.194 4.03146 11.194 3.36706C11.194 2.70266 10.6554 2.16406 9.99096 2.16406C9.32657 2.16406 8.78796 2.70266 8.78796 3.36706C8.78796 4.03146 9.32657 4.57006 9.99096 4.57006Z"
      fill="url(#paint17_radial_2523_7164)"
    />
    <path
      d="M4.92798 9.31225C5.59237 9.31225 6.13098 8.77365 6.13098 8.10925C6.13098 7.44485 5.59237 6.90625 4.92798 6.90625C4.26358 6.90625 3.72498 7.44485 3.72498 8.10925C3.72498 8.77365 4.26358 9.31225 4.92798 9.31225Z"
      fill="url(#paint18_radial_2523_7164)"
    />
    <path
      d="M4.92798 9.31225C5.59237 9.31225 6.13098 8.77365 6.13098 8.10925C6.13098 7.44485 5.59237 6.90625 4.92798 6.90625C4.26358 6.90625 3.72498 7.44485 3.72498 8.10925C3.72498 8.77365 4.26358 9.31225 4.92798 9.31225Z"
      fill="url(#paint19_radial_2523_7164)"
    />
    <path
      d="M15.953 26.0101C13.347 25.8281 11.519 25.0481 8.55798 22.6011C6.00998 20.4971 4.74498 18.6301 4.32298 16.7261L3.83398 19.5391C4.03398 20.6411 4.89398 22.1791 6.59698 23.7191C8.82398 25.7321 11.042 27.1561 13.235 27.0941C14.1439 26.7397 15.0499 26.379 15.953 26.0101Z"
      fill="url(#paint20_radial_2523_7164)"
    />
    <path
      d="M10.457 28.1632C9.20402 28.2332 7.51102 27.2112 5.75302 25.7502C4.25302 24.4532 3.51302 23.0442 3.35702 22.2842L2.86102 25.1412C2.98302 25.6242 3.35802 26.2812 4.44102 27.3912C5.65102 28.6312 6.86902 29.1152 7.59102 29.2522L9.12302 28.6702L10.457 28.1632Z"
      fill="url(#paint21_radial_2523_7164)"
    />
    <g filter="url(#filter3_d_2523_7164)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.903 13.1091C24.305 12.4061 22.179 14.0541 21.505 14.7931C21.413 14.8983 21.301 14.9841 21.1755 15.0455C21.05 15.1068 20.9135 15.1425 20.774 15.1505C20.6345 15.1584 20.4948 15.1384 20.3631 15.0917C20.2315 15.045 20.1104 14.9725 20.0072 14.8784C19.9039 14.7843 19.8204 14.6705 19.7617 14.5437C19.7029 14.4169 19.67 14.2797 19.665 14.1401C19.6599 14.0005 19.6828 13.8612 19.7323 13.7306C19.7817 13.5999 19.8568 13.4804 19.953 13.3791C20.998 12.2321 23.826 10.1721 28.306 11.0481C28.5722 11.1085 28.8044 11.2703 28.9534 11.4991C29.1024 11.7279 29.1564 12.0057 29.104 12.2736C29.0515 12.5415 28.8968 12.7785 28.6726 12.9343C28.4484 13.0901 28.1724 13.1524 27.903 13.1081V13.1091Z"
        fill="url(#paint22_radial_2523_7164)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.903 13.1091C24.305 12.4061 22.179 14.0541 21.505 14.7931C21.413 14.8983 21.301 14.9841 21.1755 15.0455C21.05 15.1068 20.9135 15.1425 20.774 15.1505C20.6345 15.1584 20.4948 15.1384 20.3631 15.0917C20.2315 15.045 20.1104 14.9725 20.0072 14.8784C19.9039 14.7843 19.8204 14.6705 19.7617 14.5437C19.7029 14.4169 19.67 14.2797 19.665 14.1401C19.6599 14.0005 19.6828 13.8612 19.7323 13.7306C19.7817 13.5999 19.8568 13.4804 19.953 13.3791C20.998 12.2321 23.826 10.1721 28.306 11.0481C28.5722 11.1085 28.8044 11.2703 28.9534 11.4991C29.1024 11.7279 29.1564 12.0057 29.104 12.2736C29.0515 12.5415 28.8968 12.7785 28.6726 12.9343C28.4484 13.0901 28.1724 13.1524 27.903 13.1081V13.1091Z"
        fill="url(#paint23_radial_2523_7164)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.104 2.08208C19.2287 2.08785 19.3511 2.11815 19.4641 2.17124C19.5771 2.22433 19.6785 2.29918 19.7625 2.39149C19.8466 2.48381 19.9116 2.59179 19.9539 2.70925C19.9962 2.82672 20.0149 2.95137 20.009 3.07608C19.9473 4.15742 19.7869 5.23088 19.53 6.28307C19.268 7.35707 18.865 8.54707 18.244 9.56807C18.394 10.3641 18.398 11.3031 18.233 12.3711C18.1945 12.6201 18.0587 12.8437 17.8554 12.9926C17.6522 13.1415 17.398 13.2035 17.149 13.1651C16.9 13.1266 16.6764 12.9908 16.5275 12.7875C16.3786 12.5842 16.3165 12.3301 16.355 12.0811C16.392 11.8431 16.418 11.6191 16.435 11.4091C16.2547 11.504 16.065 11.5797 15.869 11.6351C15.5268 11.7349 15.1669 11.7588 14.8145 11.7049C14.4621 11.651 14.1257 11.5207 13.829 11.3231C12.755 10.6081 12.26 9.04307 13.091 7.76307C13.881 6.54707 15.529 6.21607 16.781 7.08207C16.926 7.18207 17.061 7.29408 17.188 7.41908C17.388 6.91407 17.552 6.37408 17.684 5.83308C17.965 4.68108 18.082 3.60108 18.111 2.98708C18.1168 2.86245 18.147 2.74018 18.2001 2.62725C18.2531 2.51433 18.3279 2.41295 18.4201 2.32891C18.5123 2.24487 18.6201 2.17982 18.7375 2.13747C18.8548 2.09512 18.9794 2.07629 19.104 2.08208ZM16.157 9.22207C16.013 8.92207 15.841 8.74208 15.701 8.64508C15.309 8.37408 14.879 8.50007 14.684 8.79907C14.5885 8.95131 14.5557 9.13466 14.5927 9.31054C14.6296 9.48642 14.7333 9.64112 14.882 9.74207C15.002 9.82207 15.155 9.86208 15.358 9.80508C15.542 9.75308 15.823 9.60407 16.157 9.22207Z"
      fill="url(#paint24_linear_2523_7164)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.104 2.08208C19.2287 2.08785 19.3511 2.11815 19.4641 2.17124C19.5771 2.22433 19.6785 2.29918 19.7625 2.39149C19.8466 2.48381 19.9116 2.59179 19.9539 2.70925C19.9962 2.82672 20.0149 2.95137 20.009 3.07608C19.9473 4.15742 19.7869 5.23088 19.53 6.28307C19.268 7.35707 18.865 8.54707 18.244 9.56807C18.394 10.3641 18.398 11.3031 18.233 12.3711C18.1945 12.6201 18.0587 12.8437 17.8554 12.9926C17.6522 13.1415 17.398 13.2035 17.149 13.1651C16.9 13.1266 16.6764 12.9908 16.5275 12.7875C16.3786 12.5842 16.3165 12.3301 16.355 12.0811C16.392 11.8431 16.418 11.6191 16.435 11.4091C16.2547 11.504 16.065 11.5797 15.869 11.6351C15.5268 11.7349 15.1669 11.7588 14.8145 11.7049C14.4621 11.651 14.1257 11.5207 13.829 11.3231C12.755 10.6081 12.26 9.04307 13.091 7.76307C13.881 6.54707 15.529 6.21607 16.781 7.08207C16.926 7.18207 17.061 7.29408 17.188 7.41908C17.388 6.91407 17.552 6.37408 17.684 5.83308C17.965 4.68108 18.082 3.60108 18.111 2.98708C18.1168 2.86245 18.147 2.74018 18.2001 2.62725C18.2531 2.51433 18.3279 2.41295 18.4201 2.32891C18.5123 2.24487 18.6201 2.17982 18.7375 2.13747C18.8548 2.09512 18.9794 2.07629 19.104 2.08208ZM16.157 9.22207C16.013 8.92207 15.841 8.74208 15.701 8.64508C15.309 8.37408 14.879 8.50007 14.684 8.79907C14.5885 8.95131 14.5557 9.13466 14.5927 9.31054C14.6296 9.48642 14.7333 9.64112 14.882 9.74207C15.002 9.82207 15.155 9.86208 15.358 9.80508C15.542 9.75308 15.823 9.60407 16.157 9.22207Z"
      fill="url(#paint25_radial_2523_7164)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.104 2.08208C19.2287 2.08785 19.3511 2.11815 19.4641 2.17124C19.5771 2.22433 19.6785 2.29918 19.7625 2.39149C19.8466 2.48381 19.9116 2.59179 19.9539 2.70925C19.9962 2.82672 20.0149 2.95137 20.009 3.07608C19.9473 4.15742 19.7869 5.23088 19.53 6.28307C19.268 7.35707 18.865 8.54707 18.244 9.56807C18.394 10.3641 18.398 11.3031 18.233 12.3711C18.1945 12.6201 18.0587 12.8437 17.8554 12.9926C17.6522 13.1415 17.398 13.2035 17.149 13.1651C16.9 13.1266 16.6764 12.9908 16.5275 12.7875C16.3786 12.5842 16.3165 12.3301 16.355 12.0811C16.392 11.8431 16.418 11.6191 16.435 11.4091C16.2547 11.504 16.065 11.5797 15.869 11.6351C15.5268 11.7349 15.1669 11.7588 14.8145 11.7049C14.4621 11.651 14.1257 11.5207 13.829 11.3231C12.755 10.6081 12.26 9.04307 13.091 7.76307C13.881 6.54707 15.529 6.21607 16.781 7.08207C16.926 7.18207 17.061 7.29408 17.188 7.41908C17.388 6.91407 17.552 6.37408 17.684 5.83308C17.965 4.68108 18.082 3.60108 18.111 2.98708C18.1168 2.86245 18.147 2.74018 18.2001 2.62725C18.2531 2.51433 18.3279 2.41295 18.4201 2.32891C18.5123 2.24487 18.6201 2.17982 18.7375 2.13747C18.8548 2.09512 18.9794 2.07629 19.104 2.08208ZM16.157 9.22207C16.013 8.92207 15.841 8.74208 15.701 8.64508C15.309 8.37408 14.879 8.50007 14.684 8.79907C14.5885 8.95131 14.5557 9.13466 14.5927 9.31054C14.6296 9.48642 14.7333 9.64112 14.882 9.74207C15.002 9.82207 15.155 9.86208 15.358 9.80508C15.542 9.75308 15.823 9.60407 16.157 9.22207Z"
      fill="url(#paint26_radial_2523_7164)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.104 2.08208C19.2287 2.08785 19.3511 2.11815 19.4641 2.17124C19.5771 2.22433 19.6785 2.29918 19.7625 2.39149C19.8466 2.48381 19.9116 2.59179 19.9539 2.70925C19.9962 2.82672 20.0149 2.95137 20.009 3.07608C19.9473 4.15742 19.7869 5.23088 19.53 6.28307C19.268 7.35707 18.865 8.54707 18.244 9.56807C18.394 10.3641 18.398 11.3031 18.233 12.3711C18.1945 12.6201 18.0587 12.8437 17.8554 12.9926C17.6522 13.1415 17.398 13.2035 17.149 13.1651C16.9 13.1266 16.6764 12.9908 16.5275 12.7875C16.3786 12.5842 16.3165 12.3301 16.355 12.0811C16.392 11.8431 16.418 11.6191 16.435 11.4091C16.2547 11.504 16.065 11.5797 15.869 11.6351C15.5268 11.7349 15.1669 11.7588 14.8145 11.7049C14.4621 11.651 14.1257 11.5207 13.829 11.3231C12.755 10.6081 12.26 9.04307 13.091 7.76307C13.881 6.54707 15.529 6.21607 16.781 7.08207C16.926 7.18207 17.061 7.29408 17.188 7.41908C17.388 6.91407 17.552 6.37408 17.684 5.83308C17.965 4.68108 18.082 3.60108 18.111 2.98708C18.1168 2.86245 18.147 2.74018 18.2001 2.62725C18.2531 2.51433 18.3279 2.41295 18.4201 2.32891C18.5123 2.24487 18.6201 2.17982 18.7375 2.13747C18.8548 2.09512 18.9794 2.07629 19.104 2.08208ZM16.157 9.22207C16.013 8.92207 15.841 8.74208 15.701 8.64508C15.309 8.37408 14.879 8.50007 14.684 8.79907C14.5885 8.95131 14.5557 9.13466 14.5927 9.31054C14.6296 9.48642 14.7333 9.64112 14.882 9.74207C15.002 9.82207 15.155 9.86208 15.358 9.80508C15.542 9.75308 15.823 9.60407 16.157 9.22207Z"
      fill="url(#paint27_radial_2523_7164)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.842 22.6059C13.1083 22.1971 12.4018 21.7411 11.727 21.2409C12.813 19.8939 14.754 18.1279 17.284 16.8529C21.441 14.7569 26.025 15.8939 27.894 16.6349C28.0466 16.6955 28.1858 16.7855 28.3036 16.8998C28.4214 17.0141 28.5155 17.1505 28.5806 17.3012C28.6457 17.4519 28.6805 17.614 28.683 17.7781C28.6854 17.9423 28.6555 18.1053 28.595 18.2579C28.5345 18.4105 28.4444 18.5497 28.3301 18.6675C28.2158 18.7853 28.0794 18.8795 27.9287 18.9445C27.778 19.0096 27.6159 19.0444 27.4518 19.0469C27.2876 19.0493 27.1246 19.0195 26.972 18.9589C25.371 18.3239 21.627 17.4629 18.409 19.0849C16.287 20.1549 14.709 21.5929 13.842 22.6059Z"
      fill="url(#paint28_linear_2523_7164)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.842 22.6059C13.1083 22.1971 12.4018 21.7411 11.727 21.2409C12.813 19.8939 14.754 18.1279 17.284 16.8529C21.441 14.7569 26.025 15.8939 27.894 16.6349C28.0466 16.6955 28.1858 16.7855 28.3036 16.8998C28.4214 17.0141 28.5155 17.1505 28.5806 17.3012C28.6457 17.4519 28.6805 17.614 28.683 17.7781C28.6854 17.9423 28.6555 18.1053 28.595 18.2579C28.5345 18.4105 28.4444 18.5497 28.3301 18.6675C28.2158 18.7853 28.0794 18.8795 27.9287 18.9445C27.778 19.0096 27.6159 19.0444 27.4518 19.0469C27.2876 19.0493 27.1246 19.0195 26.972 18.9589C25.371 18.3239 21.627 17.4629 18.409 19.0849C16.287 20.1549 14.709 21.5929 13.842 22.6059Z"
      fill="url(#paint29_radial_2523_7164)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.842 22.6059C13.1083 22.1971 12.4018 21.7411 11.727 21.2409C12.813 19.8939 14.754 18.1279 17.284 16.8529C21.441 14.7569 26.025 15.8939 27.894 16.6349C28.0466 16.6955 28.1858 16.7855 28.3036 16.8998C28.4214 17.0141 28.5155 17.1505 28.5806 17.3012C28.6457 17.4519 28.6805 17.614 28.683 17.7781C28.6854 17.9423 28.6555 18.1053 28.595 18.2579C28.5345 18.4105 28.4444 18.5497 28.3301 18.6675C28.2158 18.7853 28.0794 18.8795 27.9287 18.9445C27.778 19.0096 27.6159 19.0444 27.4518 19.0469C27.2876 19.0493 27.1246 19.0195 26.972 18.9589C25.371 18.3239 21.627 17.4629 18.409 19.0849C16.287 20.1549 14.709 21.5929 13.842 22.6059Z"
      fill="url(#paint30_radial_2523_7164)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.842 22.6059C13.1083 22.1971 12.4018 21.7411 11.727 21.2409C12.813 19.8939 14.754 18.1279 17.284 16.8529C21.441 14.7569 26.025 15.8939 27.894 16.6349C28.0466 16.6955 28.1858 16.7855 28.3036 16.8998C28.4214 17.0141 28.5155 17.1505 28.5806 17.3012C28.6457 17.4519 28.6805 17.614 28.683 17.7781C28.6854 17.9423 28.6555 18.1053 28.595 18.2579C28.5345 18.4105 28.4444 18.5497 28.3301 18.6675C28.2158 18.7853 28.0794 18.8795 27.9287 18.9445C27.778 19.0096 27.6159 19.0444 27.4518 19.0469C27.2876 19.0493 27.1246 19.0195 26.972 18.9589C25.371 18.3239 21.627 17.4629 18.409 19.0849C16.287 20.1549 14.709 21.5929 13.842 22.6059Z"
      fill="url(#paint31_radial_2523_7164)"
    />
    <g filter="url(#filter4_d_2523_7164)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.192 6.65326C10.1121 6.50979 10.0047 6.38347 9.87599 6.2815C9.74726 6.17953 9.59971 6.10392 9.44176 6.05897C9.2838 6.01403 9.11855 6.00064 8.95542 6.01956C8.79229 6.03848 8.63449 6.08935 8.49102 6.16926C8.34755 6.24917 8.22122 6.35656 8.11925 6.48529C8.01729 6.61402 7.94167 6.76157 7.89673 6.91952C7.85178 7.07747 7.83839 7.24273 7.85732 7.40586C7.87624 7.56899 7.92711 7.72679 8.00702 7.87026C8.51402 8.78026 9.13802 10.0673 9.21802 11.7233C9.28802 13.1663 8.94802 14.9963 7.56702 17.1923C8.09135 17.879 8.6645 18.527 9.28202 19.1313C11.23 16.2983 11.819 13.7603 11.715 11.6023C11.608 9.37226 10.763 7.68026 10.192 6.65326Z"
        fill="url(#paint32_radial_2523_7164)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.192 6.65326C10.1121 6.50979 10.0047 6.38347 9.87599 6.2815C9.74726 6.17953 9.59971 6.10392 9.44176 6.05897C9.2838 6.01403 9.11855 6.00064 8.95542 6.01956C8.79229 6.03848 8.63449 6.08935 8.49102 6.16926C8.34755 6.24917 8.22122 6.35656 8.11925 6.48529C8.01729 6.61402 7.94167 6.76157 7.89673 6.91952C7.85178 7.07747 7.83839 7.24273 7.85732 7.40586C7.87624 7.56899 7.92711 7.72679 8.00702 7.87026C8.51402 8.78026 9.13802 10.0673 9.21802 11.7233C9.28802 13.1663 8.94802 14.9963 7.56702 17.1923C8.09135 17.879 8.6645 18.527 9.28202 19.1313C11.23 16.2983 11.819 13.7603 11.715 11.6023C11.608 9.37226 10.763 7.68026 10.192 6.65326Z"
        fill="url(#paint33_radial_2523_7164)"
      />
    </g>
    <path
      d="M12.855 8.21096C12.916 8.05996 12.995 7.90996 13.089 7.76396C13.879 6.54696 15.527 6.21596 16.779 7.08196C16.924 7.18196 17.059 7.29396 17.186 7.41896C17.226 7.31596 17.266 7.21096 17.304 7.10496L17.309 7.12496C17.052 7.93496 16.475 8.80296 16.154 9.22096C16.01 8.92096 15.839 8.74096 15.699 8.64496C15.307 8.37396 14.876 8.49996 14.682 8.79896C14.6746 8.81039 14.6676 8.82206 14.661 8.83396L12.855 8.21096Z"
      fill="url(#paint34_radial_2523_7164)"
    />
    <path
      d="M12.855 8.21096C12.916 8.05996 12.995 7.90996 13.089 7.76396C13.879 6.54696 15.527 6.21596 16.779 7.08196C16.924 7.18196 17.059 7.29396 17.186 7.41896C17.226 7.31596 17.266 7.21096 17.304 7.10496L17.309 7.12496C17.052 7.93496 16.475 8.80296 16.154 9.22096C16.01 8.92096 15.839 8.74096 15.699 8.64496C15.307 8.37396 14.876 8.49996 14.682 8.79896C14.6746 8.81039 14.6676 8.82206 14.661 8.83396L12.855 8.21096Z"
      fill="url(#paint35_radial_2523_7164)"
    />
    <g filter="url(#filter5_d_2523_7164)">
      <path
        d="M18.242 9.56787C18.392 10.3639 18.396 11.3039 18.231 12.3709C18.1925 12.6199 18.0567 12.8435 17.8534 12.9924C17.6501 13.1413 17.396 13.2033 17.147 13.1649C16.898 13.1264 16.6744 12.9906 16.5255 12.7873C16.3766 12.584 16.3145 12.3299 16.353 12.0809C16.39 11.8429 16.416 11.6199 16.433 11.4109C17.415 10.7939 18.063 9.97387 18.242 9.56787Z"
        fill="#44B6FF"
      />
    </g>
    <path
      d="M18.242 9.56787C18.392 10.3639 18.396 11.3039 18.231 12.3709C18.1925 12.6199 18.0567 12.8435 17.8534 12.9924C17.6501 13.1413 17.396 13.2033 17.147 13.1649C16.898 13.1264 16.6744 12.9906 16.5255 12.7873C16.3766 12.584 16.3145 12.3299 16.353 12.0809C16.39 11.8429 16.416 11.6199 16.433 11.4109C17.415 10.7939 18.063 9.97387 18.242 9.56787Z"
      fill="url(#paint36_radial_2523_7164)"
    />
    <g filter="url(#filter6_f_2523_7164)">
      <path
        d="M16.55 8.17218C16.284 7.81218 15.432 7.17218 14.362 7.83618C14.003 8.05918 13.792 8.89818 13.956 9.46918"
        stroke="url(#paint37_linear_2523_7164)"
        strokeWidth="0.3"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter7_f_2523_7164)">
      <path
        d="M19.472 3.01611C19.388 4.52611 18.734 8.09111 16.784 10.2661"
        stroke="url(#paint38_linear_2523_7164)"
        strokeWidth="0.3"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2523_7164"
        x="1.5741"
        y="10.6758"
        width="19.1616"
        height="19.7847"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.92549 0 0 0 0 0.34902 0 0 0 0 0.384314 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2523_7164"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2523_7164"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_f_2523_7164"
        x="4.69708"
        y="11.9136"
        width="15.2019"
        height="13.4209"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.25"
          result="effect1_foregroundBlur_2523_7164"
        />
      </filter>
      <filter
        id="filter2_f_2523_7164"
        x="5.83027"
        y="12.3249"
        width="13.4494"
        height="11.7242"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.1"
          result="effect1_foregroundBlur_2523_7164"
        />
      </filter>
      <filter
        id="filter3_d_2523_7164"
        x="19.4143"
        y="10.0869"
        width="10.4592"
        height="5.31543"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.25" dy="-0.25" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.721569 0 0 0 0 0.270588 0 0 0 0 0.470588 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2523_7164"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2523_7164"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_2523_7164"
        x="6.71702"
        y="5.26123"
        width="6.15942"
        height="15.1201"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.15" dy="0.25" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.721569 0 0 0 0 0.184314 0 0 0 0 0.447059 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2523_7164"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2523_7164"
          result="shape"
        />
      </filter>
      <filter
        id="filter5_d_2523_7164"
        x="16.1919"
        y="8.66787"
        width="3.31276"
        height="4.90791"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.5" dy="-0.25" />
        <feGaussianBlur stdDeviation="0.325" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.239216 0 0 0 0 0.439216 0 0 0 0 0.768627 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2523_7164"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2523_7164"
          result="shape"
        />
      </filter>
      <filter
        id="filter6_f_2523_7164"
        x="13.4495"
        y="7.09746"
        width="3.5505"
        height="2.82168"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.15"
          result="effect1_foregroundBlur_2523_7164"
        />
      </filter>
      <filter
        id="filter7_f_2523_7164"
        x="16.184"
        y="2.41621"
        width="3.88804"
        height="8.4498"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.225"
          result="effect1_foregroundBlur_2523_7164"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2523_7164"
        x1="4.22199"
        y1="19.9843"
        x2="12.284"
        y2="26.9843"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B3843C" />
        <stop offset="1" stopColor="#F17A61" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2523_7164"
        x1="3.33099"
        y1="21.0683"
        x2="5.68999"
        y2="21.5473"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B1853B" />
        <stop offset="1" stopColor="#B1853B" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2523_7164"
        x1="19.736"
        y1="23.8283"
        x2="15.753"
        y2="24.4843"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9C26C" />
        <stop offset="1" stopColor="#F9C26C" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2523_7164"
        x1="11.155"
        y1="12.3913"
        x2="8.597"
        y2="16.7343"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6BF69" />
        <stop offset="1" stopColor="#E6BF69" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2523_7164"
        x1="10.284"
        y1="21.0852"
        x2="15.4748"
        y2="16.4524"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCCB57" />
        <stop offset="1" stopColor="#F5BD55" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2523_7164"
        x1="7.06493"
        y1="13.2029"
        x2="19.2215"
        y2="24.3589"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3AB2C" />
        <stop offset="1" stopColor="#B57F1E" />
      </linearGradient>
      <radialGradient
        id="paint6_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.91006 17.3596) rotate(-69.379) scale(6.4775 1.81427)"
      >
        <stop offset="0.423" stopColor="#CA851E" />
        <stop offset="1" stopColor="#CA851E" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.0658 21.7658) rotate(-33.3512) scale(6.50953 3.81205)"
      >
        <stop stopColor="#BC7F28" />
        <stop offset="1" stopColor="#BC7F28" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.4874 15.6722) rotate(138.668) scale(1.72711)"
      >
        <stop stopColor="#5AC0FF" />
        <stop offset="1" stopColor="#3279B6" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.193 15.8339) rotate(160.051) scale(1.73439 1.90111)"
      >
        <stop offset="0.535" stopColor="#3A619E" stopOpacity="0" />
        <stop offset="1" stopColor="#3A619E" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.5281 22.3974) rotate(134.569) scale(1.97753)"
      >
        <stop stopColor="#FDD46C" />
        <stop offset="1" stopColor="#F09C53" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.0539 22.2936) rotate(100.827) scale(1.95883)"
      >
        <stop offset="0.792" stopColor="#E76260" stopOpacity="0" />
        <stop offset="0.964" stopColor="#E76260" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.405 23.1051) rotate(-147.558) scale(1.98371 2.33609)"
      >
        <stop offset="0.482" stopColor="#AA885A" stopOpacity="0" />
        <stop offset="0.907" stopColor="#AA885A" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.6846 6.54742) rotate(132.387) scale(2.06012)"
      >
        <stop stopColor="#FF46AA" />
        <stop offset="1" stopColor="#E3189D" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.331 7.23399) rotate(-173.29) scale(1.8722 2.50559)"
      >
        <stop offset="0.522" stopColor="#B63173" stopOpacity="0" />
        <stop offset="1" stopColor="#B63173" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.7784 2.8856) rotate(139.574) scale(1.84118)"
      >
        <stop stopColor="#FFF46F" />
        <stop offset="1" stopColor="#EFB93C" />
      </radialGradient>
      <radialGradient
        id="paint16_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.515 3.17197) rotate(-178.519) scale(1.91464 1.82297)"
      >
        <stop offset="0.559" stopColor="#AA904B" stopOpacity="0" />
        <stop offset="0.944" stopColor="#AA904B" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.3 2.68806) rotate(102.339) scale(2.04729)"
      >
        <stop offset="0.772" stopColor="#C47072" stopOpacity="0" />
        <stop offset="1" stopColor="#C47072" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.7911 7.64127) rotate(132.387) scale(2.06012)"
      >
        <stop stopColor="#FF46AA" />
        <stop offset="1" stopColor="#E3189D" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.43598 8.32825) rotate(-173.29) scale(1.8722 2.50559)"
      >
        <stop offset="0.522" stopColor="#B63173" stopOpacity="0" />
        <stop offset="1" stopColor="#B63173" />
      </radialGradient>
      <radialGradient
        id="paint20_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.222 25.0781) rotate(133.21) scale(4.2447 9.2935)"
      >
        <stop stopColor="#3365FF" />
        <stop offset="1" stopColor="#356DB9" />
      </radialGradient>
      <radialGradient
        id="paint21_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.40946 28.0161) rotate(131.598) scale(2.62017 6.62793)"
      >
        <stop stopColor="#3365FF" />
        <stop offset="1" stopColor="#356DB9" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(27.5341 11.8909) rotate(168.283) scale(4.30854 1.60742)"
      >
        <stop stopColor="#FF3BA9" />
        <stop offset="1" stopColor="#F422A7" />
      </radialGradient>
      <radialGradient
        id="paint23_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.4171 15.3277) rotate(-127.355) scale(4.44241 7.91727)"
      >
        <stop offset="0.752" stopColor="#B8306F" stopOpacity="0" />
        <stop offset="1" stopColor="#B8306F" />
      </radialGradient>
      <linearGradient
        id="paint24_linear_2523_7164"
        x1="19.062"
        y1="2.08107"
        x2="14.58"
        y2="12.8231"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#20B2EE" />
        <stop offset="1" stopColor="#2689DA" />
      </linearGradient>
      <radialGradient
        id="paint25_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.0781 5.57112) rotate(128.217) scale(7.76064 4.747)"
      >
        <stop offset="0.802" stopColor="#4274D2" stopOpacity="0" />
        <stop offset="0.951" stopColor="#4274D2" />
      </radialGradient>
      <radialGradient
        id="paint26_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.69 4.67208) rotate(12.095) scale(1.11858 3.75516)"
      >
        <stop stopColor="#4D8EC0" />
        <stop offset="1" stopColor="#4D8EC0" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint27_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.91 2.25007) rotate(141.953) scale(1.36904 1.23085)"
      >
        <stop stopColor="#5ADEFF" />
        <stop offset="1" stopColor="#5ADEFF" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint28_linear_2523_7164"
        x1="14.45"
        y1="21.6949"
        x2="27.86"
        y2="18.1809"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3797E2" />
        <stop offset="1" stopColor="#37AEFF" />
      </linearGradient>
      <radialGradient
        id="paint29_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.4103 23.8865) rotate(-129.838) scale(7.98002 20.0036)"
      >
        <stop offset="0.623" stopColor="#526579" stopOpacity="0" />
        <stop offset="0.962" stopColor="#526579" />
      </radialGradient>
      <radialGradient
        id="paint30_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.892 19.5799) rotate(94.399) scale(2.7392 5.50719)"
      >
        <stop offset="0.396" stopColor="#6972FF" />
        <stop offset="1" stopColor="#6972FF" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint31_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(27.954 17.2789) rotate(-68.8191) scale(0.751037 0.592273)"
      >
        <stop stopColor="#55BFFF" />
        <stop offset="1" stopColor="#55BFFF" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint32_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.844 6.43626) rotate(99.4229) scale(7.31184 2.7393)"
      >
        <stop stopColor="#FF3AAA" />
        <stop offset="1" stopColor="#FD2F89" />
      </radialGradient>
      <radialGradient
        id="paint33_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.02577 13.9765) rotate(31.176) scale(4.80845 15.0903)"
      >
        <stop offset="0.804" stopColor="#DB2C6E" stopOpacity="0" />
        <stop offset="1" stopColor="#DB2C6E" />
      </radialGradient>
      <radialGradient
        id="paint34_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.4186 9.51892) rotate(-119.23) scale(2.73834 2.04693)"
      >
        <stop stopColor="#336BAF" />
        <stop offset="1" stopColor="#336BAF" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint35_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.31 7.45296) rotate(-127.657) scale(1.85511 0.751603)"
      >
        <stop stopColor="#49A4D4" />
        <stop offset="1" stopColor="#49A4D4" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint36_radial_2523_7164"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.144 9.48487) rotate(94.8501) scale(2.58739 1.44)"
      >
        <stop stopColor="#4AB5F8" />
        <stop offset="1" stopColor="#4AB5F8" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint37_linear_2523_7164"
        x1="13.597"
        y1="8.18918"
        x2="16.378"
        y2="7.50818"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4EAFF2" />
        <stop offset="1" stopColor="#4EAFF2" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_2523_7164"
        x1="19.253"
        y1="1.95311"
        x2="15.347"
        y2="10.9531"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5BDDFF" />
        <stop offset="1" stopColor="#5BDDFF" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
