import {
  Box,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoIcon from "@mui/icons-material/Info";
import * as yup from "yup";
import { useAppContext } from "../../../../common/context/AppContext";
import { useCurrentBuyer } from "../../../../common/hooks";
import { BuyerService } from "../../../../common/services/BuyerService";
import { HookedField } from "../../../components/HookedField";
import { HookedForm } from "../../../components/HookedForm";
import { RoutePaths } from "../../../consts/enums";
import PublicBanner from "../PublicBanner";
import ProfileNavigationButtons from "../ProfileNavigationButtons";
import ProBuyerAdditionalInfoDrawer from "../ProBuyerAdditionalInfoDrawer";

const schema = yup
  .object({
    social_media: yup
      .string()
      .url()
      .required()
      .nullable()
      .max(600)
      .trim()
      .label("LinkedIn"),
    description: yup
      .string()
      .required()
      .nullable()
      .min(150)
      .max(600)
      .trim()
      .label("Short bio"),
  })
  .required();

const AdditionalInfo = () => {
  const { user, updateUser } = useAppContext();
  const { data, isLoading } = useCurrentBuyer();
  const navigate = useNavigate();

  const formHook = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  const handleNext = (values: any) => {
    values["linkedin_url"] = values["social_media"];
    BuyerService.PatchBuyer({ ...values });

    updateUser({ ...user, buyerFull: { ...user.buyerFull, ...values } });
    navigate(RoutePaths.REGISTER_ACQUIRER + "/pro-buyer-interests");
  };

  const [buyerInfoVisible, setBuyerInfoVisible] = useState(false);
  const toggleBuyerInfoVisible = () => setBuyerInfoVisible(!buyerInfoVisible);

  useEffect(() => {
    if (data && !isLoading) {
      formHook.reset({ ...data });
    }
  }, [data, isLoading]);

  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <Container maxWidth="md" sx={{ padding: 4 }}>
        <PublicBanner />
        <Paper elevation={2} sx={{ marginTop: 4, padding: 3 }}>
          <HookedForm formHook={formHook} onSubmit={handleNext}>
            <Typography variant="h3" sx={{ marginBottom: 1 }}>
              Social Media Link
            </Typography>
            <HookedField
              component={TextField}
              fullWidth
              label="LinkedIn or Social Media Link"
              name="social_media"
              variant="filled"
            />

            <Typography variant="h3" sx={{ marginTop: 2, marginBottom: 1 }}>
              Your opportunity to tell sellers a bit more about yourself.
              Outline your search criteria and any relevant experience.
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: 2, color: "common.gray" }}
            ></Typography>
            <HookedField
              hiddenLabel
              fullWidth
              component={TextField}
              name="description"
              multiline
              maxCharacters={600}
              minCharacters={150}
              characterCounter
              variant="filled"
              minRows={8}
              helperText="All Bios must be anonymous. The Foundy team reserves the right to remove personal or company identity from submissions."
              placeholder="I represent the Corporate Development team at a large tech business. We specialize in acquiring profitable SaaS businesses in the UK. We have a proven track record of success in the industry."
            />
          </HookedForm>
          <Stack
            onClick={toggleBuyerInfoVisible}
            sx={{ color: "common.gray", cursor: "pointer" }}
            alignItems="center"
            direction="row"
            gap={2}
            marginTop={2}
          >
            <InfoIcon />
            <Typography variant="body2">
              Learn more about successful buyer bios
            </Typography>
          </Stack>
        </Paper>

        <ProfileNavigationButtons
          onSaveAndContinue={formHook.handleSubmit(handleNext)}
          onBack={() =>
            navigate(RoutePaths.REGISTER_ACQUIRER + "/basic-completed")
          }
        />
      </Container>
      <ProBuyerAdditionalInfoDrawer
        open={buyerInfoVisible}
        handleDialog={toggleBuyerInfoVisible}
      />
    </Box>
  );
};

export default AdditionalInfo;
