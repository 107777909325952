import { BuyerFields, CompanyFields } from "../../v2/consts/consts";
import { BUYER_PUBLIC_KEYS, STARTUP_INTRO_KEYS } from "../constants";
import { IBuyer, ISeller, IStartup, IUserFull } from "../models";
import { getObjFields } from "../utils";
import { getFillingCompletion, getFillingPercent } from "../utils/formUtils";

export const FillingPercentsService = {
  GetPercentage: (
    user: IUserFull,
    startupId: number | null,
    fields: string[],
  ) => {
    let objectToSearch;
    if (startupId) {
      objectToSearch = user.startups?.find(
        (startup) => startup.id === startupId,
      );
      if (!objectToSearch) {
        return 0;
        // Should really throw an error, but too  many pages doing stupid stuff :/
        // throw Error("Percentage for company requested but company not found.")
      }
    } else {
      objectToSearch = user;
    }
    return getFillingCompletion(objectToSearch, fields);
  },
  GetPercentageCompany: (company: any) => {
    return getFillingCompletion(company, CompanyFields);
  },
  GetPercentageBuyer: (buyer: any) => {
    return getFillingCompletion(buyer, BuyerFields);
  },
  BuyerInfo: (buyer: IBuyer) => {
    const excludedFields: Array<keyof IBuyer> = [
      "phone_number",
      "image",
      "active_subscription",
      "title",
      "linkedin_url",
      "acquisitions_previously",
      "acquisitions_projected",
      "headline",
      "investments_projected",
      "location",
      "maximum_revenge_range",
      "minimum_revenge_range",
      "keywords",
    ];

    if (!buyer.company_name) {
      excludedFields.push("company_name");
    }

    return getFillingPercent(buyer, [...excludedFields, "bio"]);
  },

  BuyerPrivateInfo: (buyer: IBuyer) => {
    const excludedFields: Array<keyof IBuyer> = [
      ...BUYER_PUBLIC_KEYS,
      "phone_number",
      "image",
      "active_subscription",
      "title",
    ];

    if (!buyer.company_name) {
      excludedFields.push("company_name");
    }
    return getFillingPercent(buyer, [...excludedFields, "bio"]);
  },

  StartupPublicInfo: (startup: IStartup) => {
    if (!startup) return 0;
    const excludeFields: Array<string> = [
      ...STARTUP_INTRO_KEYS,
      "adjective",
      "service_area",
      "finished_at",
      "files",
      "chat_auto_approve",
      "annual_recurring_revenue",
      "age",
      "accepted_terms",
      "updated_at",
      "under_offer",
      "status",
      "published",
      "is_validated",
      "id",
      "created_at",
      "has_financial_summary",
      "open_to_offer",
      "custom_highlights",
      "seller",
      "last_month_revenue",
      "last_month_profit",
      "company_registration_number",
      "country_of_incorporation",
      "registering_body",
      "registering_url",
      "highlights",
      "number_of_customers",
      "reason",
      "keywords",
    ];

    if (startup.open_to_offer) {
      excludeFields.push("asking_price");
    }

    if (!startup.old_key_assets) {
      excludeFields.push("old_key_assets");
    }
    return getFillingPercent(startup, excludeFields);
  },

  StartupPrivateInfo: (startup?: IStartup) => {
    if (!startup) {
      return 100;
    }

    const excludedFields: Array<keyof IStartup> = [...STARTUP_INTRO_KEYS];
    return getFillingPercent(getObjFields(startup, excludedFields));
  },

  Seller: (v: ISeller) => {
    return getFillingPercent(v);
  },
};
