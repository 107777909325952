import { Box, Button, Divider, Typography } from "@mui/material";
import { abbreviateNumber } from "../../../common/utils";
import { useNavigate } from "react-router-dom";
import { useStartupTypes } from "../../../common/hooks";
import { IStartup, IStartupType } from "../../../common/models";
import { ChatMateType, SBCustomMember } from "../../containers/Messages";

interface ChatUserPopoverProps {
  otherUserSendbirdInfo?: SBCustomMember;
  startUp?: IStartup;
  otherUser?: ChatMateType;
}

export const CharUserPopover = ({
  otherUserSendbirdInfo,
  otherUser,
  startUp,
}: ChatUserPopoverProps) => {
  const { data: startupTypes } = useStartupTypes();
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h3" sx={{ fontWeight: 600 }}>
          {otherUserSendbirdInfo?.nickname}
          {startUp && ` - Startup ${startUp.id}`}
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        {otherUserSendbirdInfo?.metaData.user_type === "seller" && (
          <>
            <Typography variant="h3">
              {startUp?.business_type && (
                <span>{`${
                  startupTypes?.find(
                    (x: IStartupType) =>
                      x.id === Number(startUp?.business_type),
                  )?.name
                } ${startUp?.industry ? `- ${startUp?.industry}` : ""}`}</span>
              )}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: 2, fontWeight: 600 }}>
              Description
            </Typography>
            <Typography variant="body2" fontWeight={400}>
              {startUp?.foundy_says ?? startUp?.description}
            </Typography>
          </>
        )}
        {otherUser?.user_type !== "seller" && (
          <>
            <Typography variant="h3">
              <span>{otherUser?.role}</span>
            </Typography>
            {otherUser?.valuation && (
              <Typography variant="body2">
                {`£ ${abbreviateNumber(Number(otherUser.valuation))}`}
              </Typography>
            )}
            {otherUser?.bio && (
              <>
                <Typography
                  variant="body2"
                  sx={{ marginTop: 2, fontWeight: 600 }}
                >
                  Bio
                </Typography>
                <Typography variant="body2" fontWeight={400}>
                  {otherUser.description}
                </Typography>
              </>
            )}
          </>
        )}

        <Button
          onClick={() =>
            navigate(
              `/${
                otherUserSendbirdInfo?.metaData.user_type === "seller"
                  ? `company/${startUp?.id}`
                  : `buyer/${otherUser?.id}`
              }`,
            )
          }
          variant="roundgradient"
          sx={{ marginTop: 2 }}
        >
          View Page
        </Button>
      </Box>
    </>
  );
};
