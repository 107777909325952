import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ModalProps extends DialogProps {
  onCancel: (event: any) => void;
}

export const SellerStartModal = (props: ModalProps) => {
  return (
    <Dialog fullWidth maxWidth="lg" {...props} scroll="body">
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          width: "100%",
          positon: "relative",
        }}
      >
        <Box
          sx={{ cursor: "pointer", position: "absolute", top: 15 }}
          onClick={(e) => props.onCancel(e)}
        >
          <CloseIcon />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography
          textAlign="center"
          variant="h2"
          fontSize={18}
          marginBottom={1}
          color="rgba(120, 21, 218, 1)"
        >
          <span>You have successfully registered your account!</span> 🎉
          <br />
          <br />
          You now have the opportunity to create your public profile. <br />
          <br />
          Please ensure you have read and understood the following important
          information.
          <br />
        </Typography>
        <Container maxWidth="md">
          <Box
            component="ul"
            sx={{ padding: 0, marginTop: 5, "& li": { marginBottom: 2 } }}
          >
            <Box component="li" sx={{ fontWeight: 500 }}>
              <b>
                All listings are anonymous to protect your company information
              </b>{" "}
              - Please ensure your public listing does not contain any
              information that could reveal your personal or company identity.
              You can choose to disclose these details to buyers at a later
              stage. Foundy reserves the right to reject applications that
              aren&apos;t anonymous.
            </Box>

            <Box component="li" sx={{ fontWeight: 500 }}>
              <b>
                All businesses must have generated £10,000 revenue in the last
                twelve months
              </b>{" "}
              - Foundy is best positioned to support businesses generating a
              minimum of £10k in annual revenue. Applications below this mark
              will still be considered and may be approved if they demonstrate
              exceptional growth potential or significant assets.
            </Box>
          </Box>
          <Box sx={{ textAlign: "center", marginTop: 3 }}>
            <Button variant="roundgradient" onClick={(e) => props.onCancel(e)}>
              Continue
            </Button>
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
};
