interface SVGIconProps {
  color?: string;
}
export const PieIcon = ({ color = "black" }: SVGIconProps) => (
  <svg
    height="100%"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.28516 14.126C8.10547 14.126 8.89844 13.9847 9.66406 13.7021C10.4297 13.4242 11.127 13.0277 11.7559 12.5127L6.78613 7.64551C6.71322 7.58171 6.66309 7.51562 6.63574 7.44727C6.61296 7.37435 6.60156 7.28548 6.60156 7.18066V0.0371094C5.70833 0.128255 4.87663 0.374349 4.10645 0.775391C3.33626 1.17188 2.65951 1.68913 2.07617 2.32715C1.4974 2.96061 1.04395 3.68294 0.71582 4.49414C0.387695 5.30078 0.223633 6.15755 0.223633 7.06445C0.223633 8.0306 0.408203 8.94206 0.777344 9.79883C1.14648 10.651 1.65462 11.4007 2.30176 12.0479C2.95345 12.6995 3.7054 13.2077 4.55762 13.5723C5.40983 13.9414 6.31901 14.126 7.28516 14.126ZM14.3535 7.06445C14.3535 6.56315 14.2965 6.06413 14.1826 5.56738C14.0732 5.06608 13.916 4.60124 13.7109 4.17285L8.13965 7.37207L12.5693 11.7266C13.1208 11.1068 13.556 10.3936 13.875 9.58691C14.194 8.78027 14.3535 7.93945 14.3535 7.06445ZM7.68848 6.36035L13.1846 3.20898C12.7835 2.59831 12.2936 2.06055 11.7148 1.5957C11.1361 1.1263 10.5026 0.757161 9.81445 0.488281C9.1263 0.214844 8.41764 0.0576172 7.68848 0.0166016V6.36035Z"
      fill={
        color
          ? color === "bluegradient"
            ? "url(#paint0_linear_586_2224)"
            : color === "purplegradient"
              ? "url(#paint0_linear_586_2224)"
              : color
          : "black"
      }
    />
    <defs>
      {color === "purplegradient" && (
        <linearGradient
          id="paint0_linear_586_2224"
          x1="0.2"
          y1="-4"
          x2="26.6022"
          y2="2.83473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C72A8E" />
          <stop offset="1" stopColor="#7815DA" />
        </linearGradient>
      )}
      {color === "bluegradient" && (
        <linearGradient
          id="paint0_linear_586_2224"
          x1="-7.89109"
          y1="-5"
          x2="26.1628"
          y2="0.975647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E8EF2" />
          <stop offset="1" stopColor="#6911E7" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
