import React, { ReactNode, useMemo, useEffect } from "react";
import { SchemaOf } from "yup";
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
} from "react-hook-form";
import { Mode, UseFormReturn } from "react-hook-form/dist/types/form";
import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "lodash";

interface FormProps<T extends FieldValues> {
  onSubmit?: SubmitHandler<T>;
  onChange?: (v: T, name?: string) => void;
  schema?: SchemaOf<T>;
  defaultValues?: UnpackNestedValue<DeepPartial<T>>;
  children: ReactNode;
  formHook?: UseFormReturn<T>;
  style?: React.CSSProperties;
  mode?: Mode;
  changeDebounce?: number;
  id?: string;
}

export const HookedForm = <T extends FieldValues>({
  id,
  formHook,
  onSubmit = () => null,
  onChange,
  schema,
  defaultValues,
  children,
  mode = "onSubmit",
  style,
  changeDebounce,
}: FormProps<T>) => {
  const localHook = useForm<T>({
    // @ts-ignore
    defaultValues,
    // @ts-ignore
    resolver: schema && yupResolver(schema),
    mode,
  });
  const hook = formHook || localHook;

  const handleChange = useMemo(() => {
    if (!onChange) return;
    if (!changeDebounce) return onChange;
    return debounce(onChange, changeDebounce);
  }, [changeDebounce, onChange]);

  useEffect(() => {
    if (!handleChange) return;
    const subscription = hook.watch((v, { name }) =>
      handleChange(v as any, name),
    );
    return () => subscription.unsubscribe();
  }, [hook.watch, handleChange]);

  return (
    <FormProvider<T> {...hook}>
      <form id={id} onSubmit={hook.handleSubmit(onSubmit)} style={style}>
        {children}
      </form>
    </FormProvider>
  );
};
