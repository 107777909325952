import { forwardRef, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { InputAdornment, TextField } from "@mui/material";
import NumberFormat, { InputAttributes } from "react-number-format";

interface HookedNumberFieldProps {
  label: string;
  name: string;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  min?: number;
}

const NumberFormatCustom = forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, min, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

export const HookedNumberField = ({
  label,
  name,
  ...props
}: HookedNumberFieldProps | any) => {
  const {
    register,
    setValue,
    getFieldState,
    watch,
    formState: { errors, isDirty }, // eslint-disable-line @typescript-eslint/no-unused-vars
  } = useFormContext();

  const watchValue = watch(name);
  const { error } = getFieldState(name);

  const value: any = useMemo(() => watchValue || null, [watchValue]);

  useEffect(() => {
    register(name);
  }, [register, name]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(name, Number(event.target.value), {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [name, setValue],
  );

  return (
    <TextField
      label={label}
      value={value}
      onChange={handleChange}
      name={name}
      variant="filled"
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        ...(props.currency && {
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }),
      }}
      error={!!error}
      helperText={props.helperText || error?.message}
      {...props}
      currency=""
    />
  );
};
