interface SVGIconProps {
  color?: string;
}
export const MessageIcon = ({ color = "black" }: SVGIconProps) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7734 17.125C13.013 17.125 13.2005 17.0443 13.3359 16.8828C13.4766 16.7266 13.5469 16.513 13.5469 16.2422V13.9844H14.3906C15.0156 13.9844 15.5573 13.862 16.0156 13.6172C16.474 13.3672 16.8255 13.013 17.0703 12.5547C17.3203 12.0911 17.4453 11.5417 17.4453 10.9062V4.03906C17.4401 3.0651 17.1562 2.3099 16.5938 1.77344C16.0312 1.23177 15.2656 0.960938 14.2969 0.960938H3.6875C2.69271 0.960938 1.91927 1.23177 1.36719 1.77344C0.820312 2.3099 0.546875 3.0651 0.546875 4.03906V10.9062C0.546875 11.8698 0.820312 12.625 1.36719 13.1719C1.91927 13.7135 2.69271 13.9844 3.6875 13.9844H8.84375L11.7578 16.6016C11.9609 16.7839 12.138 16.9167 12.2891 17C12.4401 17.0833 12.6016 17.125 12.7734 17.125Z"
      fill={
        color
          ? color === "bluegradient"
            ? "url(#paint0_linear_586_2224)"
            : color === "purplegradient"
              ? "url(#paint0_linear_586_2224)"
              : color
          : "black"
      }
    />
  </svg>
);
