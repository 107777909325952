import { SVGProps } from "react";
interface IconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}
export const Marketplace = ({ width, height = "100%" }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 87 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.3501 47.6134C38.5694 47.6134 33.9845 45.7142 30.604 42.3338C27.2235 38.9533 25.3244 34.3684 25.3244 29.5876H32.5347C32.5347 32.4561 33.6742 35.207 35.7025 37.2353C37.7307 39.2636 40.4817 40.4031 43.3501 40.4031C46.2186 40.4031 48.9695 39.2636 50.9978 37.2353C53.0261 35.207 54.1656 32.4561 54.1656 29.5876H61.3759C61.3759 34.3684 59.4767 38.9533 56.0962 42.3338C52.7158 45.7142 48.1309 47.6134 43.3501 47.6134ZM43.3501 11.5619C46.2186 11.5619 48.9695 12.7014 50.9978 14.7297C53.0261 16.758 54.1656 19.5089 54.1656 22.3774H32.5347C32.5347 19.5089 33.6742 16.758 35.7025 14.7297C37.7307 12.7014 40.4817 11.5619 43.3501 11.5619ZM68.5862 22.3774H61.3759C61.3759 20.0102 60.9096 17.6662 60.0037 15.4792C59.0979 13.2922 57.7701 11.3051 56.0962 9.63124C54.4224 7.95739 52.4353 6.62963 50.2483 5.72375C48.0613 4.81787 45.7173 4.35162 43.3501 4.35162C38.5694 4.35162 33.9845 6.25076 30.604 9.63124C27.2235 13.0117 25.3244 17.5966 25.3244 22.3774H18.1141C14.1124 22.3774 10.9038 25.5859 10.9038 29.5876V72.8494C10.9038 74.7617 11.6635 76.5957 13.0157 77.9479C14.3678 79.3 16.2018 80.0597 18.1141 80.0597H68.5862C70.4984 80.0597 72.3324 79.3 73.6846 77.9479C75.0368 76.5957 75.7964 74.7617 75.7964 72.8494V29.5876C75.7964 27.6754 75.0368 25.8414 73.6846 24.4892C72.3324 23.137 70.4984 22.3774 68.5862 22.3774Z"
      fill="url(#paint0_linear_2497_5107)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2497_5107"
        x1="14.1484"
        y1="4.35162"
        x2="86.746"
        y2="20.4602"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C72A8E" />
        <stop offset="1" stopColor="#7815DA" />
      </linearGradient>
    </defs>
  </svg>
);
