import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { HookedForm } from "../HookedForm";
import { HookedField } from "../HookedField";
import {
  useGrowthOpportunity,
  useKeyAssets,
  usePatchStartup,
  useStartupBySeller,
  useStartupTypes,
} from "../../../common/hooks";
import { useForm } from "react-hook-form";
import { FoundyLoading } from "../FoundyLoading";
import { useEffect } from "react";
import { useState } from "react";
import { FillingPercentsService } from "../../../common/services/FillingPercentsService";
import { HookedRadioGroup } from "../HookedRadioGroup";
import { HookedCheckbox } from "../HookedCheckbox";
import { IStartup } from "../../../common/models";
import { IBusinessModel } from "../../../common/models";
import countries from "../../../common/assets/countries.json";
import { HookedNumberField } from "../HookedNumberField";
import { AppService } from "../../../common/services/AppService";

interface ModalProps extends DialogProps {
  userId: number;
  onCancel: (event?: any) => void;
}

export const EditSellerModal = (props: ModalProps) => {
  const formHook = useForm({ mode: "all" });
  const { userId, ...rest } = props;
  const { data: startupType } = useStartupTypes();
  const { data: growthOpportunity } = useGrowthOpportunity();
  const { data: keyAssets } = useKeyAssets();
  const { data: startup, isLoading: startupInfoLoading } =
    useStartupBySeller(userId);
  const [businessModels, setBusinessModels] = useState<IBusinessModel[]>([]);
  const patchStartup = usePatchStartup(userId);

  useEffect(() => {
    if (!startupInfoLoading && startup) {
      const fetchBusinessModelData = async () => {
        const returnedBusinessModels = await AppService.GetBusinessModels({
          id: String(startup.id),
        });
        setBusinessModels(returnedBusinessModels);
      };
      fetchBusinessModelData();
      formHook.reset({ ...startup });
    }
  }, [startup, startupInfoLoading]);

  useEffect(() => {
    const subscription = formHook.watch(() => undefined);
    return () => subscription.unsubscribe();
  }, [formHook.formState.dirtyFields]);

  const handleChange = (values: Partial<IStartup>) => {
    if (!startup?.id) return;
    const changedFields = formHook.formState.dirtyFields;
    if (!Object.keys(changedFields).length) return;

    const formValues: Partial<IStartup> = {};

    Object.keys(changedFields).forEach((x: string) => {
      if (x === "business_model" || x === "growth_opportunity") {
        formValues[x] = values[x]?.toString();
      } else {
        // @ts-ignore
        formValues[x] = values[x];
      }
    });

    patchStartup.mutate({
      id: startup?.id,
      values: { ...formValues },
    });

    rest.onCancel();
  };

  return (
    <Dialog fullWidth maxWidth="md" {...rest} scroll="body">
      <Box sx={{ padding: 5, position: "relative" }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h2" marginBottom={2}>
            {startup?.name}
          </Typography>
          <Typography variant="h3">
            <span style={{ marginRight: 15 }}>Completed</span>
            {FillingPercentsService.GetPercentageCompany(startup)}%
          </Typography>
        </Stack>
        {startupInfoLoading && <FoundyLoading width="32" height="64" />}

        {!startupInfoLoading && (
          <HookedForm<any> formHook={formHook} onSubmit={handleChange}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <HookedField
                label="Name"
                variant="filled"
                name="name"
                component={TextField}
              />
              <HookedRadioGroup
                label="Business Type"
                name="business_type"
                options={startupType?.map((x) => ({
                  label: x.name || "",
                  value: String(x.id),
                }))}
              />
              <HookedCheckbox
                simplified
                question="Revenue Streams"
                name="business_models"
                options={businessModels?.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
              />
              <HookedField
                select
                defaultValue="GB"
                component={TextField}
                fullWidth
                label="Where are you located?"
                name="location"
                variant="filled"
              >
                {countries.map((x) => (
                  <MenuItem key={x.code} value={x.code}>
                    <ListItemText sx={{ display: "inline-flex", margin: 0 }}>
                      {x.name}
                    </ListItemText>
                  </MenuItem>
                ))}
              </HookedField>
              <HookedField
                label="Reason for Selling"
                variant="filled"
                name="reason"
                component={TextField}
              />
              <HookedField
                label="Description"
                variant="filled"
                name="description"
                minRows={3}
                component={TextField}
              />

              <HookedField
                label="Website URL"
                variant="filled"
                name="website_url"
                component={TextField}
              />
              <HookedCheckbox
                simplified
                question="Growth Opportunity"
                name="growth_opportunity"
                options={growthOpportunity?.map((x: any) => ({
                  value: x.value,
                  label: x.display_name,
                }))}
              />
              <HookedCheckbox
                simplified
                question="Key Assets"
                name="key_assets_new"
                options={keyAssets?.map((x) => ({
                  value: x.value,
                  label: x.display_name,
                }))}
              />
              <HookedRadioGroup
                label="Founder Want to Stay Post Acquisition?"
                name="founders_post_acquisition"
                options={[
                  { label: "Yes", value: "YES" },
                  { label: "No", value: "NO" },
                  { label: "Possibly", value: "POSSIBLY" },
                ]}
              />

              <Stack direction="row" gap={2}>
                <HookedNumberField
                  label="Annual profit (EBITDA)"
                  name="annual_profit"
                  variant="filled"
                  currency
                />
                <HookedNumberField
                  label="Annual revenue"
                  name="annual_revenue"
                  variant="filled"
                  currency
                />
              </Stack>

              <Stack direction="row" gap={2}>
                <HookedNumberField
                  label="Average monthly net profit"
                  name="avg_net_profit_1m"
                  variant="filled"
                  currency
                />
                <HookedNumberField
                  label="Average monthly net costs"
                  name="avg_net_cost_1m"
                  variant="filled"
                  currency
                />

                <HookedNumberField
                  label="Average monthly net rev."
                  name="avg_net_revenue_1m"
                  variant="filled"
                  currency
                />
              </Stack>
              <Stack direction="row" gap={2}>
                <HookedNumberField
                  sx={{ marginBottom: 2 }}
                  label="Asking Price"
                  fullWidth
                  variant="filled"
                  name="asking_price"
                  currency
                />
                <HookedNumberField
                  label="Asking Price (Accept)"
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  variant="filled"
                  name="asking_price_accept"
                  currency
                />

                <HookedNumberField
                  label="Asking Price (Reject)"
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  variant="filled"
                  name="asking_price_reject"
                  currency
                />
              </Stack>
              <HookedField
                label="Reason for Asking Price"
                variant="filled"
                name="reason_for_ask_price"
                component={TextField}
              />
            </Box>
            <DialogActions>
              <Button type="submit" variant="roundgradient">
                Save
              </Button>
              <Button
                onClick={(e) => rest.onCancel(e)}
                variant="roundsecondary"
                sx={{ border: 0, "&:hover": { border: 0 } }}
                color="error"
              >
                Cancel
              </Button>
            </DialogActions>
          </HookedForm>
        )}
      </Box>
    </Dialog>
  );
};
