import * as prismic from "@prismicio/client";
import {
  IAcquisitionsProjected,
  IBusinessModelChoices,
  IBusinessModel,
  IGrowthOpportunity,
  IHearAbout,
  IIndustryChoices,
  IKeyAssets,
  ILocations,
  IPreviousAcquisitions,
  ITeamSize,
  IWeeklyCommitment,
  IBuyerRoles,
  IBuyer,
  IStartup,
} from "../models";
import { ApiService } from "./ApiService";
import { UserService } from "./UserService";

export const repositoryName = "foundy-cms";

export const prismicClient = prismic.createClient(repositoryName);

export const AppService = {
  Init: () => {
    return UserService.Init();
  },
  GetHearAbout: () => {
    return ApiService.GetPaginatedData<IHearAbout>("/common/hearaboutus/").then(
      (res) => {
        return res;
      },
    );
  },
  GetLocations: () => {
    return ApiService.Get<ILocations[]>("/v2/buyer-location-choices/").then(
      (res) => res,
    );
  },
  GetPreviousAcquisitions: () => {
    return ApiService.Get<IPreviousAcquisitions[]>(
      "/common/acquisitions-previously-choices/",
    ).then((res) => res);
  },
  GetAcquisitionsProjected: () => {
    return ApiService.Get<IAcquisitionsProjected[]>(
      "/common/acquisitions-projected-choices/",
    ).then((res) => res);
  },
  GetBusinessModels: ({ id }: { id: string }) => {
    return ApiService.GetPaginatedData<IBusinessModel>(
      `/v2/startups/${id}/business-models/`,
      { page_size: 100 },
    ).then((res) => res.results);
  },
  GetGrowthOpportunity: () => {
    return ApiService.Get<IGrowthOpportunity[]>(
      "/v2/startups/growth-opportunity-choices/",
    ).then((res) => res);
  },
  GetTeamSize: () => {
    return ApiService.Get<ITeamSize[]>("/v2/startups/team-size-choices/").then(
      (res) => res,
    );
  },
  GetKeyAssets: () => {
    return ApiService.Get<IKeyAssets[]>("/v2/startups/key-asset-choices/").then(
      (res) => res,
    );
  },
  GetWeeklyCommitment: () => {
    return ApiService.Get<IWeeklyCommitment[]>(
      "/v2/startups/weekly-commitment-choices/",
    ).then((res) => res);
  },
  GetIndustryChoices: () => {
    return ApiService.Get<IIndustryChoices[]>(
      "/v2/startups/industry-choices/",
    ).then((res) => res);
  },
  GetBusinessChoices: () => {
    return ApiService.Get<IBusinessModelChoices[]>(
      "/v2/startups/sell-timeline-choices/",
    ).then((res) => res);
  },
  GetBuyerRoles: () => {
    return ApiService.Get<IBuyerRoles[]>("/common/buyer-role-choices/").then(
      (res) => res,
    );
  },
  GetNewBuyers: (): Promise<IBuyer[]> => {
    return ApiService.Get("/v2/seller/new-buyers/").then((res) => res.results);
  },
  GetNewListings: (): Promise<IStartup[]> => {
    return ApiService.Get("/v2/buyer/recommended-startups/").then(
      (res) => res.results,
    );
  },
};
