import { useEffect, useState } from "react";
import { UserService } from "../../../common/services/UserService";
import { IGuideStep } from "../../../common/models";
import { useAppContext } from "../../../common/context/AppContext";
import { AppService } from "../../../common/services/AppService";

export const useYourAcquisitions = () => {
  const { user } = useAppContext();
  const [progress, setProgress] = useState<IGuideStep[]>([]);

  const getNewBuyers = async () => {
    return await AppService.GetNewBuyers();
  };

  const getNewListings = async () => {
    return await AppService.GetNewListings();
  };

  const addBuyerStep = async (step: number, value: boolean) => {
    await UserService.AddBuyerStep({
      buyer: user.buyerFull?.id as number,
      step,
      value,
    });
    getProgress();
  };

  const addSellerStep = async (step: number, value: boolean) => {
    await UserService.AddSellerStep({
      seller: user.sellerFull?.id as number,
      step,
      value,
    });
    getProgress();
  };

  const setBuyerStep = async (step: number, value: boolean) => {
    await UserService.SetBuyerStep({
      buyer: user.buyerFull?.id as number,
      step,
      value,
    });
    getProgress();
  };

  const setSellerStep = async (step: number, value: boolean) => {
    await UserService.SetSellerStep({
      seller: user.sellerFull?.id as number,
      step,
      value,
    });
    getProgress();
  };

  const getProgress = async () => {
    if (user.active_role === "seller") {
      await UserService.GetSellerStepProgress().then((res) => setProgress(res));
    } else {
      await UserService.GetBuyerStepProgress().then((res) => setProgress(res));
    }
  };

  useEffect(() => {
    getProgress();
  }, []);

  return {
    getNewBuyers,
    getNewListings,
    addBuyerStep,
    addSellerStep,
    setBuyerStep,
    setSellerStep,
    getProgress,
    progress,
  };
};
