import { Suspense } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import CacheBuster from "react-cache-buster";
import CssBaseline from "@mui/material/CssBaseline";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { IntercomProvider } from "react-use-intercom";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ModalProvider from "mui-modal-provider";
import { PrismicProvider } from "@prismicio/react";
import { AppProvider } from "./common/context/AppContext";
import { SnackbarProvider } from "notistack";
import { NotificationService } from "./common/services/NotificationService";
import { AppLoader } from "./common/components/AppLoader";
import NotificationDismissButton from "./common/components/NotificationDismissBtn/NotificationDismissButton";
import { AppRoutes } from "./v2/components/AppRoutes";
import packageInfo from "../package.json";
import { prismicClient } from "./common/services/AppService";
import { StartupProvider } from "./common/context/StartupContext";

const queryClient = new QueryClient();

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID as string,
};

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID as string;

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

TagManager.initialize(tagManagerArgs);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string);

hotjar.initialize(
  parseInt(process.env.REACT_APP_HOT_JAR_ID as string),
  parseInt(process.env.REACT_APP_HOT_JAR_SV as string),
);

export function App() {
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction}
      isVerboseMode={false}
    >
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          action={(key) => <NotificationDismissButton key={key} />}
          maxSnack={1}
          ref={NotificationService.Ref}
          autoHideDuration={3000}
        >
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <IntercomProvider autoBoot appId={INTERCOM_APP_ID}>
              <AppProvider>
                <PrismicProvider client={prismicClient}>
                  <StartupProvider>
                    <ModalProvider>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <AppRoutes />
                      </LocalizationProvider>
                    </ModalProvider>
                  </StartupProvider>
                </PrismicProvider>
              </AppProvider>
            </IntercomProvider>
          </GoogleOAuthProvider>
        </SnackbarProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </CacheBuster>
  );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function WrappedApp() {
  return (
    <Suspense fallback={<AppLoader />}>
      <App />
    </Suspense>
  );
}
