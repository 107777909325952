import { Box, Button, Container, Typography } from "@mui/material";
import YouTube, { YouTubeProps } from "react-youtube";
import ConfettiExplosion from "react-confetti-explosion";
import { useNavigate } from "react-router-dom";
import { UserCompletedIcon } from "../../../../common/assets/icons/UserCompleted";
import { useAppContext } from "../../../../common/context/AppContext";
import { RoutePaths } from "../../../consts/enums";
import { useEffect } from "react";
import { FillingPercentsService } from "../../../../common/services/FillingPercentsService";
import { EventName, trackEvent } from "../../../../common/utils/helpers";
import FundraisingBlock from "../FundraisingBlock";

const VideoID = "qy8If-phvSc";
const YouTubeVideo = () => {
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return <YouTube videoId={VideoID} opts={opts} onReady={onPlayerReady} />;
};

const BuyerCompleted = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    const percentage = FillingPercentsService.GetPercentageBuyer(
      user.buyerFull,
    );

    if (percentage === 100) {
      trackEvent(EventName.COMPLETED_BUYER);
    }
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100%",
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "common.blackBlue",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top right",
      }}
    >
      <ConfettiExplosion width={1600} particleCount={250} />
      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 10,
            marginBottom: 4,
            paddingX: 10,
            paddingY: 5,
            backgroundColor: "common.grayLighterOpacity",
            borderRadius: 2,
          }}
        >
          <UserCompletedIcon />
          <Typography
            sx={{
              marginTop: 3,
              marginBottom: 2,
              color: "white",
              fontWeight: 500,
              fontFamily: "Inter",
              fontSize: 24,
            }}
          >
            Your Professional Buyer profile has now been completed.
          </Typography>
          <Typography variant="body1" color="white" sx={{ marginBottom: 2 }}>
            Foundy’s AI-enabled deal management portal can help you save tens of
            thousands in fees and months in time sourcing and vetting sellers
            and completing the end-to-end process. Watch this short video to
            learn more:
          </Typography>
          <YouTubeVideo />
          <Typography variant="body1" color="white" sx={{ marginTop: 2 }}>
            If you’re interested in using Foundy’s AI portal and receiving a
            tailored list of off-market sellers, please contact{" "}
            <a
              style={{ color: "white", fontWeight: "bold" }}
              href="mailto:joe@foundy.com"
              target="_blank"
              rel="noreferrer"
            >
              joe@foundy.com
            </a>{" "}
            or book a{" "}
            <a
              style={{ color: "white", fontWeight: "bold" }}
              href="https://calendly.com/joe-7yw/30min?back=1&month=2024-05"
              target="_blank"
              rel="noreferrer"
            >
              15-minute call with Joe here
            </a>
            .
          </Typography>
        </Box>
        <FundraisingBlock />
        <Box marginBottom={10}>
          <Button
            variant="roundgradient"
            onClick={() => navigate(RoutePaths.DASHBOARD)}
          >
            Continue
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default BuyerCompleted;
