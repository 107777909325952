import { Typography } from "@mui/material";
import { SideDialog } from "../../components/SideDialog";

const InvestorTypesInformation = ({
  handleDialog,
  open,
}: {
  handleDialog: () => void;
  open: boolean;
}) => (
  <SideDialog handleClose={handleDialog} open={open}>
    <Typography variant="h3" sx={{ marginBottom: 1 }}>
      Sophisticated Investor
    </Typography>
    <Typography variant="body2" sx={{ marginBottom: 3, color: "common.gray" }}>
      I am a self-certified sophisticated investor because at least one of the
      following applies:
      <br />
      Sophisticated Investor
      <br />
      (i.) I am working, or have worked in the two years prior, in a
      professional capacity in the provision of finance for small and medium
      enterprises; or in private equity sector;
      <br />
      <br />
      (ii) I am a member of a network or syndicate of business angels and have
      been so for at least the last six months prior;
      <br />
      <br />
      (iii) I have made more than one investment in an unlisted company, for
      example via a regulated crowdfunding platform, in the two years prior;
      <br />
      <br />
      (iv) I am currently, or have been in the two years prior, a director of a
      company with an annual turnover of at least £1 million.
    </Typography>
    <br />
    <br />
    <Typography variant="h3" sx={{ marginBottom: 1 }}>
      High Net Worth Investor
    </Typography>
    <Typography
      variant="body2"
      sx={{ marginBottom: 3, color: "common.gray", lineHeight: 1.5 }}
    >
      (i) I had, throughout the financial year immediately preceding, an annual
      income to the value of £100,000 or more;
      <br />
      <br />
      (ii) I held, throughout the financial year immediately preceding, net
      assets to the value of £250,000 or more.
      <br />
      <br />
      (iii) A large undertaking, where two of the following requirements must be
      met:
      <br />
      a. balance sheet total of EUR 20,000,000;
      <br />
      b. net turnover of EUR 40,000,000;
      <br />
      c. own funds of EUR 2,000,000
      <br />
      <br />
    </Typography>
    <Typography variant="h3" sx={{ marginBottom: 1 }}>
      Professional Investor
    </Typography>
    <Typography
      variant="body2"
      sx={{ marginBottom: 3, color: "common.gray", lineHeight: 1.5 }}
    >
      (i) I work on behalf an authorised or regulated financial institution;
      <br />
      <br />
      (ii) I work on behalf an investment firm;
      <br />
      <br />
      (iii) I work on behalf a credit institution;
      <br />
      <br />
      (iv) I work behalf of an insurance company;
      <br />
      <br />
      (v) I work on behalf of a collective investment scheme or the management
      company of such a scheme;
      <br />
      <br />
      (vi) I work on behalf of a pension fund or the management company of a
      pension fund;
      <br />
      <br />
      (vii) I work on behalf of a commodity or commodity derivatives dealer;
      <br />
      <br />
      (viii) I work on behalf of a local authority;
      <br />
      <br />
    </Typography>
    <Typography
      variant="body2"
      sx={{ marginTop: 4, color: "common.gray", lineHeight: 1.5 }}
    >
      The information within this website is issued by Foundy Ltd. to
      sophisticated investors who are professional clients or eligible
      counterparties under AIFMD. This website is not suitable for retail
      clients.
      <br />
      <br />
      Certain jurisdictions have restrictions imposed by law on the distribution
      of financial information and the sale of investment products. The contents
      of this website are not intended for distribution to, or use by, any
      individual or entity in any jurisdiction where their distribution, or use,
      would be contrary to local law or regulations or which would subject
      Foundy Ltd. to register with that jurisdiction. It is your responsibility
      to acquaint yourself with, and observe, the laws and regulations of your
      jurisdiction.
      <br />
      <br />
      This website is published solely for informational purposes. No
      information or opinions contained in this website constitute a
      solicitation or offer to buy, or sell, securities or to furnish any
      financial, investment or other professional advice or service in any way.
      The contents of this website alone should not be relied upon to support
      any investment decision. Investments can involve significant risk. Past
      performance should not be seen as a guide to future performance. The value
      of investments can decrease as well as increase. Any investments you make
      can result in financial loss.
      <br />
      <br />
    </Typography>
  </SideDialog>
);

export default InvestorTypesInformation;
