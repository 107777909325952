import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  useBusinessModels,
  useCompany,
  useHandlePageTraversal,
} from "../../../../common/hooks";
import { HookedForm } from "../../../components/HookedForm";
import { RoutePaths } from "../../../consts/enums";
import ProfileNavigationButtons from "../ProfileNavigationButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import PublicBanner from "../PublicBanner";
import { HookedCheckbox } from "../../../components/HookedCheckbox";
import { HookedNumberField } from "../../../components/HookedNumberField";
import WelcomeSellerModal from "./WelcomeSellerModal";

const schema = yup
  .object({
    business_models: yup
      .array()
      .min(1)
      .nullable()
      .required()
      .label("Revenue streams"),
    annual_profit: yup.number().nullable().required().label("LTM EBITDA"),
    annual_revenue: yup.number().nullable().required().label("LTM Revenue"),
    cvl: yup.number().nullable().required().label("Customer Lifespan Value"),
    metric_notification: yup.boolean().nullable().label("Metrics integration"),
  })
  .required();

const FinancialInformation = () => {
  const { id: startupId } = useParams();
  if (startupId === undefined) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  const navigate = useNavigate();

  const { data: businessModels, isLoading: loadingBusiness } =
    useBusinessModels(startupId);
  const { data, isLoading, isError } = useCompany(startupId);

  const formHook = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  const { handleNext } = useHandlePageTraversal(formHook, Number(startupId));

  useEffect(() => {
    if (data && !isLoading) {
      formHook.reset({ ...data });
    }
  }, [data, isLoading]);

  if (isError) {
    return <Navigate to="/dashboard" replace={true} />;
  }
  if (!data) return <CircularProgress />;

  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundImage: "url(/images/BiggerGradient.png)",
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <WelcomeSellerModal />
      <Container maxWidth="md" sx={{ padding: 4 }}>
        <PublicBanner />
        <Typography variant="h3" sx={{ marginTop: 3, color: "white" }}>
          Complete your Financial information - this process takes 3 minutes
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "common.grayOpacity" }}>
          Please input your latest financials. Users that integrate their
          metrics for accurate, up to date figures, are up to 3x likelier to
          receive engagement from buyers.
        </Typography>
        <Paper elevation={2} sx={{ marginTop: 4, padding: 3 }}>
          <HookedForm formHook={formHook}>
            <Typography variant="h3" sx={{ marginBottom: 1 }}>
              Metrics integration
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "common.gray", marginBottom: 2 }}
            >
              Would you like to integrate your metrics?
            </Typography>
            <FormControlLabel
              name="metric_notification"
              sx={{
                "&.MuiFormControlLabel-root": {
                  backgroundColor: "common.lightgray",
                  padding: 2,
                  marginLeft: 0,
                  borderRadius: 2,
                  width: "100%",
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,

                    "&.MuiCheckbox-root": {
                      color: "black",
                    },
                    "&.MuiTypography-root": {
                      color: "common.gray",
                    },
                  },
                },
              }}
              control={
                <Controller
                  name="metric_notification"
                  control={formHook.control}
                  defaultValue={false}
                  render={({ field: { onChange } }) => (
                    <Checkbox
                      name="metric_notification"
                      checked={formHook.watch("metric_notification") || false}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={
                <>
                  <Typography
                    variant="body1"
                    fontSize={14}
                    color="common.black"
                  >
                    I would like to integrate my metrics. Please notify me when
                    I am able to do so.
                  </Typography>
                </>
              }
            />

            <Typography variant="h3" sx={{ marginTop: 3, marginBottom: 1 }}>
              What is your EBITDA and revenue for the most recent financial
              year?
            </Typography>
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              marginBottom={2}
            >
              <Typography
                variant="body2"
                fontWeight={600}
                sx={{ width: "100%" }}
                marginBottom={2}
              >
                LTM Revenue
              </Typography>
              <HookedNumberField
                currency
                variant="outlined"
                name="annual_revenue"
              />
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              marginBottom={2}
            >
              <Typography
                variant="body2"
                fontWeight={600}
                sx={{ width: "100%" }}
              >
                LTM EBITDA
              </Typography>
              <HookedNumberField
                currency
                variant="outlined"
                name="annual_profit"
              />
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              marginBottom={2}
            >
              <Typography
                variant="body2"
                fontWeight={600}
                sx={{ width: "100%" }}
              >
                CLV - Customer Value x Average Customer Lifespan
              </Typography>
              <HookedNumberField currency variant="outlined" name="cvl" />
            </Stack>
            <Typography variant="h3" sx={{ marginTop: 3, marginBottom: 1 }}>
              How do you generate revenue?
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "common.gray", marginBottom: 2 }}
            >
              Please select revenue streams.
            </Typography>
            <HookedCheckbox
              name="business_models"
              loading={loadingBusiness}
              options={businessModels?.map((x) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </HookedForm>
        </Paper>

        <ProfileNavigationButtons
          onBack={() =>
            navigate(RoutePaths.REGISTER_SELLER + `/${startupId}/business-info`)
          }
          onSaveAndContinue={formHook.handleSubmit(() =>
            handleNext(RoutePaths.REGISTER_SELLER + `/${startupId}/founder`),
          )}
        />
      </Container>
    </Box>
  );
};

export default FinancialInformation;
