import { Box } from "@mui/material";
import LogosInner from "./LogosInner";

type Props = {
  title?: string;
};

export const Logos = ({ title }: Props) => {
  const logos = [
    {
      id: "seedlegals",
      alt: "SeedLegals",
      image: "/images/logo-seedlegals-gray.png",
    },
    {
      id: "sifted",
      alt: "Tech Nation",
      image: "/images/logo-sifted-gray.png",
    },
    { id: "uktn", alt: "Tech Nation", image: "/images/logo-uktn-gray.png" },
    {
      id: "catapult-digital",
      alt: "Tech Nation",
      image: "/images/logo-catapult-digital-gray.png",
    },
    {
      id: "maddyness",
      alt: "Tech Nation",
      image: "/images/logo-maddyness-gray.png",
    },
    {
      id: "artificial",
      alt: "Artificial Lawyer",
      image: "/images/logo-artificial-lawyer-gray.png",
    },
    {
      id: "finance",
      alt: "Fintech Finance News",
      image: "/images/logo-fintech-finance-news-gray.png",
    },
  ];

  return (
    <Box
      sx={{
        position: { xs: "block", md: "absolute" },
        bottom: 15,
        left: 0,
        width: "100%",
        marginTop: { xs: 3, md: 0 },
        paddingX: { xs: 1, md: 8 },
      }}
    >
      <div className="space-y-10 lg:space-y-20">
        {title && (
          <div className="max-w-[880px] mx-auto text-center">
            <h2 className="heading-h2 text-gradient font-semibold">{title}</h2>
          </div>
        )}
        <LogosInner logos={logos} />
      </div>
    </Box>
  );
};
