import { useMediaQuery } from "@mui/material";

import {
  IPrismicImageObject,
  IStartup,
  IStartupType,
  ITeamSize,
} from "../../../common/models";

import { UserService } from "../../../common/services/UserService";
import { ClientQuery } from "../../../common/utils/query";
import { useMutation, useQueryClient } from "react-query";
import { useAppContext } from "../../../common/context/AppContext";

import { useStartupContext } from "../../../common/context/StartupContext";

import { MarketplaceItemMobile } from "./MarketplaceItemMobile";
import { MarketplaceItemDesktop } from "./MarketplaceItemDesktop";
import { useCallback } from "react";
import { useBusinessCategories, useFavourites } from "../../../common/hooks";

export const MarketplaceItem = ({
  headline,
  description,
  disableFavorite = false,
  disableNewListingGlow = true,
  startupTypes,
  teamSize,
  isHotListing = false,
  editLink = false,
  controlled = false,
  ...rest
}: IStartup & {
  disableFavorite?: boolean;
  disableNewListingGlow?: boolean;
  startupTypes?: IStartupType[];
  teamSize?: ITeamSize[];
  isHotListing?: boolean;
  controlled?: boolean;
  editLink?: boolean;
}) => {
  const { user } = useAppContext();
  const queryClient = useQueryClient();
  const matches = useMediaQuery("(min-width:1370px)");

  const { data: favouriteStartups } = useFavourites(user);

  const { categoryImages } = useStartupContext();
  const { data: buisnessCategories } = useBusinessCategories(String(rest.id));

  const favourite = useMutation(UserService.SaveFavourite, {
    onSuccess: () => {
      queryClient.invalidateQueries(ClientQuery.Favourites);
    },
  });
  const favouriteDelete = useMutation(UserService.DeleteFavourite, {
    onSuccess: () => {
      queryClient.invalidateQueries(ClientQuery.Favourites);
    },
  });

  const saveFavourite = (e: React.MouseEvent) => {
    e.preventDefault();
    const findFavourite = favouriteStartups?.results.find(
      (x: any) => x.startup === Number(rest.id),
    );

    if (findFavourite) {
      return favouriteDelete.mutateAsync(findFavourite.id);
    }
    return favourite.mutateAsync({ startup: Number(rest.id) });
  };

  const typeLabel = startupTypes?.find(
    (x) => x.id === Number(rest.business_type),
  );
  const industry = rest.industry
    ? rest.industry.toLocaleLowerCase().replace(/_/g, " ")
    : "";

  const isFavourite = favouriteStartups?.results.some(
    (x: { startup: number }) => x.startup === Number(rest.id),
  );

  const getBusinessCategoryLabel = useCallback(
    (businessCategoryId: number) => {
      if (buisnessCategories && rest.business_categories) {
        const category = buisnessCategories.find(
          (x) => x.id === businessCategoryId,
        );
        if (category) {
          return category.name;
        }
      }
      return "";
    },
    [buisnessCategories, rest],
  );

  const getCategoryImage = useCallback(() => {
    if (categoryImages.length) {
      const imageFound = categoryImages?.find(
        (x: IPrismicImageObject) =>
          rest.business_categories &&
          x.internalid === rest.business_categories[0],
      );
      if (imageFound) {
        return imageFound.image;
      } else {
        const imageFiller = categoryImages?.find(
          (x: IPrismicImageObject) =>
            rest.business_categories && x.internalid === 150,
        );
        if (imageFiller) {
          return imageFiller.image;
        }
      }
    }
    return null;
  }, [categoryImages]);

  return (
    <>
      {matches ? (
        <MarketplaceItemDesktop
          {...{
            headline,
            description,
            disableFavorite,
            disableNewListingGlow,
            startupTypes,
            teamSize,
            isHotListing,
            editLink,
            controlled,
            //
            user,
            saveFavourite,
            typeLabel,
            isFavourite,
            // linkTo,
            ...rest,
            industry,
            categoryImage: getCategoryImage(),
            getBusinessCategoryLabel: getBusinessCategoryLabel,
          }}
        />
      ) : (
        <MarketplaceItemMobile
          {...{
            headline,
            description,
            disableFavorite,
            disableNewListingGlow,
            startupTypes,
            teamSize,
            isHotListing,
            editLink,
            controlled,
            //
            user,
            typeLabel,
            isFavourite,
            saveFavourite,
            // linkTo,
            ...rest,
            industry,
            categoryImage: getCategoryImage(),
            getBusinessCategoryLabel: getBusinessCategoryLabel,
          }}
        />
      )}
    </>
  );
};
