import { Box, Button } from "@mui/material";

const ProfileNavigationButtons = ({
  onBack,
  onSaveAndContinue,
}: {
  onBack?: () => void;
  onSaveAndContinue?: () => void;
}) => (
  <Box
    sx={{
      textAlign: "right",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <Box>
      {onBack && (
        <Button onClick={onBack} sx={{ marginTop: 2 }} variant="roundsecondary">
          Back
        </Button>
      )}
    </Box>
    <Box>
      {onSaveAndContinue && (
        <Button
          onClick={onSaveAndContinue}
          sx={{ marginTop: 2 }}
          variant="roundgradient"
        >
          Save &amp; Continue
        </Button>
      )}
    </Box>
  </Box>
);

export default ProfileNavigationButtons;
