import { Dialog, DialogTitle, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface SideDialogProps {
  children: any;
  title?: string;
  handleClose?: () => void;
  open: boolean;
  width?: string;
}

export const SideDialog = ({
  width = "50vw",
  children,
  handleClose,
  open,
  title,
}: SideDialogProps) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          justifyContent: "right",
          "& .MuiPaper-root": {
            width: { xs: "70vw", md: width },
            padding: 3,
            fontFamily: "Inter",
          },
        },
      }}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      scroll="paper"
    >
      {title && (
        <DialogTitle
          sx={{
            paddingX: 0,
            paddingTop: 0,
            paddingBottom: 3,
            marginBottom: 2,
            fontWeight: 600,
            fontSize: 16,

            borderBottom: "1px solid",
            borderBottomColor: "common.lightgray",
          }}
        >
          {title}
        </DialogTitle>
      )}
      {children}
    </Dialog>
  );
};
