import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LockIcon from "@mui/icons-material/Lock";
import { HookedForm } from "../HookedForm";
import { TrolleyIcon } from "../../../common/assets/icons/TrolleyIcon";
import { isEqual } from "lodash";
import { HeartIcon, TargetIcon, WorkIcon } from "../../../common/assets/icons";
import { HookedSelect } from "../HookedSelect";
import {
  defaultFilter,
  fromFormToFilter,
} from "../../containers/Dashboard/components/Companies";
import { StartupSorting } from "../../../common/types";
import { startupSortItems } from "../../consts/consts";
import { isBasicPlan } from "../../../common/utils";
import { useEffect, useState } from "react";
import { css, keyframes } from "@emotion/react";

const SearchPlaceholderTexts = [
  "SaaS",
  "AI",
  "Ecommerce",
  "Health",
  "Profitable",
  "Content",
];
const SearchPlaceholderRotationInterval = 2500;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SearchBar = ({
  formHook,
  handleSearch,
}: {
  formHook: { register: (str: string) => any[] };
  handleSearch: () => void;
}) => {
  const [placeholder, setPlaceholder] = useState(SearchPlaceholderTexts[0]);
  useEffect(() => {
    let index = 1;
    const intervalId = setInterval(() => {
      setPlaceholder(SearchPlaceholderTexts[index]);
      index = (index + 1) % SearchPlaceholderTexts.length;
    }, SearchPlaceholderRotationInterval);

    return () => clearInterval(intervalId);
  }, []);

  const placeholderStyle = css`
    animation: ${fadeIn} 0.5s ease-in-out;
  `;
  return (
    <TextField
      {...formHook.register("search")}
      name="search"
      size="small"
      placeholder={placeholder}
      onKeyUp={(e) => {
        e.key === "Enter" && handleSearch();
      }}
      InputProps={{
        inputProps: {
          css: placeholderStyle,
        },
        startAdornment: (
          <SearchIcon
            sx={{
              width: 15,
              height: 15,
              color: "common.gray",
              marginRight: 1,
            }}
          />
        ),
      }}
      sx={{
        "& .MuiOutlinedInput-root.Mui-focused > .MuiOutlinedInput-notchedOutline":
          {
            border: "2px solid",
            borderColor: "#C72A8E !important",
            boxShadow: "0px 0px 10px rgba(203, 44, 138, 0.4) !important",
          },
        "& .MuiInputLabel-root.Mui-focused": {
          background:
            "linear-gradient(99.95deg, #C32993 -24.43%, #A15AF4 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        },
        height: 40,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
        flex: "2 0 auto",
        "& .MuiOutlinedInput-root": {
          background: "white",
          "& fieldset": {
            borderColor: "#F2F2F3",
            borderRadius: 2,
          },
        },
        border: "1px solid #F2F2F3",
        backgroundColor: "#FFFFFF",
        borderRadius: 2,
      }}
    />
  );
};

export const MarketplaceFilterComponent = ({
  formHook,
  handleSearch,
  handleClearFilter,
  handleShowFavourites,
  handleShowNewListings,
  showFavourites,
  showNewListings,
  filter,
  buyerListingsCountUnviewed,
  favourites,
  startupTypes,
  user,
  navigate,
}: any) => {
  const matches = useMediaQuery("(min-width:1370px)");
  return (
    <>
      {matches ? (
        <HookedForm id="dashboard-filters" formHook={formHook}>
          <Box
            sx={{
              marginY: 2,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}
          >
            <SearchBar formHook={formHook} handleSearch={handleSearch} />
            <Button
              variant="roundgradient"
              size="small"
              sx={{
                fontSize: { xs: 14, md: 16 },
              }}
              onClick={handleSearch}
            >
              Search
            </Button>
            <Button
              {...formHook.register("is_not_viewed")}
              variant="outlined"
              size="small"
              sx={{
                display: {
                  xs: buyerListingsCountUnviewed?.count ? "flex" : "none",
                  md: "flex",
                },
                background: "white",
                borderRadius: 2,
                fontSize: 14,
                color: "common.black",
              }}
              onClick={handleShowNewListings}
            >
              {!showNewListings ? (
                <>
                  <Box
                    sx={{
                      width: 15,
                      height: 15,
                      display: "flex",
                      alignItems: "center",
                      marginRight: 1,
                    }}
                  >
                    {!showNewListings && <TrolleyIcon color="black" />}
                  </Box>
                  New listings
                  {buyerListingsCountUnviewed?.count ? (
                    <Box
                      sx={{
                        marginLeft: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "common.lightgray",
                        fontSize: 12,
                        background: "#EC5962",
                        borderRadius: 2,
                        width: 24,
                        fontWeight: 700,
                        color: "common.white",
                      }}
                    >
                      {buyerListingsCountUnviewed?.count}
                    </Box>
                  ) : null}
                </>
              ) : (
                <>Back to Listings</>
              )}
            </Button>

            {!isEqual(filter, fromFormToFilter(defaultFilter)) && (
              <Button
                variant="contained"
                onClick={() => handleClearFilter()}
                sx={{
                  boxShadow: 0,
                  color: "common.black",
                  transition: "background 500ms ease-in-out",
                  "&:hover": {
                    color: "white",
                    background:
                      "linear-gradient(99.95deg, #EA336B -24.43%, #6911E7 100%)",
                    boxShadow: "inset 0px 2px 0px rgba(255, 255, 255, 0.1)",
                  },
                  flex: "1 0 0 auto",
                  border: "1px solid #F2F2F3",
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              >
                Clear Filters
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{
                display: { xs: "flex", md: "none" },
                padding: 1,
                background: "white",
                borderRadius: 2,
                fontSize: 14,
                justifyContent: "space-between",
                color: "common.black",
              }}
              onClick={() => handleShowFavourites()}
            >
              {!showFavourites ? (
                <>
                  <Box
                    sx={{
                      width: 15,
                      height: 15,
                      display: "flex",
                      alignItems: "center",
                      marginRight: 1,
                    }}
                  >
                    {!showFavourites && <HeartIcon color="black" />}
                  </Box>
                  Favourite listings
                  <Box
                    sx={{
                      marginLeft: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "common.lightgray",
                      fontSize: 12,
                      borderRadius: 2,
                      width: 24,
                      fontWeight: 700,
                      color: "common.gray",
                    }}
                  >
                    {favourites?.results?.length || 0}
                  </Box>
                </>
              ) : (
                <>Back to Listings</>
              )}
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              marginBottom: 2,
              columnGap: 2,
              rowGap: { xs: 2, md: 0 },
            }}
          >
            <HookedSelect
              className="hub-filter"
              {...formHook.register("business_type")}
              name="business_type"
              icon={WorkIcon}
              selection={formHook.watch("business_type")}
              values={startupTypes?.map((x: any) => ({
                label: x.name,
                value: x.id,
              }))}
              label="Categories"
              handleSearch={handleSearch}
            />

            <Button
              variant="outlined"
              size="small"
              sx={{
                display: { xs: "none", md: "flex" },
                background: "white",
                borderRadius: 2,
                border: "1px solid #F3F3F2",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                fontSize: 14,
                color: "common.black",
                "&:hover": {
                  borderColor: "black",
                },
              }}
              onClick={() => handleShowFavourites()}
            >
              {!showFavourites ? (
                <>
                  <Box
                    sx={{
                      width: 15,
                      height: 15,
                      display: "flex",
                      alignItems: "center",
                      marginRight: 1,
                    }}
                  >
                    {!showFavourites && <HeartIcon color="black" />}
                  </Box>
                  Favourite listings
                  <Box
                    sx={{
                      marginLeft: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "common.lightgray",
                      fontSize: 12,
                      borderRadius: 2,
                      width: 24,
                      fontWeight: 700,
                      color: "common.gray",
                    }}
                  >
                    {favourites?.results?.length || 0}
                  </Box>
                </>
              ) : (
                <>Back to Listings</>
              )}
            </Button>

            <HookedSelect
              className="hub-filter"
              name="ttm_revenue"
              icon={TargetIcon}
              selection={formHook.watch("ttm_revenue")}
              handleSearch={handleSearch}
              values={[
                {
                  value: 1,
                  label: "£10K to £50k",
                },
                {
                  value: 2,
                  label: "£50k to £250k",
                },
                {
                  value: 3,
                  label: "£250k to £1m",
                },
                {
                  value: 4,
                  label: "£1m to £5m",
                },
                {
                  value: 5,
                  label: "£5m to £30m",
                },
                {
                  value: 6,
                  label: "£30m to £100m",
                },
                {
                  value: 7,
                  label: "£100m +",
                },
              ]}
              label="TTM Revenue range"
            />

            <TextField
              {...formHook.register("ordering")}
              select
              size="small"
              placeholder="Placeholder"
              onChange={(e) => {
                formHook.setValue("ordering", e.target.value);
                handleSearch();
              }}
              value={formHook.watch("ordering")}
              defaultValue={StartupSorting.ProfitDesc}
              InputProps={{
                startAdornment: (
                  <Typography
                    sx={{
                      background: "white",
                      width: { xs: 80, md: 150 },
                      marginRight: 5,
                    }}
                    variant="body2"
                  >
                    Sort By
                  </Typography>
                ),
              }}
              sx={{
                "&.MuiFormControl-root": {
                  border: 0,
                  height: "auto",
                },
                "& .MuiOutlinedInput-root.Mui-focused > .MuiOutlinedInput-notchedOutline":
                  {
                    border: "2px solid",
                    borderColor: "#C72A8E !important",
                    boxShadow:
                      "0px 0px 10px rgba(203, 44, 138, 0.4) !important",
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  background:
                    "linear-gradient(99.95deg, #C32993 -24.43%, #A15AF4 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                },
                "& .MuiSelect-select": {
                  fontWeight: 500,
                  fontSize: 14,
                  paddingRight: 2,
                },
                "& .MuiInputAdornment-root": {
                  padding: "28px 14px",
                  width: 200,
                },
                "& .MuiOutlinedInput-root": {
                  background: "white",
                  height: "100%",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                  "& fieldset": {
                    border: 0,
                    borderRadius: 2,
                  },
                },
                border: "1px solid #F2F2F3",
                borderRadius: 2,
              }}
            >
              {startupSortItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          {isBasicPlan(user) && user.active_role === "buyer" && (
            <Box
              id="pro-member-banner-form"
              className="hidden"
              sx={{
                background: "#6911E7",
                color: "white",
                padding: 1,
                fontWeight: 500,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 2,
              }}
            >
              <LockIcon sx={{ marginRight: 1 }} />
              <Typography
                variant="body1"
                sx={{
                  display: { xs: "none", md: "block" },
                  fontSize: { xs: 12, md: "1rem" },
                }}
              >
                To message sellers and access other popular benefits please
                upgrade to a Pro Buyer member account
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  display: { xs: "block", md: "none" },
                  fontSize: { xs: 12, md: "1rem" },
                }}
              >
                Message sellers today!
              </Typography>
              <Button
                onClick={() => navigate("/plans")}
                variant="roundprimary"
                sx={{ marginLeft: 2, height: 32, fontSize: { xs: 10, md: 14 } }}
              >
                Become a Pro Member
              </Button>
            </Box>
          )}
        </HookedForm>
      ) : (
        <HookedForm id="dashboard-filters" formHook={formHook}>
          <Box
            sx={{
              marginY: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <SearchBar formHook={formHook} handleSearch={handleSearch} />
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item xs={6} display="flex">
                <Button
                  {...formHook.register("is_not_viewed")}
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    background: "white",
                    borderRadius: 2,
                    fontSize: 14,
                    color: "common.black",
                  }}
                  onClick={handleShowNewListings}
                >
                  {!showNewListings ? (
                    <>
                      <Box
                        sx={{
                          height: 15,
                          display: "flex",
                          alignItems: "center",
                          marginRight: 1,
                        }}
                      >
                        {!showNewListings && <TrolleyIcon color="black" />}
                      </Box>
                      New listings
                      {buyerListingsCountUnviewed?.count ? (
                        <Box
                          sx={{
                            marginLeft: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "common.lightgray",
                            fontSize: 12,
                            background: "#EC5962",
                            borderRadius: 2,
                            width: 24,
                            fontWeight: 700,
                            color: "common.white",
                          }}
                        >
                          {buyerListingsCountUnviewed?.count}
                        </Box>
                      ) : null}
                    </>
                  ) : (
                    <>Back to Listings</>
                  )}
                </Button>
              </Grid>
              <Grid item xs={6} display="flex">
                <Button
                  variant="outlined"
                  sx={{
                    display: "flex",
                    padding: 1,
                    width: "100%",
                    background: "white",
                    borderRadius: 2,
                    fontSize: 14,
                    justifyContent: "space-between",
                    color: "common.black",
                  }}
                  onClick={() => handleShowFavourites()}
                >
                  {!showFavourites ? (
                    <>
                      <Box
                        sx={{
                          // width: 15,
                          height: 15,
                          display: "flex",
                          alignItems: "center",
                          marginRight: 1,
                        }}
                      >
                        {!showFavourites && <HeartIcon color="black" />}
                      </Box>
                      Favourites
                      <Box
                        sx={{
                          marginLeft: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "common.lightgray",
                          fontSize: 12,
                          borderRadius: 2,
                          //   width: 24,
                          fontWeight: 700,
                          color: "common.gray",
                        }}
                      >
                        {favourites?.results?.length || 0}
                      </Box>
                    </>
                  ) : (
                    <>Back to Listings</>
                  )}
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                sx={{ ".hub-filter": { justifyContent: "center" } }}
              >
                <HookedSelect
                  // flex="100%"
                  className="hub-filter"
                  {...formHook.register("business_type")}
                  name="business_type"
                  icon={WorkIcon}
                  selection={formHook.watch("business_type")}
                  values={startupTypes?.map((x: any) => ({
                    label: x.name,
                    value: x.id,
                  }))}
                  label="Categories"
                  handleSearch={handleSearch}
                />
              </Grid>
              <Grid item xs={6} display="flex">
                <TextField
                  {...formHook.register("ordering")}
                  select
                  placeholder="Placeholder"
                  onChange={(e) => {
                    formHook.setValue("ordering", e.target.value);
                    handleSearch();
                  }}
                  value={formHook.watch("ordering")}
                  defaultValue={StartupSorting.ProfitDesc}
                  InputProps={{
                    startAdornment: (
                      <Typography
                        sx={{
                          background: "white",
                          // width: 150,
                          marginRight: 5,
                        }}
                        variant="body2"
                      >
                        Sort By
                      </Typography>
                    ),
                  }}
                  sx={{
                    "&.MuiFormControl-root": {
                      border: 0,
                      height: "auto",
                      width: "100%",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused > .MuiOutlinedInput-notchedOutline":
                      {
                        border: "2px solid",
                        borderColor: "#C72A8E !important",
                        boxShadow:
                          "0px 0px 10px rgba(203, 44, 138, 0.4) !important",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      background:
                        "linear-gradient(99.95deg, #C32993 -24.43%, #A15AF4 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    },
                    "& .MuiSelect-select": {
                      fontWeight: 500,
                      fontSize: 14,
                      paddingRight: 2,
                    },
                    "& .MuiInputAdornment-root": {
                      padding: "28px 14px",
                      width: 200,
                    },
                    "& .MuiOutlinedInput-root": {
                      background: "white",
                      height: "100%",
                      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                      "& fieldset": {
                        border: 0,
                        borderRadius: 2,
                      },
                    },
                    border: "1px solid #F2F2F3",
                    borderRadius: 2,
                  }}
                >
                  {startupSortItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Button
              variant="roundgradient"
              size="small"
              sx={{
                fontSize: { xs: 14, md: 16 },
              }}
              onClick={handleSearch}
            >
              Search
            </Button>
            {!isEqual(filter, fromFormToFilter(defaultFilter)) && (
              <Button
                variant="contained"
                onClick={() => handleClearFilter()}
                sx={{
                  boxShadow: 0,
                  color: "common.black",
                  transition: "background 500ms ease-in-out",
                  "&:hover": {
                    color: "white",
                    background:
                      "linear-gradient(99.95deg, #EA336B -24.43%, #6911E7 100%)",
                    boxShadow: "inset 0px 2px 0px rgba(255, 255, 255, 0.1)",
                  },
                  flex: "1 0 0 auto",
                  border: "1px solid #F2F2F3",
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              >
                Clear Filters
              </Button>
            )}
            {isBasicPlan(user) && user.active_role === "buyer" && (
              <Box
                id="pro-member-banner-form"
                className="hidden"
                sx={{
                  background: "#6911E7",
                  color: "white",
                  padding: 1,
                  fontWeight: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 2,
                }}
              >
                <LockIcon sx={{ marginRight: 1 }} />
                <Typography
                  variant="body1"
                  sx={{
                    display: { xs: "none", md: "block" },
                    fontSize: { xs: 12, md: "1rem" },
                  }}
                >
                  To message sellers and access other popular benefits please
                  upgrade to a Pro Buyer member account
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: { xs: "block", md: "none" },
                    fontSize: { xs: 12, md: "1rem" },
                  }}
                >
                  Message sellers today!
                </Typography>
                <Button
                  onClick={() => navigate("/plans")}
                  variant="roundprimary"
                  sx={{
                    marginLeft: 2,
                    height: 32,
                    fontSize: { xs: 10, md: 14 },
                  }}
                >
                  Become a Pro Member
                </Button>
              </Box>
            )}
          </Box>
        </HookedForm>
      )}
    </>
  );
};
