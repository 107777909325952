import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../common/context/AppContext";
import { Header } from "../../components/Header";
import { MarketplaceItem } from "../../components/MarketplaceItem";
import { useStartupTypes } from "../../../common/hooks";
import { RoutePaths } from "../../consts/enums";
import { StartupService } from "../../../common/services/StartupService";

export const YourListings = () => {
  const { user } = useAppContext();
  const { data = [] } = useStartupTypes();
  const navigate = useNavigate();

  const listingItems = user.startups?.map((startup) => {
    return (
      <MarketplaceItem
        key={startup.id}
        editLink={true}
        disableFavorite
        startupTypes={data}
        {...startup}
      />
    );
  });

  const createNewStartup = () => {
    StartupService.CreateStartup().then((startup) => {
      user.startups?.push(startup);
      navigate(`${RoutePaths.REGISTER_SELLER}/${startup.id}/basic`);
    });
  };

  return (
    <Box sx={{ background: "#F8F8F8", minHeight: "100%" }}>
      <Header>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Typography variant="h2" sx={{ fontSize: { xs: 16, md: 24 } }}>
            Edit your listings
          </Typography>
        </Box>
      </Header>
      <Container maxWidth="lg" sx={{ paddingY: 8 }}>
        <Stack
          gap={2}
          sx={{
            position: "relative",
            zIndex: 1,
            justifyContent: "space-between",
            alignItems: { xs: "flex-start", md: "center" },
            marginBottom: "2em",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontSize: "32px", paddingLeft: { xs: 0, md: 4 } }}
          >
            You can create up to 50 listings on this page
          </Typography>

          <Button
            id="create-new-listing"
            variant="roundgradient"
            onClick={() => createNewStartup()}
          >
            Create new listing
          </Button>
        </Stack>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
          }}
        >
          {listingItems}
        </Box>
      </Container>
    </Box>
  );
};
