declare global {
  interface Window {
    Intercom: (value?: string) => void;
  }
}

export const IntercomService = {
  Open: () => {
    if (!window.Intercom) return;
    window.Intercom("show");
  },
};
