import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../common/context/AppContext";
import { UserService } from "../../../common/services/UserService";
import { HookedField } from "../../components/HookedField";
import { HookedForm } from "../../components/HookedForm";
import { RoutePaths } from "../../consts/enums";
import { useGoogleLogin } from "@react-oauth/google";
import { ApiService } from "../../../common/services/ApiService";
import { GoogleIcon } from "../../../common/assets/icons/GoogleIcon";
import { HookedButton } from "../../components/HookedButton";

export const Login = () => {
  const { user, isAuthed, updateUser } = useAppContext();
  const formHook = useForm();
  const navigate = useNavigate();

  if (user && isAuthed) {
    return <Navigate to={RoutePaths.YOU_ACQUISITION} />;
  }

  const handleSubmit = async (values: any) => {
    try {
      const user = await UserService.SignIn(values);
      updateUser(user);

      navigate(RoutePaths.DASHBOARD);
    } catch (e) {}
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      ApiService.Post("/v2/auth/google/", { code: codeResponse.code })
        .then((res) => {
          return UserService.GoogleSignIn(res);
        })
        .then((res) => {
          updateUser(res);
          if (res.active_role) {
            navigate(RoutePaths.DASHBOARD);
            return;
          }

          navigate(RoutePaths.REGISTER + "/basic");
        });
    },
    flow: "auth-code",
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container sx={{ minHeight: "100vh" }}>
        <Grid
          item
          md={6}
          sx={{
            padding: 7,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "common.black",
            backgroundImage: "url(/images/BiggerGradient.png)",
            backgroundSize: "cover",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            component="img"
            src="/images/FoundyLogoMenu.svg"
            sx={{
              width: 98,
              marginBottom: 5,
              position: "absolute",
              top: 56,
              left: 56,
            }}
          />
          <Box sx={{ marginTop: { xs: 6, md: 0 } }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: 30, md: 54 },
                fontWeight: 600,
                color: "white",
              }}
            >
              Sign in to the UK&apos;s leading platform to{" "}
              <span>buy or sell</span> a business
            </Typography>
            <Typography variant="h3" sx={{ color: "white", marginTop: 2 }}>
              Find out why thousands of other business buyers and sellers use
              Foundy for a streamlined, transparent acquisition process
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            padding: 8,
          }}
        >
          <Typography variant="h1" sx={{ marginBottom: 2 }}>
            Sign in to your account
          </Typography>
          <HookedForm formHook={formHook} onSubmit={handleSubmit}>
            <HookedField
              component={TextField}
              name="email"
              variant="filled"
              margin="normal"
              label="Work Email"
              type="email"
              InputLabelProps={{ shrink: true }}
              fullWidth
              autoFocus
            />

            <HookedField
              component={TextField}
              name="password"
              label="Password"
              variant="filled"
              type="password"
              fullWidth
            />
            <Typography
              component="a"
              href="/forgot-password"
              variant="body2"
              sx={{
                display: "block",
                marginTop: 1,
                marginBottom: 2,
                textAlign: "right",
              }}
            >
              Forgot your password?
            </Typography>
            <FormControlLabel
              sx={{
                marginBottom: 2,
                "&.MuiFormControlLabel-root": {
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,

                    "&.MuiCheckbox-root": {
                      color: "black",
                    },
                    "&.MuiTypography-root": {
                      color: "common.gray",
                    },
                  },
                },
              }}
              control={<Checkbox defaultChecked />}
              label="Remember me"
            />
            {/* <Link to={CommonPath.Recover}>
              <Typography component="span">
                {t("auth.forgotPassword")}
              </Typography>{" "}
            </Link> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: 2,
                zIndex: 2,
                position: "relative",
                gap: 2,
              }}
            >
              <HookedButton
                fullWidth
                variant="roundgradient"
                type="submit"
                disableDirty={false}
                circularProgressColor={"common.white"}
              >
                Sign in
              </HookedButton>
              <Button
                fullWidth
                sx={{
                  "&.MuiButtonBase-root": {
                    textTransform: "inherit",
                  },
                  "& span": {
                    marginLeft: 2,
                  },
                }}
                variant="roundoutlinedgradient"
                onClick={() => googleLogin()}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <GoogleIcon />
                  <span>Log in with Google</span>
                </Box>
              </Button>
            </Box>
          </HookedForm>
          <Typography variant="body2" sx={{ color: "common.gray" }}>
            Don&apos;t have an account? <Link to="/register">Sign up</Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
