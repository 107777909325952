import { LabelsKeys } from "./enums";
import {
  CalendarIcon,
  MapIcon,
  MoneyIcon,
  SellIcon,
  TeamIcon,
  TTMIcon,
  WorkIcon,
} from "../../common/assets/icons";
import { PieIcon } from "../../common/assets/icons/PieIcon";
import { IValueItem } from "../../common/models";
import {
  AcquisitionType,
  BuyerSorting,
  StartupSorting,
} from "../../common/types";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";

export const FUNDRAISING_LINK = "https://foundy.com/fundraising";
export const PRICING_LINK = "https://foundy.com/pricing";

export const JOE_CALENDLY_LINK = "https://calendly.com/joe-7yw/30min";

export const LAUNCH_DATE = "19/04/2023";

export const CompanyFields = [
  "industry",
  "type",
  "business_model",
  "growth_opportunity",
  "date_founded",
  "startup_team_size",
  "competitors",
  "reason",
  "annual_revenue",
  "annual_cost",
  "annual_profit",
  "avg_net_revenue_1m",
  "avg_net_cost_1m",
  "avg_net_profit_1m",
  "key_assets_new",
  "founders_post_acquisition",
  "weekly_commitment",
  "description",
  "asking_price",
  "reason_for_ask_price",
  "created_at",
  // "country",
];

export const BuyerFields = [
  "acquisitions_previously",
  "acquisitions_projected",
  "role",
  "startup_types",
  "buyer_location",
  "debt_finance",
  "trailing_profit",
  "trailing_revenue",
  "valuation",
  "description",
  "buyer_type",
];

export const Locations = {
  GLOBAL: "Global",
  UNITED_KINGDOM: "United Kingdom",
  EUROPE: "Europe",
  NORTH_AMERICA: "North America",
  ASIA_PACIFIC: "Asia Pacific",
};
export type LocationKeys = keyof typeof Locations;

export const LocationsOptions = Object.entries(Locations).map(
  ([key, value]) => ({
    value: key,
    label: value,
  }),
);

export const BudgeOptions = [
  { label: "£10k+", value: "1" },
  { label: "£100k+", value: "2" },
  { label: "£1m+", value: "3" },
  { label: "£5m+", value: "4" },
];
export const ProfitOptions = [
  { label: "£10k+", value: "1" },
  { label: "£100k+", value: "2" },
  { label: "£1m+", value: "3" },
  { label: "£5m+", value: "4" },
  {
    label: "Open to hearing about loss making businesses for sale",
    value: "5",
  },
];

export enum DebtFinanceOption {
  Yes = "YES",
  No = "NO",
  Undecided = "UNDECIDED",
}

export const LabelsKeysMap = new Map<
  string,
  { label: string; icon: any; smallLabel?: string }
>([
  [LabelsKeys.STARTUP_TEAM_SIZE, { label: "Team Size", icon: TeamIcon }],
  [LabelsKeys.ACQUISITION_TYPE, { label: "Acquisition Type", icon: PieIcon }],
  [LabelsKeys.ANNUAL_PROFIT, { label: "Annual Profit", icon: MoneyIcon }],
  [
    LabelsKeys.CLV,
    { label: "CLV - Customer Value x Lifespan", icon: MoneyIcon },
  ],
  [
    LabelsKeys.ANNUAL_REVENUE,
    {
      label: "TTM revenue",
      smallLabel: "TTM Revenue",
      icon: TTMIcon,
    },
  ],
  [LabelsKeys.ASKING_PRICE, { label: "Asking Price", icon: SellIcon }],
  [LabelsKeys.CREATED_AT, { label: "Business Created", icon: CalendarIcon }],
  [LabelsKeys.DATE_FOUNDED, { label: "Founded", icon: CalendarIcon }],
  [LabelsKeys.MIN_REVENGE_RANGE, { label: "Min. Revenue", icon: MoneyIcon }],
  [LabelsKeys.STARTUP_TYPES, { label: "Category preferences", icon: WorkIcon }],
  [LabelsKeys.VALUATION, { label: "Budget", icon: MapIcon }],
  [LabelsKeys.LOCATION, { label: "Location", icon: MapIcon }],
  [LabelsKeys.COUNTRY, { label: "Location", icon: MapIcon }],
  [
    LabelsKeys.TTM_MULTIPLIER,
    { label: "TTM Revenue multiple", icon: WorkIcon },
  ],
  [LabelsKeys.TRAILING_REVENUE, { label: "Min. revenue", icon: MoneyIcon }],
]);

export const buyerToSellerTemplate =
  "Hi, it's great to connect! I came across your business on the marketplace and it seems like a good fit for my interests. I'd love to learn more about the business and explore a potential acquisition. To get the due diligence process underway, it would be great if you could answer a few initial questions. I'm happy to sign an NDA if you'd prefer. I'm also happy to answer any questions you might have for me! Thanks in advance and look forward to discussing this further.";

export enum StartupsFilterField {
  search = "search",
  type = "type",
  price = "price",
  annualProfit = "annualProfit",
  annualRecurringRevenue = "annualRecurringRevenue",
  startupAge = "startupAge",
  customers = "customers",
}

export enum BuyersFilterField {
  search = "search",
  type = "type",
  location = "location",
  valuation = "valuation",
}

export type Range = [number, number];

export type TStartupsFormFields = {
  search: string;
  business_type: number[];
  price: Range;
  annualProfit: Range;
  annualRecurringRevenue: Range;
  startupAge: Range;
  customers: number;
  ordering: string;
  acquisition_type: AcquisitionType;
};

export type TBuyersFormFields = {
  search: string;
  type: number[];
  location: string;
  valuation: Range;
  acquisition_type: AcquisitionType;
  ordering: BuyerSorting;
};

export const startupsFieldLabels = {
  search: t("marketplace.search"),
  startupType: t("marketplace.startupType"),
  price: t("marketplace.price"),
  annualProfit: t("marketplace.annualProfit"),
  annualRecurringRevenue: t("marketplace.annualRecurringRevenue"),
  startupAge: t("marketplace.startupAge"),
  customers: t("marketplace.numberOfCustomers"),
  ordering: t("marketplace.sortBy"),
  acquisitionType: t("app.acquisitionType"),
};

export const buyersFieldLabels = {
  search: t("marketplace.search"),
  startupType: t("marketplace.startupType"),
  ordering: t("marketplace.sortBy"),
  location: t("marketplace.location"),
  valuation: t("marketplace.valuation"),
  acquisitionType: t("app.acquisitionType"),
};

export const Label = styled(Typography)({
  marginBottom: 5,
  color: "#7A7A7A",
  fontWeight: 600,
  fontSize: 14,
  flex: 1,
});

export const Value = styled(Typography)({
  color: "#00004B",
  fontWeight: 600,
  fontSize: 16,
});

export const CardColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
});

const M = Math.pow(10, 6);

export const sliderMax = {
  [StartupsFilterField.price]: 50 * M,
  [StartupsFilterField.annualProfit]: 5 * M,
  [StartupsFilterField.annualRecurringRevenue]: 5 * M,
  [StartupsFilterField.startupAge]: 20,
};

export const startupSortItems: IValueItem[] = [
  { text: "Price: Lowest first", value: StartupSorting.PriceAsc },
  { text: "Price: Highest first", value: StartupSorting.PriceDesc },
  { text: "Annual profit: Lowest first", value: StartupSorting.ProfitAsc },
  { text: "Annual profit: Highest first", value: StartupSorting.ProfitDesc },
  {
    text: "Number of employees: Lowest first",
    value: StartupSorting.EmployeesAsc,
  },
  {
    text: "Number of employees: Highest first",
    value: StartupSorting.EmployeesDesc,
  },
  { text: "Creation Date: Newest first", value: StartupSorting.DateDesc },
  { text: "Creation Date: Oldest first", value: StartupSorting.DateAsc },
];

export const buyerSortItems: IValueItem[] = [
  { text: "Budget: Lowest first", value: BuyerSorting.ValuationAsc },
  { text: "Budget: Highest first", value: BuyerSorting.ValuationDesc },
  { text: "Creation Date: Newest first", value: BuyerSorting.DateDesc },
  { text: "Creation Date: Oldest first", value: BuyerSorting.DateAsc },
];

export const experienceRange: IValueItem[] = [
  { text: "0-3", value: 1 },
  { text: "3-10", value: 2 },
  { text: "10-20", value: 3 },
  { text: "20+", value: 4 },
];

export const getMax = (
  v: number | undefined,
  max: number,
): number | undefined => {
  return v === max ? undefined : v;
};
