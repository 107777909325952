import { DateTime } from "luxon";
import { useModal } from "mui-modal-provider";
import { useEffect } from "react";
import { SellerStartModal } from "../../../components/SellerStartModal";
import { useAppContext } from "../../../../common/context/AppContext";
import { IUserFull } from "../../../../common/models";

const userJoinedToday = (user: IUserFull) =>
  user.date_joined && DateTime.fromISO(user.date_joined).diffNow().days === 0;

const WelcomeSellerModal = () => {
  const { user } = useAppContext();
  const modalId = `shownWelcomeSellerModal-${user.id}`;

  const { showModal } = useModal();

  const newSellerModal = () => {
    const modal = showModal(SellerStartModal, {
      onCancel: () => {
        modal.hide();
      },
    });
  };

  useEffect(() => {
    if (!user) return;

    const checkModal = localStorage.getItem(modalId);
    if (!checkModal && userJoinedToday(user)) {
      newSellerModal();
      localStorage.setItem(modalId, "true");
    }
  }, [user]);

  return <></>;
};

export default WelcomeSellerModal;
